import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updatePageTitle, clearPageTitle } from '../redux/actions/page-actions';

const useSetPageTitle = pageTitle => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePageTitle(pageTitle));
        return () => {
            dispatch(clearPageTitle());
        };
    }, [dispatch, pageTitle]);
};

// Component to use in class page components
export const PageTitle = ({ title }) => {
    useSetPageTitle(title);
    return null;
};

PageTitle.propTypes = {
    title: PropTypes.string,
};

export default useSetPageTitle;
