import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Transition from 'react-transition-group/Transition';
import { translate } from '@mspecs/shared-utils';

/* eslint-disable no-use-before-define, no-var, vars-on-top, react/no-did-mount-set-state */

export const SIDEBAR_CONSTANTS = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};

let sidebarRoot;

/*
    For more information on portals:
    https://reactjs.org/docs/portals.html
*/
class MspxSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.dismissed = false;
        this.container = document.createElement('div');
        sidebarRoot = sidebarRoot || document.getElementById('sidebarPortal');
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        sidebarRoot.appendChild(this.container);

        this.setState({
            show: this.props.show,
        });
    }

    UNSAFE_componentWillReceiveProps({ show }) {
        if (show !== this.state.show) {
            this.setState({ show });
            document.body.classList.add('sidebarOpen');
        }
    }

    componentWillUnmount() {
        this.removeDomNode();
    }

    onExit = () => {
        if (this.dismissed) {
            this.props.onDismissed();
            document.body.classList.remove('sidebarOpen');
        }
    };

    dismiss = () => {
        this.dismissed = true;
        this.setState({
            show: false,
        });
        document.body.classList.remove('sidebarOpen');
    };

    // Called after the exit transition has finished
    removeDomNode = () => {
        if (this.container.parentNode) {
            sidebarRoot.removeChild(this.container);
        }
    };

    renderHeader = () => {
        const HeaderIcon =
            this.props.placement === SIDEBAR_CONSTANTS.RIGHT
                ? HeaderIconRight
                : HeaderIconLeft;

        return this.props.withoutHeader ? null : (
            <Header className="slide-menu-header">
                <h4>{this.props.t(this.props.headerText)}</h4>
                <HeaderIcon title="Stäng" onClick={this.dismiss}>
                    <i
                        className={`fa fa-angle-${this.props.placement.toLowerCase()}`}
                    />
                </HeaderIcon>
            </Header>
        );
    };

    renderStyledRoot = () => {
        const Root =
            this.props.placement === SIDEBAR_CONSTANTS.RIGHT
                ? StyledRootRight
                : StyledRootLeft;

        return (
            <Transition
                timeout={250}
                in={this.state.show}
                onExited={this.onExit}
                unmountOnExit={this.props.unmountOnExit}
            >
                {status => (
                    <Root status={status}>
                        {this.renderHeader()}
                        {this.props.children}
                    </Root>
                )}
            </Transition>
        );
    };

    render() {
        return ReactDOM.createPortal(this.renderStyledRoot(), this.container);
    }
}

export default translate(MspxSidebar);

MspxSidebar.propTypes = {
    t: PropTypes.func,
    show: PropTypes.bool,
    onDismissed: PropTypes.func,
    headerText: PropTypes.string,
    withoutHeader: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    children: PropTypes.element.isRequired,
    placement: PropTypes.oneOf([
        SIDEBAR_CONSTANTS.LEFT,
        SIDEBAR_CONSTANTS.RIGHT,
    ]),
};

MspxSidebar.defaultProps = {
    show: false,
    headerText: '',
    onDismissed: () => {},
    unmountOnExit: false,
    withoutHeader: false,
    placement: SIDEBAR_CONSTANTS.RIGHT,
};

/* eslint-disable */
var StyledRoot = styled('div')`
    top: 0;
    display: grid;
    height: 100%;
    width: 480px;
    z-index: 1200;
    position: fixed;
    background-color: #fff;
    backface-visibility: hidden;
    border-top-left-radius: 4px;
    grid-template-rows: auto 1fr;
    border-bottom-left-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
`;

var StyledRootLeft = styled(StyledRoot)`
    transition: all 0.25s;
    left: ${props => (props.status === 'entered' ? 0 : '-550px')};
`;

var StyledRootRight = styled(StyledRoot)`
    transition: all 0.25s;
    right: ${props => (props.status === 'entered' ? 0 : '-550px')};
`;

var Header = styled('div')`
    padding: 16px 0;
    text-align: center;
    font-weight: normal;
    background-color: #424551;

    h4 {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
    }
`;

var HeaderIcon = styled('div')`
    top: 0;
    color: #ccc;
    z-index: 1000;
    font-size: 26px;
    cursor: pointer;
    position: absolute;
    display: inline-block;
    padding: 11px;
    background-color: #424551;

    i {
        width: 30px;
        height: 25px;
        display: block;
        font-size: 20px;
        border-radius: 2px;
        font-weight: bolder;
        border: solid 2px ${({ theme }) => theme.colors.borderSecondary};
        border-right: solid 8px ${({ theme }) => theme.colors.borderSecondary};
    }
`;

var HeaderIconLeft = styled(HeaderIcon)`
    right: -45px;
    border-radius: 0 4px 4px 0;
`;

var HeaderIconRight = styled(HeaderIcon)`
    left: -45px;
    border-radius: 4px 0 0 4px;
`;
