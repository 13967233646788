import React from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { and, optionIs, rankWith } from '@jsonforms/core';
import { withJsonFormsArrayControlProps } from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { MultiSelect } from '../../../../input';

const Container = styled(Flex)`
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: relative;
    flex: 1;
    overflow: auto;
`;

const DropdownControl = props => {
    const {
        addItem,
        data,
        path,
        schema,
        handleChange,
        removeItems,
        rootSchema,
    } = props;

    const translationPrefix =
        schema?.metaProperties?.translationPrefix ??
        rootSchema?.metaProperties?.translationPrefix;

    const enums = schema?.properties?.name?.enum ?? [];

    const options = enums.map(el => {
        return {
            value: el,
            label: (translationPrefix ?? '') + el,
        };
    });

    const handleMultiSelectChange = selectedOptions => {
        // deselect all
        if (!selectedOptions) {
            return handleChange(path, []);
        }
        if (data) {
            // select all, checks for old values so we don't accidentally set everything to yes.
            if (selectedOptions.length === enums.length) {
                const oldAmenities = data;
                let newData = selectedOptions.map(element => ({
                    name: element,
                    available: true,
                }));
                newData = newData.map(el => {
                    const oldAmenity = oldAmenities.find(
                        el2 => el2.name === el.name
                    );
                    return oldAmenity ? oldAmenity : el;
                });
                return handleChange(path, newData);
            }

            // remove item from array
            if (selectedOptions.length < data.length) {
                const indexToRemove = data
                    .map(el => el.name)
                    .filter(name => !selectedOptions.includes(name))
                    .map(name => data.findIndex(el => el.name === name));

                return removeItems(path, [indexToRemove])();
            }

            // add new item
            const newItemName = selectedOptions.find(
                el => !data.find(el2 => el2.name === el)
            );
            addItem(path, {
                name: newItemName,
                available: true,
            })();
        } else {
            if (selectedOptions.length === 1) {
                return addItem(path, {
                    name: selectedOptions[0],
                    available: true,
                })();
            } else {
                // add all alternatives if data begins as empty.
                let newData = selectedOptions.map(element => ({
                    name: element,
                    available: true,
                }));
                return handleChange(path, newData);
            }
        }
    };

    return (
        <Container flexDirection="row">
            <Box width={0.45}>
                <MultiSelect
                    value={
                        data
                            ? data.filter(el => el !== null).map(el => el.name)
                            : []
                    }
                    options={options}
                    onChange={e => handleMultiSelectChange(e)}
                    search
                ></MultiSelect>
            </Box>
        </Container>
    );
};
export const dropdownControlTester = rankWith(
    3,
    and(optionIs('DropdownControl', true))
);

export default withVanillaControlProps(
    withJsonFormsArrayControlProps(DropdownControl)
);
