import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button, { BUTTON_TYPES } from '../../button/button-component';
import { ConfirmationModal } from '../../modal';
import { useTranslate } from '@mspecs/shared-utils';

const Toolbar = styled(Flex)`
    border-top: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    position: relative;
    min-height: 68px;
    margin: 0 ${({ theme }) => theme.spacing.default};
`;
const ToolbarButtons = styled(Flex)`
    padding: 1rem;
    justify-content: space-between;
`;
const DeleteButton = styled(Button)``;
const SaveButton = styled(Button)`
    margin-left: auto;
`;

const ImageEditorToolbar = ({
    onSubmit,
    onDelete,
    isSubmitActive,
    children,
    ...rest
}) => {
    const { t } = useTranslate();
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

    return (
        <Toolbar {...rest}>
            <ToolbarButtons
                width="100%"
                justifyContent="center"
                alignItems="center"
            >
                {Boolean(onDelete) && (
                    <DeleteButton
                        onClick={() => setIsConfirmingDeletion(true)}
                        buttonType={BUTTON_TYPES.TEXT}
                    >
                        {t('REMOVE')}
                    </DeleteButton>
                )}

                {isSubmitActive && (
                    <SaveButton
                        onClick={onSubmit}
                        buttonType={BUTTON_TYPES.PRIMARY}
                    >
                        {t('SAVE')}
                    </SaveButton>
                )}
                {children}
            </ToolbarButtons>
            <ConfirmationModal
                headerText={t('DELETE_IMAGE_HEADER')}
                isOpen={isConfirmingDeletion}
                onConfirm={() => {
                    onDelete();
                    setIsConfirmingDeletion(false);
                }}
                infoText={t('DELETE_IMAGE_BODY')}
                onCancel={() => setIsConfirmingDeletion(false)}
            />
        </Toolbar>
    );
};

ImageEditorToolbar.propTypes = {
    isSubmitActive: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    children: PropTypes.node,
};
export default ImageEditorToolbar;
