import React from 'react';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import ModalHeaderIcon from './header/modal-header-icon-component';
import { useIsMobile } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import { Header3Text } from '../typograph/typograph-components';
import { useTranslate } from '@mspecs/shared-utils';
import ConfirmationModal from './confirmation-modal-component';
import { BUTTON_TYPES } from '../button';

const StyledIcon = styled(ModalHeaderIcon)`
    color: ${({ theme }) => theme.colors.redPrimary};
    background: ${({ theme }) => theme.colors.red10};
`;

const MobileWrapper = styled(Flex)`
    flex-direction: column;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const MobileIcon = styled(StyledIcon)`
    height: 140px;
    width: 140px;
    min-width: 140px;
    font-size: 81px;
`;
const MobileText = styled(Header3Text)`
    padding-top: ${({ theme }) => theme.spacing.large};
    text-align: center;
`;

const ConfirmDeleteModal = ({ infoText, headerText, ...props }) => {
    const isMobile = useIsMobile();
    const { t } = useTranslate();
    return (
        <ConfirmationModal
            label="REMOVE"
            buttonType={BUTTON_TYPES.DANGER}
            headerIcon={
                !isMobile ? <StyledIcon icon={faTrashCan} /> : undefined
            }
            headerText={!isMobile ? headerText : ''}
            infoText={!isMobile ? infoText : undefined}
            {...props}
        >
            {isMobile && (
                <MobileWrapper>
                    <MobileIcon icon={faTrashCan} />
                    <MobileText>{t(infoText)}</MobileText>
                </MobileWrapper>
            )}
        </ConfirmationModal>
    );
};

export default ConfirmDeleteModal;
