import PropTypes from 'prop-types';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTheme } from '@emotion/react';
import SubTableRow from './sub-table-row-component';

const SubTableDndContent = ({ rows, rowProps, droppableProps = {} }) => {
    const theme = useTheme();
    const getItemStyle = style => ({
        userSelect: 'none',
        ...style,
        position: 'static',
    });
    const getListStyle = ({ isDraggingOver, isUsingPlaceholder }) => ({
        background: isDraggingOver ? theme.colors.borderPrimary : 'transparent',
        minHeight: isUsingPlaceholder ? '2rem' : '1px',
    });

    return (
        <Droppable type="sub-group" isCombineEnabled {...droppableProps}>
            {(droppableProvided, droppabeSnapshot) => (
                <div
                    className="rt-tbody"
                    ref={droppableProvided.innerRef}
                    style={getListStyle(droppabeSnapshot)}
                    {...droppableProvided.droppableProps}
                >
                    {rows.map((row, index) => (
                        <Draggable
                            key={row.original.id}
                            draggableId={row.original.id}
                            index={row.index}
                        >
                            {(provided, snapshot) => {
                                // this is for fixing dragging on modal
                                if (snapshot.isDragging) {
                                    provided.draggableProps.style.left =
                                        undefined;
                                    provided.draggableProps.style.top =
                                        undefined;
                                }
                                return (
                                    <div
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        style={getItemStyle(
                                            provided.draggableProps.style,
                                            snapshot
                                        )}
                                    >
                                        <SubTableRow
                                            cellProps={{
                                                dragHandleProps:
                                                    provided.dragHandleProps,
                                                isSomethingDragging:
                                                    snapshot.isDraggingOver,
                                            }}
                                            isRowDragEnabled
                                            isDragging={snapshot.isDragging}
                                            isFirst={index === 0}
                                            row={row}
                                            {...rowProps}
                                        />
                                    </div>
                                );
                            }}
                        </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

SubTableDndContent.propTypes = {
    rows: PropTypes.array,
    rowProps: PropTypes.object,
    droppableProps: PropTypes.shape({
        droppableId: PropTypes.string.isRequired,
        isCombineEnabled: PropTypes.bool,
        isDropDisabled: PropTypes.bool,
    }),
};

export default SubTableDndContent;
