import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { TabContext } from './tab-nav-provider-component';
import { StyledTabButton, TabsOverflowContainer } from './tab-nav-styles';
import {
    useTranslate,
    useWindowSize,
    useIntersectionObserver,
} from '@mspecs/shared-utils';
import { Button } from '../../button';
import { MenuPopover } from '../../popovers';

const TabButton = props => {
    const { tabStyle, isActiveFn } = useContext(TabContext);
    const isActive = isActiveFn(props);

    return (
        <StyledTabButton isActive={isActive} tabStyle={tabStyle} {...props} />
    );
};

const TabsContainer = styled(Flex)`
    flex-direction: row;
    margin-left: ${({ isSidebar }) => isSidebar && '12px'};
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.medium}) {
        background: ${({ theme }) => theme.colors.bgPrimary};
    }
`;

const StyledIcon = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => !['isActive'].includes(prop),
})`
    font-size: 20px;
    color: ${({ theme: { colors }, isActive }) =>
        isActive ? colors.primaryColor : colors.textSecondary};
    padding-left: 5px;
`;

export const TabDropdown = ({ options }) => {
    return (
        <MenuPopover options={options} placement="bottom-end" smallMenu>
            <span
                tabIndex={-1}
                style={{ marginLeft: '10px', boxShadow: 'none' }}
            >
                <Button
                    css={theme => css`
                        margin-top: 5px;
                        padding: 13px;
                        border: 1px solid ${theme.colors.borderPrimary};
                        border-bottom: 0;
                        border-radius: 3px 3px 3px 0;
                    `}
                >
                    <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                        style={{ fontSize: '14px' }}
                    />
                </Button>
            </span>
        </MenuPopover>
    );
};

const defaultTabLabelRenderer = ({ label, icon, isActive }) => (
    <>
        {label}
        {icon && <StyledIcon icon={icon} isActive={isActive} />}
    </>
);

const TabNavMenu = ({
    className,
    items,
    currentTab,
    hiddenTabsEnabled,
    renderTabLabel = defaultTabLabelRenderer,
}) => {
    const { t } = useTranslate();
    const ref = useRef();
    const tabWidthRef = useRef();
    const { width: windowWidth } = useWindowSize();
    const { onChange, isActiveFn } = useContext(TabContext);
    const isCurrentTab = ({ props }) => isActiveFn(props);
    const [noOfVisibleTabs, setNoOfVisibleTabs] = useState(0);
    const [inView] = useIntersectionObserver(ref, { threshold: 0 });
    const listOfTabs = ref.current ? Array.from(ref.current.children) : [];

    useEffect(() => {
        if (hiddenTabsEnabled) {
            const tabNavWidth =
                tabWidthRef.current.getBoundingClientRect().width - 47;
            let count = 0;
            let tabWidth = 0;
            for (let item of listOfTabs) {
                tabWidth += item.getBoundingClientRect().width;
                if (tabWidth <= tabNavWidth) {
                    count++;
                }
            }

            setNoOfVisibleTabs(count);
        }
    }, [windowWidth, inView, listOfTabs]);

    const hiddenTabs = useMemo(() => {
        const itemsArray = React.Children.toArray(items);
        const currentTabIsHidden = itemsArray
            .slice(noOfVisibleTabs)
            .find(isCurrentTab);

        return itemsArray
            .slice(currentTabIsHidden ? noOfVisibleTabs - 1 : noOfVisibleTabs)
            .filter(item => item.props.path !== '*' && item.props.tablabel)
            .filter(item => !isCurrentTab(item));
    }, [noOfVisibleTabs, currentTab]);

    const hiddenTabOptions = useMemo(
        () =>
            hiddenTabs.map(({ props }) => ({
                name: props.shortlabel || props.tablabel,
                onClick: () => onChange(props),
            })),
        [hiddenTabs]
    );

    const isActive = tab => {
        const itemsArray = React.Children.toArray(items);
        const activeTab = itemsArray.filter(item => isCurrentTab(item));
        return activeTab?.[0]?.props.tablabel === tab.props.tablabel;
    };

    const isSmallScreen = useMemo(() => windowWidth <= 640, [windowWidth]);

    const showHiddenTabs = hiddenTabsEnabled && hiddenTabs.length > 0;

    return (
        <TabsContainer className={className} ref={tabWidthRef}>
            <TabsOverflowContainer ref={ref}>
                {items
                    .filter(x => x && x.props.path !== '*')
                    .map((item, index) => {
                        return (
                            <TabButton
                                key={index}
                                className={isActive(item) ? 'active' : ''}
                                {...item.props}
                                isvisible={
                                    hiddenTabsEnabled &&
                                    (!hiddenTabs.find(
                                        ({ props: { tablabel } }) =>
                                            tablabel === item.props.tablabel
                                    )).toString()
                                }
                                onClick={() => onChange(item.props)}
                            >
                                {renderTabLabel({
                                    label:
                                        isSmallScreen && !!item.props.shortlabel
                                            ? t(item.props.shortlabel)
                                            : t(item.props.tablabel),
                                    icon: item.props.tabIcon,
                                    isActive: isActive(item),
                                })}
                            </TabButton>
                        );
                    })}
            </TabsOverflowContainer>
            {showHiddenTabs && <TabDropdown options={hiddenTabOptions} />}
        </TabsContainer>
    );
};

TabNavMenu.propTypes = {
    className: PropTypes.any,
    currentTab: PropTypes.object,
    items: PropTypes.array,
    hiddenTabsEnabled: PropTypes.bool,
    renderTabLabel: PropTypes.func,
};

export default TabNavMenu;
