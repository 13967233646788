import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import React from 'react';

const Row = styled(Flex)`
    ${({ includeBorder, theme }) =>
        includeBorder &&
        `border-bottom: solid 1px ${theme.colors.borderPrimary};`}
`;

const Label = styled.label`
    width: 40%;
`;

const LabelizedInfo = ({
    label,
    info,
    includeBorder = false,
    labelProps = {},
    children,
    ...props
}) => {
    return (
        <Row includeBorder={includeBorder} {...props}>
            <Label {...labelProps}>{label}</Label>
            {children ? children : <span>{info}</span>}
        </Row>
    );
};

LabelizedInfo.propTypes = {
    label: PropTypes.string.isRequired,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.any,
    includeBorder: PropTypes.bool,
    labelProps: PropTypes.object,
};

export default LabelizedInfo;
