import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { SideNav, SideNavItem } from '../../navigation';

const StyledSideNavHeader = styled.h4`
    background-color: ${({ theme }) => theme.colors.contrastBg75};
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 19px 15px;
    margin: 0;
    letter-spacing: 0.02rem;
    max-width: 200px;
    width: 200px;
    height: 52px;

    @media (max-width: ${({ theme }) => theme.large}) {
        display: none;
    }
`;

const StyledSideNav = styled(SideNav)`
    flex: 1;

    div:first-of-type {
        margin-top: 0;
    }
`;

const FullScreenModalSideNav = ({
    selectedTab,
    setSelectedTab,
    tabs,
    header,
}) => {
    const { t } = useTranslate();

    return (
        <>
            <StyledSideNavHeader>{t(header)}</StyledSideNavHeader>
            <StyledSideNav active={selectedTab} onClick={setSelectedTab}>
                {navProps => (
                    <React.Fragment>
                        {tabs.map((tab, index) => (
                            <SideNavItem
                                title={tab.title}
                                {...navProps}
                                key={`${tab.title}_${index}`}
                            />
                        ))}
                    </React.Fragment>
                )}
            </StyledSideNav>
        </>
    );
};

FullScreenModalSideNav.propTypes = {
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    header: PropTypes.string,
};

export default FullScreenModalSideNav;
