import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { ImageProp } from '../image.props';
import { useTranslate } from '@mspecs/shared-utils';

const FileInfo = styled(Flex)`
    flex-direction: column;
    margin-bottom: 10px;
`;
const FileInfoText = styled(Flex)`
    align-items: center;
    padding-right: 10px;
`;

const MetaLabel = styled.label`
    color: #444;
    font-weight: 700;
`;

const ImageEditorFileInfo = ({ image }) => {
    const { t } = useTranslate();

    return (
        <Flex flexDirection="column" px={2}>
            {image.originalResolution && (
                <FileInfo>
                    <MetaLabel>{t('IMAGE_EDITOR_IMAGE_SIZE')}</MetaLabel>
                    <FileInfoText>
                        {`${image.originalResolution} px`}
                    </FileInfoText>
                </FileInfo>
            )}
            <FileInfo>
                <MetaLabel>{t('SIZE')}</MetaLabel>
                <FileInfoText>{prettyBytes(image.fileSize)}</FileInfoText>
            </FileInfo>
        </Flex>
    );
};

ImageEditorFileInfo.propTypes = {
    image: ImageProp,
};
export default ImageEditorFileInfo;
