import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MspxPopover from './mspx-popover-component';
import CloseButton from './cross-icon-button-component';
import { translate } from '@mspecs/shared-utils';

const Text = styled.span`
    margin: 0;
    font-size: 14px;
`;

const Text2 = styled(Text)``;

const Link = styled.a`
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
`;

const NewsPopover = props => {
    const { headline, text, text2, href, link, children, placement, t } = props;
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(function () {
            setVisible(false);
        }, 7000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <MspxPopover
            content={
                <>
                    <CloseButton onClick={() => setVisible(false)} />
                    <h4>{t(headline)}</h4>
                    <Text>{t(text)}</Text>
                    <Link href={href} target="blank">
                        {t(link)}
                    </Link>
                    <Text2>{t(text2)}</Text2>
                </>
            }
            arrow={true}
            interactive={true}
            hideOnClick={false}
            maxWidth={250}
            placement={placement}
            showOnCreate={true}
            visible={visible}
        >
            {children}
        </MspxPopover>
    );
};

NewsPopover.propTypes = {
    arrow: PropTypes.bool,
    headline: PropTypes.string,
    href: PropTypes.string,
    interactive: PropTypes.bool,
    link: PropTypes.string,
    placement: PropTypes.string,
    t: PropTypes.func,
    text: PropTypes.string,
    text2: PropTypes.string,
};

NewsPopover.defaultProps = {
    arrow: false,
    headline: '',
    href: '',
    interactive: false,
    link: '',
    placement: 'top',
    t: x => x,
    text: '',
    text2: '',
};

export default translate(NewsPopover);
