import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { AddButton } from '../../button';

const FileUploadButton = ({ onUpload, accept = 'image/*' }) => {
    const inputRef = useRef();

    return (
        <React.Fragment>
            <AddButton onClick={() => inputRef.current.click()} />
            <input
                ref={inputRef}
                hidden
                type="file"
                accept={accept}
                onChange={e => onUpload(Object.values(e.target.files))}
                multiple
                style={{ display: 'none' }}
            />
        </React.Fragment>
    );
};

FileUploadButton.propTypes = {
    onUpload: PropTypes.func,
    accept: PropTypes.string,
};
export default FileUploadButton;
