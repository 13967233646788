import React from 'react';
import styled from '@emotion/styled';

/**
 * Visually hidden content, visible for screen readers
 * https://www.sarasoueidan.com/blog/accessible-icon-buttons/#technique-1-accessible-visually-hidden-text
 */
const HiddenContent = styled.span`
    &:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
`;

export default HiddenContent;
