import { ac } from '@mspecs/shared-utils';
import {
    notificationDeleteSuccess,
    notificationUpdateError,
    notificationUpdateSuccess,
    notificationCreateSuccess,
} from '~/utils/notification-utils';
import {
    fetchSession,
    fetchSessionAdminOrgUnits,
    fetchOrganizationUnits,
} from './session-actions';
import { legacyApi, arrayToObj } from '@mspecs/shared-utils';

export const ACTION_TYPES = {
    GET_MEMO_PADS_SUCCESS: 'GET_MEMO_PADS_SUCCESS',
    DELETE_MEMO_PADS_SUCCESS: 'DELETE_MEMO_PADS_SUCCESS',
    UPDATE_MEMO_PADS_SUCCESS: 'UPDATE_MEMO_PADS_SUCCESS',
    CREATE_MEMO_PADS_SUCCESS: 'CREATE_MEMO_PADS_SUCCESS',
};

const successGetMemoPads = memoPads =>
    ac(ACTION_TYPES.GET_MEMO_PADS_SUCCESS, { memoPads });

const successDeleteMemoPad = memoPadId =>
    ac(ACTION_TYPES.DELETE_MEMO_PADS_SUCCESS, { memoPadId });

const successUpdateMemoPad = memoPad =>
    ac(ACTION_TYPES.UPDATE_MEMO_PADS_SUCCESS, { memoPad });

const successCreateMemoPad = memoPad =>
    ac(ACTION_TYPES.CREATE_MEMO_PADS_SUCCESS, { memoPad });

const api = id => legacyApi(`/memoPad${id ? `/${id}` : ''}`);

export function getMemoPads() {
    return function (dispatch, getState) {
        return Promise.all([
            api()
                .get()
                .then(memoPads => {
                    dispatch(successGetMemoPads(arrayToObj(memoPads)));
                }),
            dispatch(fetchSession()),
            dispatch(fetchSessionAdminOrgUnits()),
        ]).then(() => {
            const state = getState();
            return dispatch(fetchOrganizationUnits(state.session.id));
        });
    };
}

export function deleteMemoPad(memoPadId) {
    return dispatch =>
        api(memoPadId)
            .delete({ memoPadId })
            .then(() => {
                notificationDeleteSuccess(dispatch);
                dispatch(successDeleteMemoPad(memoPadId));
            })
            .catch(() => {
                notificationUpdateError(dispatch);
            });
}

export function createMemoPad(memoPad) {
    return dispatch =>
        api()
            .post(memoPad)
            .then(newMemoPad => {
                notificationCreateSuccess(dispatch);
                dispatch(successCreateMemoPad(newMemoPad));
            })
            .catch(() => {
                notificationUpdateError(dispatch);
            });
}

export function updateMemoPad(id, data) {
    return dispatch =>
        api(id)
            .put(data)
            .then(newMemoPad => {
                notificationUpdateSuccess(dispatch);
                dispatch(successUpdateMemoPad(newMemoPad));
            })
            .catch(() => {
                notificationUpdateError(dispatch);
            });
}
