import React from 'react';
import { rankWith, optionIs, Paths } from '@jsonforms/core';
import { withJsonFormsArrayControlProps } from '@jsonforms/react';
import styled from '@emotion/styled';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellPropType } from './cell-prop-types';
import { useTranslate } from '@mspecs/shared-utils';
import { RadioButton, RadioButtonGroup } from '../../../input';

const Wrapper = styled.div`
    overflow-y: auto;
    height: 100%;
    max-height: 600px;
`;

const RadioButtonGroupContainer = styled.div`
    margin-left: auto;
`;

const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TextWrapper = styled.div`
    margin-left: 5%;
`;

export function RadioGroupCell(props) {
    const { t } = useTranslate();
    const {
        data,
        path,
        handleChange,
        removeItems,
        schema,
        uischema,
        rootSchema,
    } = props;

    // Manipulate 'available' in backend/publishingProxy when necessary, need to have this sort of structure to allow yes/no/undefined radio buttons.
    const handleRadioChange = (value, index) => {
        const childPath = Paths.compose(path, `${index}.available`);
        const boolValue = value === true;

        handleChange(childPath, boolValue);
    };

    const emptyPlaceholderMessage =
        uischema?.options?.emptyPlaceholderMessage || '';

    const translationPrefix = schema?.metaProperties?.translationPrefix
        ? schema.metaProperties.translationPrefix
        : rootSchema.metaProperties.translationPrefix;

    const radioButtons = [
        {
            label: 'YES',
            value: true,
        },
        {
            label: 'NO',
            value: false,
        },
    ];

    const radioElements = data?.filter?.(el => el !== null) ?? [];

    if (!radioElements.length) return <div>{emptyPlaceholderMessage}</div>;

    return (
        <Wrapper>
            {radioElements.map((el, index) => {
                return (
                    <RadioContainer key={index}>
                        <FontAwesomeIcon
                            cursor={'pointer'}
                            size={'lg'}
                            icon={faTrash}
                            onClick={() => {
                                removeItems(path, [index])();
                            }}
                        />
                        <TextWrapper>
                            {t(translationPrefix + el.name)}
                        </TextWrapper>
                        <RadioButtonGroupContainer>
                            <RadioButtonGroup
                                onChange={e =>
                                    handleRadioChange(e.target.value, index)
                                }
                                inline
                                value={String(el.available)}
                            >
                                {radioButtons.map(({ label, value }) => (
                                    <RadioButton
                                        key={label}
                                        label={label}
                                        value={String(value)}
                                    />
                                ))}
                            </RadioButtonGroup>
                        </RadioButtonGroupContainer>
                    </RadioContainer>
                );
            })}
        </Wrapper>
    );
}

RadioGroupCell.propTypes = CellPropType;

export const tester = rankWith(3, optionIs('radio', true));
export default {
    renderer: withJsonFormsArrayControlProps(RadioGroupCell),
    tester,
};
