import React, { useCallback } from 'react';

const TablePagination = ({
    gotoPage,
    pageCount,
    pageIndex,
    previousPage,
    canPreviousPage,
    _localisation,
    pageSize,
    nextPage,
    setPageSize,
    canNextPage,
}) => {
    const onPaginationChange = useCallback(
        e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;

            /* Dont allow next page to be above pageCount or below 1 */
            if (page > pageCount - 1) {
                e.target.value = pageCount;
                if (page !== pageCount) gotoPage(pageCount - 1);
            } else if (page < 0) {
                e.target.value = 1;
                if (pageIndex !== 0) gotoPage(0);
            } else {
                gotoPage(page);
            }
        },
        [pageCount, gotoPage, pageIndex]
    );

    const onPageSizeChange = e => {
        setPageSize(Number(e.target.value));
    };
    return (
        <div className="pagination-bottom">
            <div className="-pagination">
                <div className="-previous">
                    <button
                        className="-btn"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {_localisation.previousText}
                    </button>
                </div>
                <div className="-center">
                    <span className="-pageInfo">
                        {_localisation.pageText}
                        <div className="-pageJump">
                            <input
                                type="number"
                                value={pageIndex + 1 || 1}
                                onChange={onPaginationChange}
                            />
                        </div>
                        {_localisation.ofText}{' '}
                        <span className="-totalPages">{pageCount}</span>
                    </span>
                    <span className="select-wrap -pageSizeOptions">
                        <select value={pageSize} onChange={onPageSizeChange}>
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize} {_localisation.rowsText}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                <div className="-next">
                    <button
                        className="-btn"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        {_localisation.nextText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TablePagination;
