import React from 'react';
import InputSelectWithFlag, {
    InputSelectWithFlagProps,
} from './input-select-with-flag/input-select-with-flag-component';
import { getCountryCallingCode } from 'react-phone-number-input';

export default function CountrySelectWithFlag({
    iconComponent: Icon,
    options,
    disabled,
    ...props
}: Readonly<InputSelectWithFlagProps>) {
    const priorityValues = ['SE', 'DK', 'NO', 'FI', 'ES'];

    const optionsWithCountryCode = options.map(option => {
        const countryCallingCode = option.value
            ? `(+${getCountryCallingCode(option.value)})`
            : '';
        return {
            ...option,
            label: `${option.label} ${countryCallingCode}`,
        };
    });

    return (
        <InputSelectWithFlag
            {...props}
            placeholder="SEARCH_COUNTRY"
            iconComponent={Icon}
            priorityValues={priorityValues}
            options={optionsWithCountryCode}
            position="left"
            disabled={disabled}
        />
    );
}
