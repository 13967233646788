import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const verticalStyle = css`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    button {
        margin: 0 0 6px 0;
    }
`;

const leftStyle = css`
    justify-content: flex-start;
    button {
        margin-right: 10px;
        margin-left: 0px;
        &:not(:first-of-type) {
            margin-left: 0;
        }
    }
`;

const StyledButtonToolbar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button:not(:first-of-type) {
        margin-left: 10px;
    }
    margin-top: auto;
`;

const ButtonToolBar = ({ vertical, left, ...restProps }) => (
    <StyledButtonToolbar
        vertical={vertical}
        left={left}
        css={vertical ? verticalStyle : left ? leftStyle : null}
        {...restProps}
    />
);

ButtonToolBar.propTypes = {
    left: PropTypes.bool,
    vertical: PropTypes.bool,
};

ButtonToolBar.defaultProps = {
    left: false,
    vertical: false,
};

export default ButtonToolBar;
