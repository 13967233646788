import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import DisplayUnsafeHtml from '../display/display-unsafe-html-component';
import MspxPopover from './mspx-popover-component';

const Popover = styled(MspxPopover)`
    padding: 10px;

    .tippy-arrow {
        transform: scale(1.5);
    }
`;

const Text = styled.span`
    margin: 0;
    font-size: 12.5px;
`;

const TooltipPopover = props => {
    const { text, children, placement, isTooltipVisible, primary } = props;
    const { t } = useTranslate();

    return (
        <Popover
            content={
                <Text>
                    <DisplayUnsafeHtml>{t(text)}</DisplayUnsafeHtml>
                </Text>
            }
            arrow={true}
            maxWidth={210}
            placement={placement}
            showOnCreate={false}
            visible={isTooltipVisible}
            primary={primary}
        >
            {children}
        </Popover>
    );
};

TooltipPopover.propTypes = {
    arrow: PropTypes.bool,
    hideOnClick: PropTypes.bool,
    interactive: PropTypes.bool,
    isTooltipVisible: PropTypes.bool,
    placement: PropTypes.string,
    showOnCreate: PropTypes.bool,
    t: PropTypes.func,
    text: PropTypes.string,
    visible: PropTypes.bool,
    primary: PropTypes.bool,
    children: PropTypes.node,
};

TooltipPopover.defaultProps = {
    animation: 'shift-away',
    arrow: false,
    hideOnClick: true,
    interactive: false,
    isTooltipVisible: false,
    maxwidth: 350,
    placement: 'top',
    showOnCreate: false,
    t: x => x,
    text: '',
    visible: true,
};

export default TooltipPopover;
