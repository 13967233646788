import { ac } from '@mspecs/shared-utils';
import genericRoute from '~/utils/generic-route-utils';
import {
    notificationUpdateSuccess,
    notificationUpdateError,
} from '~/utils/notification-utils';

export const PUBLISHING_ACTION_TYPES = {
    GET_PROJECT_DEALS_SUCCESS: 'GET_PROJECT_DEALS_SUCCESS',
    PUBLISH_PROJECT_DEALS_SUCCESS: 'PUBLISH_PROJECT_DEALS_SUCCESS',
    UNPUBLISH_PROJECT_DEALS_SUCCESS: 'UNPUBLISH_PROJECT_DEALS_SUCCESS',
    SET_PROJECT_DEALS_SUCCESS: 'SET_PROJECT_DEALS_SUCCESS',
};

const successGetProjectDeals = deals =>
    ac(PUBLISHING_ACTION_TYPES.GET_PROJECT_DEALS_SUCCESS, deals);

const successPublishDeals = deals =>
    ac(PUBLISHING_ACTION_TYPES.PUBLISH_PROJECT_DEALS_SUCCESS, deals);

const successUnpublishDeals = deals =>
    ac(PUBLISHING_ACTION_TYPES.UNPUBLISH_PROJECT_DEALS_SUCCESS, deals);

export function getProjectDealObjectsWithPublishStatus(
    dealId,
    providerServiceId
) {
    return async dispatch => {
        const newDevelopmentDeals = await genericRoute('newDevelopmentDeals')
            .keys({ projectDealId: dealId })
            .get();
        if (newDevelopmentDeals.length) {
            const dealIds = newDevelopmentDeals.map(x => x.dealId);
            const deals = await genericRoute('deals')
                .query(`id IN ('${dealIds.join("','")}')`)
                .get();

            const estateIds = deals.map(x => x.mainEstateId);

            const estates = await genericRoute('estates')
                .query(`id IN ('${estateIds.join("','")}')`)
                .fields([
                    'id',
                    'dealId',
                    'streetAddress',
                    'livingArea',
                    'plotArea',
                    'monthlyRent',
                    'propertyName',
                    'apartmentNumber',
                ])
                .get();

            const viewings = await genericRoute('viewings')
                .query(
                    `dealId IN ('${dealIds.join(
                        "','"
                    )}') AND endDate > '${new Date().toISOString()}'`
                )
                .get();
            const dealMarketingPlaces = await genericRoute(
                'dealMarketingPlaces'
            )
                .query(
                    `dealId IN ('${dealIds.join(
                        "','"
                    )}') AND providerServiceId='${providerServiceId}'`
                )
                .get();

            const dealList = [];
            deals.forEach(deal => {
                const estate = estates.find(x => x.dealId === deal.id);
                const viewing = viewings
                    .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
                    .find(x => x.dealId === deal.id) || [{}];
                const dealMarketingPlace =
                    dealMarketingPlaces.find(x => x.dealId === deal.id) || {};
                const { id, startingPrice } = deal;
                dealList.push({
                    id,
                    startingPrice,
                    viewing,
                    estate,
                    dealMarketingPlace,
                    isChecked: true,
                });
            });
            dispatch(successGetProjectDeals(dealList));
            return dealList;
        } else {
            dispatch(successGetProjectDeals([]));
            return [];
        }
    };
}

async function unpublishWebsite(dealId, providerServiceId) {
    return await genericRoute(
        `v3/marketing/${providerServiceId}/publish/${dealId}`
    ).delete();
}

async function publishWebsite(
    dealId,
    providerServiceId,
    saleStatus,
    preview,
    grants
) {
    const postObject = { saleStatus };
    if (preview === true || preview === false) {
        postObject.preview = preview;
    }

    if (saleStatus === 'ENUMS_SALE_STATUS_SOLD' && grants) {
        postObject.grants = grants;
    }

    return await genericRoute(
        `v3/marketing/${providerServiceId}/publish/${dealId}`
    ).post(postObject);
}

export function unpublishAll(deals, providerServiceId) {
    return async dispatch => {
        try {
            if (deals.length) {
                await Promise.all(
                    deals.map(deal =>
                        unpublishWebsite(deal.id, providerServiceId).then(
                            x => (deal.dealMarketingPlace = x)
                        )
                    )
                );
                dispatch(successUnpublishDeals(deals));
                notificationUpdateSuccess(dispatch);
            }
        } catch (e) {
            notificationUpdateError(dispatch);
        }
    };
}

export function publishAll(
    projectDealId,
    deals = [],
    providerServiceId,
    isPublishProjectDeal
) {
    return async dispatch => {
        try {
            if (isPublishProjectDeal) {
                await publishWebsite(projectDealId, providerServiceId, false);
            }

            if (deals && deals.length) {
                await Promise.all(
                    deals.map(deal =>
                        publishWebsite(deal.id, providerServiceId, false).then(
                            x => (deal.dealMarketingPlace = x)
                        )
                    )
                );
                dispatch(successPublishDeals(deals));
            }
            notificationUpdateSuccess(dispatch);
        } catch (e) {
            notificationUpdateError(dispatch);
        }
    };
}

export function setSelectedDeals(deals) {
    return dispatch => {
        dispatch(ac(PUBLISHING_ACTION_TYPES.SET_PROJECT_DEALS_SUCCESS, deals));
    };
}
