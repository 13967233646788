import { useEffect, useLayoutEffect, useRef } from 'react';

// https://usehooks.com/useEventListener/
function useEventListener(eventName, handler, element) {
    const savedHandler = useRef(handler);

    useLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const targetElement = element?.current || window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }
        const eventListener = event => savedHandler.current(event);

        targetElement.addEventListener(eventName, eventListener);

        return () => {
            targetElement.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}

export default useEventListener;
