import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid/emotion';

const Branch = ({ path, ...restProps }) => {
    return <Box width={1} path={path} {...restProps} />;
};

Branch.propTypes = {
    path: PropTypes.string.isRequired,
};

export default Branch;
