/* eslint-disable no-unused-vars */
import { ACTION_TYPES } from '../actions/print-modal-actions';

import { BIDDING_PRINT_OPTIONS } from '~/components/modal/print-modal/print-modal-constants';

const {
    PRINTMODAL_CLOSE,
    PRINTMODAL_ADD_SIGNATURE,
    PRINTMODAL_SEED_SIGNATURES,
    PRINTMODAL_EDIT_SIGNATURE,
    PRINTMODAL_REMOVE_SIGNATURE,
    PRINTMODAL_UPDATE_CDS_SETTINGS,
    PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY,
    PRINTMODAL_UPDATE_BIDDING_SETTINGS,
    PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY_GLOBAL,
    PRINTMODAL_UPDATE_BROKER_JOURNAL_SETTINGS,
} = ACTION_TYPES;

const initialState = {
    baseLink: '',
    signatureSection: {
        show: true,
        city: '',
        date: '',
        signatures: {},
        hasSeededSignatures: false,
        showTypeSelector: true,
        showSections: {
            buyers: true,
            sellers: true,
            anonymous: false,
        },
        contactId: null,
    },
    biddingSection: {
        archiveComment: '',
        hasArchivedBiddings: null,
        printArchivedBiddings: false,
        printSignaturesSamePage: false,
        saveAsPdf: false,
        typePrint: BIDDING_PRINT_OPTIONS.BUYER_SELLER,
        addQrScanCode: false,
        isEsign: false,
    },
    cdsSection: {
        watermark: false,
        writtenMortgages: true,
        savePrintToFiles: false,
        saveAllPagesToOnePdf: false,
        include: {
            common: true,
            sellers: true,
            buyers: true,
        },
        addQrScanCode: false,
        isEsign: false,
    },
    brokerJournalOption: {
        addQrScanCode: false,
        isEsign: false,
    },
};

export default function printModal(state = initialState, action) {
    const { name, value } = action.payload || {};
    switch (action.type) {
        case PRINTMODAL_CLOSE:
            return {
                ...initialState,
            };
        case PRINTMODAL_SEED_SIGNATURES:
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    hasSeededSignatures: true,
                    signatures: {
                        ...(action.payload || {}),
                    },
                },
            };
        case PRINTMODAL_ADD_SIGNATURE:
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    signatures: {
                        ...state.signatureSection.signatures,
                        [action.payload.id]: action.payload,
                    },
                },
            };
        case PRINTMODAL_EDIT_SIGNATURE:
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    signatures: {
                        ...state.signatureSection.signatures,
                        [action.payload.id]: {
                            ...state.signatureSection.signatures[
                                action.payload.id
                            ],
                            ...action.payload,
                        },
                    },
                },
            };
        case PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY:
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    [name]: value,
                },
            };
        case PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY_GLOBAL:
            const isUpdateIfEmpty = action.payload;
            const { city, date, signatures } = state.signatureSection;
            const updatedSignatures = Object.entries(signatures).reduce(
                (acc, [key, signatureValue]) => {
                    acc[key] = {
                        ...signatureValue,
                        city:
                            !!isUpdateIfEmpty && signatureValue.city
                                ? signatureValue.city
                                : city,
                        date,
                    };
                    return acc;
                },
                {}
            );
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    signatures: updatedSignatures,
                },
            };
        case PRINTMODAL_REMOVE_SIGNATURE:
            const { ['fooo']: deleted, ...restSignatures } = state.signature;
            return {
                ...state,
                signatureSection: {
                    ...state.signatureSection,
                    signatures: restSignatures,
                },
            };
        case PRINTMODAL_UPDATE_CDS_SETTINGS:
            return {
                ...state,
                cdsSection: {
                    ...state.cdsSection,
                    [name]: value,
                },
            };
        case PRINTMODAL_UPDATE_BIDDING_SETTINGS:
            return {
                ...state,
                biddingSection: {
                    ...state.biddingSection,
                    [name]: value,
                },
            };
        case PRINTMODAL_UPDATE_BROKER_JOURNAL_SETTINGS:
            return {
                ...state,
                brokerJournalOption: {
                    ...state.brokerJournalOption,
                    [name]: value,
                },
            };
        default:
            return state;
    }
}
