import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NumberInput } from '.';

const Separator = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
`;

const MinMaxInput = ({ minName, maxName, ...rest }) => {
    return (
        <Flex>
            <NumberInput name={minName} placeholder="Min" {...rest} />
            <Separator>-</Separator>
            <NumberInput name={maxName} placeholder="Max" {...rest} />
        </Flex>
    );
};
MinMaxInput.propTypes = {
    minName: PropTypes.string.isRequired,
    maxName: PropTypes.string.isRequired,
};

export default MinMaxInput;
