import React, { FC } from 'react';
import {
    withFinalFormField,
    withInputValidation,
    withValidationWrapper,
} from '../../../utils/hoc';

import { compose, branch } from 'recompose';
import InputField, { InputFieldProps } from '../basic/input-field-component';

const FormInputField: FC<InputFieldProps> = ({ ...props }) => {
    return <InputField {...props} />;
};

export default compose<any, InputFieldProps>(
    withValidationWrapper,
    branch(
        ({ isFormField }: { isFormField: boolean }) => isFormField,
        withFinalFormField,
        withInputValidation as any
    )
)(FormInputField);
