import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React from 'react';
import MapMarker from './map-marker-component';
import MapStaticImage from './map-static-image-component';
import { MapDataType } from './map.props';
// Largest size Google can provide
const IMAGE_MAX_WIDTH = 640;

const GoogleMapWrapper = ({
    latitude,
    longitude,
    apiKey,
    zoomLevel = 11,
    editMode = false,
    onMapClicked = () => {},
    onZoomLevelChanged = () => {},
    markers = [],
    height = 200,
}) => {
    return (
        <div
            style={{
                height: `${height}px`,
                width: '100%',
                maxWidth: `${IMAGE_MAX_WIDTH}px`,
            }}
        >
            {editMode ? (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    center={{
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                    }}
                    onZoomAnimationEnd={onZoomLevelChanged}
                    onClick={({ lat, lng }) =>
                        onMapClicked({
                            latitude: lat,
                            longitude: lng,
                        })
                    }
                    zoom={zoomLevel}
                >
                    {markers.map(({ latitude, longitude }) => (
                        <MapMarker
                            key={`${latitude}_${longitude}`}
                            lat={parseFloat(latitude)}
                            lng={parseFloat(longitude)}
                        />
                    ))}
                </GoogleMapReact>
            ) : (
                <MapStaticImage
                    latitude={latitude}
                    longitude={longitude}
                    apiKey={apiKey}
                    zoomLevel={zoomLevel}
                    height={height}
                />
            )}
        </div>
    );
};

GoogleMapWrapper.propTypes = {
    latitude: MapDataType.isRequired,
    longitude: MapDataType.isRequired,
    apiKey: PropTypes.string.isRequired,
    zoomLevel: PropTypes.number,
    editMode: PropTypes.bool,
    onMapClicked: PropTypes.func,
    onZoomLevelChanged: PropTypes.func,
    markers: PropTypes.arrayOf(
        PropTypes.shape({
            latitude: MapDataType.isRequired,
            longitude: MapDataType.isRequired,
        })
    ),

    height: PropTypes.number,
};

export default GoogleMapWrapper;
