import { TAMBUR_ACTION_TYPES } from '~/redux/actions/tambur-actions';

const initialState = {
    isError: false,
    errorMessages: [],
    externalDealService: {},
    assistantContactIds: [], // selected assistants for tambur case
    assistants: [],
    validationFieldErrors: [],
    tamburCase: {},
    loginUrl: null,
    isLoading: false,
    isCaseCreated: false,
    hasProtectedIdentity: false,
    isTamburModalOpen: false,
    isFilesSent: false,
    categoryToDocTypeMap: {},
};

const tambur = (state = initialState, action) => {
    switch (action.type) {
        case TAMBUR_ACTION_TYPES.ERROR:
            return {
                ...state,
                isError: true,
                isLoading: false,
                errorMessages: [...action.payload],
            };

        case TAMBUR_ACTION_TYPES.SUCCESS_UPLOAD_TAMBUR_FILES:
            return {
                ...state,
                tamburCase: action.payload,
                isLoading: false,
                isFilesSent: true,
            };
        case TAMBUR_ACTION_TYPES.INIT_TAMBUR_CASE:
            const {
                externalDealService,
                assistants,
                tamburCase,
                loginUrl,
                categoryToDocTypeMap,
            } = action.payload;
            return {
                ...state,
                externalDealService,
                assistants: [...assistants],
                tamburCase: { ...tamburCase },
                loginUrl,
                isTamburModalOpen: true,
                categoryToDocTypeMap,
            };
        case TAMBUR_ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                isError: false,
                errorMessages: [],
                assistants: [],
                isLoading: false,
                isTamburModalOpen: false,
            };
        case TAMBUR_ACTION_TYPES.SUCCESS_CREATE_TAMBUR_CASE:
            return {
                ...state,
                externalDealService: action.payload,
                isCaseCreated: true,
                isLoading: false,
                errorMessages: [],
                isFilesSent: true,
            };
        case TAMBUR_ACTION_TYPES.SUCCESS_DELETE_TAMBUR_CASE:
            return {
                ...state,
                isTamburModalOpen: false,
            };
        case TAMBUR_ACTION_TYPES.SET_PROTECTED_IDENTITY:
            return {
                ...state,
                hasProtectedIdentity: action.payload,
            };
        case TAMBUR_ACTION_TYPES.SUCCESS_SET_VALIDATION:
            return {
                ...state,
                validationFieldErrors: [...action.payload],
            };
        case TAMBUR_ACTION_TYPES.SUCCESS_SET_ASSISTANTS:
            return {
                ...state,
                assistantContactIds: action.payload,
            };

        case TAMBUR_ACTION_TYPES.SET_INIT_LOADING_INDICATOR:
            return {
                ...state,
                isLoading: true,
            };
        case TAMBUR_ACTION_TYPES.RESET_SENT_FILES:
            return {
                ...state,
                isFilesSent: false,
            };

        default:
            return state;
    }
};

export default tambur;
