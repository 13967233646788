import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

// Use checkbox wrapper from sticky-overview-cell-renderer to get same padding
import { CheckboxWrapper } from '../../cell-renderers/sticky/sticky-overview-cell-renderer';
import { Checkbox } from '../../../input';

const StickyCellWrapper = styled(Flex)`
    align-items: center;
`;

const StickyOverviewHeaderRenderer = ({
    getToggleAllRowsSelectedProps,
    column,
}) => {
    return (
        <StickyCellWrapper>
            {getToggleAllRowsSelectedProps && (
                <CheckboxWrapper>
                    <Checkbox {...getToggleAllRowsSelectedProps()} />
                </CheckboxWrapper>
            )}
            <Flex flex={1}>{column.headerText}</Flex>
        </StickyCellWrapper>
    );
};

export default StickyOverviewHeaderRenderer;
