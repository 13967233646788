import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';
import { NOTIFICATION_TYPES } from '../constants';
import NotificationBottomContent from './notifiction-bottom-content-component';

const NotificationContainer = styled('div')`
    position: fixed;
    z-index: 101;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    max-height: 200px;
    margin-top: 18px;
`;

const ErrorNotification = styled(NotificationBottomContent)`
    background-color: ${({ theme }) => theme.colors.errorColor};
`;

const SuccessNotification = styled(NotificationBottomContent)`
    background-color: ${({ theme }) => theme.colors.green60};
    color: ${({ theme }) => theme.colors.textPrimary};
`;

const NotificationBottom = ({ className, messages = [], onClose }) => {
    const { t } = useTranslate();

    return messages.length ? (
        <NotificationContainer className={className}>
            {messages.map(notificationProps => {
                const spreadObject = {
                    t: t,
                    message: notificationProps.message,
                    close: () => onClose(notificationProps),
                    key: notificationProps.id,
                };
                if (notificationProps.type === NOTIFICATION_TYPES.ERROR) {
                    return <ErrorNotification {...spreadObject} />;
                } else {
                    return <SuccessNotification {...spreadObject} />;
                }
            })}
        </NotificationContainer>
    ) : null;
};

NotificationBottomContent.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.array,
    onClose: PropTypes.func,
};

export default NotificationBottom;
