import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import React from 'react';

function SortableItem({ id, children }) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'manipulation',
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className="sortable-item"
            data-sortable-id={id}
        >
            {children}
        </div>
    );
}

SortableItem.propTypes = {
    id: PropTypes.string,
    children: PropTypes.any,
};
export default SortableItem;
