import { useContext, useRef } from 'react';
import { TabContext } from './tab-nav-provider-component';
import { TabContent } from './tab-nav-styles';

const Tab = props => {
    const { render, children, ...rest } = props;
    const { isActiveFn, hideNonActive, tabContentClassName, tabStyle } =
        useContext(TabContext);
    const hasBeenShownRef = useRef(null);
    const isActive = isActiveFn(props);

    if (isActive) {
        hasBeenShownRef.current = true;
    }

    if ((hideNonActive && hasBeenShownRef.current) || isActive) {
        // Don't remount tabContent while changing tab
        return (
            <TabContent
                className={tabContentClassName}
                hideNonActive={
                    !isActive && hideNonActive && hasBeenShownRef.current
                }
                tabStyle={tabStyle}
                {...rest}
            >
                {children || render()}
            </TabContent>
        );
    } else {
        return null;
    }
};

export default Tab;
