import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { rem } from '../font/rem';
import { slideInAndOut } from './animations';
import { useTranslate } from '../../services/i18n';

const Container = styled(Flex)`
    background-color: #fff;
    height: 100%;
`;

const Text = styled.h5`
    margin-bottom: ${rem(7)};
    font-size: 20px;
    font-weight: 500;
    color: #222;
`;

const AnimationContainer = styled.div`
    width: 100px;
`;

const AnimationLine = styled.div`
    height: 4px;
    background: #222;
    animation: ${slideInAndOut} 3s infinite linear;
`;

const LoadingAnimation = ({ children, ...props }) => {
    const { t } = useTranslate();
    return (
        <Container
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            {...props}
        >
            <Text>{t(children)}</Text>
            <AnimationContainer>
                <AnimationLine />
            </AnimationContainer>
        </Container>
    );
};

export default LoadingAnimation;
