import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import BreadCrumbs from './bread-crumbs-component';

const StyledContainer = styled(Flex)`
    flex-direction: column;
    height: 100%;
`;

export const StackContext = createContext();

const useStackContext = () => {
    const [stack, setStack] = useState([]);
    const activeStack = useMemo(() => stack[stack.length - 1], [stack]);
    const navigateStack = newStack => {
        let tempStack = [];

        if (typeof newStack === 'number') {
            tempStack = [...stack];
            tempStack.splice(-Math.abs(newStack));
        } else if (Array.isArray(newStack)) {
            tempStack = newStack;
        } else if (typeof newStack === 'string') {
            tempStack = [...stack, newStack];
        }

        if (!tempStack) {
            tempStack = [];
        }

        setStack(tempStack);
    };

    return {
        stack,
        navigateStack,
        activeStack,
    };
};

const Stack = ({ children, defaultStack }) => {
    const { stack, navigateStack, activeStack } = useStackContext();

    useEffect(() => {
        if (!activeStack) {
            if (defaultStack) {
                navigateStack(defaultStack);
            } else {
                navigateStack(
                    children.find(child => child[0]?.props?.title)?.title
                );
            }
        }
    }, [activeStack]);

    return (
        <StyledContainer>
            <StackContext.Provider
                value={{
                    stack,
                    navigateStack,
                    activeStack,
                }}
            >
                <BreadCrumbs />
                {children}
            </StackContext.Provider>
        </StyledContainer>
    );
};

Stack.propTypes = {
    children: PropTypes.node,
    defaultStack: PropTypes.string,
};

export default Stack;
