import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { KebabMenu } from '../table-components';

const Wrapper = styled(Flex)`
    justify-content: flex-end;
    button {
        background: transparent;
    }
`;

const SettingsCellRenderer = props => {
    const options = props.column.getOptions(props);
    if (!options?.length) return null;
    return (
        <Wrapper>
            <KebabMenu appendTo={document.body} options={options} {...props} />
        </Wrapper>
    );
};

export default SettingsCellRenderer;
SettingsCellRenderer.propTypes = {
    column: PropTypes.shape({ getOptions: PropTypes.func }),
};
