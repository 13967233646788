import React from 'react';
import {
    and,
    optionIs,
    rankWith,
    isNumberControl,
    isIntegerControl,
    or,
    formatIs,
} from '@jsonforms/core';
import {
    getCellProps,
    parseInputToNumber,
    parseToNumber,
    withInputControlHoc,
} from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { MoneyInput } from '../../../input';

function MoneyCell(props) {
    const { path, handleChange, schema } = props;
    const { minimum, maximum } = schema;

    const baseProps = {
        ...getCellProps(props),
        onChange: e =>
            handleChange(path, parseInputToNumber(e.target.value) || undefined),
    };

    return (
        <MoneyInput
            {...baseProps}
            parse={parseToNumber}
            min={minimum}
            max={maximum}
        />
    );
}

MoneyCell.propTypes = CellPropType;

export const tester = rankWith(
    3,
    and(
        or(isNumberControl, isIntegerControl),
        or(formatIs('money'), optionIs('format', 'money'))
    )
);

export default { renderer: withInputControlHoc(MoneyCell), tester };
