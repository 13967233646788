import { TableCellRenderers as cellRenderers } from '../../cell-renderers';
import headerRenderers from '../../header-renderers';
export const COLUMN_WIDTH_SMALL = 90;
export const COLUMN_WIDTH_MEDIUM = 120;

const createColumn = (accessor, { label, ...props } = {}) => {
    const column = {
        accessor,
        Header: label || ' ', // Converts to no header in table component
        ...props,
    };
    if (column.maxWidth) {
        column.width = column.maxWidth;
    }
    return column;
};

const columnResolvers = (cbFn = createColumn) => ({
    default: cbFn,
    textBadge: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.textBadge,
    }),
    bool: (...args) => ({
        maxWidth: COLUMN_WIDTH_SMALL,
        ...cbFn(...args),
        Cell: cellRenderers.bool,
    }),
    checkbox: (...args) => ({
        maxWidth: COLUMN_WIDTH_SMALL,
        ...cbFn(...args),
        Cell: cellRenderers.checkbox,
    }),
    date: (...args) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.date,
    }),
    datetime: (...args) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.datetime,
    }),
    enum: (...args) => ({
        ...cbFn(...args),
        isEnum: true,
        Cell: cellRenderers.enum,
    }),
    fieldsSeparatedByComma: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.fieldsSeparatedByComma,
    }),
    file: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.file,
    }),
    image: (...args) => ({
        ...cbFn(...args),
        disableSort: true,
        style: {
            maxWidth: '200px',
        },
        Cell: cellRenderers.image,
    }),
    profileImage: (...args) => ({
        disableSort: true,
        width: 36,
        ...cbFn(...args),
        Cell: cellRenderers.profileImage,
    }),
    money: (...args) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.money,
    }),
    personName: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.personName,
    }),
    settings: (...args) => ({
        width: 65,
        disableSort: true,
        ...cbFn(...args),
        Cell: cellRenderers.settings,
    }),
    switch: (...args) => ({
        maxWidth: 60,
        disableSort: true,
        ...cbFn(...args),
        Cell: cellRenderers.switch,
    }),
    stickyOverview: (...args) => ({
        width: 300,
        exportHeader: args[1].headerText,
        sticky: 'left',
        ...cbFn(...args),
        Header: headerRenderers.stickyOverview,
        Cell: cellRenderers.stickyOverview,
    }),
    translated: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.translated,
    }),
    verified: (...args) => ({
        ...cbFn(...args),
        Cell: cellRenderers.verified,
    }),
    objectTypeIcon: (...args) => ({
        ...cbFn(...args),
        maxWidth: 60,
        Cell: cellRenderers.objectTypeIcon,
    }),
});

export default function columnCreator(processors = []) {
    return columnResolvers((accessor, props) =>
        processors.reduce(
            (col, processor) => processor(col, accessor),
            createColumn(accessor, props)
        )
    );
}

export const columnSizes = {
    small: col => ({ ...col, maxWidth: COLUMN_WIDTH_SMALL }),
    medium: col => ({ ...col, maxWidth: COLUMN_WIDTH_MEDIUM }),
};
