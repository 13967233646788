import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_TYPES } from '../../button/index';
import { Toolbar, ToolbarButton } from '../form/form-button-toolbar-component';
import { useKeydown, useTranslate } from '@mspecs/shared-utils';

const BottomWrapper = styled(Flex)`
    margin-top: auto;
    background: ${({ theme }) => theme.colors.bgPrimary};
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing.default};
`;
const StyledButton = styled(ToolbarButton)`
    text-transform: none;
    width: calc(50% - 10px);
`;
const FilterBottomActionButtons = ({
    onSecondAction,
    onSubmit = x => x,
    submitDisabled = false,
    secondActionLabel,
    submitLabel,
    children,
}) => {
    const { t } = useTranslate();
    useKeydown(onSubmit, 'Enter');

    return (
        <BottomWrapper>
            {children}
            <Toolbar>
                {onSecondAction && (
                    <StyledButton onClick={onSecondAction}>
                        {secondActionLabel || t('CLEAR')}
                    </StyledButton>
                )}

                <StyledButton
                    buttonType={BUTTON_TYPES.PRIMARY}
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    {submitLabel ||
                        t('ADVANCED_FILTER_SUBMIT_FORM_BUTTON_LABEL')}
                </StyledButton>
            </Toolbar>
        </BottomWrapper>
    );
};

FilterBottomActionButtons.propTypes = {
    onSecondAction: PropTypes.func,
    onSubmit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    secondActionLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    children: PropTypes.node,
};

export default FilterBottomActionButtons;
