import React from 'react';
import { css, useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';

const Shield = () => {
    const theme = useTheme();
    return (
        <FontAwesomeIcon
            icon={faShieldCheck}
            css={css`
                color: ${theme.colors.successColor};
                font-size: 1.2rem;
                margin: 0 10px;
            `}
        />
    );
};
const IdentifierVerifiedCellRenderer = ({ value }) => {
    return value === true ? <Shield /> : null;
};

export default IdentifierVerifiedCellRenderer;
