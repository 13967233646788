import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

const speedTime = {
    slow: '2s',
    medium: '1s',
    fast: '0.75s',
    faster: '0.5s',
};

const spin = keyframes`
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    } 
`;

const LoadingAnimation = styled('div')`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.borderSecondary};
    animation: ${spin} ${props => speedTime[props.speed]} linear infinite;
    border-top: 4px solid rgba(51, 122, 183, 0.9);
`;

LoadingAnimation.propTypes = {
    speed: PropTypes.oneOf(['slow', 'medium', 'fast', 'faster']),
};

LoadingAnimation.defaultProps = {
    speed: 'medium',
};

/** @component */
export default LoadingAnimation;
