import { ac } from '@mspecs/shared-utils';
import { notificationUpdateSuccess } from '~/utils/notification-utils';
import { legacyApi } from '@mspecs/shared-utils';

export const ACTIONS = {
    ESIGNATURE_SETTINGS_GET: 'ESIGNATURE_SETTINGS_GET',
    ESIGNATURE_SETTINGS_GET_SUCCESS: 'ESIGNATURE_SETTINGS_GET_SUCCESS',
    ESIGNATURE_SETTINGS_UPDATE: 'ESIGNATURE_SETTINGS_UPDATE',
    ESIGNATURE_SETTINGS_UPDATE_SUCCESS: 'ESIGNATURE_SETTINGS_UPDATE_SUCCESS',
    ESIGNATURE_SETTINGS_ERROR: 'ESIGNATURE_SETTINGS_ERROR',
};

const getEsignatureSettingsAction = () => ac(ACTIONS.ESIGNATURE_SETTINGS_GET);

const getEsignatureSettingsSuccessAction = ({ esignatureSettings }) =>
    ac(ACTIONS.ESIGNATURE_SETTINGS_GET_SUCCESS, {
        esignatureSettings,
    });

const updateEsignatureSettingsAction = () =>
    ac(ACTIONS.ESIGNATURE_SETTINGS_UPDATE);

const updateEsignatureSettingsSuccessAction = ({ esignatureSettings }) =>
    ac(ACTIONS.ESIGNATURE_SETTINGS_UPDATE_SUCCESS, {
        esignatureSettings,
    });

const handleError = error => ac(ACTIONS.ESIGNATURE_SETTINGS_ERROR, error);

export function getEsignatureSettings() {
    return async (dispatch, getState) => {
        const { esignatureSettings = {} } = getState();
        if (esignatureSettings.settings) {
            return esignatureSettings.settings;
        }
        try {
            dispatch(getEsignatureSettingsAction());
            const settings = await legacyApi(
                `/v3/document/verified/credentials`
            ).get();
            dispatch(
                getEsignatureSettingsSuccessAction({
                    esignatureSettings: settings,
                })
            );
            return settings;
        } catch (e) {
            dispatch(handleError(e));
            throw e;
        }
    };
}

export function updateEsignatureSettings(data) {
    return async dispatch => {
        try {
            dispatch(updateEsignatureSettingsAction());
            await legacyApi(`/v3/document/verified/credentials`).post(data);
            notificationUpdateSuccess(dispatch);
            dispatch(
                updateEsignatureSettingsSuccessAction({
                    esignatureSettings: data,
                })
            );
        } catch (e) {
            dispatch(handleError(e));
            throw e;
        }
    };
}
