import React from 'react';
import { keyframes } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const slideIn = keyframes`
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 1;
    }
`;

export const slideInAndOutBlue = keyframes`
    from {
          left: -80%;
          width: 80%;
     }
     to {
          left: 100%;
          width: 100%;
     }
     `;
export const slideOut = keyframes`
    0% {
        width: 100%;
        opacity: 1;
    }
    100% {
        width: 0%;
        opacity: 0;
    }
`;

export const slideInAndOut = keyframes`
  0% {
    width: 0;
  }
  50% {
    margin-left: 0;
    width: 100%;
  }
  100% {
    margin-left: 100%;
    width: 0;
  }
`;

export const FadeTransition = props => (
    <CSSTransition
        {...props}
        classNames={{
            enter: 'fade-enter',
            enterActive: 'fade-enter-active',
            exit: 'fade-exit',
        }}
        addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
        }}
    />
);
