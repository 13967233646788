import React, { Children, cloneElement } from 'react';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { StyledLoadingBlock } from './loading-block-component';

const setChild = (parent, child) => cloneElement(parent, { children: child });
const map = (children, func) => Children.map(children, child => func(child));

const replaceChild = (children, expression, newChild) =>
    map(children, c =>
        expression(c)
            ? newChild
            : c.props?.hasOwnProperty('children') &&
              setChild(
                  c,
                  map(c.props.children, c =>
                      replaceChild(c, expression, newChild)
                  )
              )
    );

const LoadingBlocks = ({ loading, children, container, expression }) =>
    loading
        ? replaceChild(
              children,
              expression,
              setChild(container, <StyledLoadingBlock />)
          )
        : children;

LoadingBlocks.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
    container: PropTypes.node,
    expression: PropTypes.func,
};
LoadingBlocks.defaultProps = {
    container: <Flex w={1} h={1} />,
};

export default LoadingBlocks;
