import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleExclamation,
    faInfoCircle,
    faXmark,
    faCircleCheck,
    faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { LinkIt } from '../link';
import { BodyTextRegular } from '../typograph/typograph-components';

export const BANNER_TYPES = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
};

const { ERROR, INFO, SUCCESS, WARNING } = BANNER_TYPES;

const getSolidColor = ({ theme: { colors }, type }) => {
    switch (type) {
        case ERROR:
            return colors.errorColor;
        case SUCCESS:
            return colors.successColor;
        case WARNING:
            return colors.warningColor;
        case INFO:
        default:
            return colors.primaryColor;
    }
};

const StyledBanner = styled(Flex)`
    background: ${({ theme: { colors }, type }) => {
        switch (type) {
            case ERROR:
                return colors.red10;
            case SUCCESS:
                return colors.green15;
            case WARNING:
                return colors.pending10;
            case INFO:
            default:
                return colors.primaryColor10;
        }
    }};
    min-height: 44px;
    width: 100%;
    align-items: center;
    border-left: 7px solid ${getSolidColor};
`;

const BannerText = styled(BodyTextRegular)`
    padding: ${({ theme: { spacing } }) => ` ${spacing.default} 0`};
`;

const BannerIcon = styled(FontAwesomeIcon)`
    width: 20px;
    height: 20px;
    color: ${getSolidColor};
    padding: ${({ theme: { spacing } }) =>
        `${spacing.small} ${spacing.default} ${spacing.small} ${spacing.default}`};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: ${({ theme: { spacing } }) =>
            `${spacing.default} ${spacing.large} ${spacing.default} ${spacing.large}`};
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-left: auto;
    padding: ${({ theme: { spacing } }) =>
        `0 ${spacing.large} 0 ${spacing.default}`};
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    :hover {
        color: ${({ theme }) => theme.colors.textPrimary};
        transform: scale(1.1);
    }
`;

const BANNER_ICONS = {
    [ERROR]: faCircleExclamation,
    [INFO]: faInfoCircle,
    [SUCCESS]: faCircleCheck,
    [WARNING]: faTriangleExclamation,
};

const Banner = forwardRef(({ text, onClose, type = INFO, ...props }, ref) => {
    return (
        <StyledBanner {...props} type={type} data-testid="banner" ref={ref}>
            <BannerIcon icon={BANNER_ICONS[type]} type={type} />
            <BannerText>
                <LinkIt text={text}></LinkIt>
            </BannerText>
            {onClose && (
                <CloseIcon
                    icon={faXmark}
                    size="lg"
                    onClick={onClose}
                    data-testid="close-icon"
                />
            )}
        </StyledBanner>
    );
});

Banner.propTypes = {
    onClose: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.oneOf(Object.values(BANNER_TYPES)),
};

export default Banner;
