import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

// TODO: Add localisation
export default function DisplayDateTIme(props) {
    if (!props.children) {
        return null;
    }
    return <span>{Moment(props.children).format('YYYY-MM-DD HH:mm')}</span>;
}

DisplayDateTIme.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
};
