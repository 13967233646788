import React from 'react';
import styled from '@emotion/styled';

const Hide = styled.div`
    width: 100%;
    height: 100%;
    display: ${props => (props.when ? 'none !important' : 'block')};
`;

export default Hide;
