import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippy.js/react';
import { useTranslate } from '@mspecs/shared-utils';
import 'tippy.js/dist/tippy.css';
import { ConfirmDeleteModal } from '../modal';
import { TEXT_STYLES } from '../typograph/typograph-components';

const Popover = styled(Tippy)`
    padding: 0;
    top: -2px;
    box-shadow: -3px 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.default};

    &.tippy-tooltip {
        background-color: ${({ theme }) => theme.colors.bgPrimary};
    }
    .tippy-content {
        padding: 0;
    }
    .tippy-arrow {
        display: none;
    }
`;

const List = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.borderRadius.default};
    min-width: ${({ smallMenu }) => (smallMenu ? '140px' : '158px')};
    margin: 2px 0 0;
    color: ${({ theme }) => theme.colors.textPrimary};
    overflow: hidden;
`;

const StyledListItem = styled(Flex)`
    ${({ theme }) => TEXT_STYLES.bodyText(theme)};
    background: transparent;
    color: ${({ theme }) => theme.colors.textPrimary};
    border: none;
    border-top: 1px solid
        ${({ theme, smallMenu }) =>
            smallMenu ? 'none' : theme.colors.borderPrimary};
    padding: 9px 21px;
    line-height: 24px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryColor5};
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
    &:first-of-type {
        border-top: none;
    }
`;

const StyledListItemIcon = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => !['smallMenu'].includes(prop),
})`
    width: 1em;
    padding-right: ${({ theme }) => theme.spacing.default};
    font-weight: 300;
    font-size: ${({ smallMenu }) => (smallMenu ? '16px' : '18px')};
`;

export const ListItem = props => {
    const { smallMenu, ...restProps } = props;

    return (
        <StyledListItem
            as="button"
            alignItems="center"
            width={1}
            smallMenu={smallMenu}
            {...restProps}
        />
    );
};

const groupHeadingStyle = {
    borderTop: 'solid 1px #666',
    color: '#666',
    textTransform: 'uppercase',
    fontSize: '12.8px',
    cursor: 'auto',
    userSelect: 'none',
};

export const ListIcon = props => {
    const { smallMenu, ...restProps } = props;

    return <StyledListItemIcon smallMenu={smallMenu} {...restProps} />;
};

const MenuPopover = props => {
    const { children, row, options, placement, smallMenu, itemProps } = props;
    const [instance, setInstance] = useState(null);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const { t } = useTranslate();
    const removeOption = options.find(({ name }) => name === 'REMOVE') ?? {};
    const { confirmationMessage } = removeOption;

    const onClickHandle = e => {
        e.stopPropagation();
        setIsDeleteConfirmation(false);
        removeOption.onClick && removeOption.onClick(row);
    };

    return (
        <Popover
            content={
                instance ? (
                    <>
                        <List>
                            {options.map((item, index) => (
                                <ListItem
                                    key={index}
                                    disabled={item.disabled}
                                    style={
                                        item.groupHeading && groupHeadingStyle
                                    }
                                    tabIndex={item.groupHeading && '-1'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (item.name === 'REMOVE') {
                                            !item.disabled &&
                                                setIsDeleteConfirmation(true);
                                        } else {
                                            !item.disabled &&
                                                item.onClick &&
                                                item.onClick(row);
                                        }
                                        instance.hide();
                                    }}
                                    smallMenu={smallMenu}
                                    {...(itemProps ?? {})}
                                >
                                    {item.icon && (
                                        <ListIcon
                                            icon={item.icon}
                                            smallMenu={smallMenu}
                                        />
                                    )}
                                    {t(item.name)}
                                </ListItem>
                            ))}
                        </List>
                        <ConfirmDeleteModal
                            headerText={
                                confirmationMessage?.header ??
                                'DELETE_CONFIRMATION_MODAL_GENERIC_HEADER'
                            }
                            isOpen={isDeleteConfirmation}
                            onConfirm={onClickHandle}
                            infoText={
                                confirmationMessage?.description ??
                                'DELETE_CONFIRMATION_MODAL_GENERIC_DESCRIPTION'
                            }
                            onCancel={e => {
                                e.stopPropagation();
                                setIsDeleteConfirmation(false);
                            }}
                        />
                    </>
                ) : (
                    ''
                )
            }
            animation="fade"
            appendTo={props.appendTo || 'parent'}
            delay={0}
            duration={0}
            interactive={true}
            onCreate={setInstance}
            placement={placement}
            trigger="click"
        >
            {children}
        </Popover>
    );
};

MenuPopover.propTypes = {
    animation: PropTypes.string,
    delay: PropTypes.array,
    disabled: PropTypes.bool,
    duration: PropTypes.array,
    interactive: PropTypes.bool,
    options: PropTypes.array,
    placement: PropTypes.string,
    smallMenu: PropTypes.bool,
};

MenuPopover.defaultProps = {
    animation: 'shift-away',
    delay: [0, 20],
    disabled: false,
    duration: [275, 250],
    interactive: false,
    options: [],
    placement: 'top',
    smallMenu: false,
};

export default MenuPopover;
