import React, { useEffect, useState } from 'react';
import { compose, branch } from 'recompose';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { SketchPicker } from 'react-color';
import { triggerOnBlur, useDebounce } from '@mspecs/shared-utils';
import {
    withLabel,
    withInputValidation,
    withFinalFormField,
} from '../../../utils/hoc';
import { InputField } from '.';

const Circle = styled.div`
    margin-left: 2px;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const ColorPicker = styled(SketchPicker)`
    display: ${props => (props.showColorPicker ? 'block' : 'none')};
    position: absolute;
    z-index: 1;
    ${({ rightPlacement }) =>
        rightPlacement ? `left: 30px;top: 30px;` : `right: 40px`}
`;

const ColorPickerInput = ({
    rightPlacement,
    onChange,
    value,
    ...inputProps
}) => {
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [localValue, setLocalValue] = useState(value);
    const debouncedValue = useDebounce(localValue || null, 500);

    const handleChangeComplete = color => setLocalValue(color.hex);

    useEffect(() => {
        if (value !== debouncedValue && debouncedValue !== null) {
            onChange(debouncedValue);
        }
    }, [debouncedValue, value]);

    useEffect(() => {
        // Not ideal, but we need to update the local value when the value prop changes
        setLocalValue(value);
    }, [value]);

    const closeColorPicker = () => setShowColorPicker(false);
    const ref = triggerOnBlur(closeColorPicker, showColorPicker);

    return (
        <Flex
            width={1}
            ref={ref}
            onClick={() => setShowColorPicker(true)}
            tabIndex={0}
            onKeyDown={e =>
                e.key === 'Enter' && setShowColorPicker(!showColorPicker)
            }
            className={inputProps.className}
        >
            <InputField
                name="color-picker"
                {...inputProps}
                value={localValue}
                onChange={e => setLocalValue(e.target.value)}
            />
            <Circle color={localValue}>
                <ColorPicker
                    rightPlacement={rightPlacement}
                    color={localValue}
                    showColorPicker={showColorPicker}
                    onChangeComplete={handleChangeComplete}
                    disableAlpha={true}
                    presetColors={[
                        '#F5A623',
                        '#7ED321',
                        '#417505',
                        '#4A90E2',
                        '#000000',
                        '#4A4A4A',
                        '#9B9B9B',
                        '#FFFFFF',
                    ]}
                />
            </Circle>
        </Flex>
    );
};

ColorPickerInput.propTypes = {
    disableAlpha: PropTypes.bool,
    handleChangeComplete: PropTypes.func,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    rightPlacement: PropTypes.bool,
};

export default compose(
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel
)(ColorPickerInput);
