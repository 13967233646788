import React, { Component } from 'react';
import { Flex, Box } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { translate } from '@mspecs/shared-utils';
import { LoadingBar } from '../animations';

class MspxPdfLoader extends Component {
    constructor(props) {
        super(props);
        this.translatedConstant = props.t('LOADING_PDF');
        this.intervalIdHolder = null;

        this.state = {
            numberOfDots: 1,
        };
    }

    componentDidMount() {
        this.intervalIdHolder = setInterval(() => {
            const newNumberOfDots =
                this.state.numberOfDots > 2 ? 1 : this.state.numberOfDots + 1;
            this.setState({ numberOfDots: newNumberOfDots });
        }, 500);
    }

    componentWillUnmount() {
        if (this.intervalIdHolder) {
            clearInterval(this.intervalIdHolder);
        }
    }

    parseLoadingConstant() {
        return `${this.translatedConstant}${'.'.repeat(
            this.state.numberOfDots
        )}`;
    }

    render() {
        return (
            <Flex flexDirection="column" alignItems="center">
                <Box width="800px">
                    <LoadingBar />
                </Box>
            </Flex>
        );
    }
}

MspxPdfLoader.propTypes = {
    className: PropTypes.any,
    t: PropTypes.func.isRequired,
};

MspxPdfLoader.defaultProps = {
    className: '',
};

export default translate(MspxPdfLoader);
