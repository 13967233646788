const defaultState = {
    sessionId: null,
    sessionUpdatedDate: null,
    filterId: null,
    email: {
        header: '',
        content: '',
    },
    sms: {
        content: '',
    },
    letter: {
        header: '',
        content: '',
    },
    dealFilterNodes: {
        0: {
            id: '0',
            type: 'Group',
            condition: 'OR',
            childNodes: ['eec2229d-ef7c-4a45-b91a-f15bc7dde759'],
        },
        'eec2229d-ef7c-4a45-b91a-f15bc7dde759': {
            type: 'Rule',
            operator: '_neq',
            value: 'Testnamn',
            name: 'displayName',
            schema: 'Deal',
            parentId: '0',
            id: 'eec2229d-ef7c-4a45-b91a-f15bc7dde759',
        },
    },
    contactFilterNodes: {
        0: {
            id: '0',
            type: 'Group',
            condition: 'OR',
            childNodes: ['294b5ed7-97e3-4903-a4f7-a22cab36d64b'],
        },
        '294b5ed7-97e3-4903-a4f7-a22cab36d64b': {
            type: 'Rule',
            operator: '_neq',
            value: 'Testnamn',
            name: 'firstName',
            schema: 'Contact',
            parentId: '0',
            id: '294b5ed7-97e3-4903-a4f7-a22cab36d64b',
        },
    },
    selectedMethods: [],
};

const communicateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'COMMUNICATE_LEAVE': {
            return defaultState;
        }
        case 'SESSION_UPDATED': {
            return {
                ...state,
                sessionUpdatedDate: action.payload.updatedDate,
            };
        }
        case 'SESSION_CREATED': {
            return {
                ...state,
                filterId: action.payload.filterId,
                sessionId: action.payload.sessionId,
                sessionUpdatedDate: action.payload.updatedDate,
            };
        }
        case 'DEALFILTER_UPDATED': {
            return {
                ...state,
                dealFilterNodes: action.payload,
            };
        }
        case 'CONTACTFILTER_UPDATED': {
            return {
                ...state,
                contactFilterNodes: action.payload,
            };
        }
        case 'COMMUNICATEMETHOD_SELECTED': {
            return {
                ...state,
                selectedMethods: action.payload,
            };
        }
        case 'EMAIL_UPDATED': {
            return {
                ...state,
                email: {
                    ...state.email,
                    ...action.payload,
                },
            };
        }
        case 'LETTER_UPDATED': {
            return {
                ...state,
                letter: {
                    ...state.letter,
                    ...action.payload,
                },
            };
        }
        case 'SMS_UPDATED': {
            return {
                ...state,
                sms: {
                    ...state.sms,
                    ...action.payload,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default communicateReducer;
