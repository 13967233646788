import React from 'react';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';

const NoDataContainer = styled.div`
    padding: 30px 15px;
`;

const NoSearchResults = () => {
    const { t } = useTranslate();
    return (
        <NoDataContainer>
            <h1>{t('TABLE_NO_SEARCH_RESULTS')}</h1>
            {t('TABLE_NO_SERACH_RESULTS_SUB')}
        </NoDataContainer>
    );
};

export default NoSearchResults;
