import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { compose, branch, withProps } from 'recompose';
import {
    withInputValidation,
    withFinalFormField,
    ErrorWrapper,
} from '../../../utils/hoc';
import { withValidationWrapper } from '../../../utils/hoc/validation-wrapper-component';
import { UrlButton } from '../../button/icon-button';
import { InputField } from '.';

export const urlPattern =
    /^http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const TestLinkButton = styled(UrlButton)`
    margin-left: 2px;
    margin-bottom: 20px;
`;

const UrlInput = ({ noTestLink, children, ...restProps }) => {
    const { value, invalid, ...errorWrapperProps } = restProps;

    return (
        <ErrorWrapper {...errorWrapperProps}>
            <Flex flexDirection="row" alignItems="flex-end" width={1}>
                <InputField name="url" {...restProps} />
                {!noTestLink && (
                    <TestLinkButton
                        hiddenLabel="CUSTOM_FIELDS_TEST_LINK"
                        disabled={invalid || !value}
                        onClick={() => window.open(value)}
                    />
                )}
            </Flex>
        </ErrorWrapper>
    );
};

UrlInput.propTypes = {
    invalid: PropTypes.bool,
    value: PropTypes.string,
    noTestLink: PropTypes.bool,
};

UrlInput.defaultProps = {
    invalid: false,
    value: '',
    noTestLink: false,
};

export default compose(
    withProps(ownerProps => ({
        validationPttrn: ownerProps.validationPttrn || urlPattern,
        errorMessage: 'VALIDATION_ERROR_URL',
    })),
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(UrlInput);
