import React, {
    useReducer,
    useEffect,
    useCallback,
    createContext,
    Children,
} from 'react';
import { compose } from 'recompose';
import { flattenDepth } from 'lodash-es';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import ButtonBar from './wiz-button-bar-component';
import PathHeader from './wiz-path-header-component';
import Step from './step-component';
import { wizReducer, wizActions, initialState } from './wiz-reducer';
import { translate } from '@mspecs/shared-utils';

const StyledWizard = styled(Flex)`
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.bgSecondary};
`;

const StyledStepsContainer = styled(Flex)`
    flex-grow: 1;
    overflow: auto;
    padding-right: 15px;
    padding-left: 15px;
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        background-color: #efefef;
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #ddd;
    }
`;

export const WizContext = createContext({});

const Wiz = props => {
    const {
        children,
        endButtonLabel,
        useOwnEndButton,
        hasValidation,
        hideNonActive,
        onCancel,
        onEnd,
        t,
        style,
    } = props;

    const [state, dispatch] = useReducer(wizReducer, initialState);
    const { current, stepPropList, validStepMap } = state;
    const next = useCallback(() => {
        dispatch({ type: wizActions.NEXT_STEP });
    }, []);

    const prev = useCallback(() => {
        dispatch({ type: wizActions.PREVIOUS_STEP });
    }, []);

    const goToStep = useCallback(index => {
        dispatch({ type: wizActions.GO_TO_STEP, payload: { index } });
    }, []);

    const setStepIsValid = useCallback(
        (stepLabel, isValid) =>
            dispatch({
                type: wizActions.UPDATE_STEP_IS_VALID,
                payload: {
                    label: stepLabel,
                    isValid,
                },
            }),
        []
    );

    useEffect(() => {
        const newStepProps = Children.toArray(children).map(rootStep => {
            const { children: rootStepChildren, ...childLessProps } =
                rootStep.props;

            if (rootStep.type.displayName === Step.displayName) {
                return [childLessProps];
            }
            let branchSteps = [];

            if (childLessProps.path !== '') {
                const activePath = Children.toArray(rootStepChildren).find(
                    child => child.props.pathId === childLessProps.path
                );

                branchSteps = Children.map(
                    activePath.props.children,
                    ({ props }) => {
                        const { children, ...branchStepProps } = props;
                        return branchStepProps;
                    }
                );

                return [...branchSteps];
            }
            return [childLessProps];
        });

        dispatch({
            type: wizActions.UPDATE_STEPS,
            payload: flattenDepth(newStepProps, 1),
        });
    }, [children]);

    if (!current.step) {
        return null;
    }

    return (
        <WizContext.Provider
            value={{
                prev,
                next,
                hideNonActive,
                hasValidation,
                setStepIsValid,
                current: current,
                stepProps: stepPropList,
            }}
        >
            <StyledWizard style={style}>
                <PathHeader
                    t={t}
                    stepProps={stepPropList}
                    currentIndex={current.index}
                    currentStep={current.step}
                    goToStep={goToStep}
                    hasValidation={hasValidation}
                    validStepMap={validStepMap}
                />
                <StyledStepsContainer>{children}</StyledStepsContainer>
                {!current?.step?.independent && (
                    <ButtonBar
                        t={t}
                        onCancel={onCancel}
                        onEnd={onEnd}
                        endButtonLabel={endButtonLabel}
                        useOwnEndButton={useOwnEndButton}
                        validStepMap={validStepMap}
                    />
                )}
            </StyledWizard>
        </WizContext.Provider>
    );
};

Wiz.propTypes = {
    children: PropTypes.node,
    endButtonLabel: PropTypes.string,
    hasValidation: PropTypes.bool,
    hideNonActive: PropTypes.bool,
    onCancel: PropTypes.func,
    onEnd: PropTypes.func,
    useOwnEndButton: PropTypes.object,
    style: PropTypes.object,
    t: PropTypes.func,
};

Wiz.defaultProps = {
    hasValidation: false,
};

export default compose(translate, React.memo)(Wiz);
