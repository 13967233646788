import { v4 as uuidv4 } from 'uuid';
import React, { useMemo } from 'react';

const useUniqueId = (defaultValue, dependencies = []) => {
    const id = useMemo(() => {
        return defaultValue ?? uuidv4();
    }, dependencies);
    return id;
};

export default useUniqueId;
