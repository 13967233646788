import PropTypes from 'prop-types';

export const TableColumPropType = PropTypes.shape({
    accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
    ]),
    Cell: PropTypes.any,
});
