import { ACTION_TYPES } from '../actions/object-description-v2-actions';

const initialState = {
    printOptions: {
        shortSellingText: true,
        objectDescription: true,
        mainImage: false,
        manualTenantListSummary: false,
        locationDescription: true,
        longSellingText: false,
        cityAddressTitle: false,
        signature: 'NONE',
        images: 'NONE',
        floorPlan: false,
        map: false,
        tenantListSummary: false,
        showPageNumbers: false,
        isCoverPage: false,
        logoPosition: 'LEFT',
        headingPosition: 'LEFT',
        header: 'HEADING_STREET_ADDRESS',
        isImageCaption: false,
        objectInformation: true,
        frontPageLayout: 'FRONT_PAGE_LAYOUT_MAIN_IMAGE',
        isFrontPageShortSellingText: false,
        isSolitaryBasicData: false,
        addQrScanCode: false,
        headingSellingDescription: true,
        headingandShortSalePosition: 'CENTER',
        contentLayout: 'OBJECT_DESCRIPTION_LAYOUT_TEMPLATE_1',
        isShortFactsOnFrontPage: false,
        hideBrokerInfo: false,
        appendixes: [],
    },
    hiddenPrintOptions: {
        watermark: '',
        type: 'full',
    },
    metaData: {
        signatures: {
            buyers: [],
            sellers: [],
            anonymous: [],
        },
    },
    id: null,
    esign: {
        digitalSignature: {},
    },
    isEditConfirmation: false,
};

export default function objectDescription(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_PRINT_OPTIONS: {
            return {
                ...state,
                printOptions: {
                    ...state.printOptions,
                    ...action.payload.options,
                },
            };
        }
        case ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_SIGNATURES: {
            const { options, signatures } = action.payload;
            return {
                ...state,
                printOptions: {
                    ...state.printOptions,
                    ...options,
                },
                metaData: {
                    signatures: {
                        ...state.metaData.signatures,
                        ...signatures,
                    },
                },
            };
        }
        case ACTION_TYPES.GET_OBJECT_DESCRIPTION_OPTIONS: {
            let { id, options } = action.payload;
            const { printOptions, ...rest } = options;

            if (id) {
                options = {
                    printOptions: {
                        ...initialState.printOptions,
                        ...printOptions,
                    },
                    ...rest,
                };
            } else {
                options = {
                    ...initialState,
                    printOptions: {
                        ...initialState.printOptions,
                        ...printOptions,
                    },
                };
            }
            return {
                ...state,
                ...options,
                id: id,
            };
        }
        case ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_OPTIONS: {
            const { options, id } = action.payload;
            return {
                ...state,
                ...options,
                id,
            };
        }
        case ACTION_TYPES.SET_ESIGN_STATUS: {
            const esign = action.payload;
            return {
                ...state,
                esign,
                isEditConfirmation: !!esign.envelopeUid,
            };
        }
        case ACTION_TYPES.SET_EDIT_CONFIRMATION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
}
