import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons';

const Container = styled(Flex)`
    flex-direction: row;
    margin: 10px 0 15px 0;
    padding-right: 15px;
    padding-left: 15px;
    letter-spacing: 0.05em;
    font-size: 0.85em;
    text-transform: uppercase;
`;

const getPathColor = ({ isHistory, isFuture, theme }) =>
    isHistory || isFuture
        ? theme.colors.primaryColor5
        : theme.colors.primaryColor;

const ARROW_SIZE = 20;
const HALF_ARROW_SIZE = ARROW_SIZE / 2;
const SQUARE_HEIGHT = parseFloat(ARROW_SIZE * Math.sqrt(2)).toFixed(2);

const ArrowContainer = styled(Flex)`
    position: relative;
    background: ${getPathColor};
    color: #${({ isFuture, isHistory }) => (isFuture || isHistory ? '222' : 'fff')};
    margin-right: ${HALF_ARROW_SIZE}px;
    cursor: ${({ disabled, clickNotAllowed }) =>
        disabled || clickNotAllowed ? 'not-allowed' : 'pointer'};
    display: ${({ hideLabel }) => hideLabel && 'none'};
    height: ${SQUARE_HEIGHT}px;
    align-items: center;
`;

const ArrowHead = styled.div`
    position: absolute;
    border-left: solid ${ARROW_SIZE}px ${getPathColor};
    border-bottom: solid ${ARROW_SIZE}px ${getPathColor};
    transform: rotate(45deg);
    right: -${HALF_ARROW_SIZE}px;
    top: 4px;
    z-index: 1;
`;

const ArrowTail = styled.div`
    position: absolute;
    border-left: solid ${ARROW_SIZE}px
        ${({ theme }) => theme.colors.primaryColor5};
    border-bottom: solid ${ARROW_SIZE}px
        ${({ theme }) => theme.colors.primaryColor5};
    transform: rotate(45deg);
    left: -${HALF_ARROW_SIZE}px;
    border-color: ${({ theme }) => theme.colors.white};
    top: 4px;
`;

const TextContainer = styled.div`
    margin-left: ${({ isFirst }) => !isFirst && `${ARROW_SIZE}px`};
    padding: 0 1.5em;
    z-index: 1;
    opacity: ${({ disabled }) => disabled && '0.5'};
    white-space: nowrap;
`;

const BranchIcon = styled(FontAwesomeIcon)`
    marginleft: -4px;
    font-size: 16px;
`;

export const PathItem = ({
    clickNotAllowed,
    isFirst,
    isHistory,
    isFuture,
    disabled,
    hideLabel,
    children,
    onClick,
    t,
}) => {
    return (
        <ArrowContainer
            isHistory={isHistory}
            isFuture={isFuture}
            hideLabel={hideLabel}
            clickNotAllowed={clickNotAllowed}
            disabled={disabled}
            onClick={onClick}
        >
            {!isFirst && <ArrowTail />}
            <ArrowHead isHistory={isHistory} isFuture={isFuture} />
            <TextContainer isFirst={isFirst} disabled={disabled} t={t}>
                {children}
            </TextContainer>
        </ArrowContainer>
    );
};

const StepHeader = ({
    t,
    goToStep,
    stepProps,
    currentIndex,
    hasValidation,
    validStepMap = {},
}) => {
    const handleDisabled = index => {
        if (index === 0) {
            return false;
        }
        const previousStepProps = stepProps[index - 1];
        return (
            hasValidation &&
            !(
                validStepMap[previousStepProps.label] === true ||
                previousStepProps.isValidated
            )
        );
    };

    return (
        <Container>
            {stepProps.map(({ label, hideLabel, path }, index) =>
                path === '' ? (
                    <PathItem isFuture clickNotAllowed key={index}>
                        <BranchIcon icon={faCodeBranch} flip="vertical" />
                    </PathItem>
                ) : (
                    <PathItem
                        key={index}
                        isFirst={index === 0}
                        isHistory={index < currentIndex}
                        isFuture={index > currentIndex}
                        hideLabel={hideLabel}
                        disabled={handleDisabled(index)}
                        onClick={() =>
                            !handleDisabled(index) && goToStep(index)
                        }
                    >
                        {`${index + 1}. ${t(label)}`}
                    </PathItem>
                )
            )}
        </Container>
    );
};

PathItem.propTypes = {
    children: PropTypes.node,
    clickNotAllowed: PropTypes.bool,
    disabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    isFirst: PropTypes.bool,
    isFuture: PropTypes.bool,
    isHistory: PropTypes.bool,
    t: PropTypes.func,
    onClick: PropTypes.func,
};

StepHeader.propTypes = {
    currentIndex: PropTypes.number,
    hasValidation: PropTypes.bool,
    goToStep: PropTypes.func,
    t: PropTypes.func,
    stepProps: PropTypes.array,
};

export default StepHeader;
