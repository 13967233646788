import thunk from 'redux-thunk';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';
import * as actions from './actions';
import mspxFetchMW from './middleware/request-mw';

/* eslint-disable */
let composeEnhancers = compose;
if (
    NODE_ENV !== 'production' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}
/* eslint-enable */

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk), applyMiddleware(mspxFetchMW))
);

/* Angular-lib shortcuts */
export { actions };
export const { dispatch, getState } = store;
