import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from '@rebass/grid/emotion';
import {
    Routes,
    Route,
    Navigate,
    NavLink,
    useNavigate,
} from 'react-router-dom';

import { useTheme } from '@emotion/react';
import { removeWildCardFromPath } from '../utils/path';
import { medium } from '@mspecs/shared-utils';
import { rem } from '@mspecs/shared-utils';
import { useTranslate } from '@mspecs/shared-utils';
import { Select } from '../../input';
import FrameContainer from '../../frame-container/frame-container-component';

const MenuItemPropType = PropTypes.shape({
    element: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    elementProps: PropTypes.object,
    label: PropTypes.string,
    path: PropTypes.string.isRequired,
    css: PropTypes.object,
    ignoreRedirect: PropTypes.bool,
});

const InsetMenuWrapper = styled(Flex)`
    overflow-y: hidden;
    flex-grow: 1;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.medium}) {
        flex-direction: row;
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    width: 215px;
    min-width: 215px;
    height: 100%;
    background: white;
    border-right: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    overflow-y: auto;
    overflow-x: hidden;
`;

const selectedMenuItemStyle =
    theme =>
    ({ isActive }) => ({
        background: isActive
            ? theme.colors.primaryColor
            : theme.colors.bgPrimary,
        color: isActive ? '#FFF' : '#000',
        fontWeight: isActive && '600',

        '&:hover': {
            backgroundColor: !isActive && theme.colors.primaryColor5,
        },
    });

const MenuItem = styled(NavLink)`
    text-transform: uppercase;
    padding: 16px 22.4px;
    height: 50px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    font-size: ${rem(12)};
    transition: background-color 200ms ease-in-out;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: none;
    }
`;

const SelectMenuContainer = styled.div`
    display: block;
    background: ${({ theme }) => theme.colors.bgPrimary};
    width: 100%;
    padding: 10px 15px;
`;

const SelectMenu = styled(props => <Select {...props} />)`
    width: 280px;
`;

const MenuContent = styled(Box)`
    position: relative;
    overflow-y: auto;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.medium}) {
        border-top: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    }
`;

const InsetMenuItem = ({ item }) => {
    const { t } = useTranslate();
    const theme = useTheme();

    return (
        <Box>
            <MenuItem
                to={removeWildCardFromPath(item.path)}
                css={item.css}
                style={selectedMenuItemStyle(theme)}
            >
                {t(item.label)}
            </MenuItem>
        </Box>
    );
};

InsetMenuItem.propTypes = {
    item: MenuItemPropType,
};

const MobileMenu = ({ items = [] }) => {
    const navigate = useNavigate();
    const toSelectItem = item => ({ label: item.label, value: item.path });
    const options = useMemo(() => items.map(toSelectItem), [items]);

    return (
        <SelectMenuContainer>
            <SelectMenu
                options={options}
                sort={false}
                isClearable={false}
                onChange={navigate}
            />
        </SelectMenuContainer>
    );
};

MobileMenu.propTypes = {
    items: PropTypes.arrayOf(MenuItemPropType),
};

const InsetMenu = ({ items = [], contentCss, menuCss, breadCrumbs }) => {
    const isSmall = useMediaQuery({ query: `(max-width:${medium})` });
    const firstItem = items.find(item => !item.ignoreRedirect);
    const itemsIncludingLabel = items.filter(item => item.label);

    const itemsWithElement = items.map(item => {
        let Element = item.element || null;
        if (typeof Element === 'function') {
            const props = item.elementProps || {};
            Element = <Element {...props} />;
        }
        return { ...item, element: Element };
    });

    return (
        <InsetMenuWrapper>
            {isSmall ? (
                <MobileMenu items={itemsIncludingLabel} />
            ) : (
                <Menu css={menuCss}>
                    {itemsIncludingLabel.map(item => (
                        <InsetMenuItem item={item} key={item.label} />
                    ))}
                </Menu>
            )}
            <MenuContent css={contentCss}>
                <FrameContainer breadCrumbs={breadCrumbs}>
                    <Routes>
                        {itemsWithElement.map(item => (
                            <Route {...item} key={item.path} />
                        ))}
                        <Route
                            element={<Navigate to={firstItem.path} replace />}
                            path="*"
                        />
                    </Routes>
                </FrameContainer>
            </MenuContent>
        </InsetMenuWrapper>
    );
};

InsetMenu.propTypes = {
    contentCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    innerContentCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    menuCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    items: PropTypes.arrayOf(MenuItemPropType),
};

InsetMenu.whyDidYouRender = true;
export default memo(InsetMenu);
