import { useCallback, useState } from 'react';
import { useWindowSize } from '@mspecs/shared-utils';

export default function useTableOverflowing({ hasBodyWrapper, itemsCount }) {
    const [isTableOverflowing, setIsTableOverflowing] = useState({
        x: false,
        y: false,
    });
    const [isTableRowsFillingBody, setIsTableRowsFillingBody] = useState(false);
    const { width: windowWidth } = useWindowSize();

    const overflowRef = useCallback(
        node => {
            if (!node?.firstChild || !itemsCount) return;
            const contentNode = node.firstChild;
            const bodyNode = hasBodyWrapper
                ? contentNode.firstChild
                : contentNode;

            setIsTableRowsFillingBody(
                bodyNode.clientHeight >= node.clientHeight
            );
            setIsTableOverflowing({
                x: bodyNode.scrollWidth > bodyNode.clientWidth,
                y: bodyNode.scrollHeight > bodyNode.clientHeight,
            });
        },
        [hasBodyWrapper, itemsCount, windowWidth]
    );

    return { isTableOverflowing, isTableRowsFillingBody, overflowRef };
}
