import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { components } from 'react-select';
import { MspxSelect as Select } from './select-component';
import { VisualCheckbox } from '../checkbox-component';
import { Flex } from '@rebass/grid/emotion';
import { RadioButton } from '../radio-button-group';
import { getSelectStyles } from './styled-components';
import { useMemo } from 'react';

const SelectWrapper = styled(Flex)`
    padding-right: 10px;
    align-items: center;
`;
const Option = props => {
    const { label } = props.data;
    const { isSelected, isMulti, selectOption, value } = props;

    return (
        <components.Option {...props}>
            <SelectWrapper>
                {isMulti ? (
                    <VisualCheckbox checked={isSelected} />
                ) : (
                    <RadioButton
                        checked={isSelected}
                        onChange={() => selectOption(value)}
                    />
                )}
            </SelectWrapper>

            <Flex alignItems="center" width="100%">
                {label}
            </Flex>
        </components.Option>
    );
};

const ValueContainer = props => {
    const { selectProps, children } = props;
    const { value, placeholder } = selectProps;
    const valueLength = value && value.length;

    return (
        <components.ValueContainer {...props}>
            {valueLength > 0
                ? `${placeholder || value[0].label} (${valueLength})`
                : placeholder}
            {children[1]}
        </components.ValueContainer>
    );
};
const SelectSmallDropdown = props => {
    const { colors } = useTheme();
    const originalStyle = getSelectStyles({});

    const customComponents = useMemo(() => {
        const items = {
            Option,
            ClearIndicator: null,
            MenuList: components.MenuList,
        };
        if (props.isMulti) {
            items.ValueContainer = ValueContainer;
        }

        return items;
    }, [props.isMulti]);

    return (
        <Flex>
            <Select
                hideSelectedOptions={false}
                closeMenuOnSelect={!props.isMulti}
                blur
                sort={false}
                {...props}
                components={customComponents}
                styles={{
                    container: provided => ({
                        ...provided,
                        boxShadow: 'none',
                    }),
                    control: (provided, props) => ({
                        ...originalStyle.control(provided, props),
                        border: 'none',
                        textDecoration: props.menuIsOpen ? 'underline' : 'none',
                        justifyContent: 'center',
                        flexWrap: 'nowrap',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        flex: 'none',
                        display: 'flex',
                        padding: 0,
                    }),
                    indicatorsContainer: provided => ({
                        ...provided,
                        height: '24px',
                        width: '24px',
                        alignSelf: 'center',
                    }),
                    placeholder: provided => ({
                        ...provided,
                        color: colors.textPrimary,
                    }),
                    option: (provided, props) => ({
                        ...originalStyle.option(provided, props),
                        height: 40,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        background: colors.bgPrimary,
                        '&:hover': {
                            background: colors.primaryColor10,
                        },
                    }),
                    menu: provided => ({
                        ...originalStyle.menu(provided, props),
                        width: 'unset',
                    }),
                    singleValue: provided => ({
                        ...provided,
                        marginRight: 0,
                        marginLeft: 0,
                    }),
                }}
            />
        </Flex>
    );
};

SelectSmallDropdown.propTypes = Select.propTypes;

export default SelectSmallDropdown;
