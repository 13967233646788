import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import UrlInput from './url-input-component';
import { useTranslate } from '@mspecs/shared-utils';
import { getVariableMatches } from '@mspecs/shared-utils';

export const urlPatternWithVariable =
    /^http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~{}:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const Variables = styled.div`
    font-size: ${({ theme }) => theme.fontSizeSmall};
    position: absolute;
    bottom: -15px;
    white-space: nowrap;
`;
const Variable = styled.span`
    font-weight: bold;
    font-style: italic;
`;

const UrlVariableInput = ({
    validVariables = [],
    anyOfRequiredVariables = [],
    ...props
}) => {
    const { t } = useTranslate();
    const { value, stackedLabel } = props;

    const foundVariables = useMemo(
        () => getVariableMatches(value).filter(v => validVariables.includes(v)),
        [value, validVariables]
    );

    const validationFun = url => {
        const matches = getVariableMatches(url);
        if (matches.some(variable => !validVariables.includes(variable))) {
            return t('URL_VARIABLE_INVALID_MESSAGE', {
                variables: validVariables.join(', '),
            });
        }
        const anyOfValid =
            anyOfRequiredVariables.length === 0 ||
            anyOfRequiredVariables.some(v => matches.includes(v));

        if (!anyOfValid) {
            return t('URL_VARIABLE_MISSING_REQUIRED_VARIABLE_MESSAGE', {
                variables: anyOfRequiredVariables.join(', '),
            });
        }
    };

    return (
        <UrlInput
            {...props}
            validationPttrn={urlPatternWithVariable}
            validationFun={validationFun}
        >
            {foundVariables.length > 0 && (
                <Variables stackedLabel={stackedLabel}>
                    {`${t('URL_VARIABLE_FOUND_VARIABLES')}: `}
                    <Variable>{foundVariables.join(', ')}</Variable>
                </Variables>
            )}
        </UrlInput>
    );
};

UrlVariableInput.propTypes = {
    validVariables: PropTypes.array,
    anyOfRequiredVariables: PropTypes.array,
    value: PropTypes.string,
    stackedLabel: PropTypes.bool,
};

export default UrlVariableInput;
