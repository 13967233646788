import { get } from 'lodash-es';
import { getDisplayName } from '@mspecs/shared-utils';

const PersonNameCellRenderer = ({ column, row }) => {
    const [firstName, lastName] = column.fields.map(field =>
        get(row.original, field)
    );

    return getDisplayName({ firstName, lastName });
};

export default PersonNameCellRenderer;
