import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useStack from './hooks/use-stack';
import { useTranslate } from '@mspecs/shared-utils';
import { BackButton } from '../../button';

const Header = styled.div`
    display: flex;
    flex: 1;
    max-height: 47px;
    align-items: center;
    padding: 10px 0 9px;
    position: relative;
    border-bottom: 0.11rem solid ${({ theme }) => theme.colors.borderPrimary};
    margin-bottom: 15px;
`;

const StyledH2 = styled.h2`
    color: ${({ theme }) => theme.colors.contrastBg75};
    font-size: 1.2rem;
    line-height: 1.4;
`;

const BackContainer = styled.div`
    margin-left: auto;
`;

const StackHeaderComponent = ({ header }) => {
    const { t } = useTranslate();
    const { stack, navigateStack } = useStack();

    if (!stack.length <= 1) {
        return null;
    }

    return (
        <Header>
            <StyledH2>{t(header)}</StyledH2>
            <BackContainer>
                <BackButton onClick={() => navigateStack(-1)} />
            </BackContainer>
        </Header>
    );
};

StackHeaderComponent.propTypes = {
    header: PropTypes.string,
};

export default StackHeaderComponent;
