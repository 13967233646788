import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { HelpingText } from '../../typograph/typograph-components';
import { useTranslate } from '@mspecs/shared-utils';

const MobileBottomMenuContainer = styled(Flex)`
    height: 79px;
    width: 100%;
    border-top: 2px solid ${({ theme }) => theme.colors.bgSecondary140};
    background: ${({ theme }) => theme.colors.bgPrimary};
`;
const IconWrapper = styled(Flex)`
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
`;

const StyledMobileMenuItem = styled(NavLink, {
    shouldForwardProp: prop => !['isActive'].includes(prop),
})`
    display: flex;
    position: relative;
    flex: 1;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing.default};
    &:hover {
        text-decoration: none;
    }
    :focus {
        box-shadow: none;
    }
    p,
    svg {
        color: ${({ theme, isActive }) =>
            isActive && theme.colors.primaryColor};
    }
`;
const LabelText = styled(HelpingText)`
    color: ${({ theme }) => theme.colors.textSecondary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ActiveIndicator = styled.div`
    height: 4px;
    width: 100%;
    background: ${({ theme }) => theme.colors.primaryColor};
    position: absolute;
    top: -2px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.4s ease-in-out;
`;

const MobileMenuItem = ({ label, icon, path, isActive, ...props }) => {
    return (
        <StyledMobileMenuItem to={path} isActive={isActive} {...props}>
            <ActiveIndicator isVisible={isActive} />
            <IconWrapper>{icon}</IconWrapper>
            <LabelText>{label}</LabelText>
        </StyledMobileMenuItem>
    );
};

function MobileBottomTabs({
    items,
    activePath,
    itemProps = item => {},
    ...props
}) {
    const { t } = useTranslate();

    return (
        <MobileBottomMenuContainer {...props}>
            {items.map(item => (
                <MobileMenuItem
                    key={item.label}
                    icon={item.icon}
                    path={item.path}
                    label={t(item.label)}
                    isActive={activePath === item.path}
                    {...itemProps(item)}
                />
            ))}
        </MobileBottomMenuContainer>
    );
}

export default MobileBottomTabs;

MobileBottomTabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
            icon: PropTypes.node,
        })
    ),
    activePath: PropTypes.string,
    itemProps: PropTypes.func,
};
