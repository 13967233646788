import { css } from '@emotion/react';
import { rem } from '@mspecs/shared-utils';
import {
    textPrimary,
    primaryColor,
    primaryColor90,
    primaryColor60,
    primaryColor10,
    borderPrimary,
} from '@mspecs/shared-utils';

const datePickerPrimaryColor = `var(--mspx-datepicker-background, ${primaryColor})`;

const datepickerBackgroundColor = datePickerPrimaryColor;
const datepickerBorderColor = 'none';
const datepickerMutedColor = '#ccc';
const darkenedDatepickerMutedColor = '#b3b3b3';
const datepickerSelectedColor = datePickerPrimaryColor;

const lightenedSelectedColor = `var(--mspx-datepicker-background--hover, ${primaryColor60})`;
const lightenedSelectedTextColor = `var(--mspx-datepicker-color--keyboard-selected, #fff)`;
const darkenedSelectedColor = datePickerPrimaryColor;
const extraDarkenedSelectedColor = `var(--mspx-datepicker-background--selected, ${primaryColor90})`;
const hoverBgColor = `var(--mspx-datepicker-background--hover, ${primaryColor10})`;
const datepickerTextColor = textPrimary;
const datepickerDayTextColor = '#fff';
const datepickerHeaderColor = '#fff';
const datepickerNavigationDisabledColor = '#e6e6e6';

const datepickerHighlightedColor = '#3dcc4a';
const darkenDatepickerHighlightedColor = '#32be3f';
const datepickerBorderRadius = 3;
const datepickerDayMargin = 1.4;
const datepickerFontSize = 14;
const datepickerFontFamily = 'inherit';
const datepickerItemSize = 30;
const datepickerMargin = `${rem(6)} ${rem(10)}`;
const datepickerNavigationSize = 6.3;
const datepickerTriangleSize = 10;

const triangleArrow = css`
    margin-left: -${datepickerTriangleSize}px;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: ${datepickerTriangleSize}px solid transparent;
        height: 0;
        border-radius: 3px;
        width: 2px;
    }

    &::before {
        content: '';
        z-index: -1;
        border-width: ${datepickerTriangleSize}px;
        left: -${datepickerTriangleSize}px;
        border-bottom-color: ${datepickerBorderColor};
    }
`;

const triangleArrowUp = css`
    ${triangleArrow}
    top: 1px;
    margin-top: -${datepickerTriangleSize}px;

    &,
    &::before {
        border-top: none;
        border-bottom-color: ${datepickerBackgroundColor};
    }

    &::before {
        top: 1px;
        border-bottom-color: ${datepickerBorderColor};
    }
`;

const triangleArrowDown = css`
    ${triangleArrow}
    bottom: 0;
    margin-bottom: -${datepickerTriangleSize}px;

    &,
    &::before {
        border-bottom: 0;
        border-top-color: #fff;
    }

    &::before {
        bottom: -1px;
        border-top-color: ${datepickerBorderColor};
    }
`;

const datePickerStyles = theme => css`

  .react-datepicker-wrapper {
    padding: 0;
    border: 0;
  }

  .react-datepicker {
    font-family: ${datepickerFontFamily};
    font-size: ${rem(datepickerFontSize)};
    background-color: #fff;
    color: ${datepickerTextColor};
    border: 1px solid ${datepickerBorderColor};
    border-radius: ${datepickerBorderRadius}px;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .react-datepicker__aria-live {
    display: none; // Temp fix for hiding aria-live label that make the ui look weird when changing month
  }

  .react-datepicker--time-only {
      width: 100%;
      .react-datepicker__triangle {
          display: none;
      }

      .react-datepicker__time-container {
          border-left: 0;
          width: 100%;
              margin-left: 0;

          .react-datepicker__time {
              border-radius: 4px;
              .react-datepicker__time-box {
                  width: 100%;
                  border-radius: 4px;
                  &::before {
                      display: none;
                  }
                  ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                    justify-content: flex-start;
                    padding: 0 10px;
                  }
              }
          }
      }
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;

    &[data-placement^='bottom'] {
      padding-top: ${datepickerTriangleSize / 2}px;

      .react-datepicker__triangle {
        ${triangleArrowUp};
      }
    }

    &[data-placement^='top'] {
      padding-bottom: ${datepickerTriangleSize / 2}px;

      .react-datepicker__triangle {
        ${triangleArrowDown};
      }
    }

    &[data-placement^='right'] {
      padding-left: ${datepickerTriangleSize}px;

      .react-datepicker__triangle {
        left: auto;
        right: 42px;
      }
    }

    &[data-placement^='left'] {
      padding-right: ${datepickerTriangleSize}px;

      .react-datepicker__triangle {
        left: 42px;
        right: auto;
      }
    }
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${datepickerBackgroundColor};
    border-bottom: 1px solid ${datepickerBorderColor};
    border-top-left-radius: ${datepickerBorderRadius}px;
    border-top-right-radius: ${datepickerBorderRadius}px;
    padding-top: 19px;
    position: relative;
    min-height: 80px;

    &--time {
      padding-bottom: 0px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-left: 5px;
      padding-right: 5px;
    }

    &.react-datepicker__header--time--only {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    &:first-letter {
      text-transform: uppercase;
    }

    padding-bottom: 12px;
    margin-top: 0;
    color: ${datepickerHeaderColor};
    font-size: 1rem;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 4px;
    font-size: ${rem(0.857142857 * datepickerFontSize)};
  }

  .react-datepicker__navigation {
    background: none;
    line-height: ${rem(datepickerItemSize)};
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 23px;
    padding: 0;
    border: none;
    height: 12px;
    width: 12px;

    span {
      text-indent: -999rem;
      font-size: 1px;
    }

    &::before {
      content: "";
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-bottom-left-radius: 2px;
      display: inline-block;
      width: 100%;
      text-indent: 0;
      height: 100%;
      position: absolute;
      z-index: 1;
    }

    &:hover::before {
      border-color: ${datepickerMutedColor};
    }

    &--previous {
      left: 20px;
        // border-right-color: ${datepickerMutedColor};
      &:before {
        transform: rotate(45deg);
      }

    }

    &--next {
      right: 20px;

      &:before {
        transform: rotate(-135deg);
      }

      &--with-time:not(&--with-today-button) {
        right: 84px;
      }
    }

    &--disabled,
    &--disabled:hover {
      border-color: ${datepickerNavigationDisabledColor};
      cursor: default;
    }

    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &-previous {
        top: 4px;
        border-top-color: ${datepickerMutedColor};

        &:hover {
          border-top-color: ${darkenedDatepickerMutedColor};
        }
      }

      &-upcoming {
        top: -4px;
        border-bottom-color: ${datepickerMutedColor};

        &:hover {
          border-bottom-color: ${darkenedDatepickerMutedColor};
        }
      }
    }
  }

  .react-datepicker__month-container {
    float: left;
    @media (min-width: 440px) {
      min-width: 237px;
    }
  }

  .react-datepicker__month {
    margin: ${datepickerMargin};
    text-align: center;

    .react-datepicker__month-text,
    .react-datepicker__quarter-text {
      display: inline-block;
      width: ${rem(56)};
      margin: 2px;
    }
  }

  .react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;

    .react-datepicker-time__caption {
      display: inline-block;
    }

    .react-datepicker-time__input-container {
      display: inline-block;

      .react-datepicker-time__input {
        display: inline-block;
        margin-left: 10px;

        input {
          width: 85px;
        }

        input[type='time']::-webkit-inner-spin-button,
        input[type='time']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='time'] {
          -moz-appearance: textfield;
        }
      }

      .react-datepicker-time__delimiter {
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  .react-datepicker__time-container {
    float: right;
    border-left: 1px solid ${datepickerBorderColor};
    width: 68px;
    margin-left: -3px;

    &--with-today-button {
      display: inline;
      border: 1px solid #aeaeae;
      border-radius: ${rem(4.2)};
      position: absolute;
      right: -72px;
      top: 0;
    }

    .react-datepicker__time {
      position: relative;
      background: white;

      .react-datepicker__time-box {
        width: 68px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;
        position: relative;
        
        &::before {
          content: "";
          position: absolute;
          top:10px;
          left:0;
          bottom:10px;
          border-left: solid 1px ${borderPrimary};
          width:2px;
        }
        
        ul.react-datepicker__time-list {
          list-style: none;
          margin: 0;
          height: calc(195px + ${rem(datepickerItemSize / 2)});
          overflow-y: scroll;
          padding-right: 0;
          padding-left: 0;
          width: 100%;
          box-sizing: content-box;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-radius: 0;
            background-color: #efefef;
          }

          &::-webkit-scrollbar {
            width: 6px;
            background-color: #fff;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            box-shadow: none;
            background-color: #ccc;
          }

          li.react-datepicker__time-list-item {
            height: 31px;
            padding: 0;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              background-color: ${hoverBgColor};
              color:  ${textPrimary};
            }

            &--selected {
              background-color: ${datepickerSelectedColor};
              color: white;
              font-weight: bold;

              &:hover {
                background-color: ${datepickerSelectedColor};
                color: white;
              }
            }

            &--disabled {
              color: ${datepickerMutedColor};

              &:hover {
                cursor: default;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .react-datepicker__week-number {
    color: ${datepickerMutedColor};
    display: inline-block;
    width: ${rem(datepickerItemSize)};
    line-height: ${rem(datepickerItemSize)};
    text-align: center;
    margin: ${rem(datepickerDayMargin)};

    &.react-datepicker__week-number--clickable {
      cursor: pointer;

      &:hover {
        border-radius: ${datepickerBorderRadius}px;
        background-color: ${datepickerBackgroundColor};
      }
    }
  }

  .react-datepicker__day-names {
    font-size: ${rem(0.857142857 * datepickerFontSize)};
    padding-bottom: 3px;
  }

  .react-datepicker__week {
    padding: 2px 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day.react-datepicker__day--outside-month {
    color: #c2c2c2;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${datepickerTextColor};
    display: inline-block;
    width: ${rem(datepickerItemSize)};
    height: ${rem(datepickerItemSize)};
    line-height: ${rem(datepickerItemSize)};
    text-align: center;
  }

  .react-datepicker__day-name {
    height: ${rem(datepickerItemSize * 0.7)};
    line-height: ${rem(datepickerItemSize * 0.7)};

    color: ${datepickerDayTextColor};

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .react-datepicker__month,
  .react-datepicker__quarter {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: ${datepickerBorderRadius}px;
      background-color: ${datepickerSelectedColor};
      color: #fff;

      &:hover {
        background-color: ${darkenedSelectedColor};
      }
    }

    &--disabled {
      color: ${datepickerMutedColor};
      pointer-events: none;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    cursor: pointer;

    &:hover {
      border-radius: ${rem(datepickerItemSize * 2)};
      background-color: ${hoverBgColor};
      box-shadow: 0px 0px 0px 1px ${hoverBgColor};
    }

    &--keyboard-selected {
      border-radius: ${rem(datepickerItemSize * 2)};
      background-color: ${lightenedSelectedColor};
      box-shadow: 0px 0px 0px 1px ${lightenedSelectedColor};
      color: ${lightenedSelectedTextColor};

      &:hover {
        background-color: ${darkenedSelectedColor};
      }
    }
    
    &--today {
      box-shadow: 0px 0px 0px 1px ${datepickerBackgroundColor};
      border-radius: ${rem(datepickerItemSize * 2)};
      background-color: #fff;
      color: ${datepickerTextColor};
    }

    &--highlighted {
      border-radius: ${datepickerBorderRadius}px;
      background-color: ${datepickerHighlightedColor};
      box-shadow: 0px 0px 0px 1px ${datepickerHighlightedColor};
      color: #fff;

      &:hover {
        background-color: ${darkenDatepickerHighlightedColor};
      }

      &-custom-1 {
        color: magenta;
      }

      &-custom-2 {
        color: green;
      }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: ${rem(datepickerItemSize * 2)};
      background-color: ${darkenedSelectedColor};
      box-shadow: 0px 0px 0px 1px ${darkenedSelectedColor};
      color: #fff;

      &:hover {
        background-color: ${extraDarkenedSelectedColor};
      }
    }

    &--in-selecting-range:not(&--in-range) {
      background-color: rgba(37, 90, 136, 0.5);
    }

    &--in-range:not(&--in-selecting-range) {
      .react-datepicker__month--selecting-range & {
        background-color: ${datepickerBackgroundColor};
        color: ${datepickerTextColor};
      }
    }

    &--disabled {
      cursor: default;
      color: ${datepickerMutedColor};

      &:hover {
        background-color: transparent;
      }
    }
  }

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range,
    &.react-datepicker__quarter--selected,
    &.react-datepicker__quarter--in-range {
      &:hover {
        background-color: ${datepickerSelectedColor};
      }
    }

    &:hover {
      background-color: ${datepickerBackgroundColor};
    }
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: ${datepickerBorderRadius}px;

    &:hover {
      cursor: pointer;

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow {
        border-top-color: ${darkenedDatepickerMutedColor};
      }
    }

    &--down-arrow {
      ${triangleArrowDown}
      border-top-color: ${datepickerMutedColor};
      float: right;
      margin-left: 20px;
      top: 8px;
      position: relative;
      border-width: ${rem(datepickerNavigationSize)};
    }
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: ${datepickerBackgroundColor};
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: ${datepickerBorderRadius}px;
    border: 1px solid ${datepickerBorderColor};

    &:hover {
      cursor: pointer;
    }

    &--scrollable {
      height: 150px;
      overflow-y: scroll;
    }
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      border-top-left-radius: ${datepickerBorderRadius}px;
      border-top-right-radius: ${datepickerBorderRadius}px;
    }

    &:last-of-type {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-bottom-left-radius: ${datepickerBorderRadius}px;
      border-bottom-right-radius: ${datepickerBorderRadius}px;
    }

    &:hover {
      background-color: ${datepickerMutedColor};

      .react-datepicker__navigation--years-upcoming {
        border-bottom-color: ${darkenedDatepickerMutedColor};
      }

      .react-datepicker__navigation--years-previous {
        border-top-color: ${darkenedDatepickerMutedColor};
      }
    }

    &--selected {
      position: absolute;
      left: 15px;
    }
  }

  .react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0px 6px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    &::after {
      cursor: pointer;
      background-color: ${datepickerSelectedColor};
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: \'\\00d7\';
    }
  }
}

.react-datepicker__today-button {
  background: ${datepickerBackgroundColor};
  border-top: 1px solid ${datepickerBorderColor};
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: ${rem(42)};
    line-height: ${rem(42)};
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: ${rem(28)};
      line-height: ${rem(28)};
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: ${rem(1.8 * datepickerFontSize)};
  }

  .react-datepicker__navigation {
    border: ${1.8 * datepickerNavigationSize}rem solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${datepickerMutedColor};

    &:hover {
      border-right-color: ${darkenedDatepickerMutedColor};
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: ${datepickerNavigationDisabledColor};
      cursor: default;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: ${datepickerMutedColor};

    &:hover {
      border-left-color: ${darkenedDatepickerMutedColor};
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: ${datepickerNavigationDisabledColor};
      cursor: default;
    }
  }
}
`;

export default datePickerStyles;
