import { useCallback, useRef } from 'react';

export default function useResizableListSize({
    defaultSize,
    getAdditionalSpace = () => 0,
}) {
    const sizeMap = useRef({});
    const listRef = useRef(null);

    const setSize = useCallback(
        (index, size) => {
            listRef.current.resetAfterIndex(index);
            sizeMap.current = {
                ...sizeMap.current,
                [index]: size,
            };
        },
        [sizeMap, listRef]
    );

    const getItemSize = useCallback(
        index =>
            sizeMap.current[index] + getAdditionalSpace(index) || defaultSize,
        [sizeMap.current, defaultSize]
    );

    return {
        getItemSize,
        setSize,
        listRef,
    };
}
