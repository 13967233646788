// Refactored from sUtil.js
function getFullPathFromPartial(partialPath) {
    return `/components/mspecs_components/static_images/files/${partialPath}`;
}

export function getExtension(originalURI) {
    const ext = /^.+\.([^.]+)$/.exec(originalURI);
    if (!ext || !ext.length) {
        return '';
    }
    return ext[1].toLowerCase();
}

export function getDefaultImageForFileType(originalURI) {
    let partialPath = '_blank.png';

    if (!originalURI) {
        return getFullPathFromPartial(partialPath);
    }

    switch (getExtension(originalURI)) {
        case 'jpg':
        case 'jpeg':
            partialPath = 'jpg.png';
            break;
        case 'png':
            partialPath = 'png.png';
            break;
        case 'pdf':
            partialPath = 'pdf.png';
            break;
        case 'docx':
        case 'doc':
            partialPath = 'doc_icon.jpg';
            break;
        case 'xlsx':
            partialPath = 'xlsx.png';
            break;
        case 'xls':
            partialPath = 'xls.png';
            break;
        case 'pptx':
        case 'ppt':
            partialPath = 'ppt.png';
            break;
        case 'txt':
            partialPath = 'txt.png';
            break;
        case 'zip':
        case 'rar':
            partialPath = 'zip.png';
            break;
        case 'xml':
            partialPath = 'xml.png';
            break;
        default:
            partialPath = '_blank.png';
    }
    return getFullPathFromPartial(partialPath);
}

export function downloadFileFromUrl(url) {
    window.open(url);
}

export function base64ToBlob(data) {
    let mimeString = '';
    const rImageType = /data:(image\/.+);base64,/;
    let raw = data.replace(rImageType, (header, imageType) => {
        mimeString = imageType;
        return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
}

export const isImageFile = extension =>
    ['jpg', 'png', 'gif'].includes(extension);

export const convertFileToImageObject = file => {
    if (!file?.type?.startsWith('image')) {
        return null;
    }

    const blobUrl = URL.createObjectURL(file);
    return {
        ...file,
        originalURI: blobUrl,
        thumbnailURI: blobUrl,
        fileSize: file.size,
        extension: file.type.split('/').pop(),
        title: file.name ?? 'newFile',
    };
};

export const downloadFile = async ({ url, title }) => {
    const blob = await fetch(url).then(res => res.blob());
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = title;
    anchor.click();
};
