import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const speedTime = {
    slow: '5s',
    medium: '3.5s',
    fast: '2s',
    faster: '1.5s',
};

const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 31px;
    height: 31px;
    color: ${({ color, theme }) => color ?? theme.colors.primaryColor};
    animation: spin linear ${props => speedTime[props.speed]} infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const LoadingSpinner = props => {
    return <SpinnerIcon icon={faSpinner} {...props} />;
};

LoadingSpinner.propTypes = {
    speed: PropTypes.oneOf(['slow', 'medium', 'fast', 'faster']),
    color: PropTypes.string,
};

LoadingSpinner.defaultProps = {
    speed: 'slow',
};

export default LoadingSpinner;
