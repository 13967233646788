import { legacyApi } from '@mspecs/shared-utils';

export function challengeSignupEmail(email) {
    return legacyApi(`/pub/api/v1/register/challenge/${email}`).get();
}

export function challengeGoToForm(signupToken) {
    return legacyApi(`/pub/api/v1/register/validate/${signupToken}`).get();
}

export function submitApplication(signupToken, data) {
    return legacyApi(`/pub/api/v1/register/submit/${signupToken}`).post(data);
}
