import React from 'react';
import { Provider } from 'react-redux';
import '@mspecs/ui-components/src/assets/global.css';
import { ThemeProvider, Global } from '@emotion/react';
import PublicLibRouter from './public-lib-router';
import { store } from '@mspecs/frontend-app/src/redux';
import { theme } from '@mspecs/shared-utils';
import { datePickerStyles } from '@mspecs/ui-components';

const rootElId = 'app';

import { createRoot } from 'react-dom/client';

const container = document.getElementById(rootElId);
const root = createRoot(container);
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Global styles={datePickerStyles} />
            <PublicLibRouter />
        </Provider>
    </ThemeProvider>
);
