import { useState, useEffect } from 'react';

// Inspired by https://usehooks.com/useDebounce/
export default function useDebounce(value, wait = 1000) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const tId = setTimeout(() => setDebouncedValue(value), wait);

        return () => clearTimeout(tId);
    }, [value, wait]);

    return debouncedValue;
}
