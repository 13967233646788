import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import OrderForm from '../features/login/order-mspx-form-component';
import OrderConfirmation from '../features/login/order-mspx-confirmation-component';
import {
    challengeGoToForm,
    submitApplication,
} from '~/utils/services/signup-service';
import { delay } from '@mspecs/shared-utils';

const OrderMspxFormPage = () => {
    const [registrateCustomerFail, setRegistrateCustomerFail] = useState(false);
    const [orderConfirmed, setOrderConfirmed] = useState(false);
    const [emailLinkIsNotValid, setEmailLinkIsNotValid] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [companyRegisteredAbroad, setCompanyRegisteredAbroad] =
        useState(false);
    const [customerType, setCustomerType] = useState('broker');

    let { signupToken } = useParams();

    useEffect(() => {
        checkIfSignUpToken();
    }, [signupToken]);

    const checkIfSignUpToken = async () => {
        try {
            const email = await challengeGoToForm(signupToken);
            setEmailAddress(email);
        } catch (e) {
            setEmailLinkIsNotValid(true);
        }
    };

    const handleRegisteredAbroad = () => {
        setCompanyRegisteredAbroad(!companyRegisteredAbroad);
    };

    const registrateCustomer = async data => {
        try {
            const { headOfficeAgreement, generalTerms, ...rest } = data;
            rest.isProvider = customerType === 'provider';
            await submitApplication(signupToken, rest);
            setOrderConfirmed(true);
        } catch (e) {
            setRegistrateCustomerFail(true);
            await delay(5000);
            setRegistrateCustomerFail(false);
        }
    };

    if (emailLinkIsNotValid) {
        return (
            <Navigate
                to={{
                    pathname: '/register',
                    state: {
                        emailLinkIsNotValid: emailLinkIsNotValid,
                    },
                }}
                replace
            />
        );
    } else if (orderConfirmed) {
        return <OrderConfirmation />;
    } else {
        return (
            <OrderForm
                registrateCustomer={registrateCustomer}
                registrateCustomerFail={registrateCustomerFail}
                emailAddress={emailAddress.email}
                handleRegisteredAbroad={handleRegisteredAbroad}
                companyRegisteredAbroad={companyRegisteredAbroad}
                customerType={customerType}
                setCustomerType={setCustomerType}
            />
        );
    }
};

export default OrderMspxFormPage;
