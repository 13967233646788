import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import { StyledPopover } from '../popovers';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const HelpIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 20px;
    :hover {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`;

const HelpPopoverIcon = ({ helpText, ...props }) => {
    return (
        <StyledPopover content={helpText}>
            <Flex alignItems="center" {...props}>
                <HelpIcon icon={faInfoCircle} />
            </Flex>
        </StyledPopover>
    );
};

export default HelpPopoverIcon;

HelpPopoverIcon.propTypes = {
    helpText: PropTypes.string.isRequired,
};
