import styled from '@emotion/styled';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled(Flex)`
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const ZoomButton = styled.span`
    margin: 2px 10px 0 10px;
`;

const SliderContainer = styled.span`
    margin: 0 10px;
`;

const thumbStyle = `
    height: 16px;
    width: 10px;
    background: #666;
    cursor: pointer;
`;
const trackStyle = `
    height: 2px;
    cursor: pointer;
    background-color: #e3e3e3;
`;
const Slider = styled.input`
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    display: block;
    margin: 0;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: -6px;
        ${thumbStyle}
    }
    &::-moz-range-thumb {
        ${thumbStyle}
        border-radius: 0;
    }

    &::-webkit-slider-runnable-track {
        ${trackStyle}
    }
    &::-moz-range-track {
        ${trackStyle}
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

const ZoomSlider = ({ min = 1, step = 1, value = 2, max = 3, setValue }) => {
    return (
        <Container>
            <ZoomButton onClick={() => setValue(Math.max(min, value - 1))}>
                <FontAwesomeIcon icon={faMinus} />
            </ZoomButton>
            <SliderContainer>
                <Slider
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={e => setValue(Number(e.target.value))}
                />
            </SliderContainer>
            <ZoomButton onClick={() => setValue(Math.min(max, value + 1))}>
                <FontAwesomeIcon icon={faPlus} />
            </ZoomButton>
        </Container>
    );
};

ZoomSlider.propTypes = {
    min: PropTypes.number,
    step: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    setValue: PropTypes.func,
};

export default ZoomSlider;
