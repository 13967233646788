import { PUBLISHING_ACTION_TYPES } from '~/redux/actions/publishing/publish-actions';

const initialState = {
    selectedDeals: [],
    deals: [],
};

const publishingProviderService = (state = initialState, action) => {
    switch (action.type) {
        case PUBLISHING_ACTION_TYPES.GET_PROJECT_DEALS_SUCCESS:
            return {
                ...state,
                deals: action.payload,
            };

        case PUBLISHING_ACTION_TYPES.PUBLISH_PROJECT_DEALS_SUCCESS:
        case PUBLISHING_ACTION_TYPES.UNPUBLISH_PROJECT_DEALS_SUCCESS:
            return {
                ...state,
                deals: [...state.deals, ...action.payload],
            };

        case PUBLISHING_ACTION_TYPES.SET_PROJECT_DEALS_SUCCESS:
            return {
                ...state,
                selectedDeals: action.payload,
            };

        default:
            return state;
    }
};

export default publishingProviderService;
