import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '../../button';

const SwitchCellRenderer = ({ column, row, value }) => {
    const switchValue = column?.getValue?.(row) ?? value;
    const isReadOnly = column?.isReadOnly?.(row) ?? false;

    return (
        <Switch
            value={switchValue}
            onChange={() => column?.onClick?.(row)}
            disabled={isReadOnly}
        />
    );
};
SwitchCellRenderer.propTypes = {
    value: PropTypes.bool,
    column: PropTypes.shape({
        onClick: PropTypes.func,
        getValue: PropTypes.func,
        isReadOnly: PropTypes.func,
    }),
    row: PropTypes.object,
};
export default SwitchCellRenderer;
