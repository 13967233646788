import React from 'react';
import PropTypes from 'prop-types';
import useStack from './hooks/use-stack';

const StackItem = ({ title, children }) => {
    const { activeStack } = useStack();

    if (activeStack !== title) {
        return null;
    }

    return <>{children}</>;
};

StackItem.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export default StackItem;
