import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from '@mspecs/shared-utils';
import { ListIcon, ListItem } from '../popovers/menu-popover-component';
import {
    DragIndicator,
    ListContainer,
    OptionsMenu,
    OptionsMenuContent,
    OptionsMenuHeader,
    OptionsSelectedIcon,
} from './mobile-options-menu-styles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import MobileBackdrop from '../layout/mobile/mobile-backdrop-component';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

export interface Option {
    name: string;
    onClick: () => void;
    icon?: IconDefinition;
    iconColor?: string;
    selected?: boolean;
}

export interface MobileOptionsMenuProps {
    title: string;
    options: Option[];
    isOpen: boolean;
    onClose: () => void;
    OptionsRender?: ({
        title,
        options,
    }: {
        title: string;
        options: Option[];
    }) => JSX.Element;
}

const DefaultOptionsRender = ({
    title,
    options,
}: {
    title: string;
    options: Option[];
}) => {
    const { t } = useTranslate();
    return (
        <>
            {title && <OptionsMenuHeader>{t(title)}</OptionsMenuHeader>}
            <ListContainer>
                {options.map((option, index) => (
                    <ListItem
                        key={index}
                        onClick={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation();
                            option.onClick && option.onClick();
                        }}
                        smallMenu
                    >
                        {option.icon && (
                            <ListIcon
                                smallMenu
                                icon={option.icon}
                                color={option.iconColor}
                            />
                        )}{' '}
                        {t(option.name)}
                        {option.selected && (
                            <OptionsSelectedIcon icon={faCheck} />
                        )}
                    </ListItem>
                ))}
            </ListContainer>
        </>
    );
};

const MobileOptionsMenu = ({
    title,
    options,
    isOpen,
    onClose,
    OptionsRender = DefaultOptionsRender,
    ...props
}: MobileOptionsMenuProps) => {
    const [bottomPosition, setBottomPosition] = useState(-window.innerHeight);
    const [isClosing, setIsClosing] = useState(false);

    const optionsMenuRef = useRef<HTMLDivElement>(null);
    const optionsMenuHeight = optionsMenuRef.current?.clientHeight || 100;
    const dragLimit = optionsMenuHeight - 40;

    const touchStartY = useRef(0);

    const handleTouchStart = (event: React.TouchEvent) => {
        touchStartY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event: React.TouchEvent) => {
        const touchEndY = event.touches[0].clientY;
        const dragDistance = touchEndY - touchStartY.current;

        if (dragDistance > 0) {
            setBottomPosition(Math.max(-dragDistance, -window.innerHeight));

            if (dragDistance > dragLimit) {
                setBottomPosition(-optionsMenuHeight);
            }
        }
    };

    const handleTouchEnd = () => {
        if (bottomPosition > -dragLimit) {
            setBottomPosition(0);
        } else {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 200);
    };

    useEffect(() => {
        if (isOpen) {
            setBottomPosition(0);
        } else {
            setBottomPosition(-window.innerHeight);
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <OptionsMenu
                bottomPosition={bottomPosition}
                isClosing={isClosing}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                ref={optionsMenuRef}
                {...props}
            >
                <DragIndicator />
                <OptionsMenuContent flexDirection="column">
                    <OptionsRender title={title} options={options} />
                </OptionsMenuContent>
            </OptionsMenu>
            <MobileBackdrop onClick={handleClose} isClosing={isClosing} />
        </>
    );
};

export default MobileOptionsMenu;
