import { arrayControlTester } from '@jsonforms/vanilla-renderers';
import ArrayControlRenderer from './array-controller';
import TableArrayControlRenderer, {
    tableArrayControlTester,
} from './table-array-controller';
import DropdownControlRenderer, {
    dropdownControlTester,
} from './dropdown-controller';

export default [
    {
        renderer: TableArrayControlRenderer,
        tester: tableArrayControlTester,
    },
    {
        renderer: ArrayControlRenderer,
        tester: arrayControlTester,
    },
    {
        renderer: DropdownControlRenderer,
        tester: dropdownControlTester,
    },
];

export { ArrayControl } from './array-controller';
export { TableArrayControl } from './table-array-controller';
export { default as BaseArrayControl } from './base-array-controller';

export {
    ArrayControlRenderer,
    TableArrayControlRenderer,
    DropdownControlRenderer,
};
