import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledDivider = styled.hr`
    border: none;
    border-top: ${props =>
        props.light
            ? '1px solid #eee'
            : `1px solid ${props.theme.colors.borderPrimary}`};
    margin: ${props => (props.short ? '1rem 16px' : '1.8rem 0')};
`;

const Divider = props => <StyledDivider {...props} />;

Divider.propTypes = {
    short: PropTypes.bool,
    light: PropTypes.bool,
};

Divider.defaultProps = {
    short: false,
    light: false,
};

export default Divider;
