import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';

export const TextBackground = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryColor5};
    color: #000;
    margin-right: 5px;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    white-space: nowrap;
`;

const TextBadge = ({ values = [], ...props }) => {
    const { t } = useTranslate();
    return (
        <Flex flexWrap="wrap">
            {values.map((value, index) => (
                <TextBackground key={`${value}_${index}`} {...props}>
                    {t(value)}
                </TextBackground>
            ))}
        </Flex>
    );
};
TextBadge.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
};

export default TextBadge;
