import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';

const FatalError = ({
    errorObject,
    header,
    contactSupportText,
    supportUrl,
    children,
}) => {
    const { t } = useTranslate();

    return (
        <div>
            <h2>{header || t('ERROR')}</h2>
            <p>
                {contactSupportText ||
                    `${t('IF_THE_PROBLEM_CONTINUES_PLEASE_CONTACT')} `}
                <a href={supportUrl} target="_blank" rel="noopener noreferrer">
                    Mspecs support
                </a>
                .
            </p>
            {errorObject && NODE_ENV !== 'production' && (
                <>
                    <hr />
                    <pre>{JSON.stringify(errorObject, null, 2)}</pre>
                </>
            )}
            {children}
        </div>
    );
};

FatalError.propTypes = {
    errorObject: PropTypes.object,
    header: PropTypes.string,
    contactSupportText: PropTypes.string,
    children: PropTypes.node,
    supportUrl: PropTypes.string,
};

export default FatalError;
