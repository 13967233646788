import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { Border, LoginContainer, InfoText, Link } from './styled-components';
import { LICENSE_AGREEMENT_WITH_ATTACHMENTS_URL } from './constants';
import { SUPPORT_URL } from '~/constants';
import {
    Button,
    BUTTON_TYPES,
    ButtonToolbar,
    Checkbox,
    EmailInput,
    HelpBox,
    SSNInput,
    TextInput,
    TelephoneInput,
    UrlInput,
    RadioButtonGroup,
    RadioButton,
    LabelWrapper,
} from '@mspecs/ui-components';
import { useTranslate } from '@mspecs/shared-utils';
import { displayErrorToast } from '../toast';

const InputGroup = styled(Flex)`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.medium}) {
        flex-direction: column;
    }
`;

const InputItem = styled(Box)`
    width: 48%;

    @media (max-width: ${({ theme }) => theme.medium}) {
        width: 100%;
    }
`;

const HeadlineSmall = styled.h5`
    font-size: 0.9rem;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 15px;
`;

const InfoTextSmall = styled.p`
    font-size: 12px;
`;

const WrappableLink = styled(Link)`
    white-space: normal;
`;

const OrderForm = props => {
    const {
        registrateCustomer,
        registrateCustomerFail,
        emailAddress,
        handleRegisteredAbroad,
        companyRegisteredAbroad,
        customerType,
        setCustomerType,
    } = props;

    const { t } = useTranslate();
    const fieldProps = {
        isFormField: true,
        required: true,
        stackedLabel: true,
    };

    useEffect(() => {
        if (registrateCustomerFail) {
            displayErrorToast('ORDER_MSPECS_FORM_ERROR', 50000);
            return;
        }
    });

    return (
        <LoginContainer
            topHeadline={t('ORDERING')}
            headline={t('MSPECS_REAL_ESTATE_SYSTEM')}
            register
            bigContainer
        >
            <Box mx={[0, 30]}>
                <HelpBox>{t('YOUR_EMAIL_ADDRESS_IS_CONFIRMED')}</HelpBox>
                <InfoText
                    infoText={t(
                        'TO_GET_YOU_STARTED_ON_MSPECS_REAL_ESTATE_SYSTEM_WE_NEED_MORE_INFORMATION_ABOUT_YOUR_COMPANY'
                    )}
                />
                <InfoText>
                    {t('TO_COMPLETE_YOUR_ORDER_FILL_OUT_THE_FORM')}
                </InfoText>
                <Form
                    onSubmit={registrateCustomer}
                    render={({ handleSubmit, invalid, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mt={10}>
                                <RadioButtonGroup
                                    value={customerType}
                                    onChange={e =>
                                        setCustomerType(e.target.value)
                                    }
                                    inline
                                >
                                    <RadioButton
                                        label="ORDER_MSPECS_BROKER"
                                        value="broker"
                                    />
                                    <RadioButton
                                        label="ORDER_MSPECS_PROVIDER"
                                        value="provider"
                                    />
                                </RadioButtonGroup>
                            </Box>
                            <Border />

                            <InfoText>
                                {`${t(
                                    'FIRST_WE_WOULD_LIKE_TO_KNOW_A_BIT_MORE_ABOUT_THE_ADMINISTRATOR'
                                )} (${emailAddress}). 
                                ${t(
                                    'PLEASE_ENTER_THE_NAME_AND_TELEPHONE_NUMBER_OF_THE_ADMINISTRATOR'
                                )}:`}
                            </InfoText>
                            <InputGroup mt={12}>
                                <InputItem>
                                    <TextInput
                                        {...fieldProps}
                                        name="firstName"
                                        label="FIRST_NAME"
                                        autoComplete="firstName"
                                        maxLength={60}
                                        autoFocus
                                    />
                                </InputItem>
                                <InputItem>
                                    <TextInput
                                        {...fieldProps}
                                        name="lastName"
                                        label="LAST_NAME"
                                        autoComplete="lastName"
                                        maxLength={60}
                                    />
                                </InputItem>
                                <InputItem>
                                    <TelephoneInput
                                        {...fieldProps}
                                        name="phoneNumber"
                                        label="TELEPHONE_NUMBER"
                                    />
                                </InputItem>
                            </InputGroup>
                            <Flex
                                flexDirection={['column', 'row']}
                                justifyContent={['flex-start', 'space-between']}
                                alignItems={'baseline'}
                            >
                                <h2>{t('COMPANY_INFORMATION')}:</h2>
                                <Flex
                                    alignItems={'center'}
                                    justifyContent={['flex-start', 'flex-end']}
                                >
                                    <InfoTextSmall>
                                        {t(
                                            'COMPANY_IS_REGISTERED_OUTSIDE_OF_SWEDEN'
                                        )}
                                    </InfoTextSmall>
                                    <Box ml={15}>
                                        <Checkbox
                                            name="registeredAbroad"
                                            type="checkbox"
                                            checked={companyRegisteredAbroad}
                                            onChange={handleRegisteredAbroad}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                            <InputGroup>
                                <InputItem>
                                    <TextInput
                                        {...fieldProps}
                                        name="name"
                                        label="COMPANY_NAME"
                                        autoComplete="name"
                                        maxLength={60}
                                    />
                                </InputItem>
                                <InputItem>
                                    {companyRegisteredAbroad ? (
                                        <TextInput
                                            {...fieldProps}
                                            name="identifier"
                                            label="COMPANY_IDENTITY_NUMBER"
                                            autoComplete="identifier"
                                            maxLength={60}
                                        />
                                    ) : (
                                        <SSNInput
                                            {...fieldProps}
                                            name="identifier"
                                            label="COMPANY_IDENTITY_NUMBER"
                                            autoComplete="identifier"
                                            maxLength={60}
                                        />
                                    )}
                                </InputItem>
                            </InputGroup>

                            <HeadlineSmall>
                                {t('BILLING_INFORMATION')}:
                            </HeadlineSmall>
                            <InputGroup>
                                <InputItem>
                                    <TextInput
                                        {...fieldProps}
                                        name="invoiceRecipient"
                                        label="INVOICE_RECIPIENT"
                                        autoComplete="invoiceRecipient"
                                        maxLength={60}
                                    />
                                </InputItem>
                                <InputItem>
                                    <EmailInput
                                        {...fieldProps}
                                        name="invoiceEmail"
                                        label="INVOICE RECIPENT_EMAIL_ADDRESS"
                                        autoComplete="invoiceEmail"
                                        maxLength={80}
                                    />
                                </InputItem>
                                <InputItem>
                                    <TextInput
                                        {...fieldProps}
                                        name="invoiceStreetAddress"
                                        label="STREET_ADDRESS_OR_BOX"
                                        autoComplete="invoiceStreetAddress"
                                        maxLength={100}
                                    />
                                </InputItem>
                                <InputItem>
                                    <LabelWrapper
                                        label="ZIP_CODE_AND_CITY"
                                        stackedLabel
                                    >
                                        <Flex
                                            flexDirection="row"
                                            flexWrap="no-wrap"
                                            flex={1}
                                        >
                                            <Box width={80} mr={2}>
                                                <TextInput
                                                    name="invoicePostalCode"
                                                    autoComplete="invoicePostalCode"
                                                    maxLength={20}
                                                    required
                                                    isFormField
                                                />
                                            </Box>
                                            <Box width={1}>
                                                <TextInput
                                                    name="invoiceCity"
                                                    autoComplete="invoiceCity"
                                                    required
                                                    hideRequiredHint
                                                    isFormField
                                                    maxLength={100}
                                                />
                                            </Box>
                                        </Flex>
                                    </LabelWrapper>
                                </InputItem>
                            </InputGroup>
                            {!companyRegisteredAbroad &&
                                customerType === 'broker' && (
                                    <Flex
                                        flexDirection={'column'}
                                        mt={3}
                                        mb={30}
                                    >
                                        <InfoText>
                                            {t(
                                                'TO_BE_ABLE_TO_USE_MSPECS_IN_SWEDEN_THE_COMPANY_NEEDS_TO_HAVE_A_MAIN_REAL_ESTATE_AGENT_REGISTERED_AT_THE_FMI_FOR_THE_MAIN_REAL_ESTATE_AGENT_PLEASE_ENTER_PERSONAL_NUMBER_OR_SSN'
                                            )}
                                            :
                                        </InfoText>
                                        <InputItem mt={12}>
                                            <SSNInput
                                                {...fieldProps}
                                                name="userIdentifier"
                                                label="PERSONAL_NUMBER_OR_SSN"
                                                autoComplete="userIdentifier"
                                                placeholder={t('YYYYMMDD')}
                                                maxLength={60}
                                            />
                                        </InputItem>
                                    </Flex>
                                )}
                            {customerType === 'provider' && (
                                <>
                                    <HeadlineSmall>
                                        {t('SIGN_UP_PROVIDER_HEADLINE')}
                                    </HeadlineSmall>
                                    <InputGroup mt={12}>
                                        <InputItem>
                                            <TextInput
                                                {...fieldProps}
                                                name="description"
                                                label="SIGN_UP_PROVIDER_DESCRIPTION"
                                                maxLength={200}
                                            />
                                        </InputItem>
                                        <InputItem>
                                            <EmailInput
                                                {...fieldProps}
                                                name="technicalContactEmail"
                                                label="SIGN_UP_PROVIDER_EMAIL_TECH_CONTACT"
                                                defaultValue={emailAddress}
                                                maxLength={400}
                                            />
                                        </InputItem>
                                        <InputItem>
                                            <UrlInput
                                                name="logoUrl"
                                                label="SIGN_UP_PROVIDER_URL_LOGO"
                                                stackedLabel
                                                isFormField
                                            />
                                        </InputItem>
                                        <InputItem>
                                            <UrlInput
                                                name="websiteUrl"
                                                label="SIGN_UP_PROVIDER_URL_WEBSITE"
                                                maxLength={200}
                                                stackedLabel
                                                isFormField
                                            />
                                        </InputItem>
                                    </InputGroup>
                                </>
                            )}
                            <InfoText>
                                {t(
                                    'TO_GET_FULL_FUNCTIONALITY_YOU_NEED_TO_COMPLETE_WITH_ADDITIONAL_INFORMATION_IN_MSPECS'
                                )}
                            </InfoText>
                            <Flex mb={3} alignItems={'center'} pt="20px">
                                <Box mr={[15]}>
                                    <Checkbox
                                        {...fieldProps}
                                        name="generalTerms"
                                        noTranslate
                                        innerLabel={
                                            <>
                                                {`${t('CHECK_TO_APPROVE')} `}
                                                <WrappableLink
                                                    text={t(
                                                        'LICENSE_AGREEMENT_WITH_ATTACHMENTS_LINK_TEXT'
                                                    )}
                                                    href={
                                                        LICENSE_AGREEMENT_WITH_ATTACHMENTS_URL
                                                    }
                                                    target="_blank"
                                                    lowercase
                                                />
                                            </>
                                        }
                                    />
                                </Box>
                            </Flex>
                            <Border />
                            <ButtonToolbar>
                                <Button
                                    label="REGISTER"
                                    type="submit"
                                    buttonType={BUTTON_TYPES.PRIMARY}
                                    disabled={invalid || submitting}
                                    large
                                />
                            </ButtonToolbar>
                            <Flex my={3}>
                                <InfoText>
                                    {t(
                                        'IF_YOU_EXPERIENCE_ANY_ISSUES_IN_FILLING_OUT_THE_FORM_PLEASE_CONTACT_SUPPORT'
                                    )}
                                    {` `}
                                    <Link
                                        text="Mspecs support"
                                        href={SUPPORT_URL}
                                        target="_blank"
                                    />
                                    .
                                </InfoText>
                            </Flex>
                        </form>
                    )}
                />
            </Box>
        </LoginContainer>
    );
};

OrderForm.propTypes = {
    registrateCustomer: PropTypes.func,
    registrateCustomerFail: PropTypes.bool,
    emailAddress: PropTypes.string,
    handleRegisteredAbroad: PropTypes.func,
    companyRegisteredAbroad: PropTypes.bool,
    customerType: PropTypes.string,
    setCustomerType: PropTypes.func,
};

export default OrderForm;
