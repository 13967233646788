import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapDataType } from './map.props';
import { useWindowSize } from '@mspecs/shared-utils';

const MapStaticImage = ({ latitude, longitude, apiKey, zoomLevel, height }) => {
    const imageRef = useRef(null);
    const [imageWidth, setImageWidth] = useState();
    const windowSize = useWindowSize();

    useEffect(() => {
        setImageWidth(imageRef.current?.clientWidth);
    }, [windowSize]);

    const staticImageUrl = useMemo(() => {
        if (!imageWidth) {
            return '';
        }
        const baseUrl = '//maps.googleapis.com/maps/api/staticmap';
        return `${baseUrl}?center=${latitude},${longitude}&zoom=${zoomLevel}&language=se&size=${imageWidth}x${height}&scale=1&maptype=roadmap&markers=color:red%7Clabel:E%7C${latitude},${longitude}&sensor=false&key=${apiKey}`;
    }, [latitude, longitude, zoomLevel, height, apiKey, imageWidth]);

    return (
        <img
            ref={imageRef}
            src={staticImageUrl}
            style={{ height: `${height}px`, width: '100%' }}
        />
    );
};

MapStaticImage.propTypes = {
    latitude: MapDataType.isRequired,
    longitude: MapDataType.isRequired,
    apiKey: PropTypes.string.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default MapStaticImage;
