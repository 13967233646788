import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { WizContext } from './wiz-component';
import { BUTTON_TYPES, Button, ButtonToolbar } from '../../button';

export const ButtonContainer = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    background: ${({ theme }) => theme.colors.bgPrimary};
    justify-content: space-between;
    padding: 15px 30px;
    margin: 0;
`;

const StepButtonBar = props => {
    const {
        onEnd,
        onCancel,
        endButtonLabel,
        useOwnEndButton,
        validStepMap = {},
    } = props;

    const { current, next, prev, hasValidation, stepProps } =
        React.useContext(WizContext);
    const { index, step, isFirst, isLast } = current;

    const { ownCancelButton, ownButtons } = stepProps[index];
    const stepIsInValid =
        hasValidation &&
        !(validStepMap[step.label] === true || step.isValidated);

    return (
        <ButtonContainer>
            {ownCancelButton ? (
                ownCancelButton()
            ) : (
                <ButtonToolbar left>
                    {onCancel && (
                        <Button
                            label="CANCEL"
                            onClick={onCancel}
                            buttonType={BUTTON_TYPES.TEXT}
                        />
                    )}
                </ButtonToolbar>
            )}
            {ownButtons ? (
                ownButtons(next, prev, stepIsInValid)
            ) : (
                <ButtonToolbar>
                    {!isFirst && (
                        <Button label="PREVIOUS" large onClick={prev} />
                    )}
                    {!isLast && (
                        <Button
                            label="NEXT"
                            large
                            onClick={next}
                            buttonType={BUTTON_TYPES.PRIMARY}
                            //disable if hasValidation is active and the step is not yet validated
                            //or if Wiz has stepBranches that is not active yet
                            disabled={
                                stepIsInValid ||
                                stepProps[index + 1].path === ''
                            }
                        />
                    )}
                    {isLast &&
                        (useOwnEndButton ? (
                            useOwnEndButton
                        ) : (
                            <Button
                                label={endButtonLabel}
                                large
                                onClick={onEnd}
                                buttonType={BUTTON_TYPES.PRIMARY}
                                disabled={stepIsInValid}
                            />
                        ))}
                </ButtonToolbar>
            )}
        </ButtonContainer>
    );
};

StepButtonBar.propTypes = {
    current: PropTypes.object,
    hasValidation: PropTypes.bool,
    endButtonLabel: PropTypes.string,
    next: PropTypes.func,
    onCancel: PropTypes.func,
    onEnd: PropTypes.func,
    useOwnEndButton: PropTypes.object,
    prev: PropTypes.func,
};

export default StepButtonBar;
