import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid/emotion';

const Path = ({ pathId, ...restProps }) => {
    return <Box width={1} pathId={pathId} {...restProps} />;
};

Path.propTypes = {
    pathId: PropTypes.string.isRequired,
};

export default Path;
