import styled from '@emotion/styled';

const DropdownContainer = styled.div<{
    position: 'left' | 'right';
    invalid?: boolean;
}>`
    position: absolute;
    left: ${({ position }) => (position === 'left' ? '10px' : 'auto')};
    right: ${({ position, invalid }) =>
        position === 'right' ? (invalid ? '40px' : '10px') : 'auto'};
    bottom: 25px;
    cursor: pointer;
`;

export default DropdownContainer;
