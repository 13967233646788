import { useCallback, useLayoutEffect, useState } from 'react';
import useEventListener from './use-event-listener-hook';

// https://usehooks-ts.com/react-hook/use-element-size
function useElementSize() {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const [ref, setRef] = useState(null);
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    const handleSize = useCallback(() => {
        setSize({
            width: ref?.offsetWidth || 0,
            height: ref?.offsetHeight || 0,
        });
    }, [ref?.offsetHeight, ref?.offsetWidth]);

    useEventListener('resize', handleSize);

    useLayoutEffect(() => {
        handleSize();
    }, [ref?.offsetHeight, ref?.offsetWidth]);

    return [setRef, { ...size, ref }];
}

export default useElementSize;
