import { React, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { StyledMenuItem } from './content-menu-item-component';

const StyledSubMenuItem = styled(StyledMenuItem)`
    background: ${({ theme }) => theme.colors.bgPrimary};
    height: 54px;
    min-height: 54px;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-weight: 500;
    border-radius: ${({ theme }) => theme.borderRadius.medium};

    &.non-active {
        background: white;
        color: ${({ theme }) => theme.colors.textPrimary};
        font-weight: 500;
    }
    &:hover {
        text-decoration: none;
    }

    ${({ theme }) => theme.mediaQueries.isAboveMobile} {
        margin-bottom: 5px;
        height: 44px;
        min-height: 44px;
        &:hover {
            background: ${({ theme }) => theme.colors.primaryColor10};
        }
        &.non-active {
            background: ${({ theme }) => theme.colors.primaryColor5};
            &:hover {
                background: ${({ theme }) => theme.colors.primaryColor5};
            }
        }
    }
`;

const SubMenuItem = memo(({ to, className, children }) => {
    return (
        <StyledSubMenuItem to={to} className={className}>
            {children}
        </StyledSubMenuItem>
    );
});

SubMenuItem.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default SubMenuItem;
