import React from 'react';
import styled from '@emotion/styled';

const StyledHeadline = styled.h1`
    margin: 0 0 15px;
    text-transform: uppercase;
    font-size: 1.9em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 15px;
    line-height: 1.3;

    @media (max-width: ${({ theme }) => theme.large}) {
        font-size: 1.25em;
    }
    @media (max-width: ${({ theme }) => theme.medium}) {
        font-size: 1.9em;
    }
`;

const Headline = props => {
    return <StyledHeadline>{props.children}</StyledHeadline>;
};

export default Headline;
