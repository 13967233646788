import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';
import {
    LoginContainer,
    Border,
    FormContent,
    LinkFooter,
} from './styled-components';
import { loginMethod } from '~/utils/services/auth-service';
import { login, resetLoginError } from '~/redux/actions/session-actions';
import {
    Button,
    BUTTON_TYPES,
    ButtonToolbar,
    PasswordInput,
    FormInputField,
} from '@mspecs/ui-components';
import { translate, increasedInputFontSize } from '@mspecs/shared-utils';
import { displayErrorToast, displaySuccessToast } from '../toast';

const Container = styled(Box)`
    &.loginInput-enter {
        opacity: 0;
    }
    &.loginInput-enter-active {
        opacity: 1;
        transition: opacity 0.5s ease-in;
    }
`;

const handleReset = (func, val) => {
    const timer = setTimeout(() => (val ? func(val) : func()), 5000);
    return () => clearTimeout(timer);
};

const Login = props => {
    const {
        login,
        loginFail: invalidLogin,
        resetLoginError,
        isSent,
        emailAddress,
        toggleForgotPassword,
        tooManyLoginAttempts,
        t,
        redirectUrl,
    } = props;

    const [hasPassword, setHasPassword] = useState(false);
    const [url, setUrl] = useState('');
    const [loginFail, setLoginFail] = useState(false);
    const [tooManyRequests, setTooManyRequests] = useState(false);
    const passwordInput = useRef(null);
    const fieldProps = {
        isFormField: true,
        hideRequiredHint: true,
        stackedLabel: true,
    };

    const handleLoginMethod = async ({ username }) => {
        try {
            const fetchedLoginMethod = await loginMethod(username, redirectUrl);
            const { allowPassword, redirect } = fetchedLoginMethod;
            if (redirect && allowPassword) {
                setHasPassword(true);
                setUrl(redirect);
            } else if (redirect) {
                <Navigate to={(window.location = redirect)} replace />;
            } else {
                setHasPassword(true);
            }
        } catch (e) {
            if (!tooManyRequests) {
                if (e.type === 'TooManyRequestsError') {
                    setTooManyRequests(true);
                } else {
                    setLoginFail(true);
                }
            }
        }
    };

    const handleLogin = ({ username: user, password }) => {
        login({ user, password });
    };
    const toggleHasPassword = () => {
        setHasPassword(!hasPassword);
        setUrl('');
    };

    useEffect(() => {
        if (!tooManyRequests) return;
        displayErrorToast({
            title: t('ERROR'),
            body: t('LOGIN_ERROR_TOO_MANY_REQUESTS'),
        });
        return handleReset(setTooManyRequests, false);
    }, [tooManyRequests]);

    useEffect(() => {
        if (!loginFail) return;
        displayErrorToast({
            title: t('ERROR'),
            body: t('LOGIN_ERROR_LOGIN_FAIL'),
        });
        return handleReset(setLoginFail, false);
    }, [loginFail]);

    useEffect(() => {
        if (!invalidLogin && !tooManyLoginAttempts) return;
        if (tooManyLoginAttempts) {
            displayErrorToast({
                title: t('ERROR'),
                body: t('LOGIN_ERROR_TOO_MANY_REQUESTS'),
            });
        } else {
            displayErrorToast({
                title: t('ERROR'),
                body: t('LOGIN_ERROR_INVALID_LOGIN'),
            });
        }
        return handleReset(resetLoginError);
    }, [invalidLogin, tooManyLoginAttempts]);

    useEffect(() => {
        if (!isSent) return;
        displaySuccessToast({
            title: t('SUCCESS'),
            body: `${t('AN_EMAIL_HAS_BEEN_SENT_TO')} ${emailAddress}`,
        });
    }, [isSent]);

    useEffect(() => {
        if (!hasPassword) return;
        passwordInput.current?.focus();
    }, [hasPassword]);

    return (
        <LoginContainer
            topHeadline={t('WELCOME_TO')}
            headline={t('MSPECS_REAL_ESTATE_SYSTEM')}
        >
            <Global styles={increasedInputFontSize} />
            <Border />
            <Form
                onSubmit={!hasPassword ? handleLoginMethod : handleLogin}
                render={({ handleSubmit, invalid, submitting, values }) => (
                    <FormContent onSubmit={handleSubmit}>
                        <CSSTransition
                            in={hasPassword}
                            timeout={500}
                            classNames="loginInput"
                        >
                            <Container>
                                {/* 
                                    NOTE: Render new text field just for show when having password.
                                    This to avoid password managers to make it look like
                                    the username is changed when selecting another user password
                                */}
                                {hasPassword ? (
                                    <FormInputField
                                        label="USER_NAME"
                                        disabled
                                        value={values.username}
                                        autoComplete="off"
                                        type="text"
                                    />
                                ) : (
                                    <FormInputField
                                        name="username"
                                        label="USER_NAME"
                                        autoComplete="username"
                                        required
                                        autoFocus
                                        type="text"
                                        {...fieldProps}
                                    />
                                )}

                                <div
                                    style={{
                                        display: hasPassword ? 'block' : 'none',
                                    }}
                                >
                                    <PasswordInput
                                        name="password"
                                        label="PASSWORD"
                                        autoComplete="current-password"
                                        inputRef={passwordInput}
                                        {...fieldProps}
                                    />
                                </div>
                                <Flex
                                    justifyContent="flex-start"
                                    flexDirection="row-reverse"
                                >
                                    <ButtonToolbar>
                                        <Button
                                            label={
                                                hasPassword ? 'LOG_IN' : 'NEXT'
                                            }
                                            type="submit"
                                            disabled={invalid || submitting}
                                            buttonType={BUTTON_TYPES.PRIMARY}
                                            large
                                        />
                                    </ButtonToolbar>
                                    {url && (
                                        <Button
                                            label="LOG_IN_WITH_SSO"
                                            buttonType={BUTTON_TYPES.TEXT}
                                            onClick={() =>
                                                (location.href = url)
                                            }
                                        />
                                    )}
                                </Flex>
                            </Container>
                        </CSSTransition>
                    </FormContent>
                )}
            />
            <LinkFooter
                back={hasPassword && toggleHasPassword}
                orderMspecs={!hasPassword}
                forgotPassword={toggleForgotPassword}
                contactSupport
            />
        </LoginContainer>
    );
};

Login.propTypes = {
    emailAddress: PropTypes.string,
    handleLogin: PropTypes.func,
    isSent: PropTypes.bool,
    login: PropTypes.func,
    loginFail: PropTypes.bool,
    resetLoginError: PropTypes.func,
    toggleForgotPassword: PropTypes.func,
    t: PropTypes.func,
};

Login.defaultProps = {
    emailAddress: '',
    isSent: false,
    loginFail: false,
};

export default connect(
    ({ session }) => ({
        loginFail: session.loginFail,
        tooManyLoginAttempts: session.tooManyLoginAttempts,
    }),
    {
        login,
        resetLoginError,
    }
)(translate(Login));
