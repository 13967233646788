import React from 'react';
import PropTypes from 'prop-types';
import { TextBadge } from '../../display';

const TextBadgeCellRenderer = ({ value, column: { valueMapper = x => x } }) => {
    const items = Array.isArray(value) ? value : [value];
    return <TextBadge values={items.filter(Boolean).map(valueMapper)} />;
};
TextBadgeCellRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    column: PropTypes.shape({ valueMapper: PropTypes.func }),
};
export default TextBadgeCellRenderer;
