import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { TEXT_STYLES } from '../typograph/typograph-components';
import { css } from '@emotion/react';

export const highlightedLinkCss = ({ theme }) => css`
    ${TEXT_STYLES.highlightedBodyText(theme)};
    color: ${theme.colors.textPrimary};
    text-decoration: underline;
    text-underline-offset: 3px;
    display: inline-flex;

    :hover {
        text-decoration-thickness: 1.5px;
    }
`;

const Link = styled.a`
    ${highlightedLinkCss}
`;

const IconWrapper = styled.span`
    margin-left: 2px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: baseline;
    justify-content: center;
`;

function HighLightedLink({ icon, children, ...props }) {
    return (
        <Link {...props}>
            {children}
            {icon && <IconWrapper>{icon}</IconWrapper>}
        </Link>
    );
}
HighLightedLink.propTypes = {
    icon: PropTypes.any,
};

export default HighLightedLink;
