import React, { useCallback } from 'react';
import {
    CheckboxTreeWrapper,
    CheckboxWrapper,
    Label,
    StyledCheckbox,
    StyledIcon,
    TreeRow,
    DraggableListRow,
} from './checkboxtree-styles';
import { useUniqueId, useTranslate } from '@mspecs/shared-utils';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import useCheckboxTree from './hooks/useCheckboxTree';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import { HelpingTextMedium } from '../typograph/typograph-components';
import { useTheme } from '@emotion/react';

const SubTitle = styled(HelpingTextMedium)`
    color: ${props => props.theme.colors.textPrimary};
`;

const StyledCheckboxWrapper = styled(CheckboxWrapper)`
    // width of icon is 10.5px, so to keep checkboxes inlined we need to add padding to have the sum of 20px
    padding-left: ${({ dragDisabled }) => (dragDisabled ? '20px' : '9.5px')};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding-left: ${({ dragDisabled }) =>
            dragDisabled ? '20px' : '9.5px'};
    }
`;

const Node = ({
    value,
    label,
    checkedValues = [],
    onChecked,
    provided,
    items = [],
    disabled,
    snapshot,
}) => {
    const theme = useTheme();
    const id = useUniqueId();
    const { t } = useTranslate();
    const hasItems = items.length > 0;
    const itemValues = items.map(c => c.value);

    const isValueChecked = useCallback(
        value => checkedValues.includes(value),
        [checkedValues]
    );
    const isAllChildrenSelected = itemValues.every(isValueChecked);
    const isChecked = hasItems ? isAllChildrenSelected : isValueChecked(value);

    const rowStyle = snapshot.isDragging
        ? {
              backgroundColor: theme.colors.primaryColor10,
              border: `1px solid ${theme.colors.primaryColor20}`,
          }
        : {};

    return (
        <DraggableListRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...rowStyle, ...provided.draggableProps.style }}
        >
            <TreeRow>
                {!disabled && <StyledIcon icon={faGripDotsVertical} />}
                <StyledCheckboxWrapper dragDisabled={disabled}>
                    <StyledCheckbox
                        id={id}
                        value={value}
                        onChange={onChecked}
                        indeterminate={false}
                        checked={isChecked}
                        disabled={disabled}
                    />
                </StyledCheckboxWrapper>
                <Label htmlFor={id}>{t(label)}</Label>
            </TreeRow>
        </DraggableListRow>
    );
};

Node.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checkedValues: PropTypes.array,
    onChecked: PropTypes.func,
    provided: PropTypes.object,
    items: PropTypes.array,
    disabled: PropTypes.bool,
    snapshot: PropTypes.object,
};
const getRenderItem =
    (nodes, checked, handleChecked) => (provided, snapshot, rubric) =>
        (
            <Node
                {...nodes[rubric.source.index]}
                checkedValues={checked}
                onChecked={handleChecked}
                provided={provided}
                snapshot={snapshot}
            />
        );
const CheckboxTree = ({
    nodes,
    checked,
    onChecked,
    forwardedRef,
    id,
    title,
}) => {
    const { handleChecked } = useCheckboxTree(nodes, onChecked, checked);
    const { t } = useTranslate();

    const renderItem = getRenderItem(nodes, checked, handleChecked);

    return (
        <CheckboxTreeWrapper ref={forwardedRef}>
            <SubTitle>{t(title)}</SubTitle>
            <Droppable droppableId={id} renderClone={renderItem}>
                {droppableProvider => (
                    <div
                        {...droppableProvider.droppableProps}
                        ref={droppableProvider.innerRef}
                    >
                        {nodes.map((node, index) => (
                            <Draggable
                                draggableId={node.value}
                                index={index}
                                key={node.value}
                                isDragDisabled={node.disabled}
                            >
                                {renderItem}
                            </Draggable>
                        ))}
                        {droppableProvider.placeholder}
                    </div>
                )}
            </Droppable>
        </CheckboxTreeWrapper>
    );
};

CheckboxTree.propTypes = {
    nodes: PropTypes.array.isRequired,
    checked: PropTypes.array,
    onChecked: PropTypes.func,
    forwardedRef: PropTypes.object,
    index: PropTypes.number,
    id: PropTypes.string,
    title: PropTypes.string,
};

const DraggableCheckboxTree = React.forwardRef((props, ref) => (
    <CheckboxTree {...props} forwardedRef={ref} />
));

export default DraggableCheckboxTree;
