import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
const TitleWrapper = styled.span`
    color: ${({ theme }) => theme.colors.contrastBg75};
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: bold;
`;

const FullScreenModalHeaderWrapper = styled(Flex)`
    height: 52px;
    justify-content: space-between;
    padding: 15px 15px 10px;
    background-color: #fff;
    border-bottom: 0.11rem solid ${({ theme }) => theme.colors.borderPrimary};
`;

const FullScreenModalHeader = ({ title }) => {
    const { t } = useTranslate();

    return (
        <FullScreenModalHeaderWrapper>
            <TitleWrapper>{t(title)}</TitleWrapper>
        </FullScreenModalHeaderWrapper>
    );
};

FullScreenModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default FullScreenModalHeader;
