import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import InputField from '../../../basic/input-field-component';
import { useDebounce } from '@mspecs/shared-utils';
import SearchInput from '../search-input-component';

const SearchWrapper = styled(Flex)`
    position: relative;
    align-items: center;
`;

const StyledSearchInput = styled(SearchInput)`
    margin: 0;
    border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const SearchIcon = styled(FontAwesomeIcon)`
    position: absolute;
    left: ${({ theme }) => theme.spacing.default};
    z-index: 2;
`;

const SearchInputWithSuggestions = ({
    searchFunction,
    renderSuggestions,
    ...restProps
}) => {
    const [searchString, setSearchString] = useState('');
    const [selectedSuggestion, setSelectedSuggestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);

    const debouncedValue = useDebounce(searchString, 500);

    useEffect(() => {
        const asyncSearch = async () => {
            setIsLoading(true);
            try {
                const result = await searchFunction(debouncedValue);
                setSearchResult(result);
            } catch {}
            setIsLoading(false);
        };
        if (debouncedValue.length > 2) {
            asyncSearch();
        }
    }, [debouncedValue]);

    const onSuggestionClick = suggestion => {
        setSelectedSuggestion(suggestion.label);
    };

    return (
        <Box width="100%">
            <StyledSearchInput
                value={selectedSuggestion || searchString}
                onChange={e => {
                    setSearchString(e.target.value);
                    setSelectedSuggestion('');
                }}
                isLoading={isLoading}
                {...restProps}
            />
            {renderSuggestions?.({
                searchResult,
                selectedSuggestion,
                onSuggestionClick,
                searchString,
            })}
        </Box>
    );
};

SearchInputWithSuggestions.propTypes = {
    searchFunction: PropTypes.func.isRequired,
    renderSuggestions: PropTypes.func,
    children: PropTypes.any,
};

export default SearchInputWithSuggestions;
