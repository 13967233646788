import React from 'react';

export default function BaseFormLayout({ className, children, visible }) {
    return (
        <div
            className={className}
            hidden={
                visible === undefined || visible === null ? false : !visible
            }
        >
            {children}
        </div>
    );
}
