import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { DotdotdotLoader } from '../../animations';

const Nav = styled.div`
    color: #fff;
    transition: all 0.2s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    z-index: 4;
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3rem;
    @media (max-width: 440px) {
        padding: 15px;
        font-size: 2rem;
    }
`;

const LeftNav = styled(Nav)`
    left: 15px;
`;

const RightNav = styled(Nav)`
    right: 15px;
`;

const ImageGalleryContainer = styled.div`
    height: 100%;
`;

const ImageSettings = {
    showIndex: true,
    showBullets: false,
    infinite: true,
    showThumbnails: true,
    showFullscreenButton: false,
    showGalleryFullscreenButton: false,
    showPlayButton: true,
    showGalleryPlayButton: true,
    showNav: true,
    slideDuration: 450,
    slideInterval: 2000,
    slideOnThumbnailOver: false,
    thumbnailPosition: 'bottom',
};

const formatImagesForGallery = files =>
    files.map(file => ({
        original: file.viewURI,
        thumbnail: file.thumbnailURI,
        description: file.description || '',
    }));

const ImageGalleryComponent = props => {
    const {
        loading,
        images: galleryImages = [],
        onScreenChange = () => {},
        setIsFullscreen = () => {},
    } = props;
    const [isFullScreen, _setIsFullscreen] = useState(false);

    const _imageGallery = useRef(null);

    const handleImageLoad = () => {
        if (
            !_imageGallery.current?.props.showFullscreenButton &&
            !_imageGallery.current?.state.isFullscreen
        ) {
            _imageGallery.current.fullScreen();
        }
    };
    const screenChange = isFullScreen => {
        // TODO: Consolidate this to only one function.
        // This implementation only call onScreenChange when isFullScreen is truthy
        !!isFullScreen && onScreenChange(isFullScreen);
        setIsFullscreen(isFullScreen);
        _setIsFullscreen(isFullScreen);
    };

    return (
        <ImageGalleryContainer>
            {loading && (
                <Flex alignItems="center" justifyContent="center" height="100%">
                    <DotdotdotLoader />
                </Flex>
            )}
            {!loading && (
                <ImageGallery
                    items={formatImagesForGallery(galleryImages)}
                    ref={_imageGallery}
                    lazyLoad={true}
                    infinite={true}
                    showBullets={ImageSettings.showBullets}
                    showFullscreenButton={
                        (ImageSettings.showFullscreenButton &&
                            ImageSettings.showGalleryFullscreenButton) ||
                        isFullScreen // Always display fullscren close button when fullscreen is entered
                    }
                    showPlayButton={
                        ImageSettings.showPlayButton &&
                        ImageSettings.showGalleryPlayButton
                    }
                    showThumbnails={ImageSettings.showThumbnails}
                    showIndex={false}
                    showNav={ImageSettings.showNav}
                    thumbnailPosition={ImageSettings.thumbnailPosition}
                    slideOnThumbnailOver={ImageSettings.slideOnThumbnailOver}
                    useBrowserFullscreen={true}
                    preventDefaultTouchmoveEvent={true}
                    onImageLoad={handleImageLoad}
                    onScreenChange={screenChange}
                    renderLeftNav={(onClick, disabled) => {
                        return (
                            <LeftNav disabled={disabled} onClick={onClick}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </LeftNav>
                        );
                    }}
                    renderRightNav={(onClick, disabled) => {
                        return (
                            <RightNav disabled={disabled} onClick={onClick}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </RightNav>
                        );
                    }}
                />
            )}
        </ImageGalleryContainer>
    );
};

ImageGalleryComponent.propTypes = {
    loading: PropTypes.bool,
    images: PropTypes.array,
    onScreenChange: PropTypes.func,
    setIsFullscreen: PropTypes.func,
};

export default ImageGalleryComponent;
