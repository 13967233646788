const ROUTE_SETTINGS = '/settings';

export const ROUTE_MY_SETTINGS = 'user';
export const ROUTE_OFFICE_SETTINGS = 'office';
export const ROUTE_COMPANY_SETTINGS = 'company';
export const ROUTE_PROVIDER_SETTINGS = 'provider';

export const ROUTE_MY_SETTINGS_BASE = `${ROUTE_SETTINGS}/${ROUTE_MY_SETTINGS}`;
export const ROUTE_OFFICE_SETTINGS_BASE = `${ROUTE_SETTINGS}/${ROUTE_OFFICE_SETTINGS}`;
export const ROUTE_COMPANY_SETTINGS_BASE = `${ROUTE_SETTINGS}/${ROUTE_COMPANY_SETTINGS}`;
export const ROUTE_PROVIDER_SETTINGS_BASE = `${ROUTE_SETTINGS}/${ROUTE_PROVIDER_SETTINGS}`;
