import React from 'react';
import styled from '@emotion/styled';

const StyledTopHeadline = styled.h4`
    font-size: 1.05rem;
    margin: 0;
    color: #666;
`;

const TopHeadline = props => {
    return <StyledTopHeadline>{props.children}</StyledTopHeadline>;
};

export default TopHeadline;
