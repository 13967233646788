import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import FullScreenModalFooter from './full-screen-modal-footer-component';
import FullScreenModalHeader from './full-screen-modal-header-component';
import FullScreenModalSideNav from './full-screen-modal-sidenav-component';
import { Modal } from '..';

const FullScreenModalContentWrapper = styled(Flex)`
    flex-grow: 1;
    overflow: auto;

    ${({ isGray, theme }) =>
        isGray && `background-color: ${theme.colors.bgSecondary}`}
`;

// This modal is applying old legacy styles, it should be refactored to use the new design system
// The component is also very bound to "marker-place-modal" and should probably be splitted into smaller pieces to avoid unnecessary overhead
const FullScreenModal = ({
    title,
    isActive,
    onClose,
    onRemove,
    onSave,
    children,
    tabs,
    selectedTab,
    setSelectedTab,
    sideNavHeader,
    isGray,
    renderFooter,
    saveLabel,
}) => {
    return (
        <Modal
            isOpen={isActive}
            size="full-size"
            onClose={onClose}
            headerText={!tabs && title}
        >
            <Flex flexDirection="row" width="100%">
                <Flex flexDirection="column" height="100%">
                    {tabs && (
                        <FullScreenModalSideNav
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            tabs={tabs}
                            header={sideNavHeader}
                        />
                    )}
                </Flex>
                <Flex flexDirection="column" flex="1">
                    {tabs && <FullScreenModalHeader title={title} />}
                    <FullScreenModalContentWrapper
                        flexDirection="column"
                        isGray={isGray}
                    >
                        <Flex
                            flexDirection="column"
                            padding={tabs ? '30px' : undefined}
                            flex="1"
                        >
                            {children}
                        </Flex>
                    </FullScreenModalContentWrapper>
                    {renderFooter ? (
                        renderFooter()
                    ) : (
                        <FullScreenModalFooter
                            onClose={onClose}
                            onRemove={onRemove}
                            onSave={onSave}
                            saveLabel={saveLabel}
                        />
                    )}
                </Flex>
            </Flex>
        </Modal>
    );
};

FullScreenModal.propTypes = {
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    onRemove: PropTypes.func,
    onSave: PropTypes.func,
    popup: PropTypes.bool,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        })
    ),
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
    sideNavHeader: PropTypes.string,
    isGray: PropTypes.bool,
    renderFooter: PropTypes.func,
    saveLabel: PropTypes.string,
};

export default FullScreenModal;
