import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Tippy from '@tippy.js/react';

const Popover = styled(Tippy)`
    padding: 8px 20px 8px 8px;

    .tippy-arrow {
        transform: scale(1.5);
    }

    &.tippy-tooltip {
        background-color: ${({ theme }) => theme.colors.primaryColor10};
        text-align: left;
        border-radius: initial;
        cursor: default;
        height: auto;

        &[data-placement^='bottom'] > .tippy-arrow {
            border-bottom-color: ${({ theme }) => theme.colors.primaryColor10};
        }

        &[data-placement^='top'] > .tippy-arrow {
            border-top-color: ${({ theme }) => theme.colors.primaryColor10};
        }

        &[data-placement^='left'] > .tippy-arrow {
            border-left-color: ${({ theme }) => theme.colors.primaryColor10};
        }

        &[data-placement^='right'] > .tippy-arrow {
            border-right-color: ${({ theme }) => theme.colors.primaryColor10};
        }
    }
`;

const CloseButton = styled.div`
    position: absolute;
    display: inline-block;
    right: 5px;
    top: 3px;
    cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
    font-size: 16px;
    color: #aaa;
`;

const Text = styled.span`
    margin: 0;
    font-size: 11px;
    color: #222;
`;

const InputHelperPopover = props => {
    const { text, children, onClose, visible } = props;
    return (
        <div style={{ width: '100%' }}>
            <Popover
                content={
                    <>
                        <CloseButton onClick={onClose}>
                            <Icon icon={faTimes} />
                        </CloseButton>
                        <Text>{text}</Text>
                    </>
                }
                animation="fade"
                arrow={true}
                delay={1000}
                duration={500}
                hideOnClick="false"
                interactive="true"
                maxWidth={250}
                placement="bottom"
                visible={visible}
            >
                {children}
            </Popover>
        </div>
    );
};

InputHelperPopover.propTypes = {
    animation: PropTypes.string,
    arrow: PropTypes.bool,
    delay: PropTypes.array,
    duration: PropTypes.array,
    hideOnClick: PropTypes.bool,
    interactive: PropTypes.bool,
    maxwidth: PropTypes.number,
    placement: PropTypes.string,
    inputHelperPopover: PropTypes.bool,
    showOnCreate: PropTypes.bool,
    t: PropTypes.func,
    text: PropTypes.string,
    visible: PropTypes.bool,
    children: PropTypes.node,
    onClose: PropTypes.func,
};

InputHelperPopover.defaultProps = {
    animation: 'shift-away',
    arrow: false,
    delay: [0, 20],
    duration: [275, 250],
    hideOnClick: true,
    inputHelperPopover: false,
    interactive: false,
    maxwidth: 350,
    placement: 'top',
    showOnCreate: false,
    t: x => x,
    textField: '',
    visible: true,
    onClose: x => x,
};

export default InputHelperPopover;
