import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryColor } from '@mspecs/shared-utils';

const InfoIconContainer = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-radius: 50%;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
`;

const Info = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
`;

const InfoIcon = props => (
    <InfoIconContainer alignItems="center" justifyContent="center" {...props}>
        <Info icon={faInfo} />
    </InfoIconContainer>
);

export default InfoIcon;
