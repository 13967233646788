import React, { useState } from 'react';
import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES } from './button-type-constants';
import Button from './button-component';

const FadeElement = styled.span`
    will-change: opacity;
    transition: opacity 0.4s ease-out;
    color: ${({ color }) => color};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

const CloseIcon = styled(FontAwesomeIcon)`
    font-size: 1.2rem;
    color: ${({ color }) => color};
`;

const AnimatedCloseButton = ({ onClose, color = '#fff', ...props }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslate();
    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            {...props}
        >
            <Button
                buttonType={BUTTON_TYPES.TEXT}
                onClick={onClose}
                style={{
                    color,
                    textTransform: 'uppercase',
                    borderBottom: `1px solid ${color}`,
                    borderRadius: 0,
                    height: 'auto',
                    paddingBottom: '10px',
                    fontSize: '0.75rem',
                }}
            >
                <FadeElement
                    visible={!isHovered}
                    style={{
                        color,
                    }}
                >
                    {t('CLOSE')}
                </FadeElement>
                <FadeElement
                    visible={isHovered}
                    style={{ position: 'absolute' }}
                >
                    <CloseIcon icon={faTimes} color={color} />
                </FadeElement>
            </Button>
        </div>
    );
};

AnimatedCloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
    color: PropTypes.string,
};

export default AnimatedCloseButton;
