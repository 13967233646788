import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

// TODO: Add localisation
export default function DisplayDate(props) {
    return (
        <span>
            {props.children ? Moment(props.children).format('YYYY-MM-DD') : ''}
        </span>
    );
}

DisplayDate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
};
