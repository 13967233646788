import { Box } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import useMouseSelectionBox from './use-mouse-selection-box';
import SelectionBox from './selection-box-component';

const MouseSelectableBox = ({
    children,
    setSelectedElements,
    isActive,
    elementClassName = 'sortable-item',
    elementIdAttribute = 'data-sortable-id',
    ...props
}) => {
    const {
        ref,
        box,
        displayBox,
        onMouseDown,
        onMouseMove,
        onMouseUp,
        calculateSelectedElements,
    } = useMouseSelectionBox({
        isActive,
        elementClassName,
        elementIdAttribute,
    });

    return (
        <Box
            // This creates some extra spacing around
            // improving usability for selecting elements at the edge
            m="-25px"
            p="25px"
            style={{ position: 'relative', userSelect: 'none' }}
            ref={ref}
            onMouseDown={onMouseDown}
            onMouseUp={() => {
                onMouseUp();
                setSelectedElements(calculateSelectedElements());
            }}
            onMouseMove={onMouseMove}
            {...props}
        >
            {displayBox && <SelectionBox box={box} />}
            {children}
        </Box>
    );
};

MouseSelectableBox.propTypes = {
    children: PropTypes.node,
    setSelectedElements: PropTypes.func,
    isActive: PropTypes.bool,
    elementClassName: PropTypes.string,
    elementIdAttribute: PropTypes.string,
};

export default MouseSelectableBox;
