import React from 'react';
import PropTypes from 'prop-types';
import domPurify from 'dompurify';

export default function DisplayUnsafeHtml(props) {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: domPurify.sanitize(props.children, props.profile),
            }}
        />
    );
}

DisplayUnsafeHtml.propTypes = {
    children: PropTypes.string,
    profile: PropTypes.shape({
        ALLOWED_TAGS: PropTypes.arrayOf(PropTypes.string),
        ALLOWED_ATTR: PropTypes.arrayOf(PropTypes.string),
    }),
};
