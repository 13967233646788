import React from 'react';
import styled from '@emotion/styled';
import { rem } from '@mspecs/shared-utils';

const SuffixContainer = styled('span')`
    padding-left: 10px;
    font-size: ${rem(12.4)};
    margin: auto 0;
`;

const SuffixSpan = props => {
    const { suffix } = props;

    if (suffix) {
        return <SuffixContainer>{suffix}</SuffixContainer>;
    }
    return null;
};

export default SuffixSpan;
