import styled from '@emotion/styled';
import BaseFormLayout from './base-layout-component';
import { createLayoutHoc } from './layout-utils';

const HorizontalLayout = styled(BaseFormLayout)`
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.medium}) {
        flex-direction: row;
    }
    gap: 0 15px;
`;

const HorizontalItemLayout = styled.div`
    flex-grow: 1;
    flex: 1;
`;

export default createLayoutHoc(
    HorizontalLayout,
    HorizontalItemLayout,
    'HorizontalLayout',
    'horizontal-layout'
);
