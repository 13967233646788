import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Image = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    // Make image not visible if no image exists to get rid
    // of the default border and to keep the columns the same size
    visibility: ${({ src }) => (src ? 'visible' : 'hidden')};
`;

const ProfileImageRenderer = ({ value }) => {
    return <Image src={value} />;
};

ProfileImageRenderer.propTypes = {
    value: PropTypes.string,
};

export default ProfileImageRenderer;
