import React from 'react';
import {
    and,
    optionIs,
    rankWith,
    isNumberControl,
    isIntegerControl,
    or,
} from '@jsonforms/core';
import {
    getCellProps,
    parseInputToNumber,
    withInputControlHoc,
} from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { YearInput } from '../../../input';

function YearCell(props) {
    const { path, handleChange, schema, data } = props;
    const { minimum, maximum } = schema;

    const baseProps = {
        ...getCellProps(props),
        value: data ? String(data) : undefined,
        onChange: e =>
            handleChange(path, parseInputToNumber(e.target.value) || undefined),
    };

    return <YearInput {...baseProps} min={minimum} max={maximum} />;
}
YearCell.propTypes = CellPropType;

export const tester = rankWith(
    3,
    and(or(isNumberControl, isIntegerControl), optionIs('format', 'year'))
);

export default { renderer: withInputControlHoc(YearCell), tester };
