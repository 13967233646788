import {
    OPEN_EMAIL_MODAL,
    CLOSE_EMAIL_MODAL,
    FETCH_EMAILS_SUCCESS,
    EDIT_EMAIL_REQUEST,
    FETCH_EMAIL_SUCCESS,
    NEW_EMAIL_REQUEST,
    CREATE_EMAIL_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    SEND_EMAIL_SUCCESS,
    DELETE_EMAIL_SUCCESS,
    SEARCH_CONTACTS_SUCCESS,
    ADD_EMAIL_CONTACT,
    REMOVE_EMAIL_CONTACT,
    POST_EMAIL_ATTACHMENT_SUCCESS,
    DELETE_EMAIL_ATTACHMENT_SUCCESS,
} from '../actions/email-actions';

const initialState = {
    isModalOpen: false,
    list: [],
    email: {
        id: '',
        createdByUserId: '',
        createdDate: '',
        sentDate: '',
        updatedDate: '',
        recipients: [],
        subject: '',
        body: '',
        attachments: [],
    },
    contactSuggestions: [],
};

function updateEmailInList(state, email) {
    return state.list.map(item => {
        if (item.id === email.id) {
            return email;
        }
        return item;
    });
}

export const emails = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_EMAIL_MODAL:
            return {
                ...state,
                isModalOpen: true,
            };
        case CLOSE_EMAIL_MODAL:
            return {
                ...state,
                isModalOpen: false,
                email: initialState.email,
            };
        case FETCH_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.payload,
            };
        case FETCH_EMAILS_SUCCESS:
            return {
                ...state,
                list: [...state.list, ...action.payload],
            };
        case EDIT_EMAIL_REQUEST:
            return {
                ...state,
                isModalOpen: true,
                email: state.list.find(item => item.id === action.payload),
            };
        case NEW_EMAIL_REQUEST:
            return {
                ...state,
                isModalOpen: true,
                email: initialState.email,
            };
        case CREATE_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.payload,
                list: [action.payload, ...state.list],
            };
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                list: updateEmailInList(state, action.payload),
            };
        case UPDATE_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.payload,
                list: updateEmailInList(state, action.payload),
            };
        case DELETE_EMAIL_SUCCESS:
            return {
                ...state,
                list: state.list.filter(item => item.id !== action.payload),
            };
        case SEARCH_CONTACTS_SUCCESS:
            return {
                ...state,
                contactSuggestions: action.payload,
            };
        case ADD_EMAIL_CONTACT:
            return {
                ...state,
                email: {
                    ...state.email,
                    recipients: [...state.email.recipients, action.payload],
                },
            };
        case REMOVE_EMAIL_CONTACT:
            return {
                ...state,
                email: {
                    ...state.email,
                    recipients: state.email.recipients.filter(
                        contact => contact.id !== action.payload.id
                    ),
                },
            };
        case POST_EMAIL_ATTACHMENT_SUCCESS:
            return {
                ...state,
                email: {
                    ...state.email,
                    attachments: [...state.email.attachments, action.payload],
                },
            };
        case DELETE_EMAIL_ATTACHMENT_SUCCESS:
            return {
                ...state,
                email: {
                    attachments: state.email.attachments.filter(
                        item => item.id !== action.payload
                    ),
                },
            };
        default:
            return state;
    }
};
