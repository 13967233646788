/**
 * Convert pixels to rem
 * @param {Number} pxValue - Pixel value to convert to REM
 * @param {Number} base - Base value. Browser default value is 14
 * @example
 * font-size: ${rem(12)}
 * @returns {String} Returns calculated REM value with appended rem unit
 */

export const rem = (
    pxValue,
    base = BASE_FONT_SIZE /* Base font size value is set in webpack config */
) => {
    if (typeof pxValue !== 'number' || typeof base !== 'number') {
        throw new Error('Pixel value must be a number');
    }
    if (typeof base !== 'number') {
        throw new Error('Base value must be a number');
    }

    return `${pxValue / base}rem`;
};
