import React from 'react';
import styled from '@emotion/styled';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import TableIcon from './table-icon-component';

const Icon = styled(TableIcon)`
    color: ${({ theme }) => theme.colors.errorColor};
`;

export const ErrorIcon = props => (
    <Icon icon={faExclamationCircle} {...props} />
);

export default ErrorIcon;
