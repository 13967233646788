import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import {
    API_PREFIX_PATH,
    LEGACY_API_PREFIX_PATH,
} from './constants/url-contants';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const GRAPHQL_URI = `${API_PREFIX_PATH}/v3/graphql`;

const batchLink = new BatchHttpLink({ uri: GRAPHQL_URI });
const uploadLink = createUploadLink({ uri: GRAPHQL_URI });
const wsLink = new WebSocketLink(
    new SubscriptionClient(`wss://${location.host}${GRAPHQL_URI}`, {
        reconnect: true,
        lazy: true,
    })
);
const restLink = new RestLink({ uri: LEGACY_API_PREFIX_PATH });

const isSubscription = ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
};
const isBatch = ({ operationName }) => operationName === 'removeFilter';
const isRest = ({ operationName }) => operationName?.includes('REST');

const link = ApolloLink.split(
    operation =>
        isSubscription(operation) || isBatch(operation) || isRest(operation),
    ApolloLink.split(
        operation => isRest(operation),
        restLink,
        ApolloLink.split(
            operation => isSubscription(operation),
            wsLink,
            batchLink
        )
    ),
    uploadLink
);

const errorLink = onError(({ networkError }) => {
    if (networkError?.statusCode === 401) {
        console.log(networkError);
    }
});

const finalLink = from([errorLink, link]);

export const createClient = () =>
    new ApolloClient({
        link: finalLink,
        cache: new InMemoryCache({
            typePolicies: {
                Contact: {
                    fields: {
                        bankAccounts: {
                            merge: false,
                        },
                        matchings: { merge: false },
                    },
                },
                QueryFilter: {
                    fields: {
                        filter: {
                            read(existing) {
                                // Parse the filter if its a string.
                                // Filters was saved as a string previously.
                                if (typeof existing === 'string') {
                                    try {
                                        return JSON.parse(existing);
                                    } catch (error) {
                                        return existing;
                                    }
                                }
                                return existing;
                            },
                        },
                    },
                },
                Query: {
                    fields: {
                        dealListSearch: {
                            merge: false,
                        },
                        flowPageQuery: {
                            merge: false,
                        },
                        // Before hitting the network, check if the objects is in the cache
                        // They might have been fetched earlier with the lists queries

                        modelSchemaFieldByName(_, { args, toReference }) {
                            return toReference({
                                __typename: 'ModelField',
                                name: args.fieldName,
                                modelSchema: {
                                    name: args.modelName,
                                },
                            });
                        },
                        listFilter(_, { args, toReference }) {
                            return toReference({
                                __typename: 'QueryFilter',
                                id: args.id,
                            });
                        },
                        sidelines: {
                            merge: false,
                        },
                        contact(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Contact',
                                id: args.id,
                            });
                        },
                    },
                },
                ModelSchema: {
                    keyFields: ['name'],
                },
                ModelField: {
                    keyFields: ['name', 'modelSchema', ['name']],
                },
            },
        }),
    });
