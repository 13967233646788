import React from 'react';
import PropTypes from 'prop-types';

const Display = props => {
    return props.when ? props.children : null;
};

Display.propTypes = {
    when: PropTypes.bool.isRequired,
};

export default Display;
