/* eslint-disable */
export const white = '#fff';
export const black = '#000';

export const gray120 = '#bbb';
export const gray100 = '#ccc';
export const gray80 = '#ddd';

export const bgPrimary = '#fff';
export const bgPrimarySemiTransparent = 'rgba(255, 255, 255, 0.75)';
export const bgSecondary = '#f8f8f8';
export const bgSecondary80 = '#fbfbfb';
export const bgSecondary120 = '#F2F2F2';
export const bgSecondary140 = '#EAEAEA';
export const borderPrimary = gray80;
export const borderSecondary = gray100;
export const textPrimary = '#211F20';
export const textSecondary = '#727272';
export const contrastBgPrimary = '#051C30';
export const contrastBg105 = '#041929';
export const contrastBg95 = '#07243E';
export const contrastBg90 = '#092C4C';
export const contrastBg85 = '#274056';
export const contrastBg75 = '#385773';
export const contrastBg65 = '#4B7090';
export const contrastBg55 = '#6E8BA5';
export const contrastBg50 = '#90A6BA';

export const primaryColor = '#0F4C81';
export const primaryColor90 = '#275E8E';
export const primaryColor80 = '#3F709A';
export const primaryColor60 = '#6F94B3';
export const primaryColor40 = '#9FB7CD';
export const primaryColor20 = '#CFDBE6';
export const primaryColor15 = '#DBE4EC';
export const primaryColor10 = '#E7EDF2';
export const primaryColor5 = '#F3F6F9';

export const greenPrimaryColor = '#0F813D';
export const green80 = '#609D50';
export const green60 = '#7DB36F';
export const green40 = '#A8CD9F';
export const green20 = '#CFE3CA';
export const green15 = '#DEECDB';
export const green7 = '#F0F6EE';

export const accentPrimary = '#3CA2DB';
export const accent50 = '#9DD1ED';
export const accent20 = '#CEE8F6';

export const redPrimary = '#BB1414';
export const red105 = '#B11212';
export const red110 = '#A71111';
export const red40 = '#E4A1A1';
export const red20 = '#F1D0D0';
export const red10 = '#F8E7E7';

export const pendingPrimary = '#DC793D';
export const pending40 = '#F1C9B1';
export const pending20 = '#F8E4D8';
export const pending10 = '#FBF2EC';

export const highlightingBgPrimary = '#FFFFB3';
export const highlightingBg50 = '#FFFFD9';
export const highlightingBg25 = '#FFFFEC';

export const errorColor = redPrimary; //for: errors like input border, red alert bottom of the page, error icon'
export const warningColor = pendingPrimary; //for: warnings, to mark field in filter, bid text in deal/estate overview
export const successColor = green60; //for: success alert, success alert bottom of the page, check ok icon
export const linkColor = primaryColor;
export const boxShadowColor = 'rgb(0 0 0 / 10%)';
