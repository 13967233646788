import React from 'react';
import { createPortal } from 'react-dom';
import MobileOptionsMenu, {
    MobileOptionsMenuProps,
} from './mobile-options-menu-component';

const MobileOptionsMenuPortal = ({
    isOpen,
    ...props
}: MobileOptionsMenuProps) => {
    return isOpen
        ? createPortal(
              <MobileOptionsMenu isOpen={isOpen} {...props} />,
              document.body
          )
        : null;
};

export default MobileOptionsMenuPortal;
