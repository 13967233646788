import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { triggerOnBlur } from '@mspecs/shared-utils';

const SelectMenu = props => {
    const { onMenuClose, isSearchable, menuIsOpen, isExplicitSearchBar } =
        props.selectProps;
    const ref = triggerOnBlur(
        event => {
            const clickedSelectContainer = event.target.closest(
                '#react-select-container'
            );
            if (!clickedSelectContainer) {
                onMenuClose();
                event.stopPropagation();
            }
        },
        menuIsOpen,
        !isSearchable || !isExplicitSearchBar
    );

    return (
        <components.Menu
            {...props}
            innerRef={el => {
                ref.current = el;
                props.innerRef.current = el;
            }}
        />
    );
};

SelectMenu.propTypes = {
    selectProps: PropTypes.object,
};

export default SelectMenu;
