import { ESIGNATURE_ACTION_TYPES } from '../../actions/esignature/esign-modal-actions';

const initialState = {
    attachments: {},
    // TODO: this should be nestled in attachments as its technically attachments
    includeFiles: {},
    receiverData: {
        receiver: {},
        receiversObject: {},
    },
    sendingData: {
        isSending: false,
        isSended: false,
        isError: false,
        error: null,
        data: {},
    },
    languages: [],
    documentAttachments: {},
    recipients: [],
    eSign: {},
    isLoading: false,
};

const esignModal = (state = initialState, action) => {
    switch (action.type) {
        case ESIGNATURE_ACTION_TYPES.MODIFY_SELCTED_DEAL_FILES:
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    ...action.payload,
                },
            };
        case ESIGNATURE_ACTION_TYPES.REMOVE_DOCUMENT_ATTACHMENT:
            const { attachments, documentAttachments } = action.payload;
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    ...attachments,
                },
                documentAttachments: documentAttachments,
            };
        case ESIGNATURE_ACTION_TYPES.UPDATE_DOCUMENT_ATTACHMENTS:
            return {
                ...state,
                documentAttachments: action.payload,
            };

        case ESIGNATURE_ACTION_TYPES.ADD_RECEIVER_STARTING:
            const receiver = {};
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiver,
                },
            };
        case ESIGNATURE_ACTION_TYPES.EDIT_RECEIVER_STARTING:
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiver: action.payload,
                },
            };
        case ESIGNATURE_ACTION_TYPES.ADD_RECEIVER_SUCCESS:
        case ESIGNATURE_ACTION_TYPES.EDIT_RECEIVER_SUCCESS:
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiver: action.payload.receiver,
                },
            };

        case ESIGNATURE_ACTION_TYPES.GET_RECEIVERS_SUCCESS:
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiversObject: action.payload,
                },
            };

        case ESIGNATURE_ACTION_TYPES.CLOSE_RECEIVER_SUCCESS:
            const receiversObject = action.payload;
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiver: {},
                    receiversObject,
                },
            };
        case ESIGNATURE_ACTION_TYPES.SENDING_FOR_ESIGNING_STARTING:
            return {
                ...state,
                sendingData: {
                    ...state.sendingData,
                    isSending: true,
                    isSended: false,
                    isError: false,
                    error: null,
                },
            };
        case ESIGNATURE_ACTION_TYPES.SEND_SUCCESS:
            return {
                ...state,
                sendingData: {
                    isSending: false,
                    isSended: true,
                    isError: false,
                    error: null,
                    data: {},
                },
            };
        case ESIGNATURE_ACTION_TYPES.SENDING_FOR_ESIGNING_ERROR:
            return {
                ...state,
                sendingData: {
                    ...state.sendingData,
                    isSending: false,
                    isSended: false,
                    isError: true,
                    error: action.payload.error,
                },
            };

        case ESIGNATURE_ACTION_TYPES.UPDATE_RECEIVER:
            return {
                ...state,
                receiverData: {
                    ...state.receiverData,
                    receiver: action.payload.receiver,
                },
            };

        case ESIGNATURE_ACTION_TYPES.UPDATE_SENDING_DATA:
            return {
                ...state,
                sendingData: {
                    ...state.sendingData,
                    data: action.payload,
                },
            };

        case ESIGNATURE_ACTION_TYPES.GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.payload,
            };

        case ESIGNATURE_ACTION_TYPES.GET_RECIPIENTS_STATUS_SUCCESS:
            return {
                ...state,
                recipients: action.payload,
            };

        case ESIGNATURE_ACTION_TYPES.PREPARE_RECIPIENTS_SUCCESS:
            return {
                ...state,
                eSign: action.payload,
                isLoading: false,
            };

        case ESIGNATURE_ACTION_TYPES.IS_START_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
};

export default esignModal;
