import { batch } from 'react-redux';
import { ac } from '@mspecs/shared-utils';
import { legacyApiMW } from '@mspecs/shared-utils';
import { normalizeSchema } from '../../utils/schema-utils';
export const FETCH_SCHEMA_REQUEST = 'FETCH_SCHEMA_REQUEST';
export const FETCH_SCHEMA_SUCCESS = 'FETCH_SCHEMA_SUCCESS';
export const FETCH_SCHEMA_ERROR = 'FETCH_SCHEMA_ERROR';

function requestFetchSchema(schemaType) {
    return ac(FETCH_SCHEMA_REQUEST, schemaType);
}

function successFetchSchema(schema) {
    return ac(FETCH_SCHEMA_SUCCESS, schema);
}

function errorFetchSchema(schemaType) {
    return ac(FETCH_SCHEMA_ERROR, schemaType);
}

function getSchema(dispatch, schemaType) {
    dispatch(requestFetchSchema(schemaType));
    return dispatch({
        errorActionType: FETCH_SCHEMA_ERROR,
        options: { waitforPending: true },
        req: legacyApiMW(`/schema/${schemaType}`).get(),
    });
}

export function fetchSchema(schemaType) {
    return async dispatch => {
        const promise = getSchema(dispatch, schemaType);
        if (!promise) return;
        promise
            .then(schema => {
                dispatch(successFetchSchema(normalizeSchema(schema)));
            })
            .catch(() => {
                dispatch(errorFetchSchema(schemaType));
            });
    };
}

export function fetchSchemas(schemaTypes) {
    return async dispatch => {
        Promise.all(
            schemaTypes.map(schemaType => getSchema(dispatch, schemaType))
        )
            .then(schemas => {
                batch(() => {
                    schemas.forEach(s => {
                        dispatch(successFetchSchema(normalizeSchema(s)));
                    });
                });
            })
            .catch(() => {
                schemaTypes.forEach(s => {
                    dispatch(errorFetchSchema(s));
                });
            });
    };
}
