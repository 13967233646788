import { ac } from '@mspecs/shared-utils';
import { arrayToObj } from '@mspecs/shared-utils';
import { legacyApi } from '@mspecs/shared-utils';

export const SIGNATURE_ACTION_TYPES = {
    GET_CONTACT_SIGNATURES_SUCCESS: 'GET_CONTACT_SIGNATURES_SUCCESS',
    DELETE_CONTACT_SIGNATURE_SUCCESS: 'DELETE_CONTACT_SIGNATURE',
    UPDATE_CONTACT_SIGNATURE_SUCCESS: 'UPDATE_CONTACT_SIGNATURE',
    CREATE_NEW_CONTACT_SIGNATURE_SUCCESS: 'CREATE_NEW_CONTACT_SIGNATURE',
};

const successGetContactSignatures = (signatures, contactId) =>
    ac(SIGNATURE_ACTION_TYPES.GET_CONTACT_SIGNATURES_SUCCESS, {
        signatures,
        contactId,
    });

const successDeletedContactSignature = (signature, contactId) =>
    ac(SIGNATURE_ACTION_TYPES.DELETE_CONTACT_SIGNATURE_SUCCESS, {
        signature,
        contactId,
    });

const successUpdateContactSignature = (signature, contactId) =>
    ac(SIGNATURE_ACTION_TYPES.UPDATE_CONTACT_SIGNATURE_SUCCESS, {
        signature,
        contactId,
    });

const successCreatedNewContactSignature = (signature, contactId) =>
    ac(SIGNATURE_ACTION_TYPES.CREATE_NEW_CONTACT_SIGNATURE_SUCCESS, {
        signature,
        contactId,
    });

const api = (subEndpoint = '') => legacyApi(`/v2/signatures${subEndpoint}`);

export function seedSignatures(dealId, signatureOptionType) {
    return api().query({ dealId, signatureOptionType }).get();
}

export function getContactSignatures(dealId, contactId, validTypes) {
    return async dispatch => {
        const signatures = await api('/contactSignatures')
            .query({
                dealId,
                contactIds: [contactId],
            })
            .get();
        dispatch(
            successGetContactSignatures(
                arrayToObj(
                    signatures.filter(
                        ({ relations, signatureType }) =>
                            relations.contactId === contactId &&
                            !!validTypes[signatureType]
                    )
                ),
                contactId
            )
        );
        return signatures;
    };
}

export function deleteSignature(signature, sellerId) {
    return dispatch =>
        api('/contactSignatures')
            .delete({ signature, sellerId })
            .then(() => {
                dispatch(
                    successDeletedContactSignature(
                        signature,
                        signature.relations.contactId
                    )
                );
            });
}

export function createOrUpdateSignature(
    signature,
    dealId,
    contactId,
    sellerId,
    buyerId
) {
    const isUpdate = !!signature.id;
    return dispatch =>
        api('/contactSignatures')
            .createOrUpdate({
                signature,
                sellerId,
                buyerId,
                contactId,
                dealId,
            })
            .then(signatures => {
                const newSignature = signatures.pop();
                const successType = isUpdate
                    ? successUpdateContactSignature
                    : successCreatedNewContactSignature;
                dispatch(
                    successType(newSignature, newSignature.relations.contactId)
                );
            });
}
