import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

const PaginatorContainer = styled('div')`
    margin: 20px 0;
    text-align: center;
`;

const Paginator = ({ dataFetch, t }) => (
    <PaginatorContainer>
        <Button bsStyle="info" onClick={() => dataFetch()}>
            {t('LOAD_MORE')}
        </Button>
    </PaginatorContainer>
);

Paginator.propTypes = {
    dataFetch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default Paginator;
