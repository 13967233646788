import styled from '@emotion/styled';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../button/button-component';

const Container = styled(Flex)`
    margin-top: auto;
    padding: 1rem;
    border-top: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    height: 68px;
`;

const Status = styled(Flex)`
    flex: 1;
    justify-content: center;
    padding: 0 5px;
`;

const NavigationButton = styled(Button)`
    padding: 0.52rem 1rem;
    flex: 1;
`;

const NavigationIcon = styled(FontAwesomeIcon)``;

const ImageEditorNavigationButtons = ({
    onNext,
    onPrevious,
    current,
    total,
}) => {
    return (
        <Container justifyContent="space-between" alignItems="center">
            <NavigationButton onClick={onPrevious}>
                <NavigationIcon icon={faChevronLeft} />
            </NavigationButton>
            <Status>{`${current} / ${total}`}</Status>
            <NavigationButton onClick={onNext}>
                <NavigationIcon icon={faChevronRight} />
            </NavigationButton>
        </Container>
    );
};

ImageEditorNavigationButtons.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    current: PropTypes.number,
    total: PropTypes.number,
};

export default ImageEditorNavigationButtons;
