import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconButton from '../../button/icon-button/icon-button-component';
import { InputField } from '.';

const StyledIconButton = styled(IconButton)`
    margin-left: 2px;
`;

const SecretValueInput = ({ name, value, label, ...rest }) => {
    const [showValue, setShowValue] = useState(false);
    return (
        <>
            <Flex flexDirection={'row'} width={1}>
                <InputField
                    disabled
                    label={label}
                    name={name}
                    value={value ? (showValue ? value : '••••••••') : ''}
                    {...rest}
                />
                <StyledIconButton
                    onClick={() => {
                        setShowValue(!showValue);
                    }}
                    icon={showValue ? faEyeSlash : faEye}
                    title="SHOW_VALUE"
                    hiddenLabel="SHOW_VALUE"
                    disabled={!value}
                />
            </Flex>
        </>
    );
};

SecretValueInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
};

export default SecretValueInput;
