import React from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import IconButton from './icon-button-component';

const UrlButton = props => (
    <IconButton
        icon={faArrowUpRightFromSquare}
        title="CUSTOM_FIELDS_TEST_LINK"
        {...props}
    />
);

export default UrlButton;
