import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BodyTextRegular } from '../../typograph/typograph-components';

const OptionsWrapper = styled(Flex)`
    height: 42px;
    align-items: center;
    cursor: pointer;
    padding: 0 ${({ theme }) => theme.spacing.default};
    border-radius: ${({ theme }) => theme.borderRadius.default};

    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor5};
    }

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        height: 34px;
    }
`;
const OptionsIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primaryColor};
    padding-right: ${({ theme }) => theme.spacing.default};
`;

const PopoverOptionItem = ({ label, icon, onClick }) => {
    return (
        <OptionsWrapper onClick={onClick}>
            {icon && <OptionsIcon icon={icon} />}
            <BodyTextRegular>{label}</BodyTextRegular>
        </OptionsWrapper>
    );
};
PopoverOptionItem.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func,
};

export default PopoverOptionItem;
