import React from 'react';
import DatePicker from './date-picker-component';

const DateTimePicker = props => {
    return (
        <DatePicker
            {...props}
            schemaFormat="datetime"
            dateFormat="yyyy-MM-dd HH:mm"
            outputFormat="string"
        />
    );
};

export default DateTimePicker;

DateTimePicker.propTypes = {
    ...DatePicker.propTypes,
};
