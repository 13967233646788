import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import RadioButton from './radio-button-component';
import { withInputValidation, withLabel } from '../../../../utils/hoc';

const RadioButtonGroupWrapper = styled('div')`
    display: flex;
    flex-direction: ${props => (props.inline ? 'row' : 'column')};
`;

const RadioButtonGroup = props => {
    const { children, name, options, onChange, isFormField, inline } = props;
    const _options =
        options && options.length ? options : React.Children.toArray(children);

    return (
        <RadioButtonGroupWrapper inline={inline}>
            {_options.map((option, index) => {
                const value =
                    typeof option === 'string' ? option : option.props.value;
                const label =
                    typeof option === 'string' ? option : option.props.label;
                const disabled = props.disabled || option.props.disabled;

                const optionChildren =
                    typeof option === 'string' ? null : option.props.children;

                const childProps = {
                    label,
                    value,
                    name,
                    onChange,
                    key: index,
                    isFormField,
                    options,
                    checked: value === props.value,
                    disabled,
                };

                return (
                    <RadioButton key={label + index} {...childProps}>
                        {optionChildren}
                    </RadioButton>
                );
            })}
        </RadioButtonGroupWrapper>
    );
};

export default compose(withLabel, withInputValidation)(RadioButtonGroup);

RadioButtonGroup.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.string,
    inline: PropTypes.bool,
    isFormField: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
};

RadioButtonGroup.defaultProps = {
    onChange: () => {},
};
