import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import bgWhiteFlowers from '@mspecs/ui-components/src/assets/images/login.jpg';
import bgPinkFlowers from '@mspecs/ui-components/src/assets/images/register.jpg';
import logo from '@mspecs/ui-components/src/assets/images/mspx_logo_light.png';
import { rem } from '@mspecs/shared-utils';

const Container = styled(Flex)`
    background-image: url('${props =>
        props.register ? bgPinkFlowers : bgWhiteFlowers}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    max-width: ${props => props.bigContainer && '230px'};

    @media (max-width: ${({ theme }) => theme.medium}) {
        max-width: 100%;
        min-height: 225px;
        height: 280px;
        background-position: ${props =>
            props.register ? 'center center' : 'center bottom'};
        padding: ${rem(24)} ${rem(24)} ${rem(16)};
    }
`;

const MspxLogo = styled.img`
    max-width: 100%;
    max-height: 120px;

    @media (max-width: ${({ theme }) => theme.medium}) {
        max-height: 90px;
    }
`;

const ImageContainer = ({ bigContainer, register }) => {
    return (
        <Container
            width={[1, 0.45]}
            pt={24}
            px={24}
            pb={30}
            alignItems="flex-end"
            justifyContent="center"
            register={register}
            bigContainer={bigContainer}
        >
            <MspxLogo src={logo} />
        </Container>
    );
};

export default ImageContainer;
