import { useDroppable } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const DroppableSortingArea = ({ id, items, children, height = 300 }) => {
    const { setNodeRef, active } = useDroppable({ id });
    const theme = useTheme();
    const droppableStyle = {
        minHeight: height,
        background: active && theme.colors.bgSecondary,
    };
    return (
        <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
            <div ref={setNodeRef} style={droppableStyle}>
                {children}
            </div>
        </SortableContext>
    );
};
DroppableSortingArea.propTypes = {
    id: PropTypes.string,
    items: PropTypes.array,
    children: PropTypes.any,
    height: PropTypes.number,
};

export default DroppableSortingArea;
