import React from 'react';
import {
    JsonFormsDispatch,
    withJsonFormsArrayControlProps,
} from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { faMinus } from '@fortawesome/pro-light-svg-icons';
import BaseArrayControl from './base-array-controller';
import IconButtonComponent from '../../../../button/icon-button/icon-button-component';
import { Divider } from '../../../../layout';
import { Button, BUTTON_TYPES } from '../../../../button';

const ButtonContainer = styled(Flex)`
    align-items: center;
    @media (min-width: 768px) {
        align-items: baseline;
    }
`;

const ItemDivider = styled(Divider)`
    margin: 10px 0 15px 0;
    @media (min-width: 768px) {
        margin: 0 0 15px 0;
    }
`;

const PendingButtonsContainer = styled(Flex)`
    justify-content: flex-end;
    padding: 10px 0 20px 0;

    button:first-of-type {
        margin-right: 10px;
    }
`;

export const ArrayControl = props => {
    const {
        config,
        path,
        schema,
        removeItems,
        renderers,
        enabled,
        hideRemoveButton,
    } = props;

    return (
        <BaseArrayControl
            {...props}
            displayAddButton={enabled && schema}
            renderField={({ index, childPath, uischema, data }) => (
                <Flex key={index} flexDirection="column">
                    {uischema.type === 'VerticalLayout' && index !== 0 && (
                        <ItemDivider />
                    )}
                    <Flex>
                        <JsonFormsDispatch
                            schema={schema}
                            uischema={uischema}
                            path={childPath}
                            renderers={renderers}
                        />
                        {enabled && !hideRemoveButton && (
                            <ButtonContainer ml={['40px', '10px']}>
                                <IconButtonComponent
                                    icon={faMinus}
                                    hiddenLabel="REMOVE"
                                    onClick={() => {
                                        removeItems(path, [index])();
                                    }}
                                />
                            </ButtonContainer>
                        )}
                    </Flex>
                    {data?.isPendingItem && config?.pendingArrayItems && (
                        <>
                            <PendingButtonsContainer>
                                <Button
                                    buttonType={BUTTON_TYPES.SECONDARY}
                                    label="CANCEL"
                                    onClick={() => {
                                        removeItems(path, [index])();
                                    }}
                                />
                                <Button
                                    buttonType={BUTTON_TYPES.PRIMARY}
                                    label="DROPDOWN_SEARCH_DONE_BUTTON_TEXT"
                                    onClick={() => {
                                        const { isPendingItem, ...validData } =
                                            data;
                                        props.handleChange(
                                            `${path}[${index}]`,
                                            validData
                                        );
                                    }}
                                />
                            </PendingButtonsContainer>
                            <ItemDivider />
                        </>
                    )}
                </Flex>
            )}
        />
    );
};

export default withVanillaControlProps(
    withJsonFormsArrayControlProps(ArrayControl)
);
