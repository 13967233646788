import { LOGIN_DONE, LOGIN_REDIRECT } from '../actions/oauth-actions';

const initialState = {
    isAuthenticating: false,
};

export default function oauth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REDIRECT:
            return {
                isAuthenticating: true,
            };
        case LOGIN_DONE:
            return {
                isAuthenticating: false,
            };
        default:
            return state;
    }
}
