import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import PropTypes from 'prop-types';
import { NavigationBackButton } from '../../navigation';

const BackButtonContainer = styled.div`
    padding: 5px 0;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: none;
    }
`;

export default function ModalBackButton({ onClick, buttonText, ...props }) {
    const { t } = useTranslate();
    return (
        <BackButtonContainer {...props}>
            <NavigationBackButton label={t(buttonText)} onClick={onClick} />
        </BackButtonContainer>
    );
}

ModalBackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
};
ModalBackButton.defaultProps = {
    buttonText: 'BACK',
};
