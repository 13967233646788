import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import styled from '@emotion/styled';
import {
    withFinalFormField,
    withInputValidation,
    withLabel,
} from '../../../utils/hoc';

const StyledSelect = styled(Select)`
    min-width: 217px;
`;

class MultiSelect extends React.PureComponent {
    onChange = (selectedItems, selectedItemsOptions) => {
        const { resultAsObject } = this.props;
        let result = selectedItems;
        if (resultAsObject) {
            result = selectedItems.length
                ? selectedItems.reduce(
                      (acc, { label, value }) => ({
                          ...acc,
                          [label]: value,
                      }),
                      {}
                  )
                : null;
        }
        this.props.onChange(result, selectedItemsOptions);
    };

    render() {
        const { t, className } = this.props;

        return (
            <StyledSelect
                defaultValue={this.defaultValue}
                {...this.props}
                isMulti
                className={className}
                onChange={this.onChange}
                getOptionLabel={x => t(x.label)}
                noOptionsMessage={() => t('NO_OPTIONS')}
            />
        );
    }
}

export default compose(
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel
)(MultiSelect);

MultiSelect.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    resultAsObject: PropTypes.bool,
};

MultiSelect.defaultProps = {
    t: x => x,
    onChange: x => x,
    resultAsObject: false,
};
