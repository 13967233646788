import React from 'react';

import { Control, withJsonFormsControlProps } from '@jsonforms/react';
import { validators } from '@mspecs/shared-tools-base';
import { translate } from '@mspecs/shared-utils';

const isNumeric = type => ['number', 'integer'].includes(type);

export const parseInputToNumber = val => {
    if (!val) return null;
    return isDecimalLast(val) ? val : parseToNumber(val);
};

export const isDecimalLast = value =>
    value?.includes(',') && value.indexOf(',') === value.length - 1;
export const parseToNumber = value =>
    value ? Number(value.replaceAll(',', '.').replaceAll(/\s/g, '')) : undefined;

export function withInputControlHoc(Comp) {
    class InputControl extends Control {
        render() {
            const { id, visible, isValid } = this.props;

            return (
                <div
                    id={id}
                    hidden={!visible}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                >
                    <Comp {...this.props} isValid={isValid ?? true} />
                </div>
            );
        }
    }

    return withJsonFormsControlProps(translate(InputControl));
}

const { fieldValidator } = validators.jsonValidators;

const validateField =
    (validator, type, t, customValidator = () => []) =>
    value => {
        let formattedValue = value;
        if (
            isNumeric(type) &&
            typeof value === 'string' &&
            !isDecimalLast(value)
        ) {
            formattedValue = parseToNumber(value);
        }

        if (type === 'booleanArray') {
            return null;
        }

        const customErrors = customValidator(formattedValue);
        if (customErrors?.length) {
            return customErrors;
        }

        const errors = validator(formattedValue);
        if (errors?.errors) {
            return errors.errors.map(err => t(err.message)).join(', ');
        }
    };
export const getSchemaType = schema => {
    if (!schema) return;
    return Array.isArray(schema.type)
        ? schema.type.filter(i => i !== 'null')?.[0]
        : schema.type;
};

export const getCellProps = props => {
    const {
        id,
        data,
        label,
        required,
        isValid,
        enabled,
        uischema,
        schema,
        rootSchema,
    } = props;

    const type = getSchemaType(schema);
    const validator = fieldValidator({ ...schema, type });
    const validationFun = validateField(
        validator,
        type,
        props.t,
        schema.customValidator
    );

    const schemaOptions = uischema.options ?? {};

    const options = {
        additionalErrors: props.errors,
        id,
        required,
        label,
        value: data,
        invalid: !isValid,
        disabled: !enabled,
        validationFun,
        hoverText: uischema.helpText,
    };

    if (rootSchema?.metaProperties?.translationPrefix) {
        options.label = rootSchema.metaProperties.translationPrefix + label;
    }

    const labelProps = [
        'stackedLabel',
        'noWidth',
        'reverseLabel',
        'inputWidth',
        'suffix',
    ];
    labelProps.forEach(prop => {
        if (schemaOptions[prop]) {
            options[prop] = schemaOptions[prop];
        }
    });

    if (schemaOptions.noLabel) {
        options.label = undefined;
    }

    return options;
};
