import { SIGNATURE_ACTION_TYPES } from '../actions/signature-actions';

const initialState = {};

export default function contacts(state = initialState, action) {
    const contact = action.payload ? state[action.payload.contactId] || {} : {};
    const signatures = contact.signatures || [];

    if (action.payload && !action.payload.contactId) {
        return state;
    }

    switch (action.type) {
        case SIGNATURE_ACTION_TYPES.GET_CONTACT_SIGNATURES_SUCCESS: {
            return {
                ...state,
                [action.payload.contactId]: {
                    ...contact,
                    signatures: Object.keys(action.payload.signatures),
                },
            };
        }
        case SIGNATURE_ACTION_TYPES.DELETE_CONTACT_SIGNATURE_SUCCESS: {
            return {
                ...state,
                [action.payload.contactId]: {
                    ...state[action.payload.contactId],
                    signatures: signatures.filter(
                        x => x !== action.payload.signature.id
                    ),
                },
            };
        }
        case SIGNATURE_ACTION_TYPES.CREATE_NEW_CONTACT_SIGNATURE_SUCCESS: {
            return {
                ...state,
                [action.payload.contactId]: {
                    ...contact,
                    signatures: [...signatures, action.payload.signature.id],
                },
            };
        }
        default:
            return state;
    }
}
