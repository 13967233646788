import { ACTION_TYPES } from '../actions/request-actions';

const initialState = {
    pending: {},
};

export default function requests(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.REQUEST_STARTED: {
            return {
                ...state,
                pending: {
                    ...state.pending,
                    [action.payload]: true,
                },
            };
        }
        case ACTION_TYPES.REQUEST_FINISHED:
        case ACTION_TYPES.REQUEST_FAILED: {
            const { [action.payload]: na, ...pending } = state.pending;
            return {
                ...state,
                pending,
            };
        }
        case ACTION_TYPES.REQUEST_SUCCESS: {
            return state;
        }
        default:
            return state;
    }
}
