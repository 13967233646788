import React, { forwardRef } from 'react';

import { ScrollSyncPane } from 'react-scroll-sync';
import { NoSearchResults, NoDataError } from '../../table-components';
import TableBodyRow from './table-body-row-component';
import DragAndDropBody from './table-drag-n-drop-body-component';
import InfiniteScrollBody from './table-body-infinite-scroll-component';

const StaticRows = ({
    showPagination,
    page,
    rows,
    prepareRow,
    isItemLoaded,
    getRowProps,
}) => {
    return (
        <ScrollSyncPane>
            <div className="rt-scrollable">
                {(showPagination ? page : rows).map((row, index) => (
                    <TableBodyRow
                        key={row?.id || index}
                        row={row}
                        index={index}
                        prepareRow={prepareRow}
                        getRowProps={getRowProps}
                        isItemLoaded={isItemLoaded}
                        cellRender={cell => cell.render('Cell')}
                    />
                ))}
            </div>
        </ScrollSyncPane>
    );
};

const TableBody = forwardRef(
    (
        { error, isSearchable, isRowDragEnabled, infiniteScroll, ...restProps },
        ref
    ) => {
        let content = <StaticRows {...restProps} />;
        if (error) {
            content = <NoDataError />;
        } else if (
            !restProps.loading &&
            !restProps.data.length &&
            isSearchable
        ) {
            content = <NoSearchResults />;
        } else if (isRowDragEnabled) {
            content = <DragAndDropBody {...restProps} />;
        } else if (infiniteScroll) {
            content = <InfiniteScrollBody {...restProps} />;
        }

        return (
            <div className="rt-tbody" ref={ref}>
                {content}
            </div>
        );
    }
);

export default TableBody;
