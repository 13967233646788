import React, {
    useRef,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { WizContext } from './wiz-component';
import Hide from '../../layout/hide-component';

const withStep = Component => {
    const Step = ({ label, step, isValidated, ...restProps }) => {
        const [wizSteps, setWizSteps] = useState([]);
        const { current, hideNonActive, hasValidation, setStepIsValid, next } =
            useContext(WizContext);
        const hasBeenShownRef = useRef(false);
        const isActive = current.step && label === current.step.label;

        useEffect(() => {
            setWizSteps([...wizSteps, { label: label, step: step }]);
        }, [label]);

        const setIsValid = useCallback(isValid => {
            setStepIsValid(label, isValid);
        }, []);
        if (!isActive) {
            return null;
        }
        if (hideNonActive && !hasBeenShownRef.current) {
            hasBeenShownRef.current = true;
        }

        return (
            <Hide
                when={!isActive}
                isValidated={hasValidation ? isValidated : true}
                style={{ width: '100%' }}
            >
                {Component ? (
                    <Component
                        {...restProps}
                        label={label}
                        setIsValid={setIsValid}
                        next={next}
                    />
                ) : (
                    restProps.children
                )}
            </Hide>
        );
    };

    Step.displayName = 'Step';

    Step.propTypes = {
        label: PropTypes.string.isRequired,
        hasValidation: PropTypes.bool,
        isValidated: PropTypes.bool,
        useOwnButtons: PropTypes.func,
    };

    Step.defaultProps = {
        label: '',
    };

    return Step;
};

export default withStep;
