import {
    ACTIONS,
    LICENSE_MARKETING_PLACE_CONSTANT,
} from '../../actions/external-marketing-place/external-marketing-place-actions';

// Constant for marketing places that belongs to license

const initialState = {
    initLoad: false,
    working: false,
    isError: false,
    error: null,
    brokerFirms: {},
    // Structure { [orgUnitId1] : { marketingPlace1: {}, marketingPlace2: {} }
    marketingPlaces: {},
};

const defaultParams = {
    isError: false,
    working: false,
    initLoad: false,
};

export default function externalMarketingPlaces(state = initialState, action) {
    const { organizationUnitId = LICENSE_MARKETING_PLACE_CONSTANT } =
        action.payload || {};

    switch (action.type) {
        case ACTIONS.EXTERNAL_MARKETING_PLACE_GET_BROKER:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES:
            return {
                ...state,
                initLoad: true,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_CREATE_BROKER:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE:
            return {
                ...state,
                working: true,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_ERROR: {
            const error = action.payload;
            return {
                ...state,
                isError: true,
                working: false,
                error,
            };
        }
        case ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_CREATED:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_FOUND:
            return {
                ...state,
                brokerFirms: {
                    ...state.brokerFirms,
                    [organizationUnitId]: true,
                },
                ...defaultParams,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_NOT_FOUND:
            return {
                ...state,
                ...defaultParams,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES_SUCCESS: {
            const { marketingPlaces, organizationUnitId } = action.payload;

            return {
                ...state,
                ...defaultParams,
                marketingPlaces: {
                    ...marketingPlaces,
                    [organizationUnitId]: marketingPlaces,
                },
            };
        }
        case ACTIONS.EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE_SUCCESS: {
            const { marketingPlace, data } = action.payload;
            const alreadyStoredMarketingPlaces = state.marketingPlaces;
            const currentOrgUnitMarketingPlace =
                alreadyStoredMarketingPlaces[organizationUnitId] || {};

            return {
                ...state,
                ...defaultParams,
                marketingPlaces: {
                    ...alreadyStoredMarketingPlaces,
                    [organizationUnitId]: {
                        ...currentOrgUnitMarketingPlace,
                        [marketingPlace]: data,
                    },
                },
            };
        }
        default:
            return state;
    }
}
