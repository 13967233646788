import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { AnimatedCloseButton, ButtonToolbar } from '../button';

const StyledButtonToolbar = styled(ButtonToolbar)`
    align-items: center;
    background-color: rgba(63, 76, 93, 0.9);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
`;

const FormFooter = ({ children, onClose }) => {
    return (
        <StyledButtonToolbar>
            {onClose && (
                <AnimatedCloseButton
                    style={{ marginRight: 'auto', color: '#fff' }}
                    onClose={onClose}
                />
            )}

            {children}
        </StyledButtonToolbar>
    );
};
FormFooter.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.element,
};

export default FormFooter;
