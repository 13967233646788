import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import HelpPopoverIcon from '../../../help/help-popover-icon-component';

const Wrapper = styled.span`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const InnerLabelWithHelpText = ({ text, helpText }) => {
    return (
        <Wrapper>
            <span>{text}</span>
            <HelpPopoverIcon helpText={helpText} />
        </Wrapper>
    );
};

export default InnerLabelWithHelpText;

InnerLabelWithHelpText.propTypes = {
    text: PropTypes.string.isRequired,
    helpText: PropTypes.string.isRequired,
};
