import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '../button/icon-button/icon-button-component';
import MenuPopover from './menu-popover-component';

const PopoverIconButton = ({ icon, hiddenLabel, ...props }) => {
    return (
        <MenuPopover {...props}>
            <span tabIndex={-1}>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    icon={icon}
                    css={css`
                        border: none;
                    `}
                    hiddenLabel={hiddenLabel}
                />
            </span>
        </MenuPopover>
    );
};
PopoverIconButton.propTypes = {
    icon: PropTypes.object.isRequired,
    hiddenLabel: PropTypes.string,
};
export default PopoverIconButton;
