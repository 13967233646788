import React from 'react';
import styled from '@emotion/styled';

const StyledMobileBackdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 31, 32, 0.3);
    z-index: 9001;
    opacity: ${({ isClosing }) => (isClosing ? 0 : 1)};
    transition: opacity 0.2s ease-in-out;
`;

const MobileBackdrop = props => <StyledMobileBackdrop {...props} />;

export default MobileBackdrop;
