import PropTypes from 'prop-types';

export const FileUploadPropType = PropTypes.shape({
    identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objectUrl: PropTypes.string,
    name: PropTypes.string,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    onRemove: PropTypes.func,
});
