import React, { useState } from 'react';
import { withJsonFormsLayoutProps, withTranslateProps } from '@jsonforms/react';
import { isCategory } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import styled from '@emotion/styled';
import { SingleCategory } from './single-category-component';
import { Tab, TabNav } from '../../../../navigation';

const StyledTab = styled(Tab)`
    padding: 20px;
    @media (max-width: 40em) {
        border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    }
`;

const Container = styled.div`
    width: 100%;
`;

// Note: This version does not support nested categorizations
const CategorizationRenderer = props => {
    const { uischema, visible, getStyleAsClassName } = props;
    const categories = uischema.elements.filter(el => isCategory(el));
    const [currentTab, setCurrentTab] = useState(categories?.[0] ?? '');

    return (
        <Container
            className={getStyleAsClassName('categorization')}
            hidden={
                visible === null || visible === undefined ? false : !visible
            }
        >
            <TabNav
                currentTab={currentTab}
                onChange={i => setCurrentTab(i.tablabel)}
            >
                {categories.map(category => (
                    <StyledTab key={category.label} tablabel={category.label}>
                        <SingleCategory
                            category={category}
                            schema={props.schema}
                            path={props.path}
                        />
                    </StyledTab>
                ))}
            </TabNav>
        </Container>
    );
};

export default withVanillaControlProps(
    withTranslateProps(withJsonFormsLayoutProps(CategorizationRenderer))
);
