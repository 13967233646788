import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons';
import { TooltipPopover } from '../../../popovers';

const PinContainer = styled(Box)`
    height: 45px;
    cursor: pointer;
    transition: all 1s ease-in-out;
    display: flex;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.medium}) {
        display: none;
    }
    :focus {
        box-shadow: none;
    }
`;

const unpinnedStyle = {
    transform: 'rotate(90deg)',
    color: '#808080',
};

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primaryColor};
    filter: brightness(135%);
    transition: all 0.25s ease-in-out;
    font-weight: 300;
    font-size: 1rem;
`;

const PinnedIcon = props => {
    const { handlePinSidebar, isPinned } = props;
    return (
        <TooltipPopover placement="left" text="MENU_IS_LOCKED">
            <PinContainer py={10} px={20} onClick={handlePinSidebar}>
                <Icon
                    icon={faThumbtack}
                    style={isPinned ? null : unpinnedStyle}
                />
            </PinContainer>
        </TooltipPopover>
    );
};

PinnedIcon.propTypes = {
    handlePinSidebar: PropTypes.func,
    isPinned: PropTypes.bool,
};

PinnedIcon.defaultProps = {
    isPinned: false,
};

export default PinnedIcon;
