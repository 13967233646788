import { css } from '@emotion/react';
import { Flex } from '@rebass/grid/emotion';

import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Modal, ConfirmationModal } from '../../modal';
import { ImageProp } from '../image.props';
import ImageEditor from './image-editor-component';
import ImageEditorFileInfo from './image-editor-file-info-component';
import ImageEditorToolbar from './image-editor-toolbar-component';
import ImageEditorNavigationButtons from './navigation/image-editor-navigation-buttons';
import ImageEditorModalSimpleEditing from './image-editor-modal-simple-editing-component';
import { useTranslate, useKeydown, base64ToBlob } from '@mspecs/shared-utils';
import ModalBackButton from '../../modal/header/modal-back-button-component';
import ModalHeaderText from '../../modal/header/modal-header-text-component';
import Button, { BUTTON_TYPES } from '../../button/button-component';
import { LoadingAnimation } from '../../animations';

const CONFIRMATION_CLOSE = 1;
const CONFIRMATION_NEXT = 2;
const CONFIRMATION_PREVIOUS = 3;

const MetadataContainer = styled(Flex)`
    min-width: 250px;
    background: ${({ theme }) => theme.colors.bgSecondary};
    padding-top: ${({ theme }) => theme.spacing.default};
`;

const DonwloadButton = styled(Button)`
    margin: 8px;
`;

const EditorHeader = styled(ModalHeaderText)`
    display: none;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    margin: 0 ${({ theme }) => theme.spacing.default};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: flex;
    }
`;

const StyledImageEditor = styled(ImageEditorModalSimpleEditing)`
    height: calc(100% - 64px);
`;
const StyledBackButton = styled(ModalBackButton)`
    padding: ${({ theme }) => theme.spacing.default};
`;

const ImageEditorModal = ({
    isOpen,
    onClose,
    onSave,
    image,
    isLoading,
    children,
    onDelete,
    navigationProps,
    hasSimpleEditing = false,
    ...props
}) => {
    const editorRef = useRef(null);
    const { t } = useTranslate();

    const [hasChanges, setHasChanges] = useState(false);
    const [isConfirmingChanges, setIsConfirmingChanges] = useState(false);
    const [confirmationReason, setConfirmationReason] = useState();
    const [isAdvancedEditing, setIsAdvancedEditing] = useState(false);

    const doClose = useCallback(() => {
        if (isAdvancedEditing) {
            setIsAdvancedEditing(false);
        } else {
            onClose();
        }
    }, [isAdvancedEditing]);
    useKeydown(doClose, 'Escape');

    const onSubmit = () => {
        const editor = editorRef.current.getInstance();
        const blob = base64ToBlob(editor.toDataURL());
        onSave(blob);
    };

    const onDownload = async () => {
        const blob = await fetch(image.originalURI).then(res => res.blob());
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = image.title || 'image';
        anchor.click();
    };

    const onAbort = () => {
        if (!hasChanges) {
            doClose();
            return;
        }
        setConfirmationReason(CONFIRMATION_CLOSE);
        setIsConfirmingChanges(true);
    };

    const onConfirmed = () => {
        const callback = {
            [CONFIRMATION_CLOSE]: doClose,
            [CONFIRMATION_NEXT]: navigationProps?.onNext,
            [CONFIRMATION_PREVIOUS]: navigationProps?.onPrevious,
        }[confirmationReason];
        callback();
        setHasChanges(false);
        setIsConfirmingChanges(false);
    };

    return (
        <Modal
            isOpen={Boolean(isOpen && image)}
            onClose={onAbort}
            size={isAdvancedEditing ? 'fullscreen' : 'full-size'}
            contentClass={theme => css`
                background-color: ${theme.colors.bgSecondary};
                padding: 0;
            `}
            renderBackButton={() => null}
            {...props}
        >
            {isLoading ? (
                <Flex m="0 auto" alignItems="center">
                    <LoadingAnimation />
                </Flex>
            ) : (
                <>
                    <ConfirmationModal
                        headerText={t('IMAGE_EDITOR_CLOSE_CONFIRMATION_HEADER')}
                        isOpen={isConfirmingChanges}
                        onConfirm={onConfirmed}
                        infoText={t(
                            confirmationReason === CONFIRMATION_CLOSE
                                ? 'IMAGE_EDITOR_CLOSE_CONFIRMATION_INFO_TEXT'
                                : 'IMAGE_EDITOR_NAVIGATION_CONFIRMATION_INFO_TEXT'
                        )}
                        onCancel={() => setIsConfirmingChanges(false)}
                    />
                    {/* Meta data */}
                    {!isAdvancedEditing && (
                        <MetadataContainer
                            flexDirection="column"
                            position="relative"
                        >
                            <StyledBackButton onClick={onAbort} />
                            {children}
                            <ImageEditorFileInfo image={image} />

                            <DonwloadButton
                                label="FILE_DOWNLOAD"
                                onClick={onDownload}
                                buttonType={BUTTON_TYPES.PRIMARY}
                            />

                            {navigationProps && (
                                <ImageEditorNavigationButtons
                                    {...navigationProps}
                                    onNext={() => {
                                        if (hasChanges) {
                                            setIsConfirmingChanges(true);
                                            setConfirmationReason(
                                                CONFIRMATION_NEXT
                                            );
                                        } else {
                                            navigationProps.onNext();
                                        }
                                    }}
                                    onPrevious={() => {
                                        if (hasChanges) {
                                            setIsConfirmingChanges(true);
                                            setConfirmationReason(
                                                CONFIRMATION_PREVIOUS
                                            );
                                        } else {
                                            navigationProps.onPrevious();
                                        }
                                    }}
                                />
                            )}
                        </MetadataContainer>
                    )}
                    {hasSimpleEditing && !isAdvancedEditing ? (
                        <Flex flexDirection="column" width="100%">
                            <EditorHeader text={'IMAGE_EDIT'} />
                            <StyledImageEditor
                                image={image}
                                onDelete={onDelete}
                                setIsAdvancedEditing={setIsAdvancedEditing}
                            />
                        </Flex>
                    ) : (
                        <ImageEditor
                            key={image?.originalURI}
                            image={image}
                            ref={editorRef}
                            editorProps={{
                                onUndoStackChanged: length =>
                                    setHasChanges(length > 0),
                            }}
                            displayLoader={isAdvancedEditing}
                            isFullscreen={isAdvancedEditing}
                            height="100%"
                        >
                            <ImageEditorToolbar
                                onSubmit={onSubmit}
                                onClose={onAbort}
                                onDelete={
                                    !isAdvancedEditing ? onDelete : undefined
                                }
                                isSubmitActive={hasChanges}
                            />
                        </ImageEditor>
                    )}
                </>
            )}
        </Modal>
    );
};

ImageEditorModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    image: ImageProp,
    isLoading: PropTypes.bool,
    children: PropTypes.node,
    onDelete: PropTypes.func,
    navigationProps: PropTypes.shape({
        current: PropTypes.number,
        total: PropTypes.number,
        onNext: PropTypes.func.isRequired,
        onPrevious: PropTypes.func.isRequired,
    }),
    hasSimpleEditing: PropTypes.bool,
};

export default ImageEditorModal;
