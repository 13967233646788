import { Flex } from '@rebass/grid/emotion';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';
import { Button, BUTTON_TYPES } from '../../../button';

/*
Dependent on customer page specific style variables. 
This should be moved to customer page, but as it is used in the GroupLayout it is left here for now.
When used in other places than customer page, this need to be refactored.
*/
const InformationButton = styled(FontAwesomeIcon)`
    margin: 2px 0 auto 10px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    font-weight: 900;
    color: var(--customerpage-accent-color, #0097ba);
    font-size: 22px;
    animation: bounce 0.3s 5 alternate;
    -webkit-animation: bounce 0.3s 8 alternate;
    @keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-3px);
        }
    }
    @-webkit-keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-3px);
        }
    }
`;
const HelperText = styled('div')`
    padding: 0 20px 15px 20px;
    height: auto;
    width: 100%;
`;
const CloseLinkWrapper = styled('div')`
    margin-right: 10px;
    margin-left: auto;
`;
const HelperTextWrapper = styled('div')`
    display: flex;
    height: auto;
    opacity: ${props => (props.showHelp ? '1' : '0')};
    max-height: ${props => (props.showHelp ? '2000px' : '0px')};
    overflow: hidden;
    transition-property: opacity, max-height;
    transition-delay: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    flex-direction: column;
    background: var(
        --customer-page-info-box-background,
        ${({ theme }) => theme.colors.primaryColor5}
    );
    width: 100%;
`;

const ToggableHelpTextHeader = ({ title = null, helpText }) => {
    const [showHelp, setShowHelp] = useState(false);

    return (
        <Flex flexDirection="column">
            <Flex>
                {title}
                <InformationButton
                    onClick={() => setShowHelp(!showHelp)}
                    icon={faInfoCircle}
                />
            </Flex>
            <HelperTextWrapper showHelp={showHelp}>
                <CloseLinkWrapper>
                    <Button
                        onClick={() => setShowHelp(false)}
                        label="CLOSE"
                        buttonType={BUTTON_TYPES.TEXT}
                    />
                </CloseLinkWrapper>
                <HelperText showHelp={showHelp}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: helpText,
                        }}
                    />
                </HelperText>
            </HelperTextWrapper>
        </Flex>
    );
};

export default ToggableHelpTextHeader;
