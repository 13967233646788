import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../input';

const CheckboxCellRenderer = ({ column, row, value }) => {
    const checkboxValue = column?.getValue?.(row) ?? value;

    return (
        <Checkbox
            checked={checkboxValue}
            onChange={() => column?.onClick?.(row)}
            disabled={column?.isReadOnly?.(row) ?? false}
        />
    );
};
CheckboxCellRenderer.propTypes = {
    value: PropTypes.bool,
    column: PropTypes.shape({
        onClick: PropTypes.func,
        getValue: PropTypes.func,
        isReadOnly: PropTypes.func,
    }),
    row: PropTypes.object,
};
export default CheckboxCellRenderer;
