import { SIGNATURE_ACTION_TYPES } from '../actions/signature-actions';

const initialState = {};

export default function signatures(state = initialState, action) {
    switch (action.type) {
        case SIGNATURE_ACTION_TYPES.GET_CONTACT_SIGNATURES_SUCCESS: {
            return {
                ...state,
                ...action.payload.signatures,
            };
        }
        case SIGNATURE_ACTION_TYPES.DELETE_CONTACT_SIGNATURE_SUCCESS: {
            /* eslint-disable no-unused-vars */
            const {
                [action.payload.signature.id]: deleted,
                ...stateWithoutDeleted
            } = state;
            /* eslint-enable no-unused-vars */
            return {
                ...stateWithoutDeleted,
            };
        }
        case SIGNATURE_ACTION_TYPES.CREATE_NEW_CONTACT_SIGNATURE_SUCCESS:
        case SIGNATURE_ACTION_TYPES.UPDATE_CONTACT_SIGNATURE_SUCCESS: {
            return {
                ...state,
                [action.payload.signature.id]: action.payload.signature,
            };
        }
        default:
            return state;
    }
}
