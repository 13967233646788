import { ACTION_TYPES } from '../actions/page-actions';

const initialState = {
    title: '',
};

export default function brfs(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_PAGE_TITLE: {
            return {
                ...state,
                title: action.payload,
            };
        }
        case ACTION_TYPES.CLEAR_PAGE_TITLE: {
            return {
                ...state,
                title: '',
            };
        }
        default:
            return state;
    }
}
