import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import { ImageContainer, Headline, TopHeadline, InfoText, Flags } from '.';
import { fadeIn } from '@mspecs/shared-utils';
import { rem } from '@mspecs/shared-utils';
import ToastComponent from '~/features/toast/components/toast-component';

const Background = styled(Flex)`
    background-color: #f3f3f3;
    color: #222;
    align-items: ${props => !props.bigContainer && 'center'};
    height: 100%;
    overflow-y: hidden;
`;

const Container = styled(Flex)`
    position: relative;
    max-width: ${props => (props.bigContainer ? '1040px' : '900px')};
    min-height: 480px;
    background: ${({ theme }) => theme.colors.bgPrimary};

    @media (max-width: ${({ theme }) => theme.medium}) {
        height: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
`;

const ContentContainer = styled(Flex)`
    position: relative;
    justify-content: ${props => (props.bigContainer ? 'flex-start' : 'center')};
    animation: ${fadeIn} 0.5s;
    padding: 24px;
    width: ${props => (props.bigContainer ? '100%' : '55%')};
    overflow-y: ${props => props.bigContainer && 'auto'};
    @media (max-width: ${({ theme }) => theme.medium}) {
        padding: ${rem(8)} 15px ${rem(8)};
        width: 100%;
        justify-content: flex-start;
    }
`;

const LoginContainer = props => {
    const {
        headline,
        topHeadline,
        infoText,
        register,
        bigContainer,
        children,
    } = props;

    return (
        <Background justifyContent="center" bigContainer={bigContainer}>
            <Container width={1} bigContainer={bigContainer}>
                <ImageContainer
                    register={register}
                    bigContainer={bigContainer}
                />
                <ContentContainer
                    flexDirection="column"
                    bigContainer={bigContainer}
                >
                    <Box mx={[0, 30]}>
                        <TopHeadline>{topHeadline}</TopHeadline>
                        <Headline>{headline}</Headline>
                        <InfoText>{infoText}</InfoText>
                    </Box>
                    {children}
                </ContentContainer>
                <Flags />
            </Container>
            <ToastComponent />
        </Background>
    );
};

LoginContainer.propTypes = {
    headline: PropTypes.string,
    topHeadline: PropTypes.string,
    infoText: PropTypes.string,
    register: PropTypes.bool,
    bigContainer: PropTypes.bool,
    children: PropTypes.node,
};

export default LoginContainer;
