import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { SubTextMedium } from '../../typograph/typograph-components';
import { Flex } from '@rebass/grid/emotion';

const Tag = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.contrastBg75};
    padding: 2px 5px 2px 5px;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
`;

const InfoText = styled(SubTextMedium)`
    color: ${({ theme }) => theme.colors.white};
`;

const InfoTag = ({ text, ...props }) => {
    return (
        <Tag {...props} data-testid="info-tag">
            <InfoText>{text}</InfoText>
        </Tag>
    );
};

InfoTag.propTypes = {
    text: PropTypes.string,
};

export default InfoTag;
