export const removeWildCardFromPath = path => {
    if (!path) {
        return path;
    }

    return path
        .split('/')
        .filter(pathSplit => pathSplit !== '*')
        .join('/');
};
