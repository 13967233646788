import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { LoadingAnimation } from '@mspecs/shared-utils';
import { fetchSession } from '~/redux/actions/session-actions';
import { PRIVATE_LANDING_PAGE } from '~/routing/routing-constants';
import { isAppLoading } from '~/redux/selectors/app-selectors';

const PublicLoginRoute = ({
    fetchSession,
    isLoggedIn,
    appLoading,
    children,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // Check if we are logged in
        fetchSession(true);

        if (!searchParams.get('redirect')) {
            const redirectUrl = window.sessionStorage.getItem('currentLocation');
            if(redirectUrl) {
                searchParams.set('redirect', redirectUrl);
                setSearchParams(searchParams);
            }
        }

        window.sessionStorage.removeItem('currentLocation');

        /**
         * Remove our references that we use to login / logout all open tabs
         *
         **If we are already logged in and have an open tab/tabs:
         *  - The open tab will notice that mspx-wall-master is removed and
         *      set it self as master. If multiple tabs, they will fight for it.
         *
         **If we are already logged in and have NO open tabs:
         *  - The tab that is logging in will set it self as master
         */
        window.localStorage.removeItem('mspx-wall-master');
        window.localStorage.removeItem('mspx-wall-messages');

        // Listen to localstorage events, to check if we should login this tab
        window.addEventListener('storage', eventListenerFun);

        return () => {
            window.removeEventListener('storage', eventListenerFun);
        };
    }, []);

    const eventListenerFun = ({ key, newValue }) => {
        if (key === 'mspx-wall-master' && newValue) {
            // A new master have been set, we should be logged in
            fetchSession(true);
        }
    };

    const redirectToPrivate = () => {
        // We retrieve the redirect query param, if set
        const redirectUrl = searchParams.get('redirect');

        // Use location.href since react route can't redirect to url
        // outside the defined route
        window.location.href = redirectUrl || PRIVATE_LANDING_PAGE;
        return null;
    };

    if (appLoading) {
        return <LoadingAnimation>Laddar...</LoadingAnimation>;
    }

    if (isLoggedIn) {
        return redirectToPrivate();
    }

    return children;
};

PublicLoginRoute.propTypes = {
    fetchSession: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    appLoading: PropTypes.bool,
    children: PropTypes.node,
};

const mapStateToProps = ({ session, ...rest }) => ({
    appLoading: isAppLoading({ session, ...rest }),
    isLoggedIn: session.isLoggedIn,
});

const mapDispatchToProps = {
    fetchSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLoginRoute);
