import styled from '@emotion/styled';

const StyledBlanked = styled.div`
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    cursor: initial;
`;

const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <StyledBlanked {...props} />
);

export default Blanket;
