import { input, useIsMobile, useTranslate } from '@mspecs/shared-utils';
import { CreatableTagSelect } from '../../select';
import { components } from 'react-select';
import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { MobileFullscreenSearch } from '../mobile-fullscreen-search';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Option = ({ renderOption, inputValue, ...props }) => {
    return (
        <components.Option {...props}>
            {renderOption?.({ data: props.data, inputValue }) ?? props.children}
        </components.Option>
    );
};

const ResultContainer = styled(Flex)`
    flex-direction: column;
    overflow-y: auto;
`;

const ResultListItem = styled(Flex)`
    padding: ${({ theme }) => theme.spacing.default} 16px;
    cursor: pointer;
    :hover {
        background: ${({ theme }) => theme.colors.primaryColor5};
    }
`;

const SelectedListItemWrapper = styled(Flex)`
    padding: ${({ theme }) => theme.spacing.small} 16px;
`;
const SelectedListItem = styled(Flex)`
    padding: ${({ theme }) =>
        `${theme.spacing.small} ${theme.spacing.default}`};
    background: ${({ theme }) => theme.colors.bgSecondary};
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CloseIconContainer = styled(Flex)`
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: pointer;
    font-size: 16px;
`;

const SearchCreatableTag = ({
    renderOption,
    onInputChange,
    options,
    mobileLabel,
    onMobileOptionSelect,
    selectedOptions = [],
    ...props
}) => {
    const { t } = useTranslate();
    const [inputValue, setInputValue] = useState('');
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const isMobile = useIsMobile();

    const { colors, borderRadius } = useTheme();

    const onSearchChange = value => {
        setInputValue(value);
        onInputChange?.(value);
    };

    const onFocus = () => {
        if (!isMobile) return;
        setIsMobileSearchOpen(true);
    };

    const onClickSuggestion = option => {
        onSearchChange('');
        onMobileOptionSelect(option);
    };

    const filteredOptions = options.filter(({ label, value }) => {
        if (!inputValue) return false;
        if (selectedOptions.some(option => option.value === value))
            return false;
        const search = inputValue.toLowerCase();
        return (
            label.toLowerCase().includes(search) ||
            value.toLowerCase().includes(search)
        );
    });

    const components = useMemo(() => {
        const defaultComponents = {
            Option: props => (
                <Option
                    {...props}
                    inputValue={inputValue}
                    renderOption={renderOption}
                />
            ),
            NoOptionsMessage: () => null,
        };
        if (!isMobile) {
            return defaultComponents;
        }
        return {
            ...defaultComponents,
            MultiValueRemove: () => null,
        };
    }, [isMobile, inputValue]);

    const formatCreateLabel = val => `${t('ADD')}: ${val}`;

    return (
        <>
            <CreatableTagSelect
                menuIsOpen={undefined}
                components={components}
                onFocus={onFocus}
                formatCreateLabel={formatCreateLabel}
                onInputChange={onSearchChange}
                styles={{
                    multiValue: provided => ({
                        ...provided,
                        backgroundColor: colors.bgSecondary140,
                        borderRadius: borderRadius.default,
                    }),
                    multiValueLabel: provided => ({
                        ...provided,
                        fontSize: '14px',
                        padding: '0 5px',
                    }),
                    multiValueRemove: provided => ({
                        ...provided,
                        ':hover': {
                            background: colors.bgSecondary120,
                        },
                        svg: {
                            height: '21px',
                        },
                    }),
                }}
                options={options}
                {...props}
            />
            <MobileFullscreenSearch
                isVisible={isMobileSearchOpen}
                onClose={() => setIsMobileSearchOpen(false)}
                label={mobileLabel}
                inputProps={{
                    value: inputValue,
                    onChange: e => onSearchChange(e.target.value),
                    placeholder: 'SEARCH',
                }}
                closeLabel={
                    inputValue || selectedOptions.length === 0
                        ? 'CANCEL'
                        : 'DROPDOWN_SEARCH_DONE_BUTTON_TEXT'
                }
            >
                <Flex flexDirection="column" height="100%">
                    <ResultContainer>
                        {inputValue &&
                            filteredOptions.map(option => (
                                <ResultListItem
                                    key={option.value}
                                    onClick={() => onClickSuggestion(option)}
                                >
                                    {renderOption?.({
                                        data: option,
                                        inputValue,
                                    }) ?? option.label}
                                </ResultListItem>
                            ))}

                        {inputValue && (
                            <ResultListItem
                                onClick={() =>
                                    onClickSuggestion({
                                        label: inputValue,
                                        value: inputValue,
                                    })
                                }
                            >
                                {formatCreateLabel(inputValue)}
                            </ResultListItem>
                        )}
                        {selectedOptions.length > 0 &&
                            !inputValue &&
                            selectedOptions.map(option => (
                                <SelectedListItemWrapper key={option.value}>
                                    <SelectedListItem>
                                        {renderOption?.({
                                            data: option,
                                            inputValue,
                                        }) ?? option.label}
                                        <CloseIconContainer
                                            onClick={() =>
                                                onMobileOptionSelect(option)
                                            }
                                        >
                                            <CloseIcon icon={faTimes} />
                                        </CloseIconContainer>
                                    </SelectedListItem>
                                </SelectedListItemWrapper>
                            ))}
                    </ResultContainer>
                </Flex>
            </MobileFullscreenSearch>
        </>
    );
};

const OptionPropType = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
});
SearchCreatableTag.propTypes = {
    options: PropTypes.arrayOf(OptionPropType),
    mobileLabel: PropTypes.string,
    renderOption: PropTypes.func,
    onInputChange: PropTypes.func,
    onMobileOptionSelect: PropTypes.func,
    selectedOptions: PropTypes.arrayOf(OptionPropType),
};

export default SearchCreatableTag;
