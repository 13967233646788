import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '@mspecs/shared-utils';
import { SearchInput } from '../../input';

const StyledSearchInput = styled(SearchInput)`
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        max-width: 320px;
    }
`;

const TopToolbarSearchInput = ({
    onDebouncedValueChange,
    defaultValue = '',
    placeholder = 'SEARCH',
}) => {
    const [value, setValue] = useState(defaultValue);
    const debouncedValue = useDebounce(value, 500);

    const handleChange = e => {
        setValue(e.target.value);
    };

    useEffect(() => {
        if (defaultValue === '') {
            setValue('');
        }
    }, [defaultValue]);

    useEffect(() => {
        onDebouncedValueChange(debouncedValue);
    }, [debouncedValue]);

    return (
        <StyledSearchInput
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
        />
    );
};

TopToolbarSearchInput.propTypes = {
    onDebouncedValueChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
};

export default TopToolbarSearchInput;
