import styled from '@emotion/styled';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { BodyTextRegular } from '../../../typograph/typograph-components';
import { MobileFullscreenSearch } from '../search';

const ResultContainer = styled(Flex)`
    flex-direction: column;
    overflow-y: auto;
`;
const Item = styled(Flex)`
    padding: ${({ theme }) => theme.spacing.default} 16px;
    align-items: center;
`;
const ItemText = styled(BodyTextRegular)`
    padding-left: ${({ theme }) => theme.spacing.default};
`;

export default function InputMobileFullscreen({
    options,
    isOpen,
    onClose,
    onSelect,
    label,
    value,
    iconComponent: Icon,
}) {
    const [inputValue, setInputValue] = useState('');
    const filteredOptions = useMemo(
        () =>
            options.filter(({ label }) =>
                label.toLowerCase().startsWith(inputValue.toLowerCase())
            ),
        [options, inputValue]
    );

    return (
        <MobileFullscreenSearch
            isVisible={isOpen}
            onClose={onClose}
            label={label}
            inputProps={{
                value: inputValue,
                onChange: e => setInputValue(e.target.value),
                placeholder: 'SEARCH',
            }}
        >
            <ResultContainer>
                {filteredOptions.map(data => (
                    <Item
                        key={`${data.value}_${data.label}`}
                        onClick={() => onSelect(data)}
                    >
                        <Icon
                            aria-hidden
                            country={data.value || ''}
                            code={data.value}
                            label={data.label}
                        />
                        <ItemText>{data.label}</ItemText>
                        {data.value === value && (
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{ marginLeft: 'auto' }}
                            />
                        )}
                    </Item>
                ))}
            </ResultContainer>
        </MobileFullscreenSearch>
    );
}

InputMobileFullscreen.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.string,
};
