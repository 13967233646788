import React, {
    forwardRef,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import Select, { StylesConfig } from 'react-select';
import { useIsMobile, useTranslate } from '@mspecs/shared-utils';
import { Country } from 'react-phone-number-input';
import { selectStyles } from './styles-config';
import DropdownContainer from './dropdown-container';
import { DropdownTarget, CaretDown } from './dropdown-target';
import StyledMenu from './styled-menu';
import Blanket from './blanket';
import Option from './option';
import DropdownIndicator from './dropdown-indicator';
import InputMobileFullscreen from './input-select-with-flag-mobile-fullscreen-component';

interface OptionType {
    value: Country;
    label: string;
    divider: boolean;
}

type Position = 'left' | 'right';

export interface InputSelectWithFlagProps {
    value: string;
    options: OptionType[];
    className?: string;
    onChange: (value: string | undefined) => void;
    disabled?: boolean;
    readOnly?: boolean;
    position?: Position;
    invalid?: boolean;
    placeholder?: string;
    selectStyles?: StylesConfig<any>;
    iconComponent?: any;
    showLabel?: boolean;
    priorityValues?: string[];
    label?: string;
}

const SelectWithRef = forwardRef<HTMLDivElement, any>((props, ref) => (
    <Select {...props} ref={ref} />
));

export default function InputSelectWithFlag({
    value,
    options = [],
    className,
    onChange,
    disabled,
    readOnly,
    position = 'left',
    invalid,
    placeholder,
    selectStyles: customSelectStyles,
    iconComponent: Icon,
    showLabel,
    priorityValues,
    ...rest
}: Readonly<InputSelectWithFlagProps>) {
    const { t } = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{
        top: string;
        left: string;
    }>({ top: '0', left: '0' });

    const selectRef = useRef<HTMLDivElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useIsMobile();

    const getMenuPosition = (position?: Position, invalid?: boolean) => {
        switch (position) {
            case 'left':
                return invalid ? 30 : 10;
            case 'right':
                return invalid ? 175 : 205;
            default:
                return 0;
        }
    };

    useLayoutEffect(() => {
        if (selectRef.current && menuRef.current) {
            const selectRect = selectRef.current.getBoundingClientRect();
            const menuRect = menuRef.current.getBoundingClientRect();
            const menuPosition = getMenuPosition(position, invalid) ?? 10;

            let newTop = `${selectRect.bottom}px`;
            let newLeft = `${selectRect.left - menuPosition}px`;

            // Adjust position if menu goes out of viewport
            if (selectRect.bottom + menuRect.height > window.innerHeight) {
                newTop = `${window.innerHeight - menuRect.height - 10}px`;
            }

            if (selectRect.left + menuRect.width > window.innerWidth) {
                newLeft = `${window.innerWidth - menuRect.width - 10}px`;
            }

            setMenuPosition({
                top: newTop,
                left: newLeft,
            });
        }
    }, [isOpen, invalid]);

    const toggleOpen = useCallback(() => {
        if (!disabled) setIsOpen(prev => !prev);
    }, [disabled]);

    const selectedOption = useMemo(() => {
        return options.find(
            option => !option.divider && option.value === value
        );
    }, [value, options]);

    const handleChange = useCallback(
        (newValue: unknown) => {
            const value = (newValue as { value: string }).value;
            onChange(value === 'ZZ' ? undefined : value);
            setIsOpen(false);
        },
        [onChange]
    );

    const mergedSelectStyles = useMemo(() => {
        return {
            ...selectStyles,
            ...customSelectStyles,
        };
    }, [customSelectStyles]);

    const sortedOptions = useMemo(() => {
        if (!priorityValues) {
            return options;
        }
        return [...options].sort((a, b) => {
            const aPriority = priorityValues.indexOf(a.value);
            const bPriority = priorityValues.indexOf(b.value);

            if (aPriority !== -1 && bPriority !== -1) {
                return aPriority - bPriority;
            } else if (aPriority !== -1) {
                return -1;
            } else if (bPriority !== -1) {
                return 1;
            } else {
                return a.label.localeCompare(b.label);
            }
        });
    }, [options, priorityValues]);

    return (
        <DropdownContainer
            position={position}
            invalid={invalid}
            ref={selectRef}
        >
            <DropdownTarget onClick={toggleOpen} position={position}>
                {!showLabel && (
                    <Icon
                        country={selectedOption?.value as Country}
                        size={20}
                        height={'auto'}
                        label={selectedOption?.label}
                    />
                )}
                {showLabel && selectedOption?.label}
                <CaretDown />
            </DropdownTarget>
            {isOpen &&
                ReactDOM.createPortal(
                    <StyledMenu
                        ref={menuRef}
                        invalid={invalid}
                        position={position}
                        length={options.length}
                        top={menuPosition.top}
                        left={menuPosition.left}
                    >
                        <InputMobileFullscreen
                            isOpen={isOpen && isMobile}
                            onClose={() => setIsOpen(false)}
                            options={sortedOptions}
                            onSelect={handleChange}
                            value={value}
                            label={rest.label}
                            iconComponent={Icon}
                        />
                        {!isMobile && (
                            <SelectWithRef
                                {...rest}
                                autoFocus
                                menuIsOpen={isOpen}
                                options={sortedOptions}
                                placeholder={t(placeholder)}
                                components={{
                                    DropdownIndicator,
                                    Option: (props: any) => (
                                        <Option
                                            {...props}
                                            iconComponent={Icon}
                                        />
                                    ),
                                }}
                                onChange={handleChange}
                                isDisabled={disabled || readOnly}
                                menuPortalTarget={document.body}
                                styles={mergedSelectStyles}
                                isOptionSelected={(option: { value: string }) =>
                                    option.value === value
                                }
                                menuShouldScrollIntoView={false}
                                maxMenuHeight={170}
                                menuPosition="fixed"
                            />
                        )}
                    </StyledMenu>,
                    document.body
                )}
            {isOpen && !isMobile && (
                <Blanket onClick={() => setIsOpen(false)} />
            )}
        </DropdownContainer>
    );
}
