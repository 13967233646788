import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { removeWildCardFromPath } from '../utils/path';
import TabNavProvider from './tab-nav-provider-component';
import { TabContent } from './tab-nav-styles';

const TabNavRouter = memo(props => {
    const { children, ...restProps } = props;
    const [currentTab, setCurrentTab] = useState(children[0]?.props || null);
    const location = useLocation();
    const navigate = useNavigate();
    const isActiveFn = useCallback(
        props => props.tablabel === currentTab?.tablabel,
        [currentTab]
    );

    useEffect(() => {
        setCurrentTab(
            children.find(child =>
                location.pathname.includes(
                    removeWildCardFromPath(child.props?.path)
                )
            )?.props
        );
    }, [children, location.pathname]);

    const onChange = props => {
        navigate(removeWildCardFromPath(props.path));

        if (props.onChange) {
            props.onChange(props);
        }
    };

    return (
        <TabNavProvider
            {...restProps}
            items={children}
            currentTab={currentTab}
            onChange={onChange}
            isActiveFn={isActiveFn}
        >
            <TabContent
                tabStyle={props.tabStyle}
                className={props.tabContentClassName}
            >
                <Routes>{children}</Routes>
            </TabContent>
        </TabNavProvider>
    );
});

TabNavRouter.propTypes = {
    tabStyle: PropTypes.oneOf(['light', 'dark', 'noBorders']),
    hideNonActive: PropTypes.bool,
    isSidebar: PropTypes.bool,
    onChange: PropTypes.func,
    tabContentClassName: PropTypes.string,
    children: PropTypes.node,
    t: PropTypes.func,
};

TabNavRouter.defaultProps = {
    tabStyle: 'light',
    hideNonActive: false,
    isSidebar: false,
    onChange: x => x,
    t: x => x,
};

export default TabNavRouter;
