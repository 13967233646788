import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex } from '@rebass/grid/emotion';
import { Button } from '../../button';

export const TabsContainer = styled(Flex)`
    flex-direction: row;
    margin-left: ${({ isSidebar }) => isSidebar && '12px'};
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        background: ${({ theme }) => theme.colors.bgPrimary};
    }
`;

export const TabsOverflowContainer = styled(Flex)`
    overflow: hidden;
    @media (max-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        margin-left: 10px;
    }
`;

const lightTabStyle = ({ theme, isActive }) => css`
    ${isActive &&
    `
        background: ${theme.colors.bgPrimary};
        color: ${theme.colors.primaryColor};
    `}
    border: 1px solid ${theme.colors.borderPrimary};
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
`;

const darkTabStyle = ({ theme, isActive }) => css`
    ${isActive &&
    `
        background: ${theme.colors.primaryColor};
        color: ${theme.colors.white};
    `}
    border: 1px solid ${theme.colors.borderPrimary};
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
`;

const noBordersTabStyle = ({ theme, isActive }) => css`
    border-bottom: solid 2px transparent;
    ${isActive &&
    `
        color: ${theme.colors.primaryColor};
        border-color: ${theme.colors.primaryColor};
        font-weight: 600;
    `}
    background: transparent;
    padding: 12px 6px 8px;
    margin: 0 6px;
    opacity: 0.9;

    :hover,
    :focus,
    :focus:active {
        filter: initial;
        color: ${theme.colors.primaryColor};
        background: transparent;
    }

    @media (max-width: ${theme.responsiveBreakpoints.s}) {
        padding: 8px 15px;
    }
`;

export const StyledTabButton = styled(Button)`
    margin: 0;
    color: ${({ theme }) => theme.colors.black};
    border: none;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.bgSecondary};
    padding: 13px 18px;

    ${({ tabStyle }) =>
        tabStyle === 'dark'
            ? darkTabStyle
            : tabStyle === 'noBorders'
            ? noBordersTabStyle
            : lightTabStyle}

    :focus,
    :focus:active {
        filter: initial;
        box-shadow: none;
    }

    ${({ isvisible }) =>
        isvisible === 'false'
            ? `
        visibility: hidden;
        position: absolute;
    `
            : ''}
`;

export const TabContent = styled(Flex)`
    width: 100%;
    display: ${props => (props.hideNonActive ? 'none' : 'flex')};
    flex-direction: column;
    flex-grow: 1;
    min-height: 50px;
    padding: ${({ tabStyle }) => tabStyle !== 'noBorders' && '10px 15px'};
    background: ${({ tabStyle, isSidebar, theme }) =>
        !isSidebar && tabStyle === 'light'
            ? theme.colors.white
            : theme.colors.bgSecondary};
    overflow-y: auto;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};

    @media (max-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        border-top: 0;
    }
`;
