import React from 'react';
import { FormInputField } from '../form';

const getValidationPattern = minCharacters =>
    new RegExp('^.{' + minCharacters + ',30}$');

const PasswordInput = ({
    minCharacters,
    errorMessage,
    autoFocus,
    ...restProps
}) => {
    return (
        <FormInputField
            {...restProps}
            validationPttrn={
                minCharacters &&
                getValidationPattern(
                    typeof minCharacters === 'number' ? minCharacters : 8
                )
            }
            type="password"
            errorMessage={errorMessage || 'CHANGE_PASSWORD_ERROR_TOO_SHORT'}
            autoFocus={autoFocus}
        />
    );
};

export default PasswordInput;
