import {
    FETCH_SCHEMA_REQUEST,
    FETCH_SCHEMA_SUCCESS,
    FETCH_SCHEMA_ERROR,
} from '../actions/schema-actions';

const initialState = {};

export default function schemas(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCHEMA_SUCCESS: {
            return {
                ...state,
                [action.payload.name]: action.payload,
            };
        }
        case FETCH_SCHEMA_REQUEST: {
            return {
                ...state,
                [action.payload]: state[action.payload],
            };
        }
        case FETCH_SCHEMA_ERROR: {
            const { [action.payload]: NA, ...rest } = state;
            return rest;
        }
        default:
            return state;
    }
}
