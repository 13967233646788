import React from 'react';
import styled from '@emotion/styled';

const InfoText = styled.p`
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #222;
`;

const InfoTextContainer = ({ children }) => {
    return <InfoText>{children}</InfoText>;
};

export default InfoTextContainer;
