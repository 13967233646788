import React from 'react';
import { InputField } from '.';
import { FormInputField } from '../form';

const ssnPattern = /^(\d{6}|\d{8})[-+]?\d{4}$/;

const SSNInput = props => {
    const Input = props.isFormField ? FormInputField : InputField;
    return (
        <Input
            name="ssn"
            validationPttrn={ssnPattern}
            errorMessage="VALIDATION_ERROR_SSN"
            {...props}
        />
    );
};

export default SSNInput;
