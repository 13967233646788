import { createContext } from 'react';
import { Flex } from '@rebass/grid/emotion';
import TabNavMenu from './tab-nav-menu-component';
export const TabContext = createContext({});

const TabNavProvider = props => {
    const {
        tabStyle,
        children,
        className,
        hideNonActive,
        tabsClassName,
        tabContentClassName,
        items,
        currentTab,
        onChange,
        isActiveFn,
        hiddenTabsEnabled,
    } = props;
    return (
        <Flex
            flexDirection="column"
            className={className}
            style={{ overflow: 'hidden', height: '100%', width: '100%' }}
        >
            <TabContext.Provider
                value={{
                    currentTab,
                    hideNonActive,
                    tabContentClassName,
                    tabStyle,
                    isActiveFn,
                    onChange,
                }}
            >
                <TabNavMenu
                    items={items || children}
                    className={tabsClassName}
                    currentTab={currentTab}
                    hiddenTabsEnabled={hiddenTabsEnabled}
                />
                {children}
            </TabContext.Provider>
        </Flex>
    );
};

export default TabNavProvider;
