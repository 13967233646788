import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';

const HeaderIconWrapper = styled(Flex)`
    font-size: 16px;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.primaryColor};
    background: ${({ theme }) => theme.colors.primaryColor10};
    height: 44px;
    width: 44px;
    min-width: 44px;
    justify-content: center;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing.default};
`;
export default function ModalHeaderIcon({ icon, ...props }) {
    return (
        <HeaderIconWrapper {...props}>
            <FontAwesomeIcon icon={icon} size="lg" />
        </HeaderIconWrapper>
    );
}

ModalHeaderIcon.propTypes = {
    icon: PropTypes.object.isRequired,
};
