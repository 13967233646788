import { StylesConfig } from 'react-select';
import { theme } from '@mspecs/shared-utils';

export const selectStyles: StylesConfig<any> = {
    control: baseStyles => ({
        ...baseStyles,
        border: '0',
        minHeight: '34px',
        maxHeight: '34px',
        boxShadow: 'none',
        minWidth: 240,
        margin: 8,
        background: theme.colors.bgSecondary,
        flexDirection: 'row-reverse',
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
    }),
    menu: baseStyles => ({
        ...baseStyles,
        width: '100%',
        zIndex: 1000,
        boxShadow: 'none',
    }),
    option: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        color: theme.colors.textPrimary,
        background: theme.colors.white,
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        margin: '5px 0 0 7px',
        minHeight: '24px',
        maxHeight: '24px',
    }),
    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none',
    }),
    input: baseStyles => ({
        ...baseStyles,
        display: 'flex',
    }),
    menuPortal: baseStyles => ({
        ...baseStyles,
        zIndex: 9999,
    }),
};

export const ROW_HEIGHT = 37;
export const SEARCH_HEIGHT = 60;
