import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { TEXT_STYLES } from '../../typograph/typograph-components';
import { Button } from '../../button';

export const Toolbar = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        &:not(:first-of-type) {
            margin-left: ${({ theme }) => theme.spacing.default};
        }
    }
`;

const FlexedButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    ${({ theme }) => TEXT_STYLES.buttonTextSmall(theme)}
`;
export const ToolbarButton = props => <FlexedButton {...props} large />;

const FormButtonToolbar = ({
    onCancel,
    submitButton,
    cancelButtonProps,
    ...props
}) => {
    const buttonProps = {
        onClick: onCancel,
        ...cancelButtonProps,
    };

    return (
        <Toolbar {...props}>
            {Boolean(onCancel) && (
                <ToolbarButton label="CANCEL" {...buttonProps} />
            )}
            {submitButton}
        </Toolbar>
    );
};

FormButtonToolbar.propTypes = {
    submitButton: PropTypes.node,
    onCancel: PropTypes.func,
    cancelButtonProps: PropTypes.object,
};

export default FormButtonToolbar;
