import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

const Label = styled.label`
    padding: 7px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 4px;
    color: #000;
    user-select: none;
    border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    background-color: ${({ theme }) => theme.colors.bgSecondary};

    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
    &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:hover {
        filter: brightness(95%);
        shape-outside: none;
        text-decoration: none;
    }

    input[type='radio']:checked + &,
    input[type='checkbox']:checked + & {
        color: white;
        background-color: ${({ theme }) => theme.colors.primaryColor};
        box-shadow: none;
    }
`;

const Radio = props => {
    return (
        <input
            css={css`
                position: absolute;
                clip: rect(0, 0, 0, 0);
            `}
            {...props}
        />
    );
};

const InputWrapper = styled.div`
    display: inline-flex;
`;

/**
 * Input component to toggle between values values
 */
const Toggle = ({ name, togglableValues, onChange, value, multi }) => {
    const identifiers = useMemo(() => {
        return togglableValues.map(() => uuidv4());
    }, [name]);

    return (
        <InputWrapper>
            {togglableValues.map((option, i) => (
                <React.Fragment key={identifiers[i]}>
                    <Radio
                        onChange={onChange}
                        value={option.value}
                        type={multi ? 'checkbox' : 'radio'}
                        checked={value === option.value}
                        name={name}
                        id={identifiers[i]}
                    />
                    <Label htmlFor={identifiers[i]}>{option.label}</Label>
                </React.Fragment>
            ))}
        </InputWrapper>
    );
};

Toggle.propTypes = {
    name: PropTypes.string.isRequired,
    togglableValues: PropTypes.arrayOf(
        PropTypes.exact({
            value: PropTypes.any,
            label: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default Toggle;
