/* eslint-disable */

export const contentIsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (var i = arr1.length; i--; ) {
        return arr2.some(x => x === arr1[i]);
    }
};

/*
    setTimeout but returning a promise instead, as seen in bluebird
*/
export const delay = ms => new Promise(res => setTimeout(res, ms));

let inputIdIncrement = 0;
// global increment for html ids
export function getUniqHtmlId() {
    return `${inputIdIncrement++}`;
}

export function removePsudoId(obj) {
    const { id, ...rest } = obj;
    return rest;
}

/**
 * Prefixes enumString with ENUMS_
 * @param {string} enumString The enum to be prefixed
 * @returns {string} The prefixed enum
 */
export function prefixEnum(enumString, prefix = 'ENUMS_') {
    if (typeof enumString !== 'string' || enumString.startsWith(prefix)) {
        return enumString;
    }

    return `${prefix}${enumString}`;
}

/**
 * Remove the ENUMS_ prefix from the enumString
 * @param {string} enumString The string to remove the prefix from
 * @returns {string} The enum string with ENUMS_ removed
 */
export function removeEnumPrefix(enumString) {
    if (typeof enumString !== 'string' || !enumString.startsWith('ENUMS_')) {
        return enumString;
    }

    return enumString.replace(/^ENUMS_/, '');
}
