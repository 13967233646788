import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '.';
import { useTranslate, formatCoordinateToDegrees } from '@mspecs/shared-utils';
import { FormInputField } from '../form';

const CoordinateInput = ({ coordinateType, displayValue, ...props }) => {
    const { t } = useTranslate();

    const Input = props.isFormField ? FormInputField : InputField;

    return (
        <>
            <Input
                style={{ marginBottom: 0, marginRight: '5px' }}
                {...props}
                placeholder={t(coordinateType)}
            />
            <Input
                disabled
                value={formatCoordinateToDegrees(displayValue, coordinateType)}
                style={{ marginLeft: '5px' }}
            />
        </>
    );
};
CoordinateInput.propTypes = {
    displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coordinateType: PropTypes.oneOf(['LATITUDES', 'LONGITUDES']).isRequired,
};

export default CoordinateInput;
