import React from 'react';
import { Field } from 'react-final-form';
import { compose, branch, mapProps, wrapDisplayName } from 'recompose';
import { omit } from 'lodash-es';

const withFinalFormField = WrappedComponent => {
    class WithFinalFormField extends React.PureComponent {
        state = {
            invalid: false,
        };

        checkInvalid = (meta, input) => {
            if (meta.error) {
                if (this.props.validateUntouched && !meta.touched) {
                    return true;
                }
                if (
                    meta.touched ||
                    !!input.value ||
                    (input.type === 'checkbox' && !input.checked && meta.dirty)
                ) {
                    return true;
                }
            }
            return false;
        };

        render() {
            const {
                validate,
                isNullWhenEmpty,
                validateUntouched,
                parse,
                instantValidation,
                ...restProps
            } = this.props;
            return (
                <Field
                    parse={value => {
                        if (parse) return parse(value);
                        return isNullWhenEmpty && value === '' ? null : value;
                    }}
                    validate={validate}
                    {...restProps}
                    render={({ input, meta, ...rest }) => {
                        return (
                            <WrappedComponent
                                {...input}
                                {...rest}
                                onChange={e => {
                                    input.onChange(e);
                                    this.setState({
                                        invalid: false,
                                    });
                                    this.props.onChange &&
                                        this.props.onChange(e);
                                }}
                                onBlur={e => {
                                    input.onBlur(e);
                                    this.setState({
                                        invalid: this.checkInvalid(meta, input),
                                    });
                                    this.props.onBlur && this.props.onBlur(e);
                                }}
                                invalid={
                                    instantValidation
                                        ? meta.invalid
                                        : this.state.invalid
                                }
                                errorMessage={meta.error}
                            />
                        );
                    }}
                />
            );
        }
    }
    WithFinalFormField.displayName = wrapDisplayName(
        WrappedComponent,
        'withFinalFormField'
    );

    return WithFinalFormField;
};

export default branch(
    /**
     * Use withFinalForm HOC only if isFormField is passed.
     * Otherwise, pass through
     */
    ({ isFormField }) => isFormField,
    compose(
        mapProps(props => omit(props, ['isFormField'])),
        withFinalFormField
    )
);
