import { branch, compose } from 'recompose';
import {
    withFinalFormField,
    withInputValidation,
    withValidationWrapper,
} from '../../utils/hoc';
import Switch from './switch-component';

const FormFieldSwitch = compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(Switch);

FormFieldSwitch.propTypes = Switch.propTypes;
FormFieldSwitch.defaultProps = Switch.defaultProp;

export default FormFieldSwitch;
