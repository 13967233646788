import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUTTON_TYPES, Button } from '../../button';

const HamburgerButton = styled(Button)`
    display: none;

    @media (max-width: ${({ theme }) => theme.large}) {
        color: #fff;
        background-color: ${({ theme }) =>
            theme.colors.contrastBgPrimary} !important;
        display: inline-flex;
        border-radius: 0;
        min-width: 46px;
        max-height: 46px;
        width: 46px;
        position: absolute;
        z-index: 1;

        &:hover {
            color: ${({ theme }) => theme.colors.bgSecondary};
        }
        &:hover,
        &:focus,
        &:focus:active {
            border-color: transparent;
        }
    }
`;

const Container = styled('div')`
    color: white;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.contrastBgPrimary};
    max-width: 200px;
    min-width: 200px;
    div:first-of-type {
        margin-top: 15px;
    }
    div {
        padding: 13px;
        position: relative;
    }

    @media (max-width: ${({ theme }) => theme.large}) {
        display: ${({ isSmallScreenMenuOpen }) =>
            isSmallScreenMenuOpen ? 'flex' : 'none'};
        position: absolute;
        padding-bottom: 15px;
        z-index: 1;
        top: 40px;
    }
`;

const SideNav = ({ active, onClick, children, ...props }) => {
    const [isSmallScreenMenuOpen, setIsSmallScreenMenuOpen] = useState(false);
    return (
        <>
            <HamburgerButton
                buttonType={BUTTON_TYPES.TEXT}
                large
                onClick={() => setIsSmallScreenMenuOpen(!isSmallScreenMenuOpen)}
            >
                <FontAwesomeIcon icon={faBars} size="lg" />
            </HamburgerButton>
            <Container isSmallScreenMenuOpen={isSmallScreenMenuOpen} {...props}>
                {children({ active, onClick, setIsSmallScreenMenuOpen })}
            </Container>
        </>
    );
};

SideNav.propTypes = {
    onChange: PropTypes.func,
    active: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any,
};

SideNav.defaultProps = {
    onChange: x => x,
};

export default SideNav;
