import { legacyApi } from '@mspecs/shared-utils';

export function sendResetPassword(email) {
    return legacyApi('/sendResetPasswordMail').post({ email });
}

export function resetPassword(token, data) {
    return legacyApi(`/setPassword/${token}`).post({ password: data.password });
}

export function loginMethod(username, redirect = '') {
    return legacyApi(
        `/pub/api/v1/auth/method/${username}?redirect=${encodeURIComponent(
            redirect
        )}`
    ).get();
}
