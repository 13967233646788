import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';

const SelectionBox = ({ box }) => {
    const theme = useTheme();
    return (
        <div
            style={{
                position: 'absolute',
                left: `${box.left}px`,
                top: `${box.top}px`,
                width: `${box.width}px`,
                height: `${box.height}px`,
                backgroundColor: theme.colors.primaryColor5,
                opacity: 0.5,
                zIndex: 999,
                border: `2px dashed ${theme.colors.primaryColor}`,
            }}
        />
    );
};

SelectionBox.propTypes = {
    box: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};

export default SelectionBox;
