import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate, prefixEnum } from '@mspecs/shared-utils';
import Select from './select/select-component';

const Enum = ({ enums, withEnumPrefix, enumPrefix, ...restProps }) => {
    const { t } = useTranslate();

    const options = enums.map(option => {
        if (option === null) return { label: t('CHOOSE'), value: null };
        return withEnumPrefix ? prefixEnum(option, enumPrefix) : option;
    });

    return <Select {...restProps} options={options} />;
};

export default Enum;

Enum.propTypes = {
    enums: PropTypes.array,
    withEnumPrefix: PropTypes.bool,
    enumPrefix: PropTypes.string,
};
