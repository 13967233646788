import styled from '@emotion/styled';
import BaseFormLayout from './base-layout-component';
import { createLayoutHoc } from './layout-utils';

const VerticalLayout = styled(BaseFormLayout)`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 5px 15px;
`;

const VerticalItemLayout = styled.div``;

export default createLayoutHoc(
    VerticalLayout,
    VerticalItemLayout,
    'VerticalLayout',
    'vertical-layout'
);
