import React from 'react';
import TextArea from './textarea-component';
import { InputField } from '.';
import { FormInputField } from '../form';

const TextInput = props => {
    const { isFormField, maxLength } = props;
    if (maxLength > 90) {
        return <TextArea {...props} />;
    }
    if (isFormField) {
        return <FormInputField {...props} type="text" />;
    }
    return <InputField {...props} type="text" />;
};

export default TextInput;
