import React from 'react';
import { rankWith, uiTypeIs } from '@jsonforms/core';
import {
    withJsonFormsLayoutProps,
    JsonFormsDispatch,
    useJsonForms,
} from '@jsonforms/react';

function renderChildren(
    ChildComp,
    layout,
    schema,
    className,
    path,
    enabled,
    visible,
    elementsWithNoDiff,
    noSticky = false
) {
    if (!layout?.elements) {
        return [];
    }

    const { renderers, cells } = useJsonForms();

    return layout.elements.map((child, index) => {
        return (
            <ChildComp
                className={className}
                key={`${path}-${index}`}
                layout={layout}
                elementsWithNoDiff={elementsWithNoDiff}
                hidden={
                    visible === undefined || visible === null ? false : !visible
                }
                noSticky={noSticky}
            >
                <JsonFormsDispatch
                    renderers={renderers}
                    cells={cells}
                    uischema={child}
                    schema={schema}
                    path={path}
                    enabled={enabled}
                />
            </ChildComp>
        );
    });
}

export function createLayoutHoc(
    Comp,
    ChildComp,
    uiType,
    classNameEx,
    rank = 1
) {
    const tester = rankWith(rank, uiTypeIs(uiType));
    const LayoutRenderer = props => {
        const { data, ...otherProps } = props;
        return <LayoutRendererComponent {...otherProps} />;
    };

    const LayoutRendererComponent = React.memo(function ({
        schema,
        uischema,
        enabled,
        visible,
        path,
        elementsWithNoDiff,
    }) {
        return (
            <Comp
                visible={visible}
                className={classNameEx}
                label={uischema.label}
                uischema={uischema}
            >
                {renderChildren(
                    ChildComp,
                    uischema,
                    schema,
                    classNameEx + '-item',
                    path,
                    enabled,
                    visible,
                    elementsWithNoDiff
                )}
            </Comp>
        );
    });

    return { renderer: withJsonFormsLayoutProps(LayoutRenderer), tester };
}
