import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import {
    faSquareArrowDown,
    faSquareArrowUp,
    faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FileUploadPropType } from './multi-file-upload-prop-types';
import MultiFileUploadListItem from './multi-file-upload-list-item-component';
import { useTranslate } from '@mspecs/shared-utils';
import IconButton from '../../../button/icon-button/icon-button-component';
import { LoadingBar } from '../../../animations';
const Container = styled(Flex)`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    min-height: 40px;
    max-height: 220px;
    max-width: 696px;
    overflow-y: auto;
    z-index: 999999;
    margin-right: auto;
    margin-left: auto;
    background: ${({ theme }) => theme.colors.bgPrimary};
    box-shadow: 0 1px 10px 0 ${({ theme }) => theme.colors.boxShadowColor};
    border-radius: 8px;
    flex-direction: column;

    @media (min-width: 768px) {
        width: 750px;
    }

    @media (min-width: 1200px) {
        width: 970px;
        padding: 15px;
    }
`;

const FileList = styled(Flex)`
    flex-direction: column;
    padding: 0 15px 25px;
    width: 100%;
    max-width: 696px;
    margin: 0 auto;
`;

const AbsoluteButton = styled(IconButton)`
    position: absolute;
    font-size: 20px;
    background-color: transparent;
    padding: 5px 10px;
    border: none;
    color: #767676;
    :focus {
        box-shadow: none;
    }
    top: 7px;
`;

const CloseIcon = styled(AbsoluteButton)`
    right: 0;
`;

const ExpandButton = styled(AbsoluteButton)`
    right: 35px;
    color: ${({ expanded, theme }) =>
        expanded ? theme.colors.primaryColor : 'inherit'};
`;

const Header = styled.div`
    transition: all 0.3s ease-in-out;
    position: relative;
    padding: ${({ expanded }) => (expanded ? '25px 15px 15px' : '15px')};
`;

const HeaderText = styled.h4`
    font-size: 1rem;
    text-align: center;
`;
const FileProgess = styled(LoadingBar)`
    padding: 15px 15px 10px;
    max-width: 696px;
    margin: 0 auto;
`;

const MultiFileUploadIndicator = ({ files, onAbortRequest }) => {
    const { t } = useTranslate();
    const [expanded, setExpanded] = useState(true);
    const onRemoveFile = file => {
        file.onRemove();
        onAbortRequest(file);
    };

    const currentFileIndex = files.findIndex(f => f.isLoading);
    const currentFile =
        currentFileIndex !== -1 ? currentFileIndex + 1 : files.length;
    const allFilesUploaded = files.every(f => !f.isLoading);
    if (files.length === 0) {
        return null;
    }
    return (
        <Container>
            <Header expanded={expanded}>
                <ExpandButton
                    expanded={expanded}
                    icon={expanded ? faSquareArrowDown : faSquareArrowUp}
                    onClick={() => setExpanded(prev => !prev)}
                    hiddenLabel={expanded ? 'HIDE' : 'SHOW'}
                />
                <CloseIcon
                    icon={faTimes}
                    onClick={() => files.map(onRemoveFile)}
                    hiddenLabel="CLOSE"
                />
                {!expanded && (
                    <>
                        <HeaderText>
                            {t('IMAGE_UPLOAD_HEADER_COUNT_TEXT', {
                                current: currentFile,
                                total: files.length,
                            })}
                        </HeaderText>
                        <FileProgess
                            completed={allFilesUploaded}
                            error={
                                allFilesUploaded && files.some(f => f.isError)
                            }
                        />
                    </>
                )}
            </Header>
            {expanded && (
                <FileList>
                    {files.map((file, index) => (
                        <MultiFileUploadListItem
                            file={file}
                            key={file.identifier}
                            isLast={index === files.length - 1}
                            onRemove={() => onRemoveFile(file)}
                        />
                    ))}
                </FileList>
            )}
        </Container>
    );
};

MultiFileUploadIndicator.propTypes = {
    files: PropTypes.arrayOf(FileUploadPropType),
    onAbortRequest: PropTypes.func,
};

export default MultiFileUploadIndicator;
