import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rem } from '@mspecs/shared-utils';

const Value = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${rem(14)};
    margin-bottom: 16px;
    margin-right: 15px;
    position: relative;
    width: 100%;
    button {
        position: absolute;
        right: 0;
    }
`;

const Label = styled.div`
    margin-bottom: 2px;
    font-size: ${rem(12)};
    text-transform: uppercase;
`;
export default function LabelizedValueWithButton({
    label,
    value,
    fallbackValue = '',
    actionButton,
}) {
    return (
        <>
            <Label>{label}</Label>
            <Value>
                <span>{value ?? fallbackValue}</span>
                {value ? actionButton : null}
            </Value>
        </>
    );
}

LabelizedValueWithButton.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    fallbackValue: PropTypes.string,
    actionButton: PropTypes.node,
};
