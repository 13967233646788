import { ACTION_TYPES } from '../actions/translation-actions';

const initialState = {
    translationsLoaded: false,
};

export default function translation(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_TRANSLATIONS_LOADED: {
            return {
                ...state,
                translationsLoaded: action.payload,
            };
        }
        default:
            return state;
    }
}
