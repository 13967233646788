import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from './multi-select-component';
import { translate } from '@mspecs/shared-utils';

class BoolSelect extends React.PureComponent {
    constructor(props) {
        super();
        const { values } = props;
        this.options = Object.entries(values || {}).map(([label, value]) => ({
            label: label.toUpperCase(),
            value: label,
        }));
        this.defaultValue = this.options.filter(({ value }) => values[value]);
    }

    onChange = result => {
        this.props.onChange(
            this.options.reduce((acc, { label, value }) => {
                acc[value] = !!(result && result[label]);
                return acc;
            }, {})
        );
    };

    render() {
        return (
            <MultiSelect
                {...this.props}
                resultAsObject
                options={this.options}
                onChange={this.onChange}
                defaultValue={this.defaultValue}
            />
        );
    }
}

export default translate(BoolSelect);
