import PropTypes from 'prop-types';

export const CellPropType = {
    id: PropTypes.string,
    data: PropTypes.any,
    label: PropTypes.string,
    required: PropTypes.bool,
    isValid: PropTypes.bool,
    enabled: PropTypes.bool,
    uischema: PropTypes.shape({}),
    schema: PropTypes.shape({}),
    path: PropTypes.string,
    handleChange: PropTypes.func,
    dropDown: PropTypes.bool,
};
