import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { ErrorIcon as Icon } from '../../icon';

const NoDataContainer = styled.div`
    padding: 30px 15px;
`;

const ErrorIcon = styled(Icon)`
    margin: 4px 6px 0 0;
`;

const NoDataError = () => {
    const { t } = useTranslate();
    return (
        <NoDataContainer>
            <Flex>
                <ErrorIcon />
                <h1>{t('TABLE_ERROR_DATA_COULD_NOT_BE_FETCHED')}</h1>
            </Flex>
            {t('TABLE_ERROR_DATA_COULD_NOT_BE_FETCHED_SUBTITLE')}
        </NoDataContainer>
    );
};

export default NoDataError;
