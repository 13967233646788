import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilSquare } from '@fortawesome/pro-light-svg-icons';
import { TextArea } from '.';

const StyledEditIcon = styled(FontAwesomeIcon)`
    font-size: 30px;
    margin-right: 24px;
`;

export const EditTextAreaInput = ({ value, ...restProps }) => {
    const [editing, setEditing] = useState(false);

    return (
        <Flex alignItems="center">
            <StyledEditIcon
                icon={faPencilSquare}
                onClick={() => setEditing(!editing)}
            />
            {editing ? (
                <TextArea {...restProps} value={value || ''} />
            ) : (
                <p className={restProps.className}>{value || ''}</p>
            )}
        </Flex>
    );
};

EditTextAreaInput.propTypes = {
    value: PropTypes.string,
};
