import React from 'react';
import styled from '@emotion/styled';

const FormContent = styled.form`
    margin: 0 30px;

    @media (max-width: ${({ theme }) => theme.medium}) {
        margin: 0;
    }
`;

const FormContentContainer = props => {
    return <FormContent {...props} />;
};

export default FormContentContainer;
