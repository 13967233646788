import React from 'react';
import styled from '@emotion/styled';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { ScrollSyncPane } from 'react-scroll-sync';

import TableBodyRow from './table-body-row-component';

// NOTE: Need min-height to be able to drop to empty table
const DroppableBody = styled.div`
    ${({ useCustomDragAndDropContext }) =>
        useCustomDragAndDropContext && `min-height: 1px`}
`;

const DraggableRow = ({ row, index, ...rowProps }) => {
    const id = row.original._newRow ?? row.original.id;

    return (
        <Draggable key={id} draggableId={id} index={row.index}>
            {(provided, snapshot) => {
                // this is for fixing dragging on modal
                if (snapshot.isDragging) {
                    provided.draggableProps.style.left = undefined;
                    provided.draggableProps.style.top = undefined;
                }

                return (
                    <TableBodyRow
                        {...rowProps}
                        row={row}
                        index={index}
                        containerProps={provided.draggableProps}
                        outerRef={provided.innerRef}
                        cellRender={cell =>
                            cell.render('Cell', {
                                dragHandleProps: provided.dragHandleProps,
                                isSomethingDragging: snapshot.isDraggingOver,
                            })
                        }
                    />
                );
            }}
        </Draggable>
    );
};

const DragAndDropBody = ({
    data,
    onReorderData,
    rows,
    getTableBodyProps,
    droppableProps = {},
    useCustomDragAndDropContext = false,
    ...restProps
}) => {
    const handleDragEnd = React.useCallback(
        result => {
            const { source, destination } = result;
            if (!destination) {
                return;
            }
            const newOrder = [...data];
            const [movedRow] = newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, movedRow);
            onReorderData(newOrder, result);
        },
        [data, onReorderData]
    );

    const ContextWrapper = useCustomDragAndDropContext
        ? React.Fragment
        : DragDropContext;

    const contextProps = useCustomDragAndDropContext
        ? {}
        : { onDragEnd: handleDragEnd };

    return (
        <ContextWrapper {...contextProps}>
            <Droppable droppableId="table-body" {...droppableProps}>
                {provided => (
                    <ScrollSyncPane>
                        <DroppableBody
                            className="rt-tbody rt-scrollable"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            {...(getTableBodyProps?.() ?? {})}
                            useCustomDragAndDropContext={
                                useCustomDragAndDropContext
                            }
                        >
                            {rows.map((row, index) => {
                                const id =
                                    row.original._newRow ?? row.original.id;

                                const RowComponent = row.original
                                    .isRowDragDisabled
                                    ? TableBodyRow
                                    : DraggableRow;
                                return (
                                    <RowComponent
                                        key={id}
                                        row={row}
                                        index={index}
                                        {...restProps}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </DroppableBody>
                    </ScrollSyncPane>
                )}
            </Droppable>
        </ContextWrapper>
    );
};

export default DragAndDropBody;
