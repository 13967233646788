import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Header1Text } from '../../typograph/typograph-components';
import { useTranslate } from '@mspecs/shared-utils';

const HeaderText = styled(Header1Text)``;
export default function ModalHeaderText({ text, ...props }) {
    const { t } = useTranslate();
    return <HeaderText {...props}>{t(text)}</HeaderText>;
}

ModalHeaderText.propTypes = {
    text: PropTypes.string.isRequired,
};
