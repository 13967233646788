import { PATTERN } from './regex-constants';
import { currentLanguage } from '@mspecs/shared-utils';

const currentLanguageCode = currentLanguage();

export const formatValue = (
    value: string,
    format?: 'money' | 'zip' | 'number' | 'year'
): string => {
    if (!value || typeof value !== 'string') return value || '';

    if (format === 'money' || format === 'number') {
        const number = Number(value.replace(PATTERN.NUMBER, ''));
        if (isNaN(number)) return value;

        switch (currentLanguageCode) {
            case 'da':
                return number.toLocaleString('da-DK');
            case 'nb':
                return number.toLocaleString('nb-NO');
            case 'fi':
                return number.toLocaleString('fi-FI');
            case 'es':
                return number.toLocaleString('es-ES');
            case 'en':
                return number.toLocaleString('en-GB');
            default:
                return number.toLocaleString('sv-SE');
        }
    }

    if (format === 'zip') {
        return value.replace(PATTERN.INPUT_ZIP, '$1 $2').trim();
    }

    if (format === 'year') {
        return value.replace(/[^-0-9.]/g, '');
    }

    return value;
};

export const getUnformattedValue = (
    value: string,
    format?: 'money' | 'zip' | 'number' | 'year'
): string => {
    if (!value || typeof value !== 'string') return '';

    if (format === 'money' || format === 'number') {
        return value.replace(/[^0-9.-]+/g, '').replace(/\s/g, '');
    }

    if (format === 'zip') {
        return value.replace(/\s/g, '');
    }

    return value;
};
