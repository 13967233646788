import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.successColor};
`;

export const TableIcon = props => <Icon {...props} />;

export default TableIcon;
