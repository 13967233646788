import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloseIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const ClearIndicator = props => {
    const { hasValue, selectProps } = props;
    return !selectProps.isMulti && selectProps.menuIsOpen && hasValue ? (
        <components.ClearIndicator {...props}>
            <CloseIcon icon={faTimes} />
        </components.ClearIndicator>
    ) : null;
};

ClearIndicator.propTypes = {
    hasValue: PropTypes.bool,
    selectProps: PropTypes.object,
};

export default ClearIndicator;
