import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import React from 'react';

const CardContainer = styled(Flex)`
    background: ${({ theme }) => theme.colors.bgPrimary};
    box-shadow: 0 1px 5px 1px ${({ theme }) => theme.colors.boxShadowColor};
    flex-direction: column;
    height: 100%;
    flex: 1;
`;

const CardContent = styled.div`
    padding: 10px;
    opacity: ${({ isActive }) => (isActive ? '1' : '0.4')};
`;

const CardBottom = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    padding: 5px;
    margin-top: auto;
    margin-bottom: 0;
    justify-content: end;
`;

const Card = ({ isActive = true, children, footer, ...props }) => {
    return (
        <CardContainer {...props}>
            <CardContent isActive={isActive}>{children}</CardContent>
            <CardBottom>{footer}</CardBottom>
        </CardContainer>
    );
};
Card.propTypes = {
    children: PropTypes.node,
    footer: PropTypes.node,
    isActive: PropTypes.bool,
};
export default Card;
