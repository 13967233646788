import {
    FETCH_DEAL_WIZ_SERVICES_SUCCESS,
    SET_DEAL_WIZ_OBJECT_TYPE_ID,
    SET_DEAL_WIZ_SELECTED_SERVICES,
} from '../actions/deal-wiz-actions';

const initialState = {
    services: [],
    /*
     selectedServices & objectTypeId is props that lives in AngularJS it´s component $scope.
     Redux is used here to share state between AngularJS and React,
     as react-directives does not know if dynamic component props are changed
    */
    selectedServices: [],
    objectTypeId: null,
};

export default function dealWizServices(state = initialState, action) {
    switch (action.type) {
        case FETCH_DEAL_WIZ_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload,
            };
        case SET_DEAL_WIZ_OBJECT_TYPE_ID:
            return {
                ...state,
                objectTypeId: action.payload,
            };
        case SET_DEAL_WIZ_SELECTED_SERVICES:
            return {
                ...state,
                selectedServices: action.payload,
            };

        default:
            return state;
    }
}
