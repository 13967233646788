import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { useIsMobile } from '@mspecs/shared-utils';
import MobileOptionsMenuPortal from '../../mobile-options-menu/mobile-options-menu-portal';
import { MenuPopover } from '../../popovers';
import { IconButtonToolbar } from '../../button/icon-button-toolbar';

const KebabMenu = ({
    options,
    row,
    buttonStyle,
    column,
    title,
    ...restProps
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const isSmall = useIsMobile();

    const handleClick = e => {
        e.stopPropagation();
        setIsOpen(prev => !prev);
    };

    const renderButton = () => (
        <IconButtonToolbar buttonStyle={buttonStyle || column?.buttonStyle}>
            <FontAwesomeIcon icon={faEllipsisV} />
        </IconButtonToolbar>
    );

    if (isSmall) {
        return (
            <>
                <div tabIndex={-1} onClick={handleClick}>
                    {renderButton()}
                </div>
                <MobileOptionsMenuPortal
                    title={title}
                    options={options || column?.options}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                />
            </>
        );
    }

    return (
        <MenuPopover
            {...restProps}
            options={options || column?.options}
            row={row}
            placement="bottom"
        >
            <div tabIndex={-1} onClick={handleClick}>
                {renderButton()}
            </div>
        </MenuPopover>
    );
};

KebabMenu.propTypes = {
    options: PropTypes.array,
    row: PropTypes.object,
    buttonStyle: PropTypes.object,
    column: PropTypes.object,
    title: PropTypes.string,
};

export default KebabMenu;
