import { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, ClassNames } from '@emotion/react';
import { compose, withProps, branch } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { TEXT_STYLES } from '../../typograph/typograph-components';
import {
    withFinalFormField,
    withOwnState,
    withInputValidation,
    ErrorWrapper,
    withValidationWrapper,
    withLabel,
} from '../../../utils/hoc';
import { hideVisually } from '@mspecs/shared-utils';
import { useTranslate } from '@mspecs/shared-utils';

const HiddenInput = styled('input')`
    ${hideVisually()}
`;

const CheckboxWrapper = styled('span')`
    display: flex;
    align-items: center;
    height: 34px;
`;

const indeterminateStyles = theme => css`
    &::after {
        position: absolute;
        content: '';
        top: 4px;
        height: 10px;
        width: 10px;
        left: 4px;
        font-size: 10px;
        color: ${theme.colors.primaryColor};
        background-color: ${theme.colors.primaryColor};
        opacity: 1;
    }
`;

const StyledCheckbox = styled('span')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    background: ${({ theme, disabled, checked }) =>
        disabled
            ? theme.colors.bgSecondary
            : checked
            ? theme.colors.primaryColor
            : '#fff'};
    box-shadow: none;
    border-radius: ${({ checked }) => (checked ? 4 : 3)}px;
    border: ${({ theme: { colors }, disabled, checked }) => {
        if (disabled && !checked) return `1px solid ${colors.borderPrimary}`;
        if (disabled && checked) return `2px solid ${colors.borderPrimary}`;
        if (checked) return `2px solid ${colors.primaryColor40} `;

        return `1px solid ${colors.textSecondary}`;
    }};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    ${HiddenInput}:focus + & {
        ${({ theme }) => theme.input.checkbox.focus};
    }
    ${props => (props.indeterminate ? indeterminateStyles(props.theme) : '')}
`;

const InnerLabel = styled.label`
    ${({ theme }) => TEXT_STYLES.bodyText(theme)}
    display: inline-block;
    padding-left: 10px;
    min-width: 100%;
    margin-bottom: 0;
`;

const CheckIcon = styled(FontAwesomeIcon)`
    transition: all 0.2s;
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.textSecondary : '#fff'};
    opacity: ${props => (props.checked ? 1 : 0)};
    width: 16px;
    height: 16px;
`;

export const VisualCheckbox = ({
    onClick,
    checked,
    disabled,
    className,
    indeterminate,
}) => {
    return (
        <StyledCheckbox
            onClick={onClick}
            checked={checked}
            disabled={disabled}
            className={className}
            indeterminate={indeterminate}
        >
            {!indeterminate && (
                <CheckIcon
                    icon={faCheck}
                    size="lg"
                    checked={checked}
                    disabled={disabled}
                />
            )}
        </StyledCheckbox>
    );
};

const Checkbox = props => {
    const {
        id,
        checked,
        reversed,
        onChange,
        disabled,
        className,
        innerLabel,
        indeterminate,
        noTranslate,
        useDBTranslation,
        allowFalseCheckbox,
        ...restProps
    } = props;

    const { t } = useTranslate({ useDBTranslation });
    const hiddenInputRef = useRef(null);
    const handleClick = e => {
        //#TODO: (axel) dont stop propa,   create wrapper for table instead(08 Jul 2021)
        e.stopPropagation();
        return hiddenInputRef.current.click(e);
    };
    const isChecked = reversed ? !checked : !!checked;

    return (
        <ClassNames>
            {({ css }) => (
                <ErrorWrapper
                    errorIconClassName={css`
                        position: initial;
                        margin-left: 8px;
                    `}
                    errorInputClassName={css`
                        width: initial;
                        box-shadow: none;
                    `}
                    errorRequiredClassName={css`
                        top: 3px;
                    `}
                    {...restProps}
                >
                    <CheckboxWrapper>
                        <HiddenInput
                            type="checkbox"
                            id={id}
                            disabled={disabled}
                            checked={checked}
                            onChange={onChange}
                            ref={hiddenInputRef}
                            onClick={e => e.stopPropagation()}
                            {...restProps}
                            required={
                                allowFalseCheckbox ? false : restProps.required
                            }
                        />
                        <VisualCheckbox
                            onClick={handleClick}
                            checked={isChecked}
                            disabled={disabled}
                            className={className}
                            indeterminate={indeterminate}
                        />
                        {innerLabel && (
                            <InnerLabel htmlFor={id}>
                                {noTranslate
                                    ? innerLabel
                                    : t(innerLabel, {
                                          postProcess: 'capitalize',
                                      })}
                            </InnerLabel>
                        )}
                    </CheckboxWrapper>
                </ErrorWrapper>
            )}
        </ClassNames>
    );
};

export { Checkbox };
export default compose(
    withProps({ type: 'checkbox' }),
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel,
    memo
)(withOwnState(Checkbox, 'checked'));

Checkbox.propTypes = {
    id: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    innerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    reversed: PropTypes.bool,
    noTranslate: PropTypes.bool,
    useDBTranslation: PropTypes.bool,
    allowFalseCheckbox: PropTypes.bool,
};

Checkbox.defaultProps = {
    checked: false,
    reversed: false,
    disabled: false,
    onChange: () => {},
};
