import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const enlarge = keyframes`
    0% { transform: scale(0); }
    100% { transform: scale(1); }
`;

const move = keyframes`
    0% { transform: translate(0, 0); }
    100% { transform: translate(15px, 0); }
`;

const shrink = keyframes`
    0% { transform: scale(1); }
    100% { transform: scale(0); }
`;

const Dot = styled.div`
    position: absolute;
    top: 10px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.borderPrimary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
`;

const LoadingContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 22px;

    ${Dot} {
        &:nth-of-type(1) {
            left: 4px;
            animation: ${enlarge} 0.6s infinite;
        }
        &:nth-of-type(2) {
            left: 4px;
            animation: ${move} 0.6s infinite;
        }
        &:nth-of-type(3) {
            left: 16px;
            animation: ${move} 0.6s infinite;
        }
        &:nth-of-type(4) {
            left: 28px;
            animation: ${shrink} 0.6s infinite;
        }
    }
`;

const DotdotdotLoader = () => (
    <LoadingContainer>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
    </LoadingContainer>
);

export default DotdotdotLoader;
