export const PRINT_MODAL_TYPES = {
    CDS: 'CDS',
    BIDDING_LIST: 'BIDDING_LIST',
    BROKER_JOURNAL: 'BROKER_JOURNAL',
    OBJECT_DESCRIPTION: 'OBJECT_DESCRIPTION',
    BIDDING_LIST_ARCHIVE: 'BIDDING_LIST_ARCHIVE',
};

export const PRINT_MODAL_LINKS = {
    CDS: 'generateCashDefinitiveStatementPdf',
    BROKER_JOURNAL: 'generateBrokerJournal',
    OBJECT_DESCRIPTION: 'generateObjectDescriptionPdf',
    MERGE_PDF: 'mergePdfFiles',
    CDS_MERGE_AND_SAVE: 'mergePdfFiles/cds',
};

export const PRINT_MODAL_OUTPUT = {
    AJAX: 'AJAX',
    WINDOW: 'WINDOW',
    NONE: 'NONE',
};

export const BIDDING_PRINT_OPTIONS = {
    BUYER_SELLER: 'BIDDING_LIST_PRINT_OPTION_BUYER_SELLER',
    ANONYMOUS: 'BIDDING_LIST_PRINT_OPTION_ANONYMOUS',
    BROKER: 'BIDDING_LIST_PRINT_OPTION_BROKER',
};

export const BIDDING_ESIGN_OPTIONS = {
    BUYER_SELLER: 'BIDDING_LIST_PRINT_OPTION_BUYER_SELLER',
};
