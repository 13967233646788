import React from 'react';
import { FormInputField } from '../form';

const PercentageInput = props => {
    return (
        <FormInputField
            suffix="%"
            inputWidth="small"
            errorMessage="MONEY_INPUT_VALIDATION_ERROR_MSG"
            mask="number"
            parse={value =>
                value
                    ? Number(value.replaceAll(',', '.').replaceAll(/\s/g, ''))
                    : undefined
            }
            {...props}
        />
    );
};

export default PercentageInput;
