import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES } from '../../../button';
import { StyledPopover } from '../../../popovers/mspx-popover-component';
import {
    Toolbar,
    ToolbarButton,
} from '../../../layout/form/form-button-toolbar-component';

const StyledButton = styled(ToolbarButton)`
    text-transform: none;
    width: 100%;
`;
const StyledToolbar = styled(Toolbar)`
    margin-top: auto;
`;

// NOTE: Tippy popover can not handle Button (react component) as direct child
const SubmitWrapper = styled(Flex)`
    width: calc(50% + 5px);
    padding-left: ${({ theme }) => theme.spacing.default};
`;
export default function VerticalWizToolbar({
    backButtonProps,
    nextButtonProps,
}) {
    const { t } = useTranslate();

    return (
        <StyledToolbar>
            <StyledButton
                buttonType={BUTTON_TYPES.CANCEL}
                {...backButtonProps}
            />
            <StyledPopover
                content={t('VERTICAL_WIZ_NEXT_BUTTON_DISABLED_TOOLTIP')}
                enabled={nextButtonProps.disabled}
            >
                <SubmitWrapper>
                    <StyledButton
                        buttonType={BUTTON_TYPES.PRIMARY}
                        {...nextButtonProps}
                    />
                </SubmitWrapper>
            </StyledPopover>
        </StyledToolbar>
    );
}

VerticalWizToolbar.propTypes = {
    backButtonProps: PropTypes.object,
    nextButtonProps: PropTypes.object,
};
