import styled from '@emotion/styled';
import ToastContent from './components/toast-content-component';

const ToastWrapper = styled(ToastContent)`
    display: flex;
    width: 100%;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.boxShadows.default};
    position: relative;
    z-index: 2000;
    margin: 1rem 0;

    opacity: 0;
    transform: translateX(-100%);
    animation: toastAnimation 0.3s forwards;

    @keyframes toastAnimation {
        from {
            opacity: 0;
            transform: translateX(-100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

export const ErrorToast = styled(ToastWrapper)`
    border-left: 7px solid ${({ theme }) => theme.colors.errorColor};
`;

export const SuccessToast = styled(ToastWrapper)`
    border-left: 7px solid ${({ theme }) => theme.colors.green80};
`;

export const InfoToast = styled(ToastWrapper)`
    border-left: 7px solid ${({ theme }) => theme.colors.primaryColor};
`;

export const LoadingToast = styled(ToastWrapper)`
    border-left: 7px solid ${({ theme }) => theme.colors.primaryColor};
`;

export const WarningToast = styled(ToastWrapper)`
    border-left: 7px solid ${({ theme }) => theme.colors.pendingPrimary};
`;
