import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Image = styled.div`
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    background: url(${({ imageUrl }) => imageUrl});
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
`;

const ImageCellRenderer = ({ value, column }) => {
    return <Image imageUrl={value} height={column?.height} />;
};

ImageCellRenderer.propTypes = {
    value: PropTypes.string,
    column: PropTypes.object,
};

export default ImageCellRenderer;
