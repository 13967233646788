import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Crumb, StyledBreadCrumbs } from './bread-crumbs-component';

const HistoryBreadcrumbs = ({
    currentTitle,
    previousCrumb,
    storeCrumb,
    ...props
}) => {
    const navigate = useNavigate();

    const onNavigatePreviousCrumb = () => {
        // Store breadcrumb on navigation, this to handle browser back navigation
        storeCrumb();
        navigate(previousCrumb.route);
    };

    return (
        <StyledBreadCrumbs {...props}>
            {previousCrumb && (
                <Crumb {...previousCrumb} onClick={onNavigatePreviousCrumb} />
            )}
            <Crumb title={currentTitle} isLast />
        </StyledBreadCrumbs>
    );
};
HistoryBreadcrumbs.propTypes = {
    currentTitle: PropTypes.string,
    previousCrumb: PropTypes.shape({
        route: PropTypes.string,
    }),
    storeCrumb: PropTypes.func,
};

export default HistoryBreadcrumbs;
