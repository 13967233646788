import {
    UPDATE_ENTITY_SUCCESS,
    FETCH_ENTITY_SUCCESS,
    FETCH_ENTITY_REQUEST,
    DELETE_ENTITY_SUCCESS,
} from '../actions/entity-actions';

const initialState = {};

export default function entities(state = initialState, action) {
    switch (action.type) {
        // case ENTITY_UPDATE_SUCCESS:
        case FETCH_ENTITY_REQUEST: {
            return state;
        }
        case UPDATE_ENTITY_SUCCESS:
        case FETCH_ENTITY_SUCCESS: {
            const { tableName, data: dataList = [] } = action.payload;
            const previousData = state[tableName] ? state[tableName] || {} : {};
            const newData = dataList.reduce(
                (acc, data) => {
                    acc[data.id] = {
                        ...(acc[data.id] || {}),
                        ...data,
                    };
                    return acc;
                },
                { ...previousData }
            );

            return {
                ...state,
                [tableName]: {
                    ...newData,
                },
            };
        }
        case DELETE_ENTITY_SUCCESS: {
            const { [action.payload.data.id]: na, ...rest } =
                state[action.payload.tableName];
            return {
                ...state,
                [action.payload.tableName]: {
                    rest,
                },
            };
        }
        default:
            return state;
    }
}
