import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const Option = ({ data, iconComponent: Icon, isSelected, ...props }: any) => {
    return (
        <components.Option {...props}>
            <Icon
                aria-hidden
                country={data.value || ''}
                code={data.value}
                label={data.label}
                height={'auto'}
                size={20}
            />
            {data.label}
            {isSelected && (
                <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginLeft: 'auto' }}
                />
            )}
        </components.Option>
    );
};

export default Option;
