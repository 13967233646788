import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Global } from '@emotion/react';
import { useTranslate } from '@mspecs/shared-utils';
import Order from '~/features/login/order-mspx-component';
import { challengeSignupEmail } from '~/utils/services/signup-service';
import { delay } from '@mspecs/shared-utils';
import { PageTitle } from '~/utils/use-set-page-title-hook';
import { increasedInputFontSize } from '@mspecs/shared-utils';

const OrderMspxPage = () => {
    const { t } = useTranslate();
    const location = useLocation();
    const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
    const [sendEmailFail, setSendEmailFail] = useState(false);
    const [usernameInUse, setUsernameInUse] = useState(false);
    const [goToLogin, setGoToLogin] = useState(false);

    const registerEmail = async ({ email }) => {
        try {
            await challengeSignupEmail(email);
            setSendEmailSuccess(true);
        } catch (e) {
            if (e.type === 'RegisterUsernameInUseError') {
                setUsernameInUse(true);
                await delay(7000);
                setUsernameInUse(false);
            } else {
                setSendEmailFail(true);
                await delay(7000);
                setSendEmailFail(false);
            }
        }
    };

    const toggleLogin = () => {
        setGoToLogin(!goToLogin);
    };

    if (goToLogin) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <PageTitle title="ORDER_MSPECS" />
            <Global styles={increasedInputFontSize} />
            <Order
                registerEmail={registerEmail}
                sendEmailSuccess={sendEmailSuccess}
                sendEmailFail={sendEmailFail}
                usernameInUse={usernameInUse}
                emailLinkIsNotValid={
                    !!(location.state && location.state.emailLinkIsNotValid)
                }
                toggleLogin={toggleLogin}
                t={t}
            />
        </>
    );
};

export default OrderMspxPage;
