import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';
import { LoadingBar } from '../animations';

const defaultCss = css`
    border: 0;
    padding: 0;
    outline: none;
    height: 100%;
    width: 100%;
`;

const appendIntegrator = (url, integrator) => {
    let newUrl = new URL(url);
    const integratorPrefix = newUrl.search.includes('?')
        ? '&integrator='
        : '?integrator=';

    return `${newUrl}${integratorPrefix}${integrator}`;
};

const IFrame = props => {
    const showLoadError = () => console.log(t('IFRAME_COMPONENT_LOAD_FAIL'));
    const {
        source,
        overflow,
        afterLoad,
        timeout,
        frameStyles,
        onTimeout = showLoadError,
        ...rest
    } = props;
    const [loading, setLoading] = useState(true);
    const { t } = useTranslate();

    useEffect(() => {
        const timer = setTimeout(() => loading && onTimeout(), timeout);
        return () => clearTimeout(timer);
    }, [loading, onTimeout]);

    const src = appendIntegrator(source, 'isMspxIframe');

    const onLoad = event => {
        afterLoad(event);
        setLoading(false);
    };
    return (
        <>
            {loading && (
                <LoadingBar
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                    `}
                />
            )}
            <iframe
                css={[defaultCss, frameStyles]}
                src={src}
                onLoad={onLoad}
                {...rest}
            />
        </>
    );
};

IFrame.propTypes = {
    source: PropTypes.string,
    afterLoad: PropTypes.func,
    overflow: PropTypes.bool,
    timeout: PropTypes.number,
    onTimeout: PropTypes.func,
};

IFrame.defaultProps = {
    afterLoad: x => x,
    overflow: false,
    timeout: 3000,
};

export default IFrame;
