import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';

const StyledIcon = styled(FontAwesomeIcon)`  
    height 24px;  
    color: ${({ theme }) => theme.colors.textSecondary};  
`;

const DropdownIndicator = () => <StyledIcon icon={faSearch} />;

export default DropdownIndicator;
