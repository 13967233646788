import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { isImageFile } from '@mspecs/shared-utils';
import { TextBadge } from '../../display';

const FileCellRenderer = ({ value: file, column }) => {
    const isImage = useMemo(
        () => isImageFile(file?.extension),
        [file?.extension]
    );

    if (!file) {
        return null;
    }

    const { width, height, css: customCss } = column;

    return isImage ? (
        <img
            src={file.thumbnailURI}
            height={height ?? 45}
            width={width ?? 'auto'}
            css={css`
                ${customCss ? customCss : ''}
            `}
        />
    ) : (
        <TextBadge
            values={[file.extension]}
            css={css`
                ${customCss ? customCss : ''}
            `}
        />
    );
};

FileCellRenderer.propTypes = {
    value: PropTypes.shape({
        extension: PropTypes.string.isRequired,
        thumbnailURI: PropTypes.string,
    }),
};

export default FileCellRenderer;
