import React from 'react';
import { Transition } from 'react-transition-group';
import { func, bool, number, string } from 'prop-types';

const HeightTransition = ({
    children,
    on,
    timeout = 200,
    height,
    ...props
}) => {
    const transitionStyles = {
        entering: { height: height, opacity: '1' },
        entered: { height: height, opacity: '1' },
        exiting: { height: '0', opacity: '0' },
        exited: { height: '0', opacity: '0' },
    };

    return (
        <Transition
            appear
            in={on}
            unmountOnExit
            timeout={timeout}
            onEnter={node => node.offsetHeight}
            {...props}
        >
            {state =>
                children({
                    style: {
                        transition: `height ease-in-out ${timeout}ms, opacity ease-in-out ${timeout}ms`,
                        height: '0',
                        opacity: '0',
                        ...transitionStyles[state],
                    },
                })
            }
        </Transition>
    );
};

HeightTransition.propTypes = {
    on: bool.isRequired,
    height: string,
    children: func,
    timeout: number,
};

export default HeightTransition;
