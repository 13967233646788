import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';

const Column = styled(Flex)`
    background: ${({ theme }) => theme.colors.bgPrimary};
    border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    width: 100%;
    flex-grow: 1;
    height: 100%;

    ${props =>
        props.simple
            ? `position: relative;
       max-width: ${props.isActive ? '100%' : '0'};
       min-width: ${props.isActive ? '50%' : '0'};
       transition: all ease-in-out 0.3s;`
            : `max-width: ${!props.isActive && '250px'};
       display: ${!props.isVisible && 'none'};
       transition: width ease-in-out 5s;`};
`;

const Divider = styled(Flex)`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primaryColor};
    padding: 2px;
    margin: 0 14px;
    height: 100%;
    max-width: 4px;
    cursor: pointer;
    & :hover {
        filter: brightness(85%);
    }
`;

const DividerArrow = styled.span`
    border-left: solid 3px ${({ theme }) => theme.colors.primaryColor};
    border-bottom: solid 3px ${({ theme }) => theme.colors.primaryColor};
    padding: 4px;
    margin-left: ${props => (props.arrowLeft ? '-13px' : 'auto')};
    margin-right: ${props => (props.arrowLeft ? 'auto' : '-13px')};
    transform: ${props =>
        props.arrowLeft ? 'rotate(45deg)' : 'rotate(-135deg)'};
`;

export const DividerContext = React.createContext({});

const ContentDivider = props => {
    const { children, simple } = props;
    const [activeColumn, setActiveColumn] = useState(0);

    const columnProps = React.Children.toArray(children).map(({ props }) => {
        const { children, ...childLessProps } = props;
        return childLessProps;
    });

    useEffect(() => {
        React.Children.map(children, (child, index) => {
            if (
                index === columnProps[index].index &&
                columnProps[index].isVisible
            ) {
                setActiveColumn(index);
            }
        });
    }, [children]);

    const onChange = index => {
        if (activeColumn >= index) {
            setActiveColumn(index - 1);
        } else {
            setActiveColumn(index);
        }
        props.onChange(index);
    };

    return (
        <Flex flexGrow="1" maxWidth="100%">
            <DividerContext.Provider value={{ activeColumn }}>
                {React.Children.map(children, (child, index) =>
                    simple ? (
                        <Column
                            simple
                            isActive={child.props.isActive}
                            isVisible={true}
                        >
                            {index !== 0 && <Divider />}
                            {child}
                        </Column>
                    ) : (
                        <Column
                            isActive={activeColumn === index}
                            isVisible={columnProps[index].isVisible}
                        >
                            {index !== 0 && (
                                <Divider onClick={() => onChange(index)}>
                                    <DividerArrow
                                        arrowLeft={activeColumn < index}
                                    />
                                </Divider>
                            )}
                            {child}
                        </Column>
                    )
                )}
            </DividerContext.Provider>
        </Flex>
    );
};

ContentDivider.propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    onChange: PropTypes.func,
    simple: PropTypes.bool,
};

ContentDivider.defaultProps = {
    isVisible: true,
    simple: false,
    onChange: x => x,
};

export default ContentDivider;
