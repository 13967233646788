import React from 'react';
import Select from './select-component';

const BooleanSelect = props => {
    const options = [
        { label: 'NO', value: false },
        { label: 'YES', value: true },
    ];

    return <Select {...props} options={options} />;
};

export default BooleanSelect;
