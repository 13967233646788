import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TabNavProvider from './tab-nav-provider-component';

const TabNav = props => {
    const { children } = props;
    const [currentTab, setCurrentTab] = useState(children[0]?.props || null);

    useEffect(() => {
        if (!currentTab && React.Children.count(children)) {
            setCurrentTab(children[0]?.props);
        }
    }, [currentTab, React.Children.count(children)]);

    const onChange = useCallback(
        label => {
            setCurrentTab(label);

            if (props.onChange) {
                props.onChange(label);
            }
        },
        [setCurrentTab, props.onChange]
    );

    const isActiveFn = useCallback(
        props => props.tablabel === currentTab?.tablabel,
        [currentTab]
    );

    return (
        <TabNavProvider
            {...props}
            onChange={onChange}
            currentTab={currentTab}
            isActiveFn={isActiveFn}
        />
    );
};

TabNav.propTypes = {
    tabStyle: PropTypes.oneOf(['light', 'dark', 'noBorders']),
    hideNonActive: PropTypes.bool,
    hiddenTabsEnabled: PropTypes.bool,
    onChange: PropTypes.func,
    tabContentClassName: PropTypes.any,
    tabsClassName: PropTypes.any,
    children: PropTypes.node,
    t: PropTypes.func,
};

TabNav.defaultProps = {
    tabStyle: 'light',
    hideNonActive: false,
    hiddenTabsEnabled: true,
    onChange: x => x,
    t: x => x,
};

export default TabNav;
