import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { delay } from '@mspecs/shared-utils';
import { sendResetPassword } from '~/utils/services/auth-service';
import Login from '~/features/login/login-component';
import ForgotPassword from '~/features/login/forgot-password-component';
import { PageTitle } from '~/utils/use-set-page-title-hook';

const LoginPage = () => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailSentFail, setEmailSentFail] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!searchParams.get('redirect')) {
            const redirectUrl = window.sessionStorage.getItem('currentLocation');
            if (redirectUrl) {
                searchParams.set('redirect', redirectUrl);
                setSearchParams(searchParams);
            }
        }
        window.sessionStorage.removeItem('currentLocation');
    }, []);

    const resetPassword = async ({ email }) => {
        try {
            await sendResetPassword(email);
            setEmailSent(true);
            setForgotPassword(false);
            setEmailAddress(email);
            await delay(5000);
            setEmailSent(false);
        } catch (e) {
            setEmailSentFail(true);
            await delay(5000);
            setEmailSentFail(false);
        }
    };

    const redirectUrl = searchParams.get('redirect');

    return forgotPassword ? (
        <>
            <PageTitle title="FORGOT_PASSWORD" />
            <ForgotPassword
                resetPassword={resetPassword}
                toggleForgotPassword={() => setForgotPassword(!forgotPassword)}
                emailSentFail={emailSentFail}
            />
        </>
    ) : (
        <Login
            isSent={emailSent}
            toggleForgotPassword={() => setForgotPassword(!forgotPassword)}
            emailAddress={emailAddress}
            redirectUrl={redirectUrl}
        />
    );
};

export default LoginPage;
