import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from '@rebass/grid/emotion';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import styled from '@emotion/styled';
import { useElementSize } from '@mspecs/shared-utils';
import { HeightTransition } from '../../../animations';
import IconButtonComponent from '../../../button/icon-button/icon-button-component';

const BoxTable = styled(Box)`
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: ${({ icon, theme }) =>
        icon ? `${theme.spacing.default} 30px` : '10px 10px 10px 0'};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
`;

const ToggleButton = styled(IconButtonComponent)`
    background-color: ${({ isExpanded }) =>
        isExpanded ? '#fff' : 'var(--customerpage-primary-color, #3B5A75)'};
    color: ${({ isExpanded }) =>
        isExpanded ? 'var(--customerpage-primary-color, #3B5A75)' : '#fff'};
    :focus {
        box-shadow: none;
    }
`;
const ToggableBoxTable = styled(BoxTable)`
    background-color: ${({ isExpanded }) =>
        isExpanded ? 'var(--customerpage-primary-color, #3B5A75)' : ''};
    color: ${({ isExpanded }) => (isExpanded ? '#fff' : '#000')};
`;
const BoxTableRow = styled(Box)`
    display: table-row;
    height: 30px;
`;
const BoxTableCell = styled(Box)`
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding-left: 10px;
`;
const ExpandableItem = ({ title, children, icon }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [setRef, { height }] = useElementSize();

    const toggleIcon = icon ?? (isExpanded ? faChevronUp : faChevronDown);

    return (
        <Box>
            <ToggableBoxTable isExpanded={isExpanded} icon={!!icon}>
                <BoxTableRow>
                    <BoxTableCell>{title}</BoxTableCell>
                    <Flex justifyContent="flex-end">
                        <ToggleButton
                            isExpanded={isExpanded}
                            icon={toggleIcon}
                            small
                            onClick={() => setIsExpanded(prev => !prev)}
                            hiddenLabel={isExpanded ? 'HIDE' : 'SHOW'}
                        />
                    </Flex>
                </BoxTableRow>
            </ToggableBoxTable>
            <HeightTransition on={isExpanded} height={`${height}px`}>
                {transitionProps => (
                    <Box {...transitionProps} style={{ overflow: 'scroll' }}>
                        <Box padding="2" ref={setRef}>
                            {children}
                        </Box>
                    </Box>
                )}
            </HeightTransition>
        </Box>
    );
};

ExpandableItem.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
};

export default ExpandableItem;
