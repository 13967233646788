export const SIGNATURE_TYPES = {
    BROKER: 'ENUMS_SIGNATURE_TYPE_BROKER',
    SECONDARY_BROKER: 'ENUMS_SIGNATURE_TYPE_BROKER_SECONDARY',
    SELLER: 'ENUMS_SIGNATURE_TYPE_SELLER',
    SELLER_CONTACT: 'ENUMS_SIGNATURE_TYPE_SELLER_CONTACTS',
    SELLER_WITNESS: 'ENUMS_SIGNATURE_TYPE_SELLERS_WITNESS',
    BUYER: 'ENUMS_SIGNATURE_TYPE_BUYER',
    BUYER_CONTACT: 'ENUMS_SIGNATURE_TYPE_BUYER_CONTACTS',
    BUYER_WITNESS: 'ENUMS_SIGNATURE_TYPE_BUYERS_WITNESS',
    BUYER_SELLER_WITNESS: 'ENUMS_SIGNATURE_TYPE_BUYERS_SELLERS_WITNESS',
    BUYER_COMPANY_SIGNATORY: 'ENUMS_SIGNATURE_TYPE_BUYER_COMPANY_SIGNATORY',
    WITNESS: 'ENUMS_SIGNATURE_TYPE_WITNESS',
    CONSENT: 'ENUMS_SIGNATURE_TYPE_CONSENT',
    ANONYMOUS: 'ENUMS_SIGNATURE_TYPE_ANONYMOUS',
    DOCUMENT_WITNESS: 'ENUMS_SIGNATURE_TYPE_DOCUMENT_WITNESS',
    SELLER_DECEASED_INHERITOR: 'ENUMS_SIGNATURE_TYPE_SELLER_DECEASED_INHERITOR',
    SELLER_DECEASED_INHERITOR_WITH_SHARE:
        'ENUMS_SIGNATURE_TYPE_SELLER_DECEASED_INHERITOR_WITH_SHARE',
    DECEASED_INHERITOR: 'ENUMS_SIGNATURE_TYPE_SELLER_DECEASED_INHERITOR',
    DECEASED_INHERITOR_WITH_SHARE:
        'ENUMS_SIGNATURE_TYPE_SELLER_DECEASED_INHERITOR_WITH_SHARE',
    SELLER_CONTACT_DECEASED_INHERITOR:
        'ENUMS_SIGNATURE_TYPE_SELLER_CONTACT_DECEASED_INHERITOR',
    SELLER_COMPANY_SIGNATORY: 'ENUMS_SIGNATURE_TYPE_SELLER_COMPANY_SIGNATORY',
};

export const BROKER_TYPES = {
    [SIGNATURE_TYPES.BROKER]: SIGNATURE_TYPES.BROKER,
    [SIGNATURE_TYPES.BROKER_SECONDARY]: SIGNATURE_TYPES.BROKER_SECONDARY,
};

export const SELLER_TYPES = {
    [SIGNATURE_TYPES.SELLER]: SIGNATURE_TYPES.SELLER,
    [SIGNATURE_TYPES.SELLER_CONTACT]: SIGNATURE_TYPES.SELLER_CONTACT,
    [SIGNATURE_TYPES.SELLERS_WITNESS]: SIGNATURE_TYPES.SELLERS_WITNESS,
    [SIGNATURE_TYPES.SELLER_DECEASED_INHERITOR]:
        SIGNATURE_TYPES.SELLER_DECEASED_INHERITOR,
    [SIGNATURE_TYPES.SELLER_DECEASED_INHERITOR_WITH_SHARE]:
        SIGNATURE_TYPES.DECEASED_INHERITOR_WITH_SHARE,
    [SIGNATURE_TYPES.SELLER_CONTACT_DECEASED_INHERITOR]:
        SIGNATURE_TYPES.SELLER_CONTACT_DECEASED_INHERITOR,
    [SIGNATURE_TYPES.SELLER_COMPANY_SIGNATORY]:
        SIGNATURE_TYPES.SELLER_COMPANY_SIGNATORY,
};

export const BUYER_TYPES = {
    [SIGNATURE_TYPES.BUYER]: SIGNATURE_TYPES.BUYER,
    [SIGNATURE_TYPES.BUYER_CONTACT]: SIGNATURE_TYPES.BUYER_CONTACT,
    [SIGNATURE_TYPES.BUYERS_WITNESS]: SIGNATURE_TYPES.BUYER_WITNESS,
    [SIGNATURE_TYPES.BUYER_COMPANY_SIGNATORY]:
        SIGNATURE_TYPES.BUYER_COMPANY_SIGNATORY,
};

export const SELLER_RELATED_SIGNATURES = {
    ...SELLER_TYPES,
    [SIGNATURE_TYPES.CONSENT]: SIGNATURE_TYPES.CONSENT,
};
