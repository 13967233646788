import { ACTION_TYPES } from '~/redux/actions/provider/provider-services-actions';

const {
    GET_PROVIDER_DATA_SUCCESS,
    CREATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
    UPDATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
    REMOVE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
    PROVIDER_SERVICE_ERROR,
    PROVIDER_SERVICE_REQUEST_START,
    SET_SELECTED_PROVIDER_SERVICE,
    GET_OBJECT_TYPES_SUCCESS,
} = ACTION_TYPES;

const initialState = {
    loading: false,
    error: false,
    subscriptions: [],
    providerServices: [],
    objectTypes: [],
    organizationUnitId: null,
    selectedProviderService: null,
};

export default function providerServices(state = initialState, action) {
    switch (action.type) {
        case PROVIDER_SERVICE_REQUEST_START: {
            return {
                ...state,
                error: false,
                loading: true,
            };
        }
        case PROVIDER_SERVICE_ERROR: {
            return {
                ...state,
                error: true,
                loading: false,
            };
        }
        case GET_PROVIDER_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                error: false,
                loading: false,
            };
        }
        case GET_OBJECT_TYPES_SUCCESS: {
            return {
                ...state,
                objectTypes: action.payload,
                error: false,
                loading: false,
            };
        }
        case UPDATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS: {
            const {
                providerServiceSubscriptionId,
                isOrganizationUnitSubscription,
                organizationUnitId,
                mandatoryObjectTypes,
                recommendedObjectTypes,
            } = action.payload;
            const { subscriptions } = state;

            return {
                ...state,
                loading: false,
                error: false,
                subscriptions: subscriptions.map(subscription =>
                    subscription.id === providerServiceSubscriptionId
                        ? {
                              ...subscription,
                              isOrganizationUnitSubscription,
                              recommendations: {
                                  recommended: recommendedObjectTypes,
                                  mandatory: mandatoryObjectTypes,
                              },
                              subscriptionType: organizationUnitId
                                  ? 'organizationUnitSubscription'
                                  : undefined,
                          }
                        : subscription
                ),
            };
        }
        case CREATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS: {
            const { subscriptions, providerServices } = state;
            return {
                ...state,
                loading: false,
                error: false,
                providerServices: providerServices.filter(
                    providerService =>
                        providerService.id !== action.payload.providerServiceId
                ),
                subscriptions: [...subscriptions, action.payload],
            };
        }
        case REMOVE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                subscriptions: action.payload,
            };
        }
        case SET_SELECTED_PROVIDER_SERVICE: {
            return {
                ...state,
                selectedProviderService: action.payload
                    ? {
                          ...state.providerServices.find(
                              providerService =>
                                  providerService.id === action.payload.id
                          ),
                          ...action.payload,
                      }
                    : null,
                loading: false,
                error: false,
            };
        }
        default:
            return state;
    }
}
