export const wizActions = {
    NEXT_STEP: 'NEXT_STEP',
    PREVIOUS_STEP: 'PREVIOUS_STEP',
    GO_TO_STEP: 'GO_TO_STEP',
    UPDATE_STEPS: 'UPDATE_STEPS',
    UPDATE_STEP_IS_VALID: 'UPDATE_STEP_IS_VALID',
};

const {
    NEXT_STEP,
    PREVIOUS_STEP,
    GO_TO_STEP,
    UPDATE_STEPS,
    UPDATE_STEP_IS_VALID,
} = wizActions;
export const initialState = {
    propList: [],
    validStepMap: {},
    current: {
        step: null,
        index: null,
        isFirst: true,
        isLast: true,
    },
};

const makeNextStep = (index, steps) => {
    return {
        index,
        step: steps[index],
        isFirst: index === 0,
        isLast: index + 1 >= steps.length,
    };
};

export function wizReducer(state, action) {
    const { stepPropList, current } = state;
    const { index, step, isLast, isFirst } = current;
    switch (action.type) {
        case NEXT_STEP:
            if (isLast) {
                return state;
            }

            return {
                ...state,
                current: makeNextStep(index + 1, stepPropList),
            };
        case PREVIOUS_STEP:
            if (isFirst) {
                return state;
            }

            return {
                ...state,
                current: makeNextStep(index - 1, stepPropList),
            };
        case GO_TO_STEP:
            return {
                ...state,
                current: makeNextStep(action.payload.index, stepPropList),
            };
        case UPDATE_STEPS:
            const stepPropListNew = action.payload;
            const correctedIndex = stepPropListNew.findIndex(newStep =>
                step ? newStep.label === step.label : false
            );

            return {
                ...state,
                current: makeNextStep(
                    correctedIndex > -1 ? correctedIndex : 0,
                    stepPropListNew
                ),
                stepPropList: stepPropListNew,
            };
        case UPDATE_STEP_IS_VALID:
            return {
                ...state,
                validStepMap: {
                    ...state.validStepMap,
                    [action.payload.label]: action.payload.isValid,
                },
            };
        default:
            return state;
    }
}
