import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { DividerContext } from './content-divider-component';

const ColumnWrapper = styled.div`
    width: 100%;
    transition: width ease-in-out 5s;
`;

const Column = props => {
    const { defaultColumn, index, smallColumn } = props;
    const { activeColumn } = useContext(DividerContext);

    return (
        <ColumnWrapper>
            {activeColumn === index ? defaultColumn : smallColumn}
        </ColumnWrapper>
    );
};

Column.propTypes = {
    defaultColumn: PropTypes.node,
    index: PropTypes.number,
    isVisible: PropTypes.bool,
    smallColumn: PropTypes.node,
};

Column.defaultProps = {
    isVisible: true,
};

export default Column;
