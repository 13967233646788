import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { theme } from '@mspecs/shared-utils';
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropdownTarget = styled(Flex)<{ position: 'left' | 'right' }>`
    gap: 10px;
    border-right: ${({ position }) =>
        position === 'left'
            ? `1px solid ${theme.colors.bgSecondary140}`
            : 'none'};
    border-left: ${({ position }) =>
        position === 'right'
            ? `1px solid ${theme.colors.bgSecondary140}`
            : 'none'};
    height: 24px;
    padding: ${({ position }) =>
        position === 'left' ? '0 10px 0 0' : '0 0 0 10px'};
    flex-wrap: wrap;
    align-content: center;
`;

const CaretDown = () => (
    <FontAwesomeIcon icon={faCaretDown} style={{ alignSelf: 'center' }} />
);

export { DropdownTarget, CaretDown };
