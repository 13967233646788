import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Tag = styled.div`
    color: ${({ theme }) => theme.colors.textPrimary};
    padding: 7px 10px;
    font-size: 11px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: ${({ type, theme: { colors } }) => {
        switch (type) {
            case 'success':
                return colors.green20;
            case 'error':
                return colors.red40;
            case 'cancelled':
                return colors.red10;
            case 'pending':
                return colors.pending20;
            case 'highlight':
                return colors.highlightingBg50;
            case 'inactive':
                return colors.borderSecondary;
            case 'waiting':
                return colors.primaryColor20;
            case 'default':
            default:
                return colors.borderPrimary;
        }
    }};
`;

const StatusTag = ({ type, children, ...props }) => {
    return (
        <Tag type={type} {...props} data-testid="status-tag">
            <span>{children}</span>
        </Tag>
    );
};

StatusTag.propTypes = {
    type: PropTypes.oneOf([
        'success',
        'error',
        'cancelled',
        'pending',
        'highlight',
        'default',
        'inactive',
        'waiting',
    ]),
    children: PropTypes.any,
};

export default StatusTag;
