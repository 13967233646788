import { ClassNames } from '@emotion/react';
import { EditorState } from 'draft-js';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { useTranslate, theme, wysiwygOptions } from '@mspecs/shared-utils';

const validInlineStyles = ['text-align'];

const CustomImageBlock = ({ block, contentState }) => {
    const data = contentState.getEntity(block.getEntityAt(0)).getData();
    return (
        <figure>
            <img src={data.src} alt={data.alt} />
        </figure>
    );
};

const customBlockRenderer = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'atomic') {
        return {
            component: CustomImageBlock,
            editable: false,
        };
    }
};

const RichTextEditor = ({
    onChange,
    content,
    placeholder,
    toolbarOptions = ['inline', 'textAlign', 'list', 'link', 'blockType'],
    ...rest
}) => {
    const { t } = useTranslate();

    const [editorState, setEditorState] = useState(
        content
            ? EditorState.createWithContent(
                  stateFromHTML(content, {
                      customBlockFn: element => {
                          const data = validInlineStyles.reduce((acc, key) => {
                              const elementStyle = element.style[key];
                              return elementStyle
                                  ? { ...acc, [key]: elementStyle }
                                  : acc;
                          }, {});
                          return {
                              data,
                          };
                      },
                  })
              )
            : EditorState.createEmpty()
    );

    const editorOptions = {
        placeholder,
        editorState,
        onEditorStateChange: v => {
            setEditorState(v);
            onChange(
                stateToHTML(editorState.getCurrentContent(), {
                    inlineStyles: {
                        BOLD: { element: 'b' },
                        ITALIC: { element: 'i' },
                    },
                    blockStyleFn: block => {
                        const validStyles = validInlineStyles.reduce(
                            (acc, key) => {
                                const dataStyle = block.getData().get(key);

                                return dataStyle
                                    ? { ...acc, [key]: dataStyle }
                                    : acc;
                            },
                            {}
                        );
                        if (Object.keys(validStyles).length) {
                            return {
                                style: validStyles,
                            };
                        }
                    },
                })
            );
        },
        blockRendererFn: customBlockRenderer,
        ...wysiwygOptions(t, {
            options: toolbarOptions,
            textAlign: {
                options: ['left', 'center', 'right'],
            },
            inline: {
                options: ['bold', 'italic', 'underline'],
            },
            blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
            },
            list: {
                options: ['unordered'],
            },
            link: {
                options: ['linkTitle', 'link'],
            },
            image: {
                urlEnabled: true,
                alignmentEnabled: false,
                previewImage: true,
                inputAccept:
                    'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                defaultSize: {
                    height: 'auto',
                    width: 'auto',
                },
                uploadEnabled: false,
            },
        }),
        ...rest,
    };
    return (
        <ClassNames>
            {({ css }) => (
                <Editor
                    {...editorOptions}
                    wrapperClassName={css`
                        padding: 0;
                        display: flex;
                        flex: 1 1 0;
                        flex-direction: column;
                    `}
                    toolbarClassName={css`
                        border: 0;
                        border-bottom: 1px solid ${theme.colors.borderPrimary};
                        padding: 0;
                        margin: 0;
                        background-color: ${theme.colors.bgSecondary};
                        font-size: 14px;
                        div[class*='-option-wrapper'] {
                            background: transparent;
                            padding: 9px;
                            margin: 0;
                            &:hover {
                                box-shadow: none;
                                background: #f1f1f1;
                            }
                        }

                        div[class*='-option-active'] {
                            background: #e1e1e1;
                        }

                        div[class$='-inline-wrapper'],
                        div[class$='-list-wrapper'],
                        div[class$='-block-wrapper'],
                        div[class$='-text-align-wrapper'],
                        div[class$='-link-wrapper'] {
                            margin: 0;
                        }
                        div[class$='-link-modal'] {
                            min-height: 235px;
                        }
                    `}
                    editorClassName={css`
                        padding: 5px;
                        border-radius: 10px;

                        &:focus {
                            box-shadow: none;
                            background: red;
                        }

                        .DraftEditor-root:focus {
                            box-shadow: none;
                        }

                        .public-DraftEditor-content:focus  {
                            box-shadow: none;
                        }
                    `}
                />
            )}
        </ClassNames>
    );
};
RichTextEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    content: PropTypes.string,
    toolbarOptions: PropTypes.arrayOf(PropTypes.string),
};

export default RichTextEditor;
