import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BodyTextRegular } from '../../typograph/typograph-components';
import { v4 as uuidv4 } from 'uuid';
import { Flex } from '@rebass/grid/emotion';

const Radio = styled.input`
    accent-color: ${({ theme }) => theme.colors.primaryColor}};
    transform: scale(1.5);
    margin-bottom: 10px;
`;

const RadioSwitch = props => {
    return <Radio type={'radio'} {...props} />;
};

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Label = styled(BodyTextRegular)`
    @media (max-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        margin-left: 10px;
        font-size: 16px;
    }
`;

const RadioWrapper = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding-right: 30px;
        flex-direction: row;
        align-items: baseline;
    }
`;

/**
 * Input component to toggle between values values
 */
const MergeToggle = ({
    name,
    togglableValue,
    isChecked,
    onChange,
    ...props
}) => {
    const identifier = useMemo(() => {
        return uuidv4();
    }, [name]);

    return (
        <>
            {togglableValue ? (
                <RadioWrapper key={identifier}>
                    <RadioSwitch
                        onChange={onChange}
                        value={togglableValue}
                        checked={isChecked}
                        name={name}
                        id={identifier}
                        {...props}
                    />
                    <Label htmlFor={identifier}>{togglableValue}</Label>
                </RadioWrapper>
            ) : (
                <Wrap>
                    <p>-</p>
                </Wrap>
            )}
        </>
    );
};

MergeToggle.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export default MergeToggle;
