import React from 'react';
import {
    rankWith,
    isNumberControl,
    isIntegerControl,
    or,
    and,
    optionIs,
} from '@jsonforms/core';
import {
    getCellProps,
    parseInputToNumber,
    withInputControlHoc,
} from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { PercentageInput } from '../../../input';

function PercentageCell(props) {
    const { path, handleChange, data } = props;
    const baseProps = {
        ...getCellProps(props),
        value: data ? `${data}`.replaceAll('.', ',') : undefined,
        onChange: e => {
            handleChange(path, parseInputToNumber(e.target.value) || undefined);
        },
    };

    return <PercentageInput {...baseProps} />;
}

PercentageCell.propTypes = CellPropType;

export const tester = rankWith(
    3,
    and(or(isNumberControl, isIntegerControl), optionIs('format', 'percentage'))
);

export default { renderer: withInputControlHoc(PercentageCell), tester };
