import { createSelector } from 'reselect';
import { jsonObjectToQueryString } from '@mspecs/shared-utils';

const getHiddenPrintOptions = state =>
    state.objectDescription.hiddenPrintOptions;
const getPrintOptions = (state, pdfOptions) => {
    const { printOptions } = state.objectDescription;
    const { addQrScanCode } = printOptions;
    let options = printOptions;

    if (pdfOptions) {
        options = { ...options, ...pdfOptions };
    }
    if (addQrScanCode) {
        return { ...options, isLowResolutionImages: true };
    }
    return options;
};

const getBaseUrl = path => `${window.location.origin}${path}`;

const signatureOptions = createSelector(
    state => state.objectDescription.metaData.signatures,
    ({ buyers, sellers }) => {
        return [...buyers, ...sellers];
    }
);

const printOptionsParam = pdfOptions =>
    createSelector(
        state => getPrintOptions(state, pdfOptions),
        getHiddenPrintOptions,
        signatureOptions,
        (printOptions, hiddenPrintOptions, signatures) => {
            return jsonObjectToQueryString({
                ...printOptions,
                ...hiddenPrintOptions,
                signatures,
            });
        }
    );

const baseUrlGenSelector = (base, pdfOptions) => {
    return createSelector(
        printOptionsParam(pdfOptions),
        (_, props) => props.dealId,
        (queryParams, dealId) => `${base}${dealId}?${queryParams}`
    );
};

export const pdfUrlSelector = baseUrlGenSelector(
    '/generateObjectDescriptionPdf/'
);
export const pdfSaveUrlSelector = baseUrlGenSelector(
    '/createObjectDescriptionPdfFile/',
    { isLowResolutionImages: true }
);

export const pdfEsignUrlSelector = baseUrlGenSelector(
    getBaseUrl('/generateObjectDescriptionPdf/'),
    { isEsign: true, isLowResolutionImages: true }
);

export const printOptionSelector = createSelector(
    getPrintOptions,
    printOptions => {
        return Object.entries(printOptions)
            .filter(([key, value]) => value)
            .map(([key, value]) => key);
    }
);
