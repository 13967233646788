import React from 'react';
import { css } from '@emotion/react';
import { columnCreator } from './columns';
import { DragIcon } from '../../icon';

const DragNDropCell = ({ dragHandleProps, className }) => (
    <span {...dragHandleProps} className={className} aria-label="move">
        <DragIcon />
    </span>
);

export const useDraggableRow = hooks => {
    hooks.visibleColumns.push((columns, ...rest) => [
        {
            id: 'draggableRow',
            width: 60,
            minWidth: 60,
            css: css`
                flex: unset !important;
            `,
            disableSort: true,
            Cell: props =>
                !props.row.original.isRowDragDisabled && (
                    <DragNDropCell {...props} />
                ),
        },
        ...columns,
    ]);
};

export const useRowMenu = (menuFirst, options) => hooks => {
    hooks.visibleColumns.push(columns => {
        const Col = columnCreator();
        const menuCol = Col.settings('rowMenu', {
            getOptions: () => options,
            id: 'menuCol',
        });

        return menuFirst ? [menuCol, ...columns] : [...columns, menuCol];
    });
};
