import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TEXT_STYLES } from '../typograph/typograph-components';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES } from './button-type-constants';

const StyledButton = styled.button`
    display: inline-flex;
    cursor: pointer;
    height: ${({ small, large }) => {
        if (small) return '30px';
        if (large) return '42px';
        return '34px';
    }};
    padding: ${({ small, large }) => {
        if (small) return '10px';
        if (large) return '10px 36px';
        return '10px 20px';
    }};
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme, square }) =>
        square ? 0 : theme.borderRadius.medium};
    border: 2px solid transparent;
    white-space: nowrap;

    ${({ theme }) => TEXT_STYLES.buttonTextSmall(theme)}
    ${({ fluid }) => (fluid ? 'width: 100%;' : '')}

    :disabled {
        background: ${({ theme }) => theme.colors.bgSecondary120};
        color: ${({ theme }) => theme.colors.textSecondary};
        border-color: transparent;
        cursor: not-allowed;

        :hover,
        :active,
        :focus,
        :focus:active {
            background: ${({ theme }) => theme.colors.bgSecondary120};
            color: ${({ theme }) => theme.colors.textSecondary};
            border-color: transparent;
        }
    }
`;

const DefaultButton = styled(StyledButton)`
    border-color: ${({ theme }) => theme.colors.primaryColor};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primaryColor};

    :hover {
        background: ${({ theme }) => theme.colors.primaryColor90};
        border-color: ${({ theme }) => theme.colors.primaryColor90};
        color: ${({ theme }) => theme.colors.white};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.primaryColor};
        background: ${({ theme }) => theme.colors.primaryColor};
        color: ${({ theme }) => theme.colors.white};
    }
    :focus:active {
        background: ${({ theme }) => theme.colors.primaryColor90};
        border-color: ${({ theme }) => theme.colors.primaryColor90};
        color: ${({ theme }) => theme.colors.white};
    }
`;

const PrimaryButton = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryColor};
    :hover {
        background: ${({ theme }) => theme.colors.primaryColor90};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.primaryColor40};
    }
    :focus:active {
        background: ${({ theme }) => theme.colors.primaryColor90};
        border-color: ${({ theme }) => theme.colors.primaryColor40};
    }
`;
const PrimaryLightButton = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.primaryColor};
    background: ${({ theme }) => theme.colors.primaryColor10};
    :hover {
        background: ${({ theme }) => theme.colors.primaryColor15};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.primaryColor20};
        background: ${({ theme }) => theme.colors.primaryColor10};
    }
    :focus:active {
        background: ${({ theme }) => theme.colors.primaryColor15};
        border-color: ${({ theme }) => theme.colors.primaryColor10};
    }
`;
const SecondaryActionButton = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.primaryColor};
    background: ${({ theme }) => theme.colors.bgSecondary140};
    :hover {
        background: ${({ theme }) => theme.colors.bgSecondary120};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.gray80};
        background: ${({ theme }) => theme.colors.gray80};
    }
    :focus:active {
        border-color: ${({ theme }) => theme.colors.gray80};
        background: ${({ theme }) => theme.colors.bgSecondary120};
    }
`;
const ClickableText = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.primaryColor};
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 0;
    :hover,
    :active,
    :focus,
    :focus:active {
        box-shadow: none;
    }
    :hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1.5px;
    }
    :disabled {
        background: transparent;
        :hover {
            background: transparent;
            text-decoration: none;
        }
    }
`;
const DangerButton = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.redPrimary};
    :hover {
        background: ${({ theme }) => theme.colors.red105};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.red10};
        background: ${({ theme }) => theme.colors.red110};
    }
    :focus:active {
        border-color: ${({ theme }) => theme.colors.red10};
        background: ${({ theme }) => theme.colors.red105};
    }
`;

const PrimaryTransparentButton = styled(StyledButton)`
    color: ${({ theme }) => theme.colors.primaryColor};
    background: transparent;
    :hover {
        background: ${({ theme }) => theme.colors.primaryColor10};
    }
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.primaryColor20};
        background: ${({ theme }) => theme.colors.primaryColor10};
    }
    :focus:active {
        background: ${({ theme }) => theme.colors.primaryColor20};
        border-color: ${({ theme }) => theme.colors.primaryColor20};
    }
`;

function Button({ buttonType, ...props }) {
    const [disabled, setDisabled] = useState();
    const { t } = useTranslate();
    const timer = useRef(null);

    useEffect(() => () => clearTimeout(timer.current), []);

    const handleClick = e => {
        if (disabled) return;
        setDisabled(true);
        onClick(e);
        // Prevent double click
        timer.current = setTimeout(() => {
            setDisabled(false);
        }, 500);
    };
    const ButtonComponent = useMemo(() => {
        switch (buttonType) {
            case BUTTON_TYPES.PRIMARY:
                return PrimaryButton;
            case BUTTON_TYPES.SECONDARY:
                return SecondaryActionButton;
            case BUTTON_TYPES.TEXT:
                return ClickableText;
            case BUTTON_TYPES.DANGER:
                return DangerButton;
            case BUTTON_TYPES.PRIMARY_LIGHT:
                return PrimaryLightButton;
            case BUTTON_TYPES.PRIMARY_TRANSPARENT:
                return PrimaryTransparentButton;
            default:
                return DefaultButton;
        }
    }, [buttonType]);

    const renderChildren = () => {
        const { children } = props;
        if (typeof children === 'string') {
            return t(children);
        }
        return children;
    };

    const { label, onClick, ...restProps } = props;

    return (
        <ButtonComponent
            {...restProps}
            onClick={onClick && handleClick}
            disable={disabled}
        >
            {renderChildren() || t(label)}
        </ButtonComponent>
    );
}

Button.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    fluid: PropTypes.bool,
    large: PropTypes.bool,
    onClick: PropTypes.func,
    small: PropTypes.bool,
    square: PropTypes.bool,
    buttonType: PropTypes.oneOf([...Object.values(BUTTON_TYPES), null]),
};

Button.defaultProps = {
    type: 'button',
    small: false,
    large: false,
    fluid: false,
    square: false,
    buttonType: null,
};
export default Button;

export { BUTTON_TYPES };
