import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { delay } from '@mspecs/shared-utils';
import ChangePassword from '~/features/login/change-password-component';
import { resetPassword } from '~/utils/services/auth-service';
import { PageTitle } from '~/utils/use-set-page-title-hook';

const ChangePasswordPage = () => {
    const { token } = useParams();
    const [passwordChanged, setPasswordChanged] = useState('');
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const handleChangePassword = async data => {
        try {
            await resetPassword(token, data);
            setPasswordChanged('success');
            await delay(4000);
            setPasswordChanged('');
            setRedirectToLogin(true);
        } catch (e) {
            setPasswordChanged('error');
            await delay(5000);
            setPasswordChanged('');
        }
    };

    if (redirectToLogin) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <PageTitle title="CHANGE_PASSWORD" />
            <ChangePassword
                handleChangePassword={handleChangePassword}
                passwordChanged={passwordChanged}
            />
        </>
    );
};

export default ChangePasswordPage;
