import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Link = styled.a`
    color: ${({ theme }) => theme.colors.linkColor};
    white-space: nowrap;
    text-transform: ${props => props.lowercase && 'lowercase'};
    cursor: pointer;
`;

const LinkContainer = props => {
    const { text, href, target, onClick, lowercase, ...restProps } = props;

    return (
        <Link
            href={href}
            target={target}
            onClick={onClick}
            lowercase={lowercase}
            {...restProps}
        >
            {text}
        </Link>
    );
};

LinkContainer.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    lowercase: PropTypes.bool,
};

LinkContainer.defaultProps = {
    lowercase: false,
};

export default LinkContainer;
