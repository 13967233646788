import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { rem } from '@mspecs/shared-utils';

const NotificationRow = styled('div')`
    width: 100%;
    color: white;
    max-height: 80px;
    user-select: none;
    align-items: center;
    justify-content: center;
    transition: bottom ease-in-out 0.15s;
    text-align: center;
    display: flex;
    padding: ${rem(14)} ${rem(17.5)};
`;

const NotificationText = styled('span')`
    margin-right: 20px;
`;

const NotificationBtn = styled('span')`
    right: 0;
    top: 0;
    position: absolute;
    cursor: pointer;
    padding: ${rem(10.5)} ${rem(17.5)};
    font-size: ${rem(21)};
    font-weight: 700;
`;

const NotificationBottomContent = ({ t, close, message, className }) => (
    <NotificationRow data-cy="notification" className={className}>
        <NotificationText>{t(message)}</NotificationText>
        <NotificationBtn onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
        </NotificationBtn>
    </NotificationRow>
);

NotificationBottomContent.propTypes = {
    className: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default NotificationBottomContent;
