import {
    faBuilding,
    faChartScatter,
    faFarm,
    faPencilRuler,
    faApartment,
    faHouseDay,
    faWarehouseFull,
    faHouse,
    faHotel,
} from '@fortawesome/pro-regular-svg-icons';

import {
    BRF,
    COMMERCIAL,
    CONDOMINIUM,
    FARMING,
    HOUSE,
    LAND,
    PREMISE,
    PROJECT,
    RECREATIONAL_HOUSE,
} from './constants/object-types-constants';

export const getObjectTypeIcon = (objectTypeName: string) => {
    switch (objectTypeName) {
        case BRF:
            return faBuilding;
        case RECREATIONAL_HOUSE:
            return faHouseDay;
        case COMMERCIAL:
            return faApartment;
        case FARMING:
            return faFarm;
        case PREMISE:
            return faWarehouseFull;
        case PROJECT:
            return faPencilRuler;
        case HOUSE:
            return faHouse;
        case LAND:
            return faChartScatter;
        case CONDOMINIUM:
            return faHotel;
        default:
            return faHouse;
    }
};
