import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import PinnedIcon from './pinned-icon-component';
import { useSidebarContext } from '../../../layout/main-layout/sidebar-state-context';
import {
    Header1Text,
    Header3Text,
} from '../../../typograph/typograph-components';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import { StyledSidebarHeader } from './inset-sidebar-component';
import { CrossButton } from '../../../button';
import NavigationBackButton from '../../back-button/navigation-back-button';

const SidebarSidePropType = PropTypes.oneOf(['right', 'left']);

const Header = styled(Box)`
    height: 64px;
    text-align: center;
    margin: 0 ${({ theme }) => theme.spacing.default};
    display: flex;
    flex-direction: ${({ side }) => (side === 'left' ? 'row' : 'row-reverse')};
    justify-content: space-between;
    position: relative;
    border-bottom: ${({ theme }) => `1px solid  ${theme.colors.borderPrimary}`};
`;

const HeaderText = styled(Header3Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const HeaderTextContainer = styled(Flex)`
    align-items: center;
    overflow: hidden;
`;
const ButtonWrapper = styled(Flex)`
    width: 3rem;
    justify-content: center;
    align-items: center;
`;

const HeaderIconWrapper = styled.span`
    padding-right: ${({ theme }) => theme.spacing.default};
`;

const MobileHeaderWrapper = styled(Flex)`
    flex-direction: column;
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} ${spacing.large} 0 ${spacing.large}`};
`;

const StyledBackButton = styled(NavigationBackButton)`
    padding-bottom: ${({ theme }) => theme.spacing.default};
`;

export const DefaultSidebarHeader = ({
    side,
    pinned,
    isPinnable,
    header,
    onClose,
    isPinned,
    headerIcon,
    ...props
}) =>
    header && (
        <Header {...props} side={side}>
            <ButtonWrapper>
                {!pinned && !isPinned && <CrossButton onClick={onClose} />}
            </ButtonWrapper>
            <HeaderTextContainer>
                <HeaderText>
                    {headerIcon && (
                        <HeaderIconWrapper>{headerIcon}</HeaderIconWrapper>
                    )}
                    {header}
                </HeaderText>
            </HeaderTextContainer>
            <ButtonWrapper>
                {!pinned && isPinnable && <PinComponent side={side} />}
            </ButtonWrapper>
        </Header>
    );
DefaultSidebarHeader.propTypes = {
    side: SidebarSidePropType,
    header: PropTypes.string,
    onClose: PropTypes.func,
    isPinned: PropTypes.bool,
    isPinnable: PropTypes.bool,
    pinned: PropTypes.bool,
    headerIcon: PropTypes.object,
};

export const MobileSidebarHeader = ({
    backLabel,
    onBack,
    children,
    ...props
}) => {
    const currentBreakPoint = useCurrentBreakpoint();
    const onBackClick = onBack || props.onClose;

    if (currentBreakPoint !== 'xs') {
        return <StyledSidebarHeader {...props} />;
    }

    return (
        <MobileHeaderWrapper>
            {backLabel && (
                <StyledBackButton onClick={onBackClick} label={backLabel} />
            )}
            <Flex justifyContent="space-between" alignItems="center">
                <Header1Text>{props.header}</Header1Text>
                {children}
            </Flex>
        </MobileHeaderWrapper>
    );
};
MobileSidebarHeader.propTypes = {
    ...DefaultSidebarHeader.propTypes,
    onBack: PropTypes.func,
    backLabel: PropTypes.string,
};

const PinComponent = ({ side }) => {
    const [state, { toggle }] = useSidebarContext();

    return (
        <PinnedIcon
            handlePinSidebar={() =>
                toggle({
                    pinned: !state[side].pinned,
                    side,
                })
            }
            isPinned={state[side].pinned}
        />
    );
};

PinComponent.propTypes = {
    side: SidebarSidePropType,
};
