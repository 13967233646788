import React, { useMemo } from 'react';
import { rankWith, isEnumControl } from '@jsonforms/core';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { EnumSelect } from '../../../input';
export function EnumCell(props) {
    const { path, schema, uischema, handleChange, rootSchema } = props;

    const translationPrefix =
        schema?.metaProperties?.translationPrefix ??
        rootSchema?.metaProperties?.translationPrefix;

    const enums = useMemo(() => {
        const hasTranslationPrefix =
            translationPrefix && translationPrefix !== 'noPrefix';
        if (!hasTranslationPrefix) return schema.enum;

        return schema.enum.map(value => ({
            label: value === null ? '---' : translationPrefix + value.toUpperCase(),
            value,
        }));
    }, [translationPrefix, schema.enum]);

    const baseProps = {
        ...getCellProps(props),
        defaultValue: uischema.options?.defaultValue,
        onChange: e => handleChange(path, e),
    };

    return <EnumSelect enums={enums ?? []} {...baseProps} />;
}

EnumCell.propTypes = CellPropType;

export const tester = rankWith(3, isEnumControl);

export default { renderer: withInputControlHoc(EnumCell), tester };
