import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { Link as RectLink } from 'react-router-dom';
import { SUPPORT_URL } from '~/constants';
import { translate } from '@mspecs/shared-utils';

const LinkGroup = styled(Flex)`
    position: absolute;
    bottom: 24px;
    right: 24px;
    left: 24px;
    a:only-child {
        margin-left: auto;
    }
    @media (max-width: ${({ theme }) => theme.medium}) {
        position: static;
    }
`;

const Link = styled.a`
    color: ${({ theme }) => theme.colors.textSecondary};
    white-space: nowrap;
    cursor: pointer;

    :hover {
        color: ${({ theme }) => theme.colors.linkColor};
        text-decoration: none;
    }
`;

const StyledRectLink = styled(RectLink)`
    color: ${({ theme }) => theme.colors.textSecondary};
    white-space: nowrap;

    :hover {
        color: ${({ theme }) => theme.colors.linkColor};
        text-decoration: none;
    }
`;

const LinkFooter = props => {
    const { back, contactSupport, forgotPassword, orderMspecs, t } = props;
    return (
        <LinkGroup justifyContent="space-between" flexWrap="wrap" pt={15}>
            {back && (
                <Link
                    onClick={back}
                    onKeyDown={e => e.key === 'Enter' && back()}
                    tabIndex={0}
                >
                    {t('BACK')}
                </Link>
            )}
            {orderMspecs && (
                <StyledRectLink data-cy="register-link" to="/register">
                    {t('ORDER_MSPECS')}
                </StyledRectLink>
            )}
            {forgotPassword && (
                <Link
                    data-cy="forgot-link"
                    tabIndex={0}
                    onClick={forgotPassword}
                    onKeyDown={e => e.key === 'Enter' && forgotPassword()}
                >
                    {t('FORGOT_PASSWORD')}
                </Link>
            )}
            {contactSupport && (
                <Link data-cy="support-link" href={SUPPORT_URL} target="_blank">
                    {t('CONTACT_SUPPORT')}
                </Link>
            )}
        </LinkGroup>
    );
};

LinkFooter.propTypes = {
    back: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    contactSupport: PropTypes.bool,
    forgotPassword: PropTypes.func,
    orderMspecs: PropTypes.bool,
    t: PropTypes.func,
};

export default translate(LinkFooter);
