import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

// NOTE: This is a workaround to not display the input field inline
// Instead the input field is rendered in select-menu-list
const SelectInput = props => {
    const {
        onMenuOpen,
        menuIsOpen,
        onMenuClose,
        isSearchable,
        isExplicitSearchBar,
    } = props.selectProps;

    const onClick = () => {
        menuIsOpen ? onMenuClose() : onMenuOpen();
    };

    if (isSearchable && isExplicitSearchBar) {
        return (
            <span
                style={{
                    height: '34px',
                    position: 'absolute',
                    width: '100%',
                }}
                onClick={onClick}
                onTouchEnd={onClick}
            ></span>
        );
    }

    return <components.Input {...props} />;
};

SelectInput.propTypes = {
    selectProps: PropTypes.object,
};

export default SelectInput;
