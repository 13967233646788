import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { useTranslate } from '@mspecs/shared-utils';

const DropzoneContainer = styled(Flex)`
    border: dashed 1px ${({ theme }) => theme.colors.borderSecondary};
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    border-radius: 4px;
    &:hover {
        p {
            color: ${({ theme }) => theme.colors.primaryColor};
        }
    }
`;

const DefaultDropText = () => {
    const { t } = useTranslate();
    return <p>{t('FILE_UPLOAD_DROP_ZONE_HELP')}</p>;
};

const FileUploadDropzone = props => {
    const { disabled, onDrop, dropText = <DefaultDropText />, ...rest } = props;

    return (
        <Dropzone
            disabled={disabled}
            className="mspx-file-upload-dropzone"
            onDrop={onDrop}
            {...rest}
        >
            {({ getRootProps, getInputProps }) => {
                const dropProps = getRootProps();
                if (props.onClick) dropProps.onClick = props.onClick;
                if (props.style) dropProps.style = props.style;

                return (
                    <DropzoneContainer {...dropProps}>
                        <input {...getInputProps()} />
                        {dropText}
                    </DropzoneContainer>
                );
            }}
        </Dropzone>
    );
};

FileUploadDropzone.propTypes = {
    disabled: PropTypes.bool,
    onDrop: PropTypes.func,
    onClick: PropTypes.func,
    style: PropTypes.object,
    dropText: PropTypes.object,
};

export default FileUploadDropzone;
