/**
 *
 * Converts a jsonObject to a queryString with a shallow conversion.
 *
 * @param jsonObject {}
 * @returns {string}
 */
export function jsonObjectToQueryString(jsonObject) {
    if (
        !jsonObject ||
        Array.isArray(jsonObject) ||
        jsonObject !== Object(jsonObject)
    ) {
        return '';
    }
    /* eslint-disable no-unused-vars */
    return Object.entries(jsonObject)
        .filter(([key, val]) => val != null)
        .map(
            ([key, val]) =>
                `${key}=${encodeURIComponent(
                    val === Object(val) ? JSON.stringify(val) : val
                )}`
        )
        .join('&');
}

export async function parseResponseBody(response) {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
        /*
            response.json() throws an error when the response body is empty,
            using a combo of.text/JSON.parse instead as this gives a more predictable result
        */
        const text = await response.text();
        return text.length ? JSON.parse(text) : null;
    }
    return response;
}
