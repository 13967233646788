import React from 'react';
import PropTypes from 'prop-types';

export const SidebarValueContext = React.createContext();
export const SidebarDispatchContext = React.createContext();

export const useSidebarContext = () => {
    const dispatch = React.useContext(SidebarDispatchContext);
    const values = React.useContext(SidebarValueContext);
    return [values, dispatch];
};

const pinReducer = (state, { type, payload = {} }) => {
    if (!type || !payload.side) throw Error('No action type of side provided');

    switch (type) {
        case 'toggle':
            return {
                ...state,
                [payload.side]: {
                    ...state[payload.side],
                    pinned:
                        typeof payload.pinned !== undefined
                            ? !!payload.pinned
                            : !state.pinned,
                },
            };

        case 'toggleOpen':
            return {
                ...state,
                [payload.side]: {
                    ...state[payload.side],
                    sidebarOpen:
                        typeof payload.open !== undefined
                            ? !!payload.open
                            : !state[payload.side].pinned,
                    tab: payload.tab,
                },
            };
    }
};

const SidebarProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(pinReducer, {
        left: {
            pinned: false,
            sidebarOpen: false,
        },
        right: {
            pinned: false,
            sidebarOpen: false,
        },
    });

    const toggle = props => dispatch({ type: 'toggle', payload: props });
    const toggleOpen = props =>
        dispatch({ type: 'toggleOpen', payload: props });

    return (
        <SidebarDispatchContext.Provider value={{ toggle, toggleOpen }}>
            <SidebarValueContext.Provider value={state}>
                {children}
            </SidebarValueContext.Provider>
        </SidebarDispatchContext.Provider>
    );
};

SidebarProvider.propTypes = {
    children: PropTypes.node,
};

export default SidebarProvider;
