import range from 'lodash/range';
import React from 'react';
import { composePaths, createDefaultValue, Helpers } from '@jsonforms/core';

import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { AddButton } from '../../../../button';
import { useChildUiSchema } from '../../hooks/uischema';

const { convertToValidClassName } = Helpers;
const ButtonLabel = styled.span`
    display: flex;
    margin-right: 10px;
`;
const Container = styled.div`
    width: 100%;
`;

const BaseArrayControl = props => {
    const {
        classNames,
        data,
        label,
        path,
        schema,
        errors,
        addItem,
        renderField,
        uischema,
        getStyleAsClassName,
        displayAddButton,
        config,
    } = props;

    const controlElement = uischema;
    const { t } = useTranslate();
    const childUiSchema = useChildUiSchema(props);
    const isValid = errors.length === 0;
    const validationClass = getStyleAsClassName('array.control.validation');
    const divClassNames = [validationClass]
        .concat(
            isValid ? '' : getStyleAsClassName('array.control.validation.error')
        )
        .join(' ');

    const controlClass = [
        getStyleAsClassName('array.control'),
        convertToValidClassName(controlElement.scope),
    ].join(' ');

    const addText = `${t('ADD')} ${t(label).toLowerCase()}`;

    const defaultItem = config?.pendingArrayItems
        ? {
              ...createDefaultValue(schema),
              isPendingItem: true,
          }
        : createDefaultValue(schema);

    return (
        <Container className={controlClass}>
            <div className={divClassNames}>{errors}</div>
            <div className={classNames.children}>
                {data &&
                    range(0, data.length).map(index => {
                        const childPath = composePaths(path, `${index}`);
                        const _uiSchema = childUiSchema || uischema;

                        return renderField({
                            index,
                            childPath,
                            uischema: _uiSchema,
                            data: data[index],
                        });
                    })}
            </div>
            {displayAddButton && (
                <Flex alignItems="center" justifyContent="flex-end" mb="4">
                    <ButtonLabel>{addText}</ButtonLabel>
                    <AddButton
                        onClick={addItem(path, defaultItem)}
                        hiddenLabel={addText}
                    />
                </Flex>
            )}
        </Container>
    );
};

export default BaseArrayControl;
