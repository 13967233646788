import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { ErrorIcon, Triangle } from '../../icon';
import { useTranslate } from '@mspecs/shared-utils';

const Container = styled('div')`
    min-height: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: left;
`;

const LinkContainer = styled('a')`
    min-height: 45px;
    display: flex;
    align-items: center;
    padding: 13px;
    color: ${({ theme }) => theme.colors.white};
    justify-content: left;
    span:first-of-type {
        border-radius: 50%;
        padding: 0;
        width: 1.5em;
        display: inline-flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        span:first-of-type {
            filter: brightness(110%);
        }
    }
`;

const LinkBackground = styled.span`
    background-color: ${({ theme }) => theme.colors.primaryColor};
`;

const activeClass = theme => css`
    background: ${theme.colors.contrastBg85};
`;

const StyledTriangle = styled(Triangle)`
    top: 0;
    left: 100%;
    position: absolute;
    @media (max-width: ${({ theme }) => theme.large}) {
        display: none;
    }
`;

const Title = styled('span')`
    margin-left: 10px;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

const InvalidContainer = styled.span`
    position: relative;
    margin-left: auto;
    padding-left: 5px;
`;

const WhiteIcon = styled(FontAwesomeIcon)`
    color: white;
`;

const Icon = styled(ErrorIcon)`
    position: absolute;
    right: -2px;
`;

const InvalidIcon = () => {
    return (
        <InvalidContainer>
            <WhiteIcon icon={faCircle} />
            <Icon />
        </InvalidContainer>
    );
};

const SideNavItem = ({
    active,
    title,
    icon,
    link,
    setIsSmallScreenMenuOpen,
    showError,
    onClick: _onClick,
}) => {
    const { t } = useTranslate();
    const theme = useTheme();

    const onClick = () => {
        if (active !== title) {
            _onClick(title);
            setIsSmallScreenMenuOpen(false);
        }
    };

    const isActive = active === title;

    if (link) {
        return (
            <LinkContainer href={link} target="_blank">
                {icon && (
                    <LinkBackground>
                        <FontAwesomeIcon
                            icon={icon}
                            style={{ minHeight: '20px' }}
                            fixedWidth
                        />
                    </LinkBackground>
                )}
                <Title>{t(title)}</Title>
            </LinkContainer>
        );
    }

    return (
        <Container
            name={title}
            onClick={onClick}
            css={[isActive && activeClass]}
        >
            {icon && <FontAwesomeIcon icon={icon} size="lg" fixedWidth />}
            <Title>{t(title)}</Title>
            {showError && !isActive && <InvalidIcon />}
            {isActive && <StyledTriangle fill={theme.colors.contrastBg85} />}
        </Container>
    );
};

SideNavItem.propTypes = {
    active: PropTypes.string,
    showError: PropTypes.bool,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object,
    link: PropTypes.string,
    onClick: PropTypes.func,
    setIsSmallScreenMenuOpen: PropTypes.func,
};

SideNavItem.defaultProps = {
    showError: false,
};

export default SideNavItem;
