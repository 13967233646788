import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';

// the key is derived from each object with id chosen as default.
export function arrayToObj(
    arr,
    { key = 'id', addDisplayOrder = false, generateIds = false } = {}
) {
    return arr.reduce((acc, curr, index) => {
        const idKey = curr[key] || (generateIds ? uuidv4() : index);
        if (addDisplayOrder) {
            curr.displayOrder = index;
        }

        if (generateIds) {
            curr.id = idKey;
        }

        acc[idKey] = curr;
        return acc;
    }, {});
}

const formatInTimeZone = (date, fmt, tz) =>
    format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export function mysqlDateFormatter(mysqlTimestamp, fmt = 'yyyy-MM-dd') {
    const parsedTime = parseISO(mysqlTimestamp);
    const formattedTime = formatInTimeZone(parsedTime, fmt, 'UTC');
    return formattedTime;
}
