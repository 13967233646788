import React from 'react';
import PropTypes from 'prop-types';
import FatalError from './fatal-error-component';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: false };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ error: false });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            (this.state.error && this.props.location !== nextProps.location) ||
            this.state.error !== nextState.error
        );
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        if (this.state.error) {
            return (
                <FatalError supportUrl={this.props.supportUrl}>
                    {this.state.error.message && (
                        <pre>{this.state.error.message}</pre>
                    )}
                    {this.state.error.stack && (
                        <pre>{this.state.error.stack}</pre>
                    )}
                </FatalError>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.string.isRequired,
    supportUrl: PropTypes.string,
};

export default ErrorBoundary;
