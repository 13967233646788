import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';

const ActiveText = styled.span`
    color: ${({ theme }) => theme.colors.green80};
`;

const InActiveText = styled.span`
    color: ${({ theme }) => theme.colors.errorColor};
`;

function DisplayActiveText({ active = true }) {
    const { t } = useTranslate();

    return active ? (
        <ActiveText>{t('STATUS_ACTIVE')}</ActiveText>
    ) : (
        <InActiveText>{t('STATUS_INACTIVE')}</InActiveText>
    );
}

DisplayActiveText.propTypes = {
    active: PropTypes.bool,
};

export default DisplayActiveText;
