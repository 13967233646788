import React from 'react';
import styled from '@emotion/styled';

const BorderContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    margin: 1em 0;
`;

const Border = () => {
    return <Border />;
};

export default BorderContainer;
