import { ACTIONS } from '../../actions/external-marketing-place/external-marketing-place-data-actions';

// Structure { [dealId]: { 'hemnet-v1': {}, blocket: {} } }
const initialState = {
    loadingDealData: false,
    loadingDealDataError: false,
    working: false,
};

export default function externalMarketingPlaceData(
    state = initialState,
    action
) {
    const { marketingPlace, marketingPlaceData, dealId } = action.payload || {};

    switch (action.type) {
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA:
            return {
                ...state,
                loadingDealData: true,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_ERROR:
            return {
                ...state,
                loadingDealData: false,
                loadingDealDataError: true,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE_SUCCESS:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_SUCCESS:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_SUCCESS:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_SUCCESS: {
            const prevDealData = state[dealId] || {};
            return {
                ...state,
                loadingDealData: false,
                working: false,
                [dealId]: {
                    ...prevDealData,
                    [marketingPlace]: {
                        ...marketingPlaceData,
                    },
                },
            };
        }

        case ACTIONS.EXTERNAL_MARKETING_PLACE_HEMNET_MARK_AS_SOLD:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH:
            return {
                ...state,
                working: true,
            };

        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE_ERROR:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_ERROR:
        case ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_ERROR:
            return {
                ...state,
                working: false,
            };

        default:
            return state;
    }
}
