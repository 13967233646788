import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { compose, withProps } from 'recompose';
import { withFinalFormField } from '../../../../utils/hoc';
import { TEXT_STYLES } from '../../../typograph/typograph-components';
import { useTranslate } from '@mspecs/shared-utils';

const HiddenInput = styled.input`
    display: none;
`;

const RadioButtonWrapper = styled.span`
    display: flex;
    align-items: center;
    min-height: 34px;
    cursor: pointer;
`;

const RadioButtonLabel = styled.span`
    display: inline-block;
    padding-left: ${({ theme }) => theme.spacing.default};
    ${({ theme }) => TEXT_STYLES.bodyText(theme)}
    padding-right: ${({ theme }) => theme.spacing.large};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const StyledRadioButton = styled.span`
    width: 22px;
    min-width: 22px;
    height: 22px;;
    background: ${({ disabled, theme }) =>
        disabled ? theme.colors.bgSecondary : theme.colors.bgPrimary};
    border-radius: 50%;
    border: ${({ theme: { colors }, disabled, checked }) => {
        if (disabled && !checked) return `1px solid ${colors.borderPrimary}`;
        if (disabled && checked) return `2px solid ${colors.borderPrimary}`;
        if (checked) return `2px solid ${colors.primaryColor40} `;

        return `1px solid ${colors.textSecondary}`;
    }};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    :after{
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        transition: all 0.1s ease-in-out;
        height: ${({ checked }) => (checked ? '12px' : '0')};
        width: ${({ checked }) => (checked ? '12px' : '0')};

        background-color: ${({ checked, disabled, theme: { colors } }) => {
            if (checked) {
                return disabled ? colors.textSecondary : colors.primaryColor;
            }
            return colors.bgPrimary;
        }};
        }
    }
`;
function RadioButton(props) {
    const { label, checked, disabled, children, ...rest } = props;
    const hiddenInputRef = useRef();
    const { t } = useTranslate();

    const onClick = () => {
        if (!disabled) {
            hiddenInputRef.current.click();
        }
    };

    return (
        <RadioButtonWrapper onClick={onClick}>
            <StyledRadioButton checked={checked} disabled={disabled} />
            {label && (
                <RadioButtonLabel disabled={disabled}>
                    {t(label)}
                </RadioButtonLabel>
            )}
            <HiddenInput
                type="radio"
                checked={checked}
                disabled={disabled}
                ref={hiddenInputRef}
                {...rest}
            />
            {children}
        </RadioButtonWrapper>
    );
}

export default compose(
    withProps({ type: 'radio' }),
    withFinalFormField
)(RadioButton);

RadioButton.propTypes = {
    t: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    isFormField: PropTypes.bool,
    children: PropTypes.any,
};
