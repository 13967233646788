import React from 'react';
import styled from '@emotion/styled';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import IconButton from './icon-button-component';

const StyledHelpButton = styled(IconButton)`
    height: 40px;
    border-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.primaryColor5};
    font-size: 13px;

    :hover,
    :focus {
        filter: none;
        box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.25);
    }

    :focus {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
    ${({ isRound }) => isRound && `height: 34px;`}
`;

const HelpButton = props => <StyledHelpButton icon={faQuestion} {...props} />;

export default HelpButton;
