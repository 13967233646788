import { findUISchema } from '@jsonforms/core';
import { useMemo } from 'react';

export const useChildUiSchema = props => {
    const { uischema, schema, path, uischemas, rootSchema } = props;
    const childUiSchema = useMemo(
        () =>
            findUISchema(
                uischemas,
                schema,
                uischema.scope,
                path,
                undefined,
                uischema,
                rootSchema
            ),
        [uischemas, schema, uischema.scope, path, uischema, rootSchema]
    );

    return childUiSchema;
};
