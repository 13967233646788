import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { HelpButton } from '../button';
import { CloseIcon, InfoIcon } from '../icon';
import { useState } from 'react';

export const HelpContainer = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.primaryColor5};
    border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    margin-bottom: 15px;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const HelpText = styled.div`
    font-size: 13px;
    margin: 3px 13px 0 0;
    width: 100%;
`;

const HelpBox = ({ info, children, close, isNoHelpButton, ...rest }) => {
    const [isOpen, setIsOpen] = useState(true);

    const onClick = () => setIsOpen(prev => !prev);

    return (
        <>
            {!close || isOpen ? (
                <HelpContainer {...rest}>
                    {info && (
                        <InfoIcon style={{ margin: 'auto 10px auto 0' }} />
                    )}
                    <HelpText>{children}</HelpText>
                    {close && <CloseIcon onClose={onClick} />}
                </HelpContainer>
            ) : (
                !isNoHelpButton && <HelpButton onClick={onClick} />
            )}
        </>
    );
};

HelpBox.propTypes = {
    info: PropTypes.bool,
    close: PropTypes.bool,
    children: PropTypes.node,
};

export default HelpBox;
