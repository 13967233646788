import { ac } from '@mspecs/shared-utils';
import {
    startRequest,
    failRequest,
    successRequest,
    finishRequest,
} from './request-actions';
import { legacyApi } from '@mspecs/shared-utils';

export const FETCH_ENTITY_REQUEST = 'FETCH_ENTITY_REQUEST';
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS';
export const FETCH_ENTITY_ERROR = 'FETCH_ENTITY_ERROR';

export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';

export const CREATE_ENTITY_ERROR = 'CREATE_ENTITY_ERROR';
export const UPDATE_ENTITY_ERROR = 'UPDATE_ENTITY_ERROR';
export const DELETE_ENTITY_ERROR = 'DELETE_ENTITY_ERROR';

export function successFetchEntity(payload) {
    return ac(FETCH_ENTITY_SUCCESS, payload);
}

export function successUpdateEntity(payload) {
    return ac(UPDATE_ENTITY_SUCCESS, payload);
}

export function errorUpdateEntity(payload) {
    return ac(UPDATE_ENTITY_ERROR, payload);
}

export function successCreateEntity(payload) {
    return ac(CREATE_ENTITY_SUCCESS, payload);
}

export function errorCreateEntity(payload) {
    return ac(CREATE_ENTITY_ERROR, payload);
}

export function successEntityDelete(payload) {
    return ac(DELETE_ENTITY_SUCCESS, payload);
}

export function errorEntityDelete(payload) {
    return ac(DELETE_ENTITY_ERROR, payload);
}

function errorEntityRequest(err) {
    return ac(ENTITY_REQUEST_ERROR, err);
}

const requestApi = (tableName, id) =>
    legacyApi(`/${tableName}${id ? `/${id}` : ''}`);

export function fetchEntity(tableName, id) {
    return async dispatch => {
        try {
            if (!tableName || !id) {
                throw new Error('Missing tablename or ID');
            }

            dispatch(startRequest(id));
            const data = await requestApi(tableName, id).get();

            dispatch(successRequest(id));
            dispatch(successFetchEntity({ tableName, data: [data] }));
        } catch (e) {
            dispatch(failRequest(e));
            dispatch(errorEntityRequest(e));
            throw e;
        } finally {
            dispatch(finishRequest(id));
        }
    };
}

export function createOrUpdateEntity(tableName, data) {
    return async dispatch => {
        try {
            const successMethod = data.id
                ? successUpdateEntity
                : successCreateEntity;

            const result = await requestApi(tableName).createOrUpdate(data);
            dispatch(successMethod({ tableName, data: [result] }));
        } catch (error) {
            const errorMethod = data.id ? errorUpdateEntity : errorCreateEntity;
            dispatch(errorMethod({ tableName }));
            throw error;
        }
    };
}

export function deleteEntity(tableName, id) {
    return dispatch =>
        requestApi(tableName, id)
            .delete()
            .then(resData =>
                dispatch(successEntityDelete({ tableName, data: resData }))
            )
            .catch(error => dispatch(errorEntityRequest(error)));
}
