import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleCheck,
    faCircleExclamation,
    faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@emotion/react';
import { TOAST_TYPES } from '../constants';
import { useTranslate } from '@mspecs/shared-utils';
import { LinkIt } from '../../link';
import { LoadingSpinner } from '../../animations';

const ToastRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 73px !important;
    background: ${({ theme }) => theme.colors.bgPrimary};
`;

const ToastIcon = styled.div`
    width: 30px;
    font-size: 30px;
    margin: 10px;
`;

const ToastMessage = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.textSecondary};
    justify-content: center;
`;

const ToastBtn = styled.div`
    width: 44px;
    height: 44px;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const ToastTitle = styled.h1`
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    font-weight: 400;
`;

const ToastContent = ({ title, body, close, className, type }) => {
    const { colors } = useTheme();
    const { t } = useTranslate();
    return (
        <ToastRow className={className}>
            <ToastIcon>
                {type === TOAST_TYPES.SUCCESS ? (
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: colors.green60 }}
                    />
                ) : type === TOAST_TYPES.ERROR ? (
                    <FontAwesomeIcon
                        icon={faCircleExclamation}
                        style={{ color: colors.redPrimary }}
                    />
                ) : type === TOAST_TYPES.LOADING ? (
                    <LoadingSpinner />
                ) : type === TOAST_TYPES.WARNING ? (
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        style={{ color: colors.pendingPrimary }}
                    />
                ) : (
                    <LoadingSpinner />
                )}
            </ToastIcon>
            <ToastMessage>
                <ToastTitle>{t(title)}</ToastTitle>
                {body && <LinkIt text={t(body)}></LinkIt>}
            </ToastMessage>
            <ToastBtn onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
            </ToastBtn>
        </ToastRow>
    );
};

ToastContent.propTypes = {
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    close: PropTypes.func.isRequired,
    type: PropTypes.string,
};

export default ToastContent;
