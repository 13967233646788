import React from 'react';
import PropTypes from 'prop-types';

export default (Component, propValueName = 'value') => {
    class InputState extends React.PureComponent {
        state = {
            [propValueName]: this.props[propValueName] || '',
        };
        debouncedTimeout = null;
        debouncedOnChange = x => x;

        clearMyTimeout = () => {
            if (this.debouncedTimeout) {
                this.debouncedTimeout = clearTimeout(this.debouncedTimeout);
            }
        };

        onChange = e => {
            const value = e.target ? e.target[propValueName] : e;
            const { debounceMs } = this.props;
            this.debouncedOnChange = () => this.props.onChange(e);
            this.setState({
                [propValueName]: value,
            });

            this.clearMyTimeout();

            if (debounceMs) {
                if (e.target) {
                    e.persist();
                }
                this.debouncedTimeout = setTimeout(() => {
                    this.debouncedOnChange();
                    this.debouncedTimeout = null;
                }, debounceMs);
            } else {
                this.props.onChange(e);
            }
        };

        componentWillUnmount() {
            this.clearMyTimeout();
            this.debouncedOnChange();
        }

        onBlur = e => {
            if (this.debouncedTimeout) {
                this.clearMyTimeout();
                this.props.onChange(e);
            }
            this.props.onBlur(e);
        };

        render() {
            const { hasOwnState, debounceMs, ...restProps } = this.props;
            return (
                <Component
                    {...restProps}
                    {...this.state}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                />
            );
        }
    }

    InputState.propTypes = {
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        hasOwnState: PropTypes.bool,
        debounceMs: PropTypes.number,
    };

    InputState.defaultProps = {
        onBlur: x => x,
        onChange: x => x,
        debounceMs: 1500,
        hasOwnState: false,
    };

    const IfHoc = props =>
        props.hasOwnState ? (
            <InputState {...props} />
        ) : (
            <Component {...props} />
        );

    return IfHoc;
};
