import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { wrapDisplayName } from 'recompose';
import omit from 'lodash-es/omit';
import { useTheme } from '@emotion/react';
import { useTranslate } from '@mspecs/shared-utils';
import SuffixSpan from '../../components/input/basic/suffix-span-component';
import InputHelperPopover from '../../components/popovers/input-helper-popover-component';
import { SubTextMedium } from '../../components/typograph/typograph-components';

export const errorPropKeys = [
    'errorMessage',
    'invalid',
    'inputWidth',
    'helpText',
];

const ErrorContainer = styled(Flex)`
    align-items: center;
    position: relative;
    flex: ${({ inputWidth }) => !inputWidth && '1'};
    width: 100%;
`;

export const ErrorIcon = styled.div`
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 20px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: none;
    outline: none;
    max-width: ${({ inputWidth }) =>
        inputWidth === 'small'
            ? '70px'
            : inputWidth === 'medium'
            ? '175px'
            : inputWidth === 'large'
            ? '271px'
            : 'initial'};
`;

const HelpText = styled(SubTextMedium)`
    padding-left: 10px;
    color: ${({ theme: { colors }, invalid }) =>
        invalid ? colors.errorColor : colors.textPrimary};
`;

export const ErrorWrapper = props => {
    const {
        suffix,
        errorMessage: _errorMessage,
        children,
        invalid,
        inputWidth,
        errorIconClassName,
        errorInputClassName,
        helpText,
    } = props;
    const [showError, setShowError] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (!invalid && showError) {
            setShowError(false);
        }
    }, [invalid]);

    const { t } = useTranslate();
    const errorMessage = t(_errorMessage);

    return (
        <Flex flexDirection="column" width="100%">
            <Flex>
                <ErrorContainer inputWidth={inputWidth}>
                    <InputHelperPopover
                        text={errorMessage}
                        visible={showError}
                        onClose={() => setShowError(false)}
                    >
                        <InputContainer
                            inputWidth={inputWidth}
                            tabIndex="-1"
                            css={errorInputClassName}
                        >
                            {children}
                        </InputContainer>
                    </InputHelperPopover>
                    {invalid && (
                        <ErrorIcon
                            onClick={() => setShowError(!showError)}
                            css={errorIconClassName}
                        >
                            <FontAwesomeIcon
                                color={theme.colors.errorColor}
                                icon={faExclamationCircle}
                            />
                        </ErrorIcon>
                    )}
                </ErrorContainer>
                <SuffixSpan suffix={suffix} />
            </Flex>
            {helpText && <HelpText>{t(helpText)}</HelpText>}
        </Flex>
    );
};

const withErrorWrapper = Component => {
    function WithErrorWrapper(props) {
        const componentProps = omit(props, [
            'suffix',
            'errorMessage',
            'helpText',
        ]);

        return (
            <ErrorWrapper {...props}>
                <Component {...componentProps} />
            </ErrorWrapper>
        );
    }

    WithErrorWrapper.displayName = wrapDisplayName(
        Component,
        'withErrorWrapper'
    );

    return WithErrorWrapper;
};

ErrorWrapper.propTypes = {
    invalid: PropTypes.bool,
    suffix: PropTypes.string,
    errorMessage: PropTypes.string,
    errorIconClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    errorInputClassName: PropTypes.string,
    errorRequiredClassName: PropTypes.string,
    inputWidth: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.node,
    helpText: PropTypes.string,
};

ErrorWrapper.defaultProps = {
    invalid: false,
    suffix: '',
    errorMessage: '',
};

export default withErrorWrapper;
