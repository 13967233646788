import { useReactiveVar } from '@apollo/client';
import { toastsVar } from '../stores';
import { removeToast } from '../services';
import { Toast } from '@mspecs/ui-components';

const ToastContainer = () => {
    const toasts = useReactiveVar(toastsVar);
    const toastMessages = Object.values(toasts);

    const messages = [
        ...toastMessages,
    ];

    const onClose = message => {
        removeToast(message);
    };
    return <Toast messages={messages} onClose={onClose} />;
};

export default ToastContainer;
