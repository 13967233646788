import styled from '@emotion/styled';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header5Text } from '@mspecs/ui-components';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';

const BackTextWrapper = styled(Flex)`
    align-items: center;
    cursor: pointer;
`;
const BackIcon = styled(FontAwesomeIcon)`
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} ${spacing.default} ${spacing.default} 0`};
`;

const NavigationBackButton = ({ label, ...props }) => {
    return (
        <BackTextWrapper {...props}>
            <BackIcon icon={faChevronLeft} />
            <Header5Text>{label}</Header5Text>
        </BackTextWrapper>
    );
};
NavigationBackButton.propTypes = {
    label: PropTypes.string,
};

export default NavigationBackButton;
