import { css } from '@emotion/react';
import { errorColor } from '@mspecs/shared-utils';

const successBorderClass = css`
    border-color: #82a76d;
`;

const errorBorderClass = css`
    border-color: ${errorColor};
`;

export const useSuccessErrorBorderProps = ({
    showSuccessBorder,
    showErrorBorder,
}) => {
    return {
        className: showSuccessBorder && 'field-saved',
        css: [
            showErrorBorder && errorBorderClass,
            showSuccessBorder && successBorderClass,
        ],
    };
};

const withBorders = WrappedComponent => {
    const WithBorders = ({ showSuccessBorder, showErrorBorder, ...props }) => {
        const borderProps = useSuccessErrorBorderProps({
            showSuccessBorder,
            showErrorBorder,
        });
        return <WrappedComponent {...borderProps} {...props} />;
    };
    return WithBorders;
};

export default withBorders;
