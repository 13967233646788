import React, { useState } from 'react';
import { compose, branch } from 'recompose';
import Downshift from 'downshift';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { InputField } from '../.';
import {
    withInputValidation,
    withFinalFormField,
    withValidationWrapper,
} from '../../../../utils/hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { triggerOnBlur } from '@mspecs/shared-utils';

const INPUT_HEIGHT = 34;
const LABEL_HEIGHT = 31;

const OptionsContainer = styled.div`
    background: #fff;
    position: absolute;
    top: ${({ hasLabel }) => INPUT_HEIGHT + (hasLabel ? LABEL_HEIGHT : 0)}px;
    left: 0;
    right: 0;
    z-index: 4;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    border-radius: 4px;
`;

const OptionsItem = styled.div`
    padding: 5px 24px;
    border-radius: ${({ isLast, isFirst }) =>
        isFirst ? '4px 4px 0 0' : isLast ? '0 0 4px 4px' : '0'};
    cursor: pointer;
    background-color: ${({ isHighlighted, isSelected, theme: { colors } }) =>
        isSelected
            ? colors.primaryColor10
            : isHighlighted
            ? colors.bgSecondary
            : 'inherit'};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'inherit')};
    color: ${({ theme }) => theme.colors.textPrimary};
`;

const StyledInput = styled(InputField)`
    padding-right: 44px;
`;

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.gray80 : theme.colors.textPrimary};
    font-size: 17px;
`;

const TextAutoSelectionInput = ({ items, value, onChange, ...props }) => {
    const [isManuallyOpened, setIsManuallyOpened] = useState(false);
    const { disabled } = props;

    const optionsRef = triggerOnBlur(
        () => setIsManuallyOpened(false),
        isManuallyOpened
    );

    const onManuallyOpen = () => {
        if (disabled) return;
        setIsManuallyOpened(true);
    };
    return (
        <Downshift
            {...props}
            onInputValueChange={value => {
                onChange(value);
                if (value) {
                    setIsManuallyOpened(false);
                }
            }}
            selectedItem={value}
        >
            {({
                getInputProps,
                getItemProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
            }) => {
                const filteredItems = items.filter(
                    ({ label }) =>
                        isManuallyOpened ||
                        label
                            .toLowerCase()
                            .startsWith?.(inputValue?.toLowerCase() || '')
                );

                return (
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div
                            style={{ position: 'relative', width: '100%' }}
                            ref={optionsRef}
                        >
                            <StyledInput
                                {...getInputProps(props)}
                                value={
                                    items.find(i => i.value === inputValue)
                                        ?.label ?? inputValue
                                }
                                onFocus={onManuallyOpen}
                                suffixIcon={
                                    <Icon
                                        icon={faPen}
                                        onClick={onManuallyOpen}
                                        disabled={disabled}
                                    />
                                }
                            />
                            {(isOpen || isManuallyOpened) &&
                                !!filteredItems.length && (
                                    <OptionsContainer
                                        className="downshift-options"
                                        hasLabel={Boolean(props.label)}
                                    >
                                        {filteredItems.map(
                                            ({ value, label }, index) => {
                                                const itemProps = getItemProps({
                                                    index,
                                                    item: value,
                                                });
                                                if (isManuallyOpened) {
                                                    itemProps.onClick = () => {
                                                        onChange(value);
                                                        setIsManuallyOpened(
                                                            false
                                                        );
                                                    };
                                                }

                                                return (
                                                    <OptionsItem
                                                        key={value}
                                                        {...itemProps}
                                                        isFirst={index === 0}
                                                        isLast={
                                                            index ===
                                                            filteredItems.length -
                                                                1
                                                        }
                                                        isHighlighted={
                                                            highlightedIndex ===
                                                            index
                                                        }
                                                        isSelected={
                                                            selectedItem ===
                                                            value
                                                        }
                                                    >
                                                        {label}
                                                    </OptionsItem>
                                                );
                                            }
                                        )}
                                    </OptionsContainer>
                                )}
                        </div>
                    </div>
                );
            }}
        </Downshift>
    );
};
export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(TextAutoSelectionInput);

TextAutoSelectionInput.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
    ).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
};
