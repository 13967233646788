import styled from '@emotion/styled';
import { slideInAndOutBlue, useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';

const Container = styled(Flex)`
    width: 100%;
`;

const Text = styled.h5`
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
`;

const AnimationContainer = styled.div`
    position: relative;
    width: 100%;
    height: 4px;
    overflow: hidden;
`;

const AnimationLine = styled.div`
    position: absolute;
    opacity: ${({ completed }) => (completed ? '1' : '0.2')};
    background: ${({ completed, error, theme }) =>
        completed
            ? theme.colors[error ? 'errorColor' : 'successColor']
            : '#337ab7'};
    width: 110%;
    height: 4px;
`;
const AnimationSubLine = styled.div`
    position: absolute;
    background: ${({ theme }) => theme.colors.primaryColor};
    height: 4px;
    animation: ${slideInAndOutBlue}2s infinite;
    -webkit-animation: ${slideInAndOutBlue} 2s infinite;
`;

const LoadingBar = ({ title, idle, completed, error, ...restProps }) => {
    const { t } = useTranslate();
    const isLoading = !completed && !idle;
    return (
        <Container
            {...restProps}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
        >
            {title && <Text>{t(title)}</Text>}
            <AnimationContainer>
                <AnimationLine completed={completed} error={error} />
                {isLoading && <AnimationSubLine />}
            </AnimationContainer>
        </Container>
    );
};

LoadingBar.propTypes = {
    title: PropTypes.string,
    idle: PropTypes.bool,
    completed: PropTypes.bool,
    error: PropTypes.bool,
};

export default LoadingBar;
