import {
    failRequest,
    successRequest,
    startRequest,
    finishRequest,
} from '../actions/request-actions';
import { ac } from '@mspecs/shared-utils';

const hasPendingRequest = (store, url) => {
    return store.getState().requests.pending[url];
};

/**
 * Redux middleware that prevents multiple request to the same URL
 *
 *  {
 *      type: 'WHATEVER',
 *      successActionType: 'success type'
 *      errorActionType: 'error type'
 *      req: legacyApi('whatever').mw().get(),
 *      options: { waitForPending: true }
 *  }
 */

const mspxFetchMW = store => next => action => {
    const { req, options } = action;

    // Pass through actions without the req parameter
    if (!action.req) {
        return next(action);
    }

    // Break if the request is pending.
    if (options.waitforPending && hasPendingRequest(store, req.fullUrl)) {
        return;
    }

    const successAction = !!action.successActionType
        ? data => ac(action.successActionType, data)
        : successRequest;

    const errorAction = !!action.errorActionType
        ? err => ac(action.errorActionType, err)
        : failRequest;

    store.dispatch(startRequest(req.fullUrl));

    return req
        .exec()
        .then(data => {
            store.dispatch(successAction(data));
            return data;
        })
        .catch(err => store.dispatch(errorAction(err)))
        .finally(() => {
            store.dispatch(finishRequest(req.fullUrl));
        });
};

export default mspxFetchMW;
