import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

export const LineContainer = styled(Flex)`
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    width: 26px;
    margin-top: 2px;
    margin-right: 1em;
    cursor: pointer;
    display: inline-flex;
`;

export const Line = styled.span`
    display: inline-block;
    width: 100%;
    border-top: solid 2px ${({ theme }) => theme.colors.borderPrimary};
    height: 6px;
`;

const DragIcon = props => (
    <LineContainer {...props}>
        <Line />
        <Line />
        <Line />
    </LineContainer>
);

export default DragIcon;
