import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '../input';

export const CheckboxTreeWrapper = styled.div`
    font-size: 13.3px;
`;

export const TreeRow = styled(Flex)`
    align-items: center;
    margin-bottom: 4px;
`;

export const CheckboxWrapper = styled.span`
    padding: 5px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: 0 5px;
    }
`;

export const Label = styled.label`
    display: flex;
    flex-grow: 1;
    vertical-align: middle;
    align-self: stretch;
    align-items: center;
`;

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
`;
export const StyledListItem = styled.li`
    ${StyledList} {
        padding-left: 45px;
    }
`;

export const ExpandIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const StyledCheckbox = styled(Checkbox)`
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        min-height: 20px;
        max-height: 20px;
    }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.gray120};
    font-size: 21px;
`;

export const DraggableListRow = styled.div`
    padding: 0 7px;
    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor5};
    }
`;
