import React from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const Icon = ({ isOpen, ...props }) => (
    <StyledIcon icon={isOpen ? faCaretUp : faCaretDown} {...props} />
);

const DropdownIndicator = props => {
    const {
        onMenuOpen,
        menuIsOpen,
        onMenuClose,
        isSearchable,
        isExplicitSearchBar,
    } = props.selectProps;
    const onClick = () => {
        menuIsOpen ? onMenuClose() : onMenuOpen();
    };

    if (isSearchable && isExplicitSearchBar)
        return (
            <Icon
                onClick={onClick}
                style={{ padding: '8px' }}
                isOpen={props.selectProps.menuIsOpen}
            />
        );
    return (
        <components.DropdownIndicator {...props}>
            <Icon isOpen={props.selectProps.menuIsOpen} />
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;
