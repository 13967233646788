import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { changeLanguage, currentLanguage } from '@mspecs/shared-utils';

import sweFlag from '@mspecs/ui-components/src/assets/images/sv.png';
import engFlag from '@mspecs/ui-components/src/assets/images/eng.png';

const LANGUAGES = {
    SV: { isoCode: 'sv', flag: sweFlag },
    EN: { isoCode: 'en', flag: engFlag },
};

const FlagsContainer = styled(Flex)`
    position: absolute;
    top: 24px;
    right: 24px;
    @media (max-width: ${({ theme }) => theme.medium}) {
        position: absolute;
        top: 8px;
        right: 6px;
    }
`;

const Flag = styled.img`
    margin: 0 3px;
    width: 20px;
    height: 20px;
    opacity: ${props => (props.active ? '1' : '0.7')};

    :hover {
        cursor: pointer;
        opacity: 1;
    }

    @media (max-width: ${({ theme }) => theme.medium}) {
        margin: 0 4px;
        width: 28px;
        height: 28px;
    }
`;

const Flags = () => {
    return (
        <FlagsContainer justifyContent="flex-end">
            {Object.values(LANGUAGES).map((flags, index) => {
                return (
                    <Flag
                        key={index}
                        src={flags.flag}
                        active={currentLanguage() === flags.isoCode}
                        onClick={() => changeLanguage(flags.isoCode)}
                    />
                );
            })}
        </FlagsContainer>
    );
};

export default Flags;
