import generateColorHsl from './styles/color/hsl-color-generator';

export const getDisplayName = contact => {
    if (!contact) {
        return '';
    }

    if (contact.isLegalEntity) {
        return contact.companyName;
    }

    const name = [contact.firstName, contact.lastName].filter(x => x);
    return name.join(' ');
};

export const getContactColor = contact =>
    generateColorHsl(`${contact.firstName} ${contact.lastName}`);
