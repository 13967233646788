import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getObjectTypeIcon } from '@mspecs/shared-utils';
import styled from '@emotion/styled';

const ObjectTypeIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    width: 20px;
`;

const objectTypeIconRenderer = ({ value }) => {
    const objectTypeIcon = getObjectTypeIcon(value);

    if (!objectTypeIcon) return null;

    return <ObjectTypeIcon icon={objectTypeIcon} />;
};

export default objectTypeIconRenderer;
