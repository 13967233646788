import React from 'react';
import DisplayUnsafeHtml from '../display/display-unsafe-html-component';
import { useTheme } from '@emotion/react';
const urlPattern =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/gm;

export const LinkIt = ({ text }) => {
    const theme = useTheme();
    const addAnchorTags = messageString => {
        if (!messageString) return;
        const newText = messageString.replaceAll(urlPattern, url => {
            const cleanedUrl = url.replace(/(http|https):\/\//, '');
            return `<a href='//${cleanedUrl}' target='_blank' style='color: ${theme.colors.primaryColor}; text-decoration: underline;'> ${cleanedUrl}</a>`;
        });
        return `<p>${newText}</p>`;
    };

    return (
        <DisplayUnsafeHtml
            profile={{
                ALLOWED_TAGS: ['a'],
                ALLOWED_ATTR: ['href', 'style', 'target'],
            }}
        >
            {addAnchorTags(text)}
        </DisplayUnsafeHtml>
    );
};
