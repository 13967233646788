import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpArrowDown,
    faArrowUpLong,
    faArrowDownLong,
} from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { css } from '@emotion/react';
import { Box } from '@rebass/grid/emotion';
import { useTranslate } from '@mspecs/shared-utils';
import styled from '@emotion/styled';
import { LoadingBar } from '../../animations';
import { TooltipPopover } from '../../popovers';
import { TEXT_STYLES } from '../../typograph/typograph-components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const SortIcon = styled(FontAwesomeIcon)`
    padding-left: ${({ theme }) => theme.spacing.small};
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const InfoIcon = styled(FontAwesomeIcon)`
    color: ${({ theme, isTooltipVisible }) =>
        isTooltipVisible
            ? theme.colors.textPrimary
            : theme.colors.textSecondary};
    font-size: 20px;
    :hover {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`;

const Item = styled.div`
    span {
        ${({ theme }) => TEXT_STYLES.helpingTextMedium(theme)}
        font-weight: 600 !important;
        &::first-letter {
            text-transform: capitalize;
        }
    }
`;

const HeaderItem = ({
    disableSort,
    isColumnDragEnabled,
    column,
    index,
    theme,
}) => {
    const { t } = useTranslate();
    const columnHeader = column.render('Header');
    const [isHelpTooltipVisible, setIsHelpTooltipVisible] = useState(false);

    const isSortEnabled = !disableSort && !column.disableSort;

    return (
        <Draggable
            isDragDisabled={
                !isColumnDragEnabled || column.disableDrag || !column.id
            }
            index={index}
            draggableId={column.id}
        >
            {provided => (
                <Item
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className={`rt-th ${isSortEnabled ? ' --sortable' : ''} ${
                        column.isSorted
                            ? `--is-sorted ${
                                  column.isSortedDesc
                                      ? '-sort-desc'
                                      : '-sort-asc'
                              }`
                            : ''
                    }`}
                    {...(!disableSort && !column.disableSort
                        ? column.getSortByToggleProps()
                        : {})}
                    {...column.getHeaderProps({
                        style: provided.draggableProps.style,
                    })}
                    css={[
                        column.headerCss || column.css,
                        isColumnDragEnabled && !column.disableDrag && column.id
                            ? css`
                                  background-color: ${theme.colors
                                      .primaryColor} !important;
                                  color: ${theme.colors.white};
                                  border-right: dashed 1px ${theme.colors.white};
                                  &:hover {
                                      background-color: ${theme.colors
                                          .contrastBg85};
                                  }
                              `
                            : null,
                        column.style,
                    ]}
                >
                    <span>
                        {typeof columnHeader === 'string'
                            ? t(columnHeader)
                            : columnHeader}
                    </span>
                    {isSortEnabled ? (
                        <SortIcon
                            icon={
                                column.isSorted
                                    ? column.isSortedDesc
                                        ? faArrowUpLong
                                        : faArrowDownLong
                                    : faArrowUpArrowDown
                            }
                            fontSize={column.isSorted ? '13px' : '15px'}
                        />
                    ) : null}
                    {column.helpText && (
                        <TooltipPopover
                            placement="right"
                            text={column.helpText}
                            isTooltipVisible={isHelpTooltipVisible}
                        >
                            <Box
                                pl={1}
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsHelpTooltipVisible(prev => !prev);
                                }}
                            >
                                <InfoIcon icon={faInfoCircle} />
                            </Box>
                        </TooltipPopover>
                    )}
                </Item>
            )}
        </Draggable>
    );
};

HeaderItem.propTypes = {
    disableSort: PropTypes.bool,
    isColumnDragEnabled: PropTypes.bool,
    column: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};

const Header = ({
    setColumnOrder,
    onColumnOrderChange,
    headerGroups = [],
    theme,
    disableSort,
    isColumnDragEnabled,
    loading,
    columns,
}) => {
    const currentColOrder = useRef();

    useEffect(() => {
        currentColOrder.current = columns.map(o => o.accessor);
    }, [columns]);

    const onHeaderDragEnd = useCallback(
        dragUpdateObject => {
            const colOrder = [...currentColOrder.current];
            const startIndex = dragUpdateObject.source.index;
            const dropIndex = dragUpdateObject.destination?.index;

            if ([startIndex, dropIndex].every(n => typeof n === 'number')) {
                colOrder.splice(startIndex, 1);
                colOrder.splice(dropIndex, 0, dragUpdateObject.draggableId);

                setColumnOrder(colOrder);
                onColumnOrderChange?.(colOrder);
                currentColOrder.current = colOrder;
            }
        },
        [currentColOrder.current, onColumnOrderChange]
    );

    return (
        <ScrollSyncPane>
            <div className="rt-thead -header">
                <DragDropContext onDragEnd={onHeaderDragEnd}>
                    {headerGroups.map(headerGroup => (
                        <Droppable
                            droppableId="droppable"
                            key={headerGroup.getHeaderGroupProps().key}
                            direction="horizontal"
                        >
                            {droppableProvider => (
                                <div
                                    ref={droppableProvider.innerRef}
                                    className="rt-tr"
                                    {...headerGroup.getHeaderGroupProps({
                                        style: {
                                            backgroundColor:
                                                theme.colors.contrastBg85,
                                        },
                                    })}
                                >
                                    {headerGroup.headers.map(
                                        (column, index) => (
                                            <HeaderItem
                                                key={
                                                    column.getHeaderProps().key
                                                }
                                                column={column}
                                                index={index}
                                                theme={theme}
                                                disableSort={disableSort}
                                                isColumnDragEnabled={
                                                    isColumnDragEnabled &&
                                                    !column.sticky
                                                }
                                            />
                                        )
                                    )}
                                    {droppableProvider.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
                {loading && <LoadingBar />}
            </div>
        </ScrollSyncPane>
    );
};

Header.propTypes = {
    allColumns: PropTypes.array,
    setColumnOrder: PropTypes.func,
    onColumnOrderChange: PropTypes.func,
    headerGroups: PropTypes.array,
    theme: PropTypes.object.isRequired,
    disableSort: PropTypes.bool,
    isColumnDragEnabled: PropTypes.bool,
    loading: PropTypes.bool,
    columns: PropTypes.array.isRequired,
};

export default Header;
