import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { Button, BUTTON_TYPES } from '..';
import { useTranslate } from '@mspecs/shared-utils';

const StyledButton = styled(Button)`
    color: ${({ theme }) => theme.colors.textPrimary};
`;

const CrossButton = props => {
    const { t } = useTranslate();
    return (
        <StyledButton
            aria-label={t('CLOSE')}
            buttonType={BUTTON_TYPES.TEXT}
            {...props}
        >
            <FontAwesomeIcon icon={faTimes} size="xl" />
        </StyledButton>
    );
};
export default CrossButton;
