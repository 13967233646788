import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Navigate, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PublicLoginRoute from '~/routing/public-login-route-component';
import LoginPage from '~/pages/login-page-component';
import OrderMspxPage from '~/pages/order-mspx-page-component';
import OrderMspxFormPage from '~/pages/order-mspx-form-page-component';
import ChangePasswordPage from '~/pages/change-password-page-component';
import { setTranslationsLoaded } from '~/redux/actions/translation-actions';
import { i18n } from '@mspecs/shared-utils';

const PublicLibRouter = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const onLoaded = () => dispatch(setTranslationsLoaded(true));
        i18n.on('translations-loaded', onLoaded);
        return () => {
            i18n.off('translations-loaded', onLoaded);
        };
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/login/resetPassword/:token"
                    element={
                        <PublicLoginRoute>
                            <ChangePasswordPage />
                        </PublicLoginRoute>
                    }
                />
                <Route
                    path="/login"
                    element={
                        <PublicLoginRoute>
                            <LoginPage />
                        </PublicLoginRoute>
                    }
                />
                <Route path="/register" element={<OrderMspxPage />} />
                <Route
                    path="/register/:signupToken"
                    element={<OrderMspxFormPage />}
                />
                <Route
                    path="*"
                    element={
                        <Navigate
                            from="/"
                            to={`/login${window.location.search}`}
                            replace
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default PublicLibRouter;
