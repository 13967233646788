import { css } from '@emotion/react';
import { Flex } from '@rebass/grid/emotion';
import ToastImageEditor from '@toast-ui/react-image-editor';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageProp } from '../image.props';
import './image-editor-style.css';
import { useImageUploadLocale } from './locale';
import { white } from './themes';
import { LoadingAnimation } from '@mspecs/shared-utils';

const VERTICAL_PADDING = 96;
const HORIZONTAL_PADDING = 64;

const ImageEditor = forwardRef(
    (
        {
            image,
            editorProps = {},
            isFullscreen = false,
            children,
            displayLoader,
            ...props
        },
        editorRef
    ) => {
        const containerRef = useRef(null);
        const locale = useImageUploadLocale();

        const [maxWidth, setMaxWidth] = useState();
        const [maxHeight, setMaxHeight] = useState();
        const [isLoading, setIsLoading] = useState(false);
        const [isLoaded, setIsLoaded] = useState(false);

        useEffect(() => {
            const width = containerRef.current?.offsetWidth;
            const height = isFullscreen
                ? window.innerHeight
                : containerRef.current?.offsetHeight;
            setMaxWidth(width - (isFullscreen ? HORIZONTAL_PADDING : 0));
            setMaxHeight(height - VERTICAL_PADDING);
        }, [containerRef.current]);

        useEffect(() => {
            if (!displayLoader) return;
            const editor = editorRef?.current?.getInstance?.();
            if (editor && !isLoading && !isLoaded) {
                setIsLoading(true);
                editor
                    .loadImageFromURL(
                        image.originalURI,
                        image.title || image.originalURI
                    )
                    .then(() => {
                        editor.ui.initializeImgUrl = image.originalURI;
                        editor.clearUndoStack();
                        editor.ui.activeMenuEvent();
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setIsLoaded(true);
                    });
            }
        }); // Leaving out dependancy array on purpose

        return (
            <Flex
                width="100%"
                flexDirection="column"
                ref={containerRef}
                style={{ position: 'relative' }}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                {...props}
            >
                {isLoading && (
                    <LoadingAnimation
                        css={css`
                            position: absolute;
                            z-index: 2;
                            width: 100%;
                        `}
                    >
                        LOADING
                    </LoadingAnimation>
                )}
                {maxWidth && (
                    <ToastImageEditor
                        ref={editorRef}
                        includeUI={{
                            ...(displayLoader
                                ? {}
                                : {
                                      loadImage: {
                                          path: image.originalURI,
                                          name:
                                              image.title || image.originalURI,
                                      },
                                  }),
                            locale,
                            theme: white,
                            menu: ['crop', 'filter', 'rotate', 'resize'],
                            menuBarPosition: 'left',
                        }}
                        usageStatistics={false}
                        cssMaxWidth={maxWidth}
                        cssMaxHeight={maxHeight}
                        {...editorProps}
                    />
                )}
                {children}
            </Flex>
        );
    }
);

ImageEditor.propTypes = {
    image: ImageProp,
    editorProps: PropTypes.object,
    children: PropTypes.any,
    isFullscreen: PropTypes.bool,
    displayLoader: PropTypes.bool,
};
export default ImageEditor;
