import React from 'react';
import { branch, compose } from 'recompose';
import omit from 'lodash-es/omit';
import withBorders from './with-borders-hoc';

const falseBorders = {
    successBorder: false,
    errorBorder: false,
};

/*
    Adds a notificationborder to a component on Blur
*/
const withConfirmationBorders = WrappedComponent => {
    return class BorderSaveStatus extends React.Component {
        state = {
            ...falseBorders,
        };

        componentDidUpdate(prevProps, prevState) {
            if (
                !prevState.successBorder &&
                !prevState.errorBorder &&
                (this.state.successBorder || this.state.errorBorder)
            ) {
                setTimeout(() => this.resetBorders(), 1500);
            }
        }

        resetBorders() {
            this.setState(falseBorders);
        }

        onBlurChange = (value, e) => {
            const { onBlurChange } = this.props;
            if (onBlurChange) {
                const promise = onBlurChange(value, e);
                if (promise) {
                    promise
                        .then(() => {
                            this.setState({ successBorder: true });
                        })
                        .catch(() => {
                            this.setState({ errorBorder: true });
                        });
                }
            }
        };

        render() {
            const { errorBorder, successBorder } = this.state;
            const componentProps = omit(this.props, ['onBlurChange']);
            return (
                <WrappedComponent
                    {...componentProps}
                    onBlur={this.onBlurChange}
                    showSuccessBorder={successBorder}
                    showErrorBorder={errorBorder}
                />
            );
        }
    };
};

export default branch(
    /**
     * Use this HOC only if onBlurChange is passed.
     * Otherwise, pass through
     */
    ({ onBlurChange }) => onBlurChange,
    compose(withConfirmationBorders, withBorders)
);
