import { useMemo } from 'react';

const useCheckboxTree = (nodes, onChecked, checked) => {
    const flattenedNodes = useMemo(() => {
        return nodes.reduce(
            (acc, { items, ...curr }) => [
                ...acc,
                {
                    ...curr,
                    ...(items
                        ? {
                              childValues: items.map(c => c.value),
                          }
                        : {}),
                },
                ...(items
                    ? items.map(c => ({
                          ...c,
                          parentValue: curr.value,
                      }))
                    : []),
            ],
            []
        );
    }, [nodes]);

    const handleChecked = ({ target: { value } }) => {
        const selectedNode = flattenedNodes.find(n => n.value === value);
        // Checked leaf node checkbox is clicked, remove its value
        if (checked.includes(value)) {
            onChecked(checked.filter(v => v !== value));
        } else if (selectedNode.childValues) {
            // Checkbox with child values is clicked
            // Are all child nodes selected? - Remove them
            if (selectedNode.childValues.every(v => checked.includes(v))) {
                onChecked(
                    checked.filter(v => !selectedNode.childValues.includes(v))
                );
            } else {
                // Are only some checkboxes checked? - Check all
                onChecked([
                    ...checked,
                    ...selectedNode.childValues.filter(
                        value => !checked.includes(value)
                    ),
                ]);
            }
        } else {
            // Unchecked leaf node checkbox is clicked - add the value
            onChecked(checked.concat(value));
        }
    };

    return { flattenedNodes, handleChecked };
};

export default useCheckboxTree;
