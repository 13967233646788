import { ac } from '@mspecs/shared-utils';
import { loginOffice365 } from '../../utils/services/office-365-service';

/*
    This is really the o365 login flow, atm a login is issued and a popup is opned, redirecting to
    the o365 login-page. When the login is done the user needs to click a button in the main mspx ui
    to request the new session data (as this is where the login information is stored atm).

    TODO: The logic whould be handled through polling or websockets in the long run.
*/

export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';

function loginRedirect() {
    return ac(LOGIN_REDIRECT);
}

export function cancelLogin() {
    return ac(LOGIN_DONE);
}

export function loginDone() {
    return async dispatch => {
        dispatch(cancelLogin());
    };
}

export function issueLoginRedirect() {
    return async dispatch => {
        const redirectUrl = `https://${window.location.host}/pub/closeAfterLogin.html`;

        await loginOffice365({
            callbackUri: redirectUrl,
        });
        dispatch(loginRedirect());
    };
}
