import { useEffect, useState } from 'react';

const useScrollProgress = ({ ref, numberOfElements }) => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        if (!ref?.current) return;

        const element = ref?.current;

        const scrollListener = () => {
            const windowScroll = element.scrollLeft;
            const totalWidth = element.scrollWidth - element.clientWidth;

            if (windowScroll === 0) setScrollProgress(0);
            if (windowScroll > totalWidth) setScrollProgress(100);
            return setScrollProgress((windowScroll / totalWidth) * 100);
        };

        element.addEventListener('scroll', scrollListener, {
            passive: true,
        });
        return () => element.removeEventListener('scroll', scrollListener);
    }, [ref]);

    const activeElement = Math.floor((scrollProgress * numberOfElements) / 110);

    return activeElement;
};

export default useScrollProgress;
