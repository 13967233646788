import { useTranslate } from '@mspecs/shared-utils';

export const useImageUploadLocale = () => {
    const { t } = useTranslate();
    return {
        Crop: t('IMAGE_EDITOR_CROP'),
        Rotate: t('IMAGE_EDITOR_ROTATE'),
        Resize: t('IMAGE_EDITOR_RESIZE'),
        Filter: t('IMAGE_EDITOR_FILTER'),
        Apply: t('IMAGE_EDITOR_APPLY'),
        Cancel: t('CANCEL'),

        // Crop
        Square: t('IMAGE_EDITOR_SQUARE'),
        Custom: t('IMAGE_EDITOR_CUSTOM'),

        // Resize
        'Lock Aspect Ratio': t('IMAGE_EDITOR_KEEP_ASPECT_RATIO'),
        Width: t('IMAGE_EDITOR_WIDTH'),
        Height: t('IMAGE_EDITOR_HEIGHT'),

        // Filter
        Grayscale: t('IMAGE_EDITOR_GRAYSCALE'),
        Invert: t('IMAGE_EDITOR_INVERT'),
        Sepia: t('IMAGE_EDITOR_SEPIA'),
        Sepia2: t('IMAGE_EDITOR_SEPIA2'),
        Blur: t('IMAGE_EDITOR_BLUR'),
        Sharpen: t('IMAGE_EDITOR_SHARPEN'),
        Emboss: t('IMAGE_EDITOR_EMBOSS'),
        'Remove White': t('IMAGE_EDITOR_REMOVE_WHITE'),
        Distance: t('IMAGE_EDITOR_DISTANCE'),
        Brightness: t('IMAGE_EDITOR_BRIGHTNESS'),
        Noise: t('IMAGE_EDITOR_NOISE'),
        Pixelate: t('IMAGE_EDITOR_PIXELATE'),
        'Color Filter': t('IMAGE_EDITOR_COLOR_FILTER'),
        Threshold: t('IMAGE_EDITOR_THRESHOLD'),

        // General tools
        ZoomIn: t('IMAGE_EDITOR_ZOOM_IN'),
        ZoomOut: t('IMAGE_EDITOR_ZOOM_OUT'),
        Hand: t('IMAGE_EDITOR_HAND'),
        History: t('IMAGE_EDITOR_HISTORY'),
        Undo: t('IMAGE_EDITOR_UNDO'),
        Redo: t('IMAGE_EDITOR_REDO'),
        Reset: t('IMAGE_EDITOR_RESET'),
        Delete: t('REMOVE'),
        DeleteAll: t('IMAGE_EDITOR_DELETE_ALL'),
    };
};
