import React, { useState, ComponentType, FC } from 'react';

interface WithOnBlurChangeProps {
    onBlurChange?: (
        value: string,
        event: React.FocusEvent<HTMLInputElement>
    ) => Promise<void> | void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    invalid?: boolean;
    saved?: boolean;
    value?: string;
}

const withOnBlurChange = <P extends object>(
    InputComponent: ComponentType<P>
): FC<P & WithOnBlurChangeProps> => {
    const WithOnBlurChange: FC<P & WithOnBlurChangeProps> = ({
        onBlurChange,
        ...props
    }) => {
        const [isValid, setIsValid] = useState(true);
        const [isSaved, setIsSaved] = useState(false);

        const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
            if (!onBlurChange) return;
            const value = props?.value ?? '';
            const promise = onBlurChange(value, e);

            if (!promise) return;

            try {
                await promise;
                setIsValid(true);
                setIsSaved(true);
                setTimeout(() => {
                    setIsSaved(false);
                }, 2000);
            } catch {
                setIsValid(false);
            }

            if (props?.onBlur) {
                props.onBlur(e);
            }
        };

        return (
            <InputComponent
                {...(props as P)}
                invalid={props.invalid || !isValid}
                saved={props.saved || isSaved}
                onBlur={handleBlur}
            />
        );
    };

    return WithOnBlurChange;
};

export default withOnBlurChange;
