import { ACTION_TYPES } from '../actions/memoPad-actions';

const initialState = {
    data: {},
};

export default function memoPads(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_MEMO_PADS_SUCCESS: {
            return {
                ...state,
                data: action.payload.memoPads,
            };
        }
        case ACTION_TYPES.DELETE_MEMO_PADS_SUCCESS: {
            /* eslint-disable no-unused-vars */
            const {
                data: {
                    [action.payload.memoPadId]: deleted,
                    ...stateWithoutDeleted
                },
            } = state;
            /* eslint-enable no-unused-vars */
            return {
                ...state,
                data: {
                    ...stateWithoutDeleted,
                },
            };
        }
        case ACTION_TYPES.CREATE_MEMO_PADS_SUCCESS:
        case ACTION_TYPES.UPDATE_MEMO_PADS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.memoPad.id]: action.payload.memoPad,
                },
            };
        }

        default:
            return state;
    }
}
