import styled from '@emotion/styled';
import { ROW_HEIGHT, SEARCH_HEIGHT } from './styles-config';

const StyledMenu = styled.div<{
    invalid?: boolean;
    position?: 'left' | 'right';
    length: number;
    top: string;
    left: string;
}>`
    position: absolute;
    z-index: 9001;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100%;
    background: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    box-shadow: ${({ theme }) => theme.boxShadows.default};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        ${({ theme: { spacing }, length, top, left }) => `
    margin-top: ${spacing.small};
    z-index: 2;
    height: ${length * ROW_HEIGHT + SEARCH_HEIGHT}px;
    max-height: 230px;
    width: 271px;
    top: ${top};
    left: ${left};
      `}
    }
`;

export default StyledMenu;
