import PropTypes from 'prop-types';

import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';
import { BodyTextRegular } from '../typograph/typograph-components';

const InfoWrapper = styled(Flex)`
    padding-top: ${({ theme }) => theme.spacing.small};
    align-items: center;
`;

const InfoIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.default};
`;

const FormInfoText = ({ children }) => {
    return (
        <InfoWrapper>
            <InfoIcon icon={faInfoCircle} />
            <BodyTextRegular>{children}</BodyTextRegular>
        </InfoWrapper>
    );
};

FormInfoText.propTypes = {
    children: PropTypes.any,
};

export default FormInfoText;
