import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tippy from '@tippy.js/react';
import { useTheme } from '@emotion/react';
import { TEXT_STYLES } from '../typograph/typograph-components';

const getBackgroundColor = ({
    theme: { colors } = {},
    popoverTheme = undefined,
    backgroundColor = undefined,
}) =>
    popoverTheme === 'primary'
        ? `var(--mspx-datepicker-popover-background, ${colors.primaryColor})`
        : backgroundColor || colors.primaryColor10;

export const StyledPopover = styled(Tippy, {
    shouldForwardProp: prop =>
        !['popoverTheme', 'backgroundColor'].includes(prop),
})`
    padding: ${({ theme }) => theme.spacing.default};
    .tippy-content {
        padding: 0;
    }
    .tippy-arrow {
        transform: scale(1.8);
    }
    &.tippy-tooltip {
        ${({ theme }) => TEXT_STYLES.highlightedBodyText(theme)};
        background-color: ${getBackgroundColor};
        text-align: left;
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        box-shadow: ${({ theme }) => theme.boxShadows.default};
        cursor: default;
        color: ${({ theme: { colors }, popoverTheme }) =>
            popoverTheme === 'primary' ? colors.white : colors.textPrimary};

        &[data-placement^='bottom'] > .tippy-arrow {
            border-bottom-color: ${getBackgroundColor};
        }

        &[data-placement^='top'] > .tippy-arrow {
            border-top-color: ${getBackgroundColor};
        }

        &[data-placement^='left'] > .tippy-arrow {
            border-left-color: ${getBackgroundColor};
        }

        &[data-placement^='right'] > .tippy-arrow {
            border-right-color: ${getBackgroundColor};
        }
    }
`;

export const StyledContrastPopover = props => {
    const theme = useTheme();
    return (
        <StyledPopover backgroundColor={theme.colors.contrastBg90} {...props} />
    );
};

const MspxPopover = props => {
    const { primary, useBackdrop, ...rest } = props;

    const backdropProps = useBackdrop
        ? {
              appendTo: document.body,
              className: `${props.className} --has-popper-backdrop`,
          }
        : {};

    return (
        <StyledPopover
            animation="fade"
            delay={500}
            duration={500}
            popoverTheme={primary ? 'primary' : undefined}
            {...rest}
            {...backdropProps}
        />
    );
};

MspxPopover.propTypes = {
    animation: PropTypes.string,
    arrow: PropTypes.bool,
    className: PropTypes.string,
    content: PropTypes.object,
    delay: PropTypes.array,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    hideOnClick: PropTypes.bool,
    interactive: PropTypes.bool,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    placement: PropTypes.string,
    showOnCreate: PropTypes.bool,
    visible: PropTypes.bool,
    primary: PropTypes.bool,
    backgroundColor: PropTypes.string,
    useBackdrop: PropTypes.bool,
    children: PropTypes.node,
    enabled: PropTypes.bool,
    distance: PropTypes.number,
};

MspxPopover.defaultProps = {
    animation: 'shift-away',
    arrow: false,
    className: '',
    content: '',
    delay: [0, 20],
    duration: [275, 250],
    hideOnClick: true,
    interactive: false,
    maxWidth: 350,
    placement: 'top',
    showOnCreate: false,
    visible: true,
    useBackdrop: false,
};

export default MspxPopover;
