import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUploadPropType } from './multi-file-upload-prop-types';
import { redPrimary } from '@mspecs/shared-utils';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { LoadingBar } from '../../../animations';

const FileItem = styled(Flex)`
    padding: 5px;
    border-bottom: ${({ isLast, theme }) =>
        isLast ? 0 : `1px solid ${theme.colors.bgSecondary}`};
    align-items: center;
`;
const FileImage = styled.img`
    padding-right: 5px;
`;
const FileName = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
    @media (min-width: ${({ theme }) => theme.large}) {
        width: 175px;
    }
}`;

const FileProgess = styled(LoadingBar)`
    padding: 0 10px;
`;

const RemoveButton = styled(FontAwesomeIcon)`
    font-size: 1.2rem;
    cursor: pointer;
    color: #a1a1a1;
    :hover {
        color: ${redPrimary};
    }
`;

const MultiFileUploadListItem = ({ file, isLast, onRemove }) => {
    const ref = useRef();

    useEffect(() => {
        if (file.isLoading && ref?.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [file.isLoading, ref]);

    return (
        <FileItem key={file.identifier} isLast={isLast} ref={ref}>
            <Flex alignItems="center">
                <FileImage
                    src={file.objectUrl}
                    height="40"
                    width="60"
                    alt={file.name}
                />
                <FileName>{file.name}</FileName>
            </Flex>
            <FileProgess
                idle={!file.isLoading}
                completed={!file.isLoading && (file.isError || file.isSuccess)}
                error={file.isError}
            />
            <RemoveButton icon={faMinusCircle} onClick={onRemove} size="1x" />
        </FileItem>
    );
};

MultiFileUploadListItem.propTypes = {
    file: FileUploadPropType.isRequired,
    onRemove: PropTypes.func.isRequired,
    isLast: PropTypes.bool,
};

export default MultiFileUploadListItem;
