import { useEffect } from 'react';

export default function useKeydown(onKeyDown, key) {
    useEffect(() => {
        const onKeydown = event => {
            if (event.key === key) {
                onKeyDown();
            }
        };
        document.addEventListener('keydown', onKeydown, true);

        return () => {
            document.removeEventListener('keydown', onKeydown, true);
        };
    }, [onKeyDown, key]);
}
