import { useTranslate } from '@mspecs/shared-utils';
import { prefixEnum } from '@mspecs/shared-utils';

const EnumCellRenderer = ({ value }) => {
    const { t } = useTranslate();
    if (!value) return '';
    return t(prefixEnum(value));
};

export default EnumCellRenderer;
