import React from 'react';
import { rankWith, isBooleanControl } from '@jsonforms/core';
import { Flex } from '@rebass/grid/emotion';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { BooleanSelect, Checkbox } from '../../../input';

function BooleanCell(props) {
    const { path, data, handleChange } = props;

    const baseProps = {
        ...getCellProps(props),
        innerLabel: props.innerLabel,
        dropDown: props.dropDown,
    };
    const isSelected = !!data;

    return (
        <Flex width={['100%']}>
            {baseProps.dropDown || baseProps.required ? (
                <BooleanSelect
                    {...baseProps}
                    onChange={value => handleChange(path, value)}
                    isClearable={false}
                />
            ) : (
                <Checkbox
                    {...baseProps}
                    onChange={e => handleChange(path, e.target.checked)}
                    checked={isSelected}
                />
            )}
        </Flex>
    );
}

BooleanCell.propTypes = CellPropType;

export const tester = rankWith(2, isBooleanControl);

export default { renderer: withInputControlHoc(BooleanCell), tester };
