import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScrollSyncPane } from 'react-scroll-sync';
import { VariableSizeList } from 'react-window';
import { useAutoSizer } from '@mspecs/shared-utils';

const ResizeableList = forwardRef(({ style = {}, ...props }, ref) => {
    const resizeRef = useRef(null);
    const { width, height } = useAutoSizer(resizeRef);

    return (
        <div
            ref={resizeRef}
            style={{ flex: '1 1 auto', overflow: 'hidden', ...style }}
        >
            {props.scrollSync ? (
                <ScrollSyncPane>
                    <VariableSizeList
                        {...props}
                        ref={ref}
                        width={width}
                        height={height}
                    />
                </ScrollSyncPane>
            ) : (
                <VariableSizeList
                    {...props}
                    ref={ref}
                    width={width}
                    height={height}
                />
            )}
        </div>
    );
});

ResizeableList.propTypes = {
    scrollSync: PropTypes.bool,
};

export default ResizeableList;
