import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@mspecs/shared-utils';

const MspxPdfError = props => {
    return (
        <span className={props.className}>{props.t('FAILED_LOADING_PDF')}</span>
    );
};

MspxPdfError.propTypes = {
    className: PropTypes.any,
    t: PropTypes.func.isRequired,
};

MspxPdfError.defaultProps = {
    className: '',
};

export default translate(MspxPdfError);
