import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { MspxPdfDocument, Page } from './mspx-pdf-document-component';
import { useDebounce } from '@mspecs/shared-utils';
import { useIntersectionObserverPDF, useTranslate } from '@mspecs/shared-utils';

const CenteredDocument = styled(MspxPdfDocument)`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .react-pdf__Page__textContent {
        border-bottom: ${({ noBorderStyle, theme }) =>
            noBorderStyle ? '' : `solid 12px ${theme.colors.bgSecondary}`};
        height: ${({ noBorderStyle }) => (noBorderStyle ? '0px' : '2px')};
    }
    .react-pdf__Page__textContent div {
        opacity: 0.5;
    }
    .react-pdf__Page__textContent div::selection {
        background-color: #8ab6fc;
    }
    .react-pdf__Page__textContent span {
        display: none;
    }
    .react-pdf__Page__textContent br {
        display: none;
    }
    .react-pdf__Page__annotations {
        display: none;
        height: 2px;
    }
`;

const CenteredPage = styled(Page)`
    margin: 0px auto;
    border: ${({ customStyle }) =>
        customStyle?.border ? customStyle.border : ''};
    margin-bottom: ${({ customStyle }) => (customStyle?.border ? '8' : '0')}px;
`;

const FooterOverlay = styled.div`
    position: sticky;
    bottom: 10px;
    padding: 5px 10px;
    align-items: center;
    align-self: center;
    gap: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    background: ${({ theme }) => theme.colors.bgSecondary};
`;

// solution for display page number in pdf - https://github.com/wojtekmaj/react-pdf/issues/632
function PageWithObserver({ pageNumber, setPageVisibility, ...rest }) {
    const [page, setPage] = useState();

    const onIntersectionChange = useCallback(
        ([entry]) => {
            setPageVisibility(pageNumber, entry.isIntersecting);
        },
        [pageNumber, setPageVisibility]
    );

    useIntersectionObserverPDF(page, { threshold: 0 }, onIntersectionChange);

    return (
        <CenteredPage canvasRef={setPage} pageNumber={pageNumber} {...rest} />
    );
}

const PdfDocument = ({
    pdfUrl,
    pageWidth,
    customStyle,
    showPageNumberOverlay,
}) => {
    const [numberOfPages, setNumberOfPages] = useState(null);
    const [visiblePages, setVisiblePages] = useState({});
    const { t } = useTranslate();

    const debouncedUrl = useDebounce(pdfUrl, 1000);
    const listOfPages = useMemo(
        () => [...Array(numberOfPages || 0)],
        [numberOfPages]
    );

    const onDocLoaded = useCallback(e => {
        setNumberOfPages(e.numPages);
    });

    const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
        setVisiblePages(prevVisiblePages => ({
            ...prevVisiblePages,
            [pageNumber]: isIntersecting,
        }));
    }, []);

    return (
        <CenteredDocument
            file={debouncedUrl}
            onLoadSuccess={onDocLoaded}
            noBorderStyle={!!customStyle}
        >
            {listOfPages.map((_, index) => (
                <PageWithObserver
                    width={pageWidth ? pageWidth : 800}
                    key={index}
                    pageNumber={index + 1}
                    customStyle={customStyle}
                    setPageVisibility={setPageVisibility}
                />
            ))}
            {!!showPageNumberOverlay && (
                <FooterOverlay>
                    {t('PDF_PAGE')}&nbsp;
                    {Object.entries(visiblePages)
                        .filter(([key, value]) => value)
                        .map(([key]) => key)
                        .slice(-1)}{' '}
                    &nbsp; / {numberOfPages}
                </FooterOverlay>
            )}
        </CenteredDocument>
    );
};

PdfDocument.propTypes = {
    pdfUrl: PropTypes.string.isRequired,
};

export default React.memo(PdfDocument);
