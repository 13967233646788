import {
    GET_COMMUNICATE_COMMUNICATION_SUCCESS,
    GET_COMMUNICATE_COMMUNICATIONS_SUCCESS,
    GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS,
    TOGGLE_GDPR_MODULE,
    SHOW_GDPR_MODULE,
} from '../actions/communicate-old-actions';

const initialState = {
    flaggedGdprContacts: {
        EMAIL: {
            count: 0,
            contactIds: [],
        },
        LETTER: {
            count: 0,
            contactIds: [],
        },
        SMS: {
            count: 0,
            contactIds: [],
        },
    },
    showGdprCommunicate: false,
    onCloseFunc: null,
    showGdprEmailAndSms: false,
    organizationName: null,
    communications: [],
    communication: {
        contactGroups: [],
        methods: [],
        numContacts: 0,
        emailId: null,
        smsOutboxId: null,
        senderEmails: [],
        contacts: [],
    },
};

export const communicate = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_GDPR_MODULE:
            const {
                contactIds,
                senderEmails,
                onCloseFunc = null,
                organizationName = null,
                showGdprEmailAndSms,
            } = action.payload;
            return {
                ...state,
                showGdprCommunicate: !state.showGdprCommunicate,
                communication: {
                    ...state.communication,
                    contacts: contactIds,
                    senderEmails,
                    methods: [],
                },
                onCloseFunc,
                organizationName,
                showGdprEmailAndSms,
            };
        case SHOW_GDPR_MODULE:
            return {
                ...state,
                showGdprCommunicate: true,
            };
        case GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS:
            return {
                ...state,
                flaggedGdprContacts: {
                    ...state.flaggedGdprContacts,
                    ...action.payload,
                },
            };
        case GET_COMMUNICATE_COMMUNICATION_SUCCESS:
            return {
                ...state,
                communication: action.payload,
            };
        case GET_COMMUNICATE_COMMUNICATIONS_SUCCESS:
            return {
                ...state,
                communications: [...state.communications, ...action.payload],
            };
        default:
            return state;
    }
};
