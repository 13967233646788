import { ACTION_TYPES } from '../actions/country-actions';

export default function countries(state = {}, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default:
            return state;
    }
}
