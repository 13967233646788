import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Flex } from '@rebass/grid/emotion';

const CloseButton = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textSecondary};
`;
const CloseButtonContainer = styled(Flex)`
    position: absolute;
    top: 0;
    right: 0;
    height: 64px;
    width: 64px;
    z-index: 2;
    justify-content: center;
    align-items: center;

    display: none;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: flex;
    }
`;

const ModalCloseButton = ({ onClose, ...props }) => {
    return (
        <CloseButtonContainer {...props}>
            <CloseButton onClick={onClose} icon={faXmark} size="xl" />
        </CloseButtonContainer>
    );
};

ModalCloseButton.propTypes = {
    onClose: PropTypes.func,
};

ModalCloseButton.defaultProps = {
    onClose: () => {},
};

export default ModalCloseButton;
