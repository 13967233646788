import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import React from 'react';
import PropTypes from 'prop-types';
import MspxPdfLoader from './mspx-pdf-loader-component';
import MspxPdfError from './mspx-pdf-error-component';

const _MspxPdfDocument = props => (
    <Document
        loading={<MspxPdfLoader className={props.loadingClassName} />}
        error={<MspxPdfError className={props.errorClassName} />}
        {...props}
    >
        {props.children}
    </Document>
);

_MspxPdfDocument.propTypes = {
    loadingClassName: PropTypes.any,
    errorClassName: PropTypes.any,
    children: PropTypes.node,
};

_MspxPdfDocument.defaultProps = {
    children: null,
    loadingClassName: '',
    errorClassName: '',
};

const MspxPdfDocument = React.memo(_MspxPdfDocument);

export { Page, MspxPdfDocument };
