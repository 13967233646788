import { useCallback, useEffect, useRef } from 'react';

export function triggerOnBlur(onBlurFun, isVisible, disable = false) {
    const ref = useRef(null);

    const handleHideDropdown = event => {
        if (isVisible && event.key === 'Escape') {
            onBlurFun();
        }
    };

    const handleClickOutside = useCallback(
        event => {
            if (
                isVisible &&
                ref.current &&
                !ref.current.contains(event.target) &&
                !disable
            ) {
                onBlurFun(event);
            }
        },
        [isVisible, ref, disable, onBlurFun]
    );

    const removeEventListeners = () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
    };

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
            document.addEventListener('click', handleClickOutside, true);
        } else {
            removeEventListeners();
        }
        return removeEventListeners;
    }, [isVisible, handleClickOutside]);
    return ref;
}
