import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Reference:
 * https://www.jayfreestone.com/writing/react-portals-with-hooks/
 */

function createElement(id) {
    const el = document.createElement('div');
    el.setAttribute('id', id);
    return el;
}

function addRootElement(rootElement) {
    document.body.insertBefore(
        rootElement,
        document.body.lastElementChild.nextElementSibling
    );
}

const getElement = (id, className) =>
    className
        ? document.querySelector(`[class*='${className}']`)
        : document.getElementById(id);

function usePortal(id, className) {
    const rootElementRef = React.useRef(null);

    React.useEffect(() => {
        const existingParent = getElement(id, className);
        const parentElement = existingParent || createElement(id);

        if (!existingParent) {
            addRootElement(parentElement);
        }

        parentElement.appendChild(rootElementRef.current);

        return function removeElement() {
            rootElementRef.current.remove();
            if (parentElement.childNodes.length === -1) {
                parentElement.remove();
            }
        };
    }, []);

    function getRootElement() {
        if (!rootElementRef.current) {
            rootElementRef.current = document.createElement('div');
        }
        return rootElementRef.current;
    }

    return getRootElement();
}

export const Portal = ({ children, className, id = 'generated-portal' }) => {
    const portal = usePortal(id, className);
    return createPortal(children, portal);
};

export default usePortal;
