import React from 'react';
import { FormInputField } from '../form';

const ERRORMESSAGE = 'YEAR_INPUT_VALIDATION_ERROR_MSG';

const yearValidator = value => {
    if (!value) return;
    if (String(value).length !== 4) {
        return ERRORMESSAGE;
    }

    const valueAsNum = Number(value);

    if (isNaN(valueAsNum)) return ERRORMESSAGE;

    if (valueAsNum < 1000 || valueAsNum > 3000) {
        return ERRORMESSAGE;
    }

    return;
};

const YearInput = props => {
    const validateProps = props.isFormField
        ? {
              validate: value => {
                  const error = yearValidator(value);
                  return error || props.validate?.(value);
              },
          }
        : {};
    return (
        <FormInputField
            name="year"
            placeholder="YEAR_FORMAT_PLACEHOLDER"
            validationFun={yearValidator}
            inputWidth="small"
            {...props}
            mask="year"
            {...validateProps}
        />
    );
};

export default YearInput;
