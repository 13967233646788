import React from 'react';
import {
    rankWith,
    isNumberControl,
    isIntegerControl,
    or,
} from '@jsonforms/core';
import {
    getCellProps,
    isDecimalLast,
    parseToNumber,
    withInputControlHoc,
} from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { NumberInput } from '../../../input';

function NumberCell(props) {
    const { path, schema, handleChange } = props;
    const { minimum, maximum, type } = schema;
    const baseProps = {
        ...getCellProps(props),
        onChange: number => handleChange(path, number === '' ? null : number),
        mask: 'number',
    };

    let decimalProps = {
        allowDecimal: type === 'number',
        decimalSymbol: ',',
    };

    if (decimalProps.allowDecimal) {
        decimalProps = {
            ...decimalProps,
            parse: val => {
                if (!val) return null;
                return isDecimalLast(val) ? val : parseToNumber(val);
            },
        };
    }

    return (
        <NumberInput
            {...baseProps}
            min={minimum}
            max={maximum}
            allowNegative={minimum < 0}
            {...decimalProps}
        />
    );
}

NumberCell.propTypes = CellPropType;

export const tester = rankWith(2, or(isNumberControl, isIntegerControl));

export default { renderer: withInputControlHoc(NumberCell), tester };
