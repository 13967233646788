import React from 'react';
import { css } from '@emotion/react';
import { Checkbox } from '../../input';

export const useSelectable = checkboxPositionIndex => {
    return hooks => {
        hooks.visibleColumns.push(columns => {
            if (columns.some(column => column.id === 'selection'))
                return columns;

            columns.splice(checkboxPositionIndex, 0, {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <Checkbox {...getToggleAllRowsSelectedProps()} />
                    </div>
                ),
                width: 44,
                css: theme => css`
                    width: 44px;
                    max-width: 44px;
                    min-width: 44px;
                    background-color: ${theme.colors.bgPrimary};
                `,
                disableSort: true,
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                    <div>
                        <Checkbox {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
                sticky: checkboxPositionIndex === 0 ? 'left' : 'right',
            });

            return [...columns];
        });
    };
};
