import React from 'react';
import { wrapDisplayName } from 'recompose';
import { useTranslate } from '@mspecs/shared-utils';

export const withValidationWrapper = WrappedComponent => {
    const WithValidationWrapper = props => {
        const { validationPttrn, validationFun, errorMessage, ...rest } = props;
        return (
            <ValidationWrapper
                required={props.required}
                errorMessage={errorMessage}
                validationFun={validationFun}
                validationPttrn={validationPttrn}
                type={rest.type}
                allowFalseCheckbox={rest.allowFalseCheckbox}
            >
                {validate => <WrappedComponent validate={validate} {...rest} />}
            </ValidationWrapper>
        );
    };
    WithValidationWrapper.displayName = wrapDisplayName(
        WrappedComponent,
        'withValidation'
    );
    return WithValidationWrapper;
};

function isEmpty(value) {
    return value == null || value.length === 0;
}

/**
 * Composes a validation function from the passed props,
 * and passes it down to a render prop
 */
function ValidationWrapper({
    children,
    validationPttrn,
    validationFun,
    errorMessage,
    required,
    type,
    allowFalseCheckbox = false,
}) {
    const { t } = useTranslate();

    const validate = (...args) => {
        const [value] = args;
        if (!required && isEmpty(value)) {
            return;
        }
        if (
            required &&
            (type === 'checkbox'
                ? (value === false && !allowFalseCheckbox) ||
                  value === undefined
                : isEmpty(value))
        ) {
            return t('VALIDATION_WRAPPER_REQUIRED');
        }

        if (value && validationPttrn) {
            // Reset lastIndex to 0 so it check string from the beginning
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex

            validationPttrn.lastIndex = 0;
            if (!validationPttrn.test(value)) {
                return t(errorMessage) || 'Validation error';
            }
        }

        if (validationFun) {
            let validationFunctionResult;
            if (Array.isArray(validationFun)) {
                validationFunctionResult = validationFun
                    .map(fn => fn(...args))
                    .filter(x => x)[0];
            } else {
                validationFunctionResult = validationFun(...args);
            }

            if (validationFunctionResult) {
                return typeof validationFunctionResult === 'string'
                    ? t(validationFunctionResult)
                    : t(errorMessage || 'Validation error');
            }
        }

        return null;
    };

    return children(validate);
}

export default ValidationWrapper;
