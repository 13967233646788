import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { LoginContainer, InfoText } from './styled-components';
import { rem } from '@mspecs/shared-utils';
import { useTranslate } from '@mspecs/shared-utils';

const SubHeadline = styled.h2`
    font-size: 1.4em;
    font-weight: 500;
    color: #222;
    margin-bottom: ${rem(8)};
`;

const OrderConfirmation = () => {
    const { t } = useTranslate();
    return (
        <LoginContainer
            topHeadline={t('ORDERING')}
            headline={t('MSPECS_REAL_ESTATE_SYSTEM')}
            register
        >
            <Box mx={[0, 30]}>
                <SubHeadline>{t('THANK_YOU_FOR_YOUR_ORDER')}</SubHeadline>
                <InfoText>
                    {t(
                        'YOUR_ORDER_WILL_BE_PROCESSED_MANUALLY_NEW_ACCOUNTS_WILL_BE_ACTIVATED_ON_WORKDAYS_BETWEEN_NINE_AND_FIVE'
                    )}
                </InfoText>
                <Box mt={14}>
                    <InfoText>
                        {t(
                            'YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT_IS_ACTIVATED'
                        )}
                    </InfoText>
                </Box>
            </Box>
        </LoginContainer>
    );
};

export default OrderConfirmation;
