import React, { useMemo } from 'react';
import { Flex } from '@rebass/grid/emotion';

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslate } from '@mspecs/shared-utils';
import { StyledPopover } from '../../../popovers/mspx-popover-component';
import {
    Header5Text,
    HelpingTextMedium,
} from '../../../typograph/typograph-components';

export const STEP_STATES = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    DISABLED: 'DISABLED',
};

const StepCount = styled(HelpingTextMedium)`
    width: 24px;
    height: 24px;
    background: ${({ theme, state }) => {
        if (state === STEP_STATES.ACTIVE) return theme.colors.primaryColor;
        if (state === STEP_STATES.COMPLETED) return theme.colors.successColor;
        return theme.colors.textSecondary;
    }};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: ${({ theme }) => theme.spacing.default};
`;

const StepContainer = styled(Flex)`
    align-items: center;
`;

export default function WizStep({ label, stepCount, state, onClick }) {
    const { t } = useTranslate();
    const cursorStyle = useMemo(
        () =>
            state === STEP_STATES.DISABLED
                ? 'not-allowed'
                : state === STEP_STATES.ACTIVE
                ? 'initial'
                : 'pointer',
        [state]
    );

    return (
        <StyledPopover
            content={t('VERTICAL_WIZ_STEP_DISABLED_TOOLTIP')}
            enabled={state === STEP_STATES.DISABLED}
        >
            <StepContainer
                onClick={state === STEP_STATES.DISABLED ? undefined : onClick}
                style={{ cursor: cursorStyle }}
            >
                <StepCount state={state}>
                    {state === STEP_STATES.COMPLETED ? (
                        <FontAwesomeIcon icon={faCheck} />
                    ) : (
                        stepCount
                    )}
                </StepCount>
                <Header5Text>{label}</Header5Text>
            </StepContainer>
        </StyledPopover>
    );
}

WizStep.propTypes = {
    label: PropTypes.string,
    stepCount: PropTypes.number,
    state: PropTypes.oneOf(Object.values(STEP_STATES)),
    onClick: PropTypes.func,
};
