import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import Button, { BUTTON_TYPES } from '../../button-component';
import { HighlightedBodyTextBold } from '../../../typograph/typograph-components';

const ButtonText = styled(HighlightedBodyTextBold)`
    color: inherit;
`;

const StyledButton = styled(Button)`
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.textPrimary : theme.colors.textSecondary};
    position: relative;
    gap: ${({ theme }) => theme.spacing.small};

    :after {
        content: '';
        height: 4px;
        background: ${({ theme, isSelected }) =>
            isSelected ? theme.colors.primaryColor90 : theme.colors.gray100};
        width: 100%;
        position: absolute;
        bottom: -5px;
        opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
        transition: opacity 0.2s;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    :hover {
        text-decoration: none;
        :after {
            opacity: 1;
        }
    }
`;

const OptionsContainer = styled(Flex)`
    gap: ${({ theme }) => theme.spacing.large};
    padding-bottom: 5px; // to make space for the bottom border
`;

const IconWrapper = styled(Flex)`
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
`;

const TabOptions = ({ options, selectedValue, setSelectedValue, ...props }) => {
    return (
        <OptionsContainer {...props}>
            {options.map(({ value, label, icon }) => (
                <StyledButton
                    key={value}
                    buttonType={BUTTON_TYPES.TEXT}
                    onClick={() => setSelectedValue(value)}
                    isSelected={selectedValue === value}
                >
                    {icon && <IconWrapper>{icon}</IconWrapper>}
                    <ButtonText>{label}</ButtonText>
                </StyledButton>
            ))}
        </OptionsContainer>
    );
};

export default TabOptions;

TabOptions.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            icon: PropTypes.node,
        })
    ).isRequired,
    selectedValue: PropTypes.string,
    setSelectedValue: PropTypes.func.isRequired,
};
