import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useElementSize } from '@mspecs/shared-utils';
import { StyledToggleIcon, ToggleMenuItem } from './content-menu-component';
import { useTranslate } from '@mspecs/shared-utils';
import SubMenuItem from './content-menu-sub-item-component';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { Flex } from '@rebass/grid/emotion';
import { HeightTransition } from '../../animations';

const SubMenuContainer = styled.div`
    ${({ theme }) => theme.mediaQueries.isAboveMobile} {
        border-bottom: 2px solid ${({ theme }) => theme.colors.bgSecondary140};
        border-top: 2px solid ${({ theme }) => theme.colors.bgSecondary140};
        padding-top: 5px;
    }
`;

const SubItemsContainer = styled.div`
    background: ${({ theme }) => theme.colors.bgPrimary};
    ${({ theme }) => theme.mediaQueries.isAboveMobile} {
        background: ${({ theme }) => theme.colors.primaryColor5};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
    }
`;

const SubItemsHeader = styled(Flex)`
    display: none;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textSecondary};
    padding: 0 0 5px 0;
    margin: 0 0 5px 0;
    ${({ theme }) => theme.mediaQueries.isAboveMobile} {
        display: flex;
        text-align: center;
        justify-content: center;
    }
`;

const SubItemsSubHeader = styled(SubItemsHeader)`
    display: flex;
    padding-left: 10px;
    ${({ theme }) => theme.mediaQueries.isAboveMobile} {
        padding-left: 0;
    }
`;

const SubItems = ({ subNavItems, currentMainItemLabel, subTitle }) => {
    const [showSubItems, setShowSubItems] = useState(false);

    const [subItemsRef, { height: subItemsHeight }] = useElementSize();
    const { t } = useTranslate();
    const handleToggleSubItems = useCallback(() => {
        setShowSubItems(prevShowSubItems => !prevShowSubItems);
    }, []);

    return (
        <>
            {currentMainItemLabel && (
                <SubItemsHeader>{currentMainItemLabel}</SubItemsHeader>
            )}
            <SubMenuContainer>
                {subTitle && <SubItemsSubHeader>{subTitle}</SubItemsSubHeader>}
                {subNavItems?.map(item => {
                    return item.type === 'select' ? (
                        <div key={item.label}>
                            <ToggleMenuItem onClick={handleToggleSubItems}>
                                {t(item.label)}
                                <StyledToggleIcon
                                    icon={faAngleUp}
                                    toggled={showSubItems ? 'true' : 'false'}
                                    size="lg"
                                />
                            </ToggleMenuItem>
                            <HeightTransition
                                on={showSubItems}
                                height={`${subItemsHeight}px`}
                            >
                                {transitionProps => (
                                    <div {...transitionProps}>
                                        <SubItemsContainer ref={subItemsRef}>
                                            {item.children.map(child => (
                                                <SubMenuItem
                                                    to={child.path}
                                                    key={child.label}
                                                    className={
                                                        child.isTitle &&
                                                        'non-active'
                                                    }
                                                >
                                                    {child.isTitle ? '' : '-'}{' '}
                                                    {t(child.label)}
                                                </SubMenuItem>
                                            ))}
                                        </SubItemsContainer>
                                    </div>
                                )}
                            </HeightTransition>
                        </div>
                    ) : (
                        <SubMenuItem key={item.path} to={item.path}>
                            {t(item.label)}
                        </SubMenuItem>
                    );
                })}
            </SubMenuContainer>
        </>
    );
};

SubItems.propTypes = {
    subNavItems: PropTypes.array,
    currentMainItemLabel: PropTypes.string,
};

export default SubItems;
