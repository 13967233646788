import { ACTIONS } from '../../actions/esignature/esignature-settings-actions';

const initialState = {
    initLoad: false,
    working: false,
    isError: false,
    error: null,
};

const defaultState = {
    isError: false,
    working: false,
    initLoad: false,
};

export default function esignatureSettings(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.ESIGNATURE_SETTINGS_GET:
            return {
                ...state,
                initLoad: true,
            };

        case ACTIONS.ESIGNATURE_SETTINGS_UPDATE:
            return {
                ...state,
                working: true,
            };

        case ACTIONS.ESIGNATURE_SETTINGS_ERROR: {
            const error = action.payload;
            return {
                ...state,
                isError: true,
                working: false,
                error,
            };
        }

        case ACTIONS.ESIGNATURE_SETTINGS_GET_SUCCESS:
        case ACTIONS.ESIGNATURE_SETTINGS_UPDATE_SUCCESS: {
            const { esignatureSettings } = action.payload;

            return {
                ...state,
                ...defaultState,
                settings: {
                    ...esignatureSettings,
                },
            };
        }

        default:
            return state;
    }
}
