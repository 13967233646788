/* eslint-disable no-unused-vars */
const SET_CURRENT_DEAL_ID = 'SET_CURRENT_DEAL_ID';

export default function deal(state = {}, action) {
    switch (action.type) {
        case SET_CURRENT_DEAL_ID:
            // Naive merge strategy assuming normalized newer input
            return {
                ...state,
                currentDealId: action.payload,
            };
        default:
            return state;
    }
}
