import React from 'react';
import { rankWith, isStringControl } from '@jsonforms/core';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { TextInput } from '../../../input';

function StringCell(props) {
    const { path, data, handleChange, schema } = props;

    const baseProps = {
        ...getCellProps(props),
        maxLength: schema.maxLength,
        value: data || '',
        onChange: e => handleChange(path, e.target.value),
    };

    return <TextInput {...baseProps} autoComplete="off" stackedLabel />;
}
StringCell.propTypes = CellPropType;

export const tester = rankWith(2, isStringControl);

export default { renderer: withInputControlHoc(StringCell), tester };
