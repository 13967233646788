import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES, Button } from '../../button';
import { Footer } from '../../layout';

const LeftMostButton = styled(Button)`
    margin-left: 25px;
`;

const StyledFooter = styled(Footer)`
    padding: 15px 0 0 0;
    height: unset;
    min-height: unset;
`;

const FullScreenModalFooter = ({
    onClose,
    onRemove,
    onSave,
    saveLabel = 'SAVE',
}) => {
    const { t } = useTranslate();
    const [removeActive, setRemoveActive] = useState(false);

    return (
        <StyledFooter>
            <Flex>
                {onRemove ? (
                    <>
                        {removeActive ? (
                            <>
                                <Button
                                    onClick={onRemove}
                                    buttonType={BUTTON_TYPES.DANGER}
                                >
                                    {t('REMOVE')}
                                </Button>
                                <LeftMostButton
                                    onClick={() => setRemoveActive(false)}
                                >
                                    {t('CANCEL')}
                                </LeftMostButton>
                            </>
                        ) : (
                            <Button
                                onClick={() => setRemoveActive(true)}
                                buttonType={BUTTON_TYPES.DANGER}
                            >
                                {t('REMOVE')}
                            </Button>
                        )}
                    </>
                ) : null}
            </Flex>

            <Flex>
                <Button onClick={onClose} buttonType={BUTTON_TYPES.TEXT}>
                    {t('CLOSE')}
                </Button>
                {onSave ? (
                    <LeftMostButton
                        onClick={onSave}
                        buttonType={BUTTON_TYPES.PRIMARY}
                    >
                        {t(saveLabel)}
                    </LeftMostButton>
                ) : null}
            </Flex>
        </StyledFooter>
    );
};

FullScreenModalFooter.propTypes = {
    onClose: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    onSave: PropTypes.func,
    saveLabel: PropTypes.string,
};

export default FullScreenModalFooter;
